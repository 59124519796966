import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import Reducer from './src/screens/redux/Reducers';
import login from './auth/reducer';
import snackbar from './common/snackbar/reducer';
import loader from './common/loader/reducer';
import placement from './pages/Placement/reducer';
import trainer from './pages/Trainer/reducer';
import student from './pages/Students/reducer';
import courses from './pages/Course/reducer';
import tickets from './pages/HelpandSupport/reducer';
import dashboard from './pages/Dashboard/reducer'
import assignment from './pages/Assignment/reducer'
import discussion from './pages/Discussion/reducer';
import attendance from './pages/Attendance/reducer';
import classes from './pages/Classes/reducer'

const persistConfig = {
  key: 'root',
  storage,
}

//import banner from "./src/screens/Home/Reducers";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
  snackbar,
  loader,
  login,
  placement,
  trainer,
  student,
  courses,
  tickets,
  dashboard,
  assignment,
  discussion,
  attendance,
  classes
});

const persistedReducer = persistReducer(persistConfig, reducer)
const configureStore = (initialState) => createStoreWithMiddleware(persistedReducer, initialState);
const store = configureStore();
// const persistor = persistStore(store)
// let store = createStore(persistedReducer)
let persistor = persistStore(store)
export default { store, persistor }

