import React, { Component } from "react";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import first from "../pages/Images/first.png";
import second from "../pages/Images/second.png";
import third from "../pages/Images/third.png";
// import { TextArea } from 'semantic-ui-react';
import { Textarea } from "theme-ui";
// import river from "../../Images/river.mp4";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
// import Third from "../../Images/third.png"
import Calender from "../pages/Images/Vector (1).png";

// import "../styles/Studashboard.scss";
import { Box, Button, Dialog, Divider, Grid, Typography, DialogTitle, DialogContent } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import AddIcon from "@mui/icons-material/Add";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import TimelineIcon from "@mui/icons-material/Timeline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VideocamIcon from "@mui/icons-material/Videocam";
import PersonIcon from "@mui/icons-material/Person";
import { AiOutlineCalendar } from "react-icons/ai";
import CardActions from "@mui/material/CardActions";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
// import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import PersonIcon from "@mui/icons-material/Person";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import img1 from "../../../admin/Recordedclass/Component/Images/online-event.jpg"
// import img1 from "../../Images/online-event.jpg";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import Textarea from "@mui/joy/Textarea/Textarea";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardHeader from "@mui/material/CardHeader";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Avatar from "@mui/joy/Avatar";
import AvatarGroup from "@mui/joy/AvatarGroup";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import moment from "moment";
import "../pages/Accdash.scss"
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Pagination from '@mui/material/Pagination';

import {
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  // DialogTitle,
  // DialogContent,
  DialogActions
} from "@mui/material";


const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
  width: "80%",
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#4C6FFF" : "#4C6FFF",
  },
}));
const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  width: "80%",
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#FBBC28" : "#FBBC28",
  },
}));
const BorderLinearProgress3 = styled(LinearProgress)(({ theme }) => ({
  width: "80%",
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#FF7373" : "#FF7373",
  },
}));

export default class Studashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'Placement',
      addticket: false,
      tab1: false,
      tab2: false,
      type: "Trainer",
      any_url: "",
      page: 0,
      setPage: 0,
      rowsPerPage: 10,
      setRowsPerPage: "",
      anchorEl: null,
      open: false,
      name: "",
      ticket_id: "",
      statuss: "Completed",
      viewticket: false,
      completeticket: false,
      noticeviewmodal: false,
      noticeviewmodaladmin: false,
      assigementModalOpen: false,
      openStudntDetailsForm: false,
      modalclosed: false,
      data: [
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "notsubmit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "notsubmit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "notsubmit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "notsubmitunsplash",
        },
      ],
      datas: [
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: " PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "HTML",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "HTML",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images..com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "CSS",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: " With PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full StackP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "volopement With PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack  With PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },

      ],



      message: "",
      subject: [
        {
          _id: "1724r3ytr83w7er934weyr843",
          subject_name: "Html",
        },
        {
          _id: "1724r3ytr83w7er934weyr843",
          subject_name: "Html",
        },
        {
          _id: "1724r3ytr83w7er934weyr843",
          subject_name: "Html",
        },
        {
          _id: "1724r3ytr83w7er934weyr843",
          subject_name: "Html",
        },
        {
          _id: "1724r3ytr83w7er934weyr843",
          subject_name: "Html",
        },
        {
          _id: "1724r3ytr83w7er934weyr843",
          subject_name: "Html",
        },
        {
          _id: "1724r3ytr83w7er934weyr843",
          subject_name: "Html",
        },
        {
          _id: "1724r3ytr83w7er934weyr843",
          subject_name: "Html",
        },
        {
          _id: "1724r3ytr83w7er934weyr843",
          subject_name: "Html",
        },
        {
          _id: "1724r3ytr83w7er934weyr843",
          subject_name: "Html",
        },
        {
          _id: "1724r3ytr83w7er934weyr843",
          subject_name: "Html",
        },
      ],
      dics: [
        {
          name: "Subhasmita Karna",
          desc: "Lorem ipsum dolor sit amet. Sed neque velit eos fuga dolores estculpa alias aut quia cupiditate aut reiciendis voluptatum autfugit illum qui omnis eaque. Aut iure pariatur quo voluptatem corrupti ut totam voluptatum a voluptatum itaque ut atque dolores",
          time: "6 hours ago",
          reply: "Reply",
        },
        {
          name: "Subhasmita Karna",
          desc: "Lorem ipsum dolor sit amet. Sed neque velit eos fuga dolores estculpa alias aut quia cupiditate aut reiciendis voluptatum autfugit illum qui omnis eaque. Aut iure pariatur quo voluptatem corrupti ut totam voluptatum a voluptatum itaque ut atque dolores",
          time: "6 hours ago",
          reply: "Reply",
        },
        {
          name: "Subhasmita Karna",
          desc: "Lorem ipsum dolor sit amet. Sed neque velit eos fuga dolores estculpa alias aut quia cupiditate aut reiciendis voluptatum autfugit illum qui omnis eaque. Aut iure pariatur quo voluptatem corrupti ut totam voluptatum a voluptatum itaque ut atque dolores",
          time: "6 hours ago",
          reply: "Reply",
        },
      ],


      // placement
      setRowsPerPage: "",
      job_Profile: "N",
      placestddata: [
        {
          companyname: "ABCD Company",
          companyemail: "abcd@abcd.com",
          companywebsite: "ABCD.com",
        },
        {
          companyname: "ABCD Company",
          companyemail: "abcd@abcd.com",
          companywebsite: "ABCD.com",
        },
        {
          companyname: "ABCD Company",
          companyemail: "abcd@abcd.com",
          companywebsite: "ABCD.com",
        },
      ],
      appliedstddata: [
        {
          companyname1: "ABCD Company",
          profile1: "UI/UX Design Internship",
          appliedon: "20 Feb",
          applicationstatus: "Not Joined",
        },
        {
          companyname1: "ABCD Company",
          profile1: "UI/UX Design Internship",
          appliedon1: "20 Feb",
          applicationstatus1: "Not Selected",
        },
        {
          companyname1: "ABCD Company",
          profile1: "UI/UX Design Internship",
          appliedon1: "20 Feb",
          applicationstatus1: "Hired",
        },
        {
          companyname1: "ABCD Company",
          profile1: "UI/UX Design Internship",
          appliedon1: "20 Feb",
          applicationstatus1: "Applied",
        },
      ]

    };
  }



  handleTabClick = (tabName) => {
    this.setState({ selectedTab: tabName });
  };

  render() {
    const handleChangePlacementStudent = (event, newPage) => {
      this.props.viewAllCompany(newPage - 1, this.state.rowsPerPage)
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };
    return (
      <center>
      <div style={{ marginTop: "8%" }}>


        {/* <Accordion  sx={{ width: "300px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer
            sx={{ width: "300px",  marginTop: "1%" }}
            component={Paper}
          >
            <Table className="trow thead" aria-label="simple table">
              <TableHead style={{ backgroundColor: "#eeecfe" }}>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    Name{" "}
                  </TableCell>

                  <TableCell style={{ textAlign: "center" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="trow" style={{ backgroundColor: "" }}>
                {this.state.spaceaccdata.map((item) => {
                  return (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell style={{ textAlign: "center" }}>
                        {item.Name}
                      </TableCell>

                      <TableCell style={{ textAlign: "center" }}>
                        <center>
                          <Tooltip>
                            <IconButton>
                              <ModeEditIcon
                                onClick={() => {
                                  this.setState({ edituser: true });
                                }}
                                style={{ color: "#e975a8" }}
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip>
                            <IconButton>
                              <DeleteIcon
                                onClick={() => {
                                  this.setState({ deletespace: true });
                                }}
                                style={{
                                  color: "red",
                                  fontWeight: "800",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                         
                        </center>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion> */}
        <div className="task-nav">
          <Box className="completed1">
            <div className="tasknavtopaccessSection1">
              <div id="access-icon" className="icon1" style={{ marginLeft: "-10px" }}>
                <TrendingUpIcon style={{ width: "42px", height: "20px", marginTop: "10px" }} />
              </div>
              <div className="task-nav-line-space">
                <h2>
                  1
                  {/* {(courses.student_course.length) - (dashboard.in_progress_length)}/{courses.student_course.length} */}
                </h2>
                <h6 style={{ color: "var(--Paragraph, #767278)" }}>Courses in progress</h6>
              </div>
            </div>
            <div className="bottomTaskNav">
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "18px",
                  justifyContent: "space-between",
                  paddingInlineStart: "0px",
                  marginBlock: "12px",
                  marginInline: "7px",
                }}
              >
                <BorderLinearProgress2
                  variant="determinate"
                  // value={((courses.student_course.length) - (dashboard.in_progress_length)) / (courses.student_course.length) * 100}
                  style={{
                    width: "150px",
                    marginLeft: "13%",
                    color: "FBBC28",
                    marginTop: "2%",
                  }}
                />
                30%
                {/* {parseInt(((courses.student_course.length) - (dashboard.in_progress_length)) / (courses.student_course.length) * 100)}% */}
              </Box>
            </div>
          </Box>


        </div>



        <div className="task-nav" style={{ marginTop: "2%" }}>
          <Box className="completed1">
            <div className="tasknavtopaccessSection1">
              <div id="tick-icon" className="icon1">
                <center>
                  <ArticleOutlinedIcon style={{ width: "42px", height: "20px", marginTop: "10px" }} /></center>
              </div>
              <div className="task-nav-line-space">
                <h2>
                  7/10
                  {/* {(courses.student_course.length) - (dashboard.in_progress_length)}/{courses.student_course.length} */}
                </h2>
                <h6 style={{ color: "var(--Paragraph, #767278)" }}>Assignment Completed</h6>
              </div>
            </div>
            <div className="bottomTaskNav">
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "18px",
                  justifyContent: "space-between",
                  paddingInlineStart: "0px",
                  marginBlock: "12px",
                  marginInline: "7px",
                }}
              >
                <BorderLinearProgress2
                  variant="determinate"
                  // value={((courses.student_course.length) - (dashboard.in_progress_length)) / (courses.student_course.length) * 100}
                  style={{
                    width: "150px",
                    marginLeft: "13%",
                    color: "FBBC28",
                    marginTop: "2%",
                  }}
                />
                10%
                {/* {parseInt(((courses.student_course.length) - (dashboard.in_progress_length)) / (courses.student_course.length) * 100)}% */}
              </Box>
            </div>
          </Box>


        </div>



        <div className="task-nav" style={{ marginTop: "2%" }}>
          <Box className="completed1">
            <div className="tasknavtopaccessSection1">
              <div id="hours-icon" className="icon1" style={{ marginLeft: "-40px" }}>

                <ArticleOutlinedIcon style={{ width: "42px", height: "20px", marginTop: "10px" }} />
              </div>
              <div className="task-nav-line-space">
                <h2>
                  20hr
                  {/* {(courses.student_course.length) - (dashboard.in_progress_length)}/{courses.student_course.length} */}
                </h2>
                <h6 style={{ color: "var(--Paragraph, #767278)" }}>Total Subjects</h6>
              </div>
            </div>
            <div className="bottomTaskNav">
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "18px",
                  justifyContent: "space-between",
                  paddingInlineStart: "0px",
                  marginBlock: "12px",
                  marginInline: "7px",
                }}
              >
                <BorderLinearProgress2
                  variant="determinate"
                  // value={((courses.student_course.length) - (dashboard.in_progress_length)) / (courses.student_course.length) * 100}
                  style={{
                    width: "150px",
                    marginLeft: "13%",
                    color: "FBBC28",
                    marginTop: "2%",
                  }}
                />
                10%
                {/* {parseInt(((courses.student_course.length) - (dashboard.in_progress_length)) / (courses.student_course.length) * 100)}% */}
              </Box>
            </div>
          </Box>


        </div>




        <Card style={{ width: "300px", marginTop: "2%" }}>
          <div style={{ padding: "20px" }}>
            <div>
              <Typography style={{ fontSize: "13px" }} fontWeight={"400"}>
                full stack
                {/* {dashboard.schedule_by_trainer !== undefined && (dashboard.schedule_by_trainer.course_name)}.. */}
              </Typography>
              <div style={{ padding: "10px", color: "#828282" }}>
                <Grid container>
                  <Grid item xs={4} display={"flex"}>
                    <CalendarMonthIcon style={{ fontSize: "1rem" }} />
                    {/* {moment(dashboard.schedule_by_trainer !== undefined && dashboard.schedule_by_trainer.date).format('dddd, D MMM , YYYY')} */}
                  </Grid>
                  <Grid item xs={4} display={"flex"}>
                    <AccessTimeIcon style={{ fontSize: "1rem" }} />
                    {/* {dashboard.schedule_by_trainer !== undefined && dashboard.schedule_by_trainer.time} - {dashboard.schedule_by_trainer !== undefined && dashboard.schedule_by_trainer.end_time} */}
                  </Grid>
                </Grid>
              </div>
              <div style={{ marginBottom: "20px", fontSize: "13px" }}>
                Redirect zoom :-
                <Button
                  variant="contained"
                  // onClick={() => {
                  //   console.log((moment(dashboard.schedule_by_trainer.date + " " + dashboard.schedule_by_trainer.end_time)).isBefore(moment()))
                  //   this.props.add_attendance(this.props.login.user_id)
                  //   window.location.href = dashboard.schedule_by_trainer.class_link
                  // }}
                  style={{
                    width: "90px", fontSize: "10px", marginLeft: "20px",
                    backgroundColor: "#0000B3",
                  }}
                >
                  Join class
                </Button>
              </div>
            </div>
          </div>
          <div>


          </div>
        </Card>


        <Card style={{ width: "300px", marginTop: "2%", height: "auto" }}>
          <div style={{ padding: "20px" }}>
            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ fontSize: "13px" }} fontWeight={"500"}>Recorded class</Typography>
              <Link style={{ textDecoration: "none" }} to="/StudentcourseDetails">

                <Typography style={{ fontSize: "13px" }} color={"black"}>View All</Typography>

              </Link>
            </span>
            <Typography style={{ fontSize: "13px", marginTop: "2%" }}>Recently uploaded HTML Class Video</Typography>


            {/* for horizontal scrolll */}
            {/* display: "flex", flexDirection: "row", overflowX: "scroll", width: "95%", gap: 30,  */}
            <Grid
              //  style={{display: "flex", flexDirection: "row", overflowX: "scroll", width: "300px", gap: 30,}}
              container
              padding={0.5}
              spacing={2}
            >
              {/* {Array.isArray(dashboard.record_details) && dashboard.record_details.map((item, index) => {
                  return ( */}
              <Grid item xs={4}>
                <Card sx={{ maxWidth: 250, marginBottom: "20px", borderRadius: 4, marginTop: "4%" }}>
                  <CardMedia
                    component="video"
                    height={150}
                    // width={200}
                    controls
                  // image={item.record_image}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {/* {item.session} */}
                    </Typography>
                    <Typography>
                      {/* {item.title} */}
                    </Typography>
                  </CardContent>
                  <Typography style={{ marginLeft: "2%" }} className="courseduration-student">

                    <img
                      src={Calender}
                      style={{ marginRight: "5px", marginLeft: "15px" }}
                    />
                    {/* {item.date} */}
                  </Typography>
                </Card>
              </Grid>
              {/* );
                })} */}
            </Grid>
          </div>
        </Card>





        {/* //notice board........................................... */}




        <Card className="" style={{ height: "auto", marginTop: "3%", width: "300px" }}>
          <div style={{ display: "flex", justifyContent: "", }}>
            <div className="side-notification-text" style={{ fontSize: "13px", width: "60%" }}>Notice Board</div>

            <div className="flex-line">

              <Paper
                component="form"
                style={{
                  borderRadius: "8px",
                  border: "0.5px solid #D2D2D2",
                  display: "flex",
                  marginLeft: "5px",
                  width: "100px",
                  height: "30px",
                }}
              >
                <IconButton
                  type="button"
                  style={{ p: "100px" }}
                  aria-label="search"
                >
                  <SearchIcon style={{ fontSize: "15px" }} />
                </IconButton>
                <InputBase
                  style={{ ml: 5, flex: 1, fontSize: "15px" }}
                  // placeholder="Search here"
                  inputProps={{ "aria-label": "Search here" }}
                // onChange={(e) => this.props.searchNotice(dashboard.notices, e.target.value)}
                />
              </Paper>
            </div>
          </div>

          <div style={{ overflowY: "scroll", height: "305px" }}>
            {/* {dashboard.search_notice.length == "0" && */}
            <Grid style={{ textAlign: "center", marginTop: "20px", fontSize: "13px", }}>
              <Typography>No Result Found</Typography>
            </Grid>
            {/* } */}
            {/* {Array.isArray(dashboard.search_notice) && dashboard.search_notice.map((item) => {
                  return ( */}
            <div className="noticeBoardContent">
              <Typography className="noticeBoardContentHeader">
                {/* {item.course_details !== undefined && item.course_details.course_name}<br /> */}
                <Typography style={{ fontWeight: 400 }} >
                  {/* {item.batch_details.batch_number} */}
                </Typography></Typography>
              <div className="noticeBoardContentBottom">
                <Typography style={{ marginLeft: "10px", fontSize: "12px", fontWeight: 400, color: "var(--secondary, #0000B3)" }}>
                  {/* {item.date} */}
                </Typography>
                <Button
                  onClick={() => {
                    this.setState({
                      noticeviewmodaladmin: true,
                      //     notice_id: item._id,
                      //     course_name: item.course_details.course_name,
                      //     batch_number: item.batch_details.batch_number,
                      //     desc: item.desc,
                      //     schedule: item.schedule

                    });
                  }}
                  style={{
                    color: "#0000B3",
                    fontSize: "12px",
                    marginTop: "-10px"
                  }}
                >
                  View
                </Button>
              </div>
            </div>
            {/* );
                })} */}
          </div>
        </Card>





        <Card className="" style={{ height: "auto", marginTop: "3%", width: "300px" }}>
          <div className="top">
            <div className="side-notification-text" style={{ marginBottom: "10px", fontSize: "13px" }}>Assignment</div>
          </div>

          {/* {Array.isArray(dashboard.student_assignment_length) && dashboard.student_assignment_length.map((item, index) => {
                return ( */}
          <div style={{ padding: "5px 15px 5px 15px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ flexDirection: "column" }}>
                <Typography style={{ fontWeight: 450 }}>
                  {/* {item.title} */}
                </Typography>
                <Typography style={{ fontWeight: 350, fontSize: "12px", color: "#9E9E9E" }}>
                  {/* {this.state.status !== "In-Completed" ? "Due " : "Submited"}
                          {moment(item.due_date).format('D MMM')} */}
                </Typography>
              </span>
              <Link to="/studentAssignmentdetails">
                <Button

                  // disabled={this.state.state == "Completed"}
                  style={this.state.status == "In-Completed" ?
                    {
                      backgroundColor: "gray",
                      color: "white",
                      width: "80px",
                      height: "25px",
                      fontSize: "10px",


                    } : {
                      backgroundColor: "#0000B3",
                      color: "white",
                      width: "80px",
                      height: "25px",
                      fontSize: "10px",


                    }}>
                  {/* {this.state.status == "In-Completed" ? "Submited" : "Submit"} */}

                  Submit
                </Button>
              </Link>
            </div>
            <ul>
              <li>
                {/* {item.course_code} */}
              </li>
            </ul>
          </div>
          {/* );
              })} */}
        </Card>







        {/* Dicussion................................................. */}




        <Grid style={{ marginTop: "3%", height: "auto", width: "300px" }}>
          <Card style={{ margin: "1% 2.5% 2.5% 0%" }}>
            <Typography style={{ color: "black", fontSize: "15px", margin: "1% 0% 0% 2%", fontWeight: 450 }}>
              Discussion
            </Typography>
            <div style={{ border: "1px solid #D6D6D6", margin: "20px" }}>
              {/* {Array.isArray(this.props.discussion.discussion_details) && this.props.discussion.discussion_details.map((item) => {
                            return ( */}
              <div >
                <div style={{ display: "flex", flexdirection: "row", padding: "15px" }}>
                  <Avatar
                    // alt={item.user_details.name} src={item.user_details.profile_pic}
                    style={{ width: 40, height: 40 }} >
                  </Avatar>
                  <Typography style={{ marginLeft: "2%" }}><span style={{ fontSize: "13px", fontWeight: 500 }}>
                    {/* {item.user_details.name} */}
                  </span>
                    <span style={{ color: "#0000B3", fontSize: "13px", }}>( Student )</span></Typography>
                </div>
                <Typography
                  style={{
                    color: "#222",
                    marginLeft: "25px"
                  }}>
                  {/* {item.message} */}
                </Typography>
                <Typography
                  style={{
                    marginLeft: "25px",
                    color: "gray",
                    fontSize: "14px"
                  }}
                >
                  {/* {moment().startOf(item.date).fromNow()} */}
                </Typography>
                <Divider sx={{ background: "#EEEEEE", padding: "0.2px", margin: "10px", borderBottom: "none" }} />
              </div>
              {/* );
                        })} */}
            </div>
            <Divider style={{ width: "100%" }} />
            <Grid container style={{ padding: "20px 0px 20px 0px", height: "auto", width: "300px" }} >

              <TextField
                multiline={4}
                // fullWidth
                style={{ marginLeft: "25px", width: "150px", }}
                placeholder="Type here…"
                variant="outlined"
              // onChange={(text) =>
              //     this.setState({ message: text.target.value })}

              />



              <Button
                variant="contained"
                style={{
                  backgroundColor: "#0000B3",
                  width: "60px",
                  fontSize: "13px",
                  height: "30px",
                  marginLeft: "5%"
                }}
              // onClick={() => {
              //     this.setState({ addDiscussion: false, message: "" });
              //     this.props.addDiscussion(this.props.login.user_id, this.state.message)
              //     console.log("dis", this.props.login.user_id, this.state.message)

              // }}
              >
                Send
              </Button>
            </Grid>


          </Card>
          {/* <LoaderCon /> */}
          {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
        </Grid>




        {/* //Help & support/......................................................... */}


        <Grid style={{ marginTop: "3%", width: "300px", }}>
          <Typography variant="h6" style={{ color: "black", margin: "1% 0% 0% 2%", fontWeight: 450 }}>Help & Support</Typography>
          <Card style={{ margin: "1% 2.5% 2.5% 0%", padding: "20px" }}>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "2%", marginRight: "2%", }}>
              <Typography style={{ fontSize: "20px" }} fontWeight={500}>Tickets</Typography>
              <Button
                onClick={() => {
                  this.setState({
                    addticket: true,
                  });
                }}
                style={{
                  borderRadius: "5px",
                  border: "1px solid var(--secondary, #0000B3)",
                  height: "2.25rem",
                  marginBottom: "1%",
                  backgroundColor: "#0000B3",
                  fontSize: "13px"
                }}
                variant="contained"
              >
                + Raise Ticket
              </Button>
            </div>
            {/* {this.state.trainerStudentSetionOpenOff ? ( */}
            <TableContainer style={{ marginTop: "4%" }}>
              <Table className='hori' style={{ display: "flex", flexDirection: "row", overflowX: "scroll", width: "300px", gap: 30, }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F8F7F7", }}>
                    <TableCell style={{ fontSize: "12px", textAlign: "center", color: "#766C6C" }}>
                      NAME
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", textAlign: "center", color: "#766C6C" }}>
                      DATE
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", textAlign: "center", color: "#766C6C" }}>
                      COMPLAIN
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", textAlign: "center", color: "#766C6C" }}>
                      STATUS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {tickets.search_ticket.length == "0" && */}
                  {/* <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                      <Typography>No Result Found</Typography>
                    </Grid> */}
                  {/* } */}
                  {/* {Array.isArray(tickets.search_ticket) && tickets.search_ticket.map((item, index) => {
                    return ( */}
                  <>
                    {/* {item.user_details.type == "S" && */}
                    <TableRow style={{ textDecoration: "none" }} className="table-row">
                      <TableCell
                        style={{ color: "#5F5A5A", fontSize: "12px", letterSpacing: 1 }}
                        align="center"
                      >
                        {/* {item.user_details.name} */}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#5F5A5A", fontSize: "12px", letterSpacing: 1
                        }}
                        align="center"
                      >
                        {/* {item.date} */}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#5F5A5A", fontSize: "12px", letterSpacing: 1
                        }}
                        align="center"
                      >
                        {/* {item.message} */}
                      </TableCell>
                      {/* {item.status == "Completed" &&  */}
                      <TableCell
                        style={{
                          color: "#5F5A5A", fontSize: "12px", letterSpacing: 1, cursor: "pointer"
                        }}
                        align="center"
                        onClick={() => {
                          this.setState({
                            viewticket: true,
                            // ticket_id: item._id,
                            // name: item.user_details.name,
                            // date: item.date,
                            // complain: item.message,

                          })
                        }}
                      >
                        Completed
                      </TableCell>
                      {/* } */}
                      {/* {item.status == "InCompleted" &&  */}
                      <TableCell
                        style={{
                          color: "red", fontSize: "12px", letterSpacing: 1, cursor: "pointer"
                        }}
                        align="center"
                        onClick={() => {
                          this.setState({
                            completeticket: true,
                            // ticket_id: item._id,
                            // name: item.user_details.name,
                            // date: item.date,
                            // complain: item.message,
                          })
                        }}
                      >
                        Incompleted
                      </TableCell>
                      {/* } */}

                    </TableRow>
                    {/* } */}
                  </>

                  {/* );
                  })} */}

                </TableBody>
              </Table>
            </TableContainer>
            {/* ) : ( */}
            <TableContainer>
              <Table className='hori' style={{ display: "flex", flexDirection: "row", overflowX: "scroll", width: "300px", gap: 30, }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F8F7F7", }}>
                    <TableCell style={{ fontSize: "12px", textAlign: "center", color: "#766C6C" }}>
                      NAME
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", textAlign: "center", color: "#766C6C" }}>
                      DATE
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", textAlign: "center", color: "#766C6C" }}>
                      COMPLAIN
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", textAlign: "center", color: "#766C6C" }}>
                      STATUS
                    </TableCell>
                  </TableRow>
                </TableHead>


                <TableBody>
                  {/* {tickets.search_ticket.length == "0" && */}
                  {/* <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                      <Typography>No Result Found</Typography>
                    </Grid> */}
                  {/* }
                  {Array.isArray(tickets.search_ticket) && tickets.search_ticket.map((e, index) => {
                    return ( */}
                  <>
                    {/* {e.user_details.type == "T" && */}
                    <TableRow style={{ textDecoration: "none" }}>
                      <TableCell
                        style={{ color: "#5F5A5A", fontSize: "12px", letterSpacing: 1 }}
                        align="center"
                      >
                        {/* {e.user_details.name} */}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#5F5A5A", fontSize: "12px", letterSpacing: 1
                        }}
                        align="center"
                      >
                        {/* {e.date} */}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#5F5A5A", fontSize: "12px", letterSpacing: 1
                        }}
                        align="center"
                      >
                        {/* {e.message} */}
                      </TableCell>
                      {/* {e.status == "Completed" &&  */}
                      <TableCell
                        style={{
                          color: "#5F5A5A", fontSize: "12px", letterSpacing: 1, cursor: "pointer"
                        }}
                        align="center"
                        onClick={() => {
                          this.setState({
                            viewticket: true,
                            // ticket_id: e._id,
                            // name: e.user_details.name,
                            // date: e.date,
                            // complain: e.message,

                          })
                        }}
                      >
                        Completed
                      </TableCell>
                      {/* } */}
                      {/* {e.status == "InCompleted" &&  */}
                      <TableCell
                        style={{
                          color: "red", fontSize: "12px", letterSpacing: 1, cursor: "pointer"
                        }}
                        align="center"
                        onClick={() => {
                          this.setState({
                            completeticket: true,
                            // ticket_id: e._id,
                            // name: e.user_details.name,
                            // date: e.date,
                            // complain: e.message,
                          })
                        }}
                      >
                        Incompleted
                      </TableCell>

                      {/* } */}

                    </TableRow>
                    {/* } */}
                  </>
                  {/* );
                  })} */}
                </TableBody>
              </Table>
            </TableContainer>
            {/* )} */}
            <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
              {/* <Pagination
              count={parseInt(tickets.ticket_length / 10) + 1}
              siblingCount={0}
              size="small"
              onChange={handleChangePage}
            /> */}
            </div>
          </Card>


          {/*------------------------------------------------ Dialog ---------------------------------------------------------*/}

          {/*Mark as Complete*/}
          <Dialog open={this.state.completeticket} maxWidth="sm" fullWidth
            sx={{
              // 👇 Another option to style Paper
              "& .MuiDialog-paper": {
                borderRadius: "10px",
              },
            }}
          >
            <Grid>
              <DialogTitle style={{ marginBottom: "1.5%" }}>
                <Grid container>
                  <Grid item xs={11}>
                    <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                      Complaint
                    </Typography>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Grid container >
                  <Grid item xs={1.5}>

                  </Grid>
                  <Grid item xs={9} direction='column'>
                    <TextField
                      fullWidth
                      required
                      value={this.state.name}
                      sx={{
                        borderRadius: "5px",
                      }}
                    />
                    <TextField
                      fullWidth
                      sx={{
                        borderRadius: "5px",
                        marginTop: 3,
                      }}
                      value={this.state.date}
                    >
                    </TextField>
                    <Textarea
                      size="lg"
                      required
                      fullWidth
                      sx={{
                        height: "150px",
                        marginTop: 3,
                      }}
                      name="Outlined"
                      value={this.state.complain}
                    />
                    <DialogActions style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 3, marginTop: 10 }} >
                      <Button
                        variant="outlined"
                        style={{
                          fontSize: "15px",
                          height: "38px",
                          width: "120px",
                          color: "#0000B3",
                          border: "1px solid #0000B3",
                        }}
                        onClick={() => {
                          this.setState({ completeticket: false });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#0000B3",
                          fontSize: "15px",
                          height: "38px",
                          width: "160px",
                          color: "white",
                          marginLeft: "2%",

                        }}
                        onClick={() => {
                          this.setState({ completeticket: false })
                          this.props.updateTicketStatusByTicketID(this.state.ticket_id, this.state.statuss, this.state.page, this.state.rowsPerPage)
                        }}
                      >
                        Mark as complete
                      </Button>
                    </DialogActions>
                  </Grid>
                  <Grid item xs={1.5}>

                  </Grid>
                </Grid>
              </DialogContent>

            </Grid>
          </Dialog>

          {/*View*/}
          <Dialog open={this.state.viewticket} maxWidth="sm" fullWidth
            sx={{
              // 👇 Another option to style Paper
              "& .MuiDialog-paper": {
                borderRadius: "10px",
              },
            }}
          >
            <Grid>
              <DialogTitle style={{ marginBottom: "1.5%" }}>
                <Grid container>
                  <Grid item xs={11}>
                    <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                      Complaint
                    </Typography>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Grid container >
                  <Grid item xs={1.5}>

                  </Grid>
                  <Grid item xs={9} direction='column'>
                    {/* <TextField
                    fullWidth
                    required
                    value={this.state.name}
                    sx={{
                      borderRadius: "5px",
                    }}
                  /> */}
                    <TextField
                      fullWidth
                      sx={{
                        borderRadius: "5px",
                        marginTop: 3,
                      }}
                      value={this.state.date}
                    >
                    </TextField>
                    <Textarea
                      size="lg"
                      required
                      fullWidth
                      sx={{
                        height: "150px",
                        marginTop: 3,
                      }}
                      name="Outlined"
                      value={this.state.complain}
                    />
                    <DialogActions style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 3, marginTop: 10 }} >
                      <Button
                        variant="outlined"
                        style={{
                          fontSize: "15px",
                          height: "38px",
                          width: "120px",
                          color: "#0000B3",
                          border: "1px solid #0000B3",
                        }}
                        onClick={() => {
                          this.setState({ viewticket: false });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#0000B3",
                          fontSize: "15px",
                          height: "38px",
                          width: "120px",
                          color: "white",
                          marginLeft: "2%",

                        }}
                        onClick={() => {
                          this.setState({ viewticket: false })
                        }}
                      >
                        Okay
                      </Button>
                    </DialogActions>
                  </Grid>
                  <Grid item xs={1.5}>

                  </Grid>
                </Grid>
              </DialogContent>

            </Grid>
          </Dialog>
          {/* <LoaderCon /> */}
          {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}




          {/* //helpand support Modal...................... */}


          <Dialog open={this.state.addticket}
            // maxWidth="sm" fullWidth
            sx={{
              // 👇 Another option to style Paper
              "& .MuiDialog-paper": {
                borderRadius: "10px",
                height: "auto", width: "300px"

              },
            }}>
            <Grid>
              <DialogTitle style={{ marginBottom: "1.5%" }}>
                <Grid container>
                  <Grid item xs={11}>
                    <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                      Complain
                    </Typography>
                  </Grid>
                  <Grid item xs={1} justifyContent={"center"}>
                    <CloseOutlinedIcon
                      onClick={() => {
                        this.setState({ addticket: false });
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Grid container >
                  <Grid item xs={1.5}>
                  </Grid>
                  <Grid item xs={9} direction={'column'}>

                    <Textarea
                      size="lg"
                      fullWidth
                      placeholder="Enter your complain"
                      sx={{
                        height: "150px",
                        marginTop: 3,
                        backgroundColor: "white",
                        border: "1px solid #ACACAC",
                        borderRadius: "5px",
                      }}
                    // onChange={(e) =>
                    //   this.setState({ message: e.target.value })}
                    />
                    <Box marginTop={3}>
                      {/* {this.state.any_url === "" ? */}
                      <Button fullWidth variant="outlined" component="label" style={{ color: "#c4c4c4", border: "1px solid #c4c4c4", height: "50px", justifyContent: "space-between" }}
                        endIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M16.5002 18.25H14.9902C14.5802 18.25 14.2402 17.91 14.2402 17.5C14.2402 17.09 14.5802 16.75 14.9902 16.75H16.5002C19.1202 16.75 21.2502 14.62 21.2502 12C21.2502 9.38 19.1202 7.25 16.5002 7.25H15.0002C14.5902 7.25 14.2502 6.91 14.2502 6.5C14.2502 6.09 14.5802 5.75 15.0002 5.75H16.5002C19.9502 5.75 22.7502 8.55 22.7502 12C22.7502 15.45 19.9502 18.25 16.5002 18.25Z" fill="#989898" />
                          <path d="M9 18.25H7.5C4.05 18.25 1.25 15.45 1.25 12C1.25 8.55 4.05 5.75 7.5 5.75H9C9.41 5.75 9.75 6.09 9.75 6.5C9.75 6.91 9.41 7.25 9 7.25H7.5C4.88 7.25 2.75 9.38 2.75 12C2.75 14.62 4.88 16.75 7.5 16.75H9C9.41 16.75 9.75 17.09 9.75 17.5C9.75 17.91 9.41 18.25 9 18.25Z" fill="#989898" />
                          <path d="M16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#989898" />
                        </svg>
                        }
                      >
                        {/* {this.state.document !== "" ? (this.state.document).name : "Attachment File"} */}
                        <input hidden type="file" multiple
                        // onChange={(e) => { this.setState({ document: e.target.files[0] }) }}
                        />
                      </Button>
                      :
                      <Button variant="outlined" component="label" style={{ color: "#c4c4c4", border: "1px solid #c4c4c4", height: "50px", flexDirection: "row-reverse", justifyContent: "space-between" }}
                        startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }}
                        />}>
                        {/* {this.state.document !== "" ? (this.state.document).name : "Attachment File"} */}
                      </Button>
                      {/* } */}
                    </Box>
                    <DialogActions style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 3, marginTop: 10 }} >
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#0000B3",
                          fontSize: "15px",
                          height: "38px",
                          width: "120px",
                          color: "white",
                          marginLeft: "2%",

                        }}
                      // onClick={() => {
                      //   this.setState({ addticket: false, document: "", message: "" })
                      //   this.props.uploadDocument(this.props.login.user_id, this.state.document, this.state.message, this.state.page, this.state.rowsPerPage)
                      //   console.log("sdfgh", this.props.login.user_id, this.state.document, this.state.message, this.state.page, this.state.rowsPerPage)
                      // }}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </DialogContent>


            </Grid>
          </Dialog>
        </Grid>







        {/* //placement....................................................... */}


        {/* <div style={{ display: "flex", flexDirection: "row" }}>
          <Link style={{ textDecoration: "none" }} to="/Company">
            <Button style={{ color: "#7A7E86" }}>Placement Company</Button>
          </Link>
          <ArrowForwardIosIcon
            style={{ fontSize: "20px", marginTop: "10px" }}
          />
          <Link style={{ textDecoration: "none" }} to="">
            <Button style={{ color: "black" }}>Company Name</Button>
          </Link>
        </div> */}



        <div className="Placementstudent1">
          <div className="tabs-container">
            <div
            // className={`tab ${selectedTab === 'Placement' ? 'active' : ''}`}
            // onClick={() => this.handleTabClick('Placement')}
            >
              Placement
            </div>
            <div
            // className={`tab ${selectedTab === 'Applied' ? 'active' : ''}`}
            // onClick={() => this.handleTabClick('Applied')}
            >
              Applied
            </div>



          </div>





          <div>
                    {/* {selectedTab === 'Placement' && */}
                     <div>
                        <div style={{ display: "flex" }}>

                        
                            <Paper
                                component="form"
                                style={{ display: "flex", marginLeft: "30px", width: "207px", height: "33px", backgroundColor: "#F8F7F7", boxShadow: "none", marginTop: 12, marginBottom: "1%" }}
                            >
                                <IconButton

                                    type="button"
                                    style={{ p: "15px" }}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    style={{ ml: 5, flex: 1, fontSize: "15px" }}
                                    placeholder="Search "
                                    inputProps={{ "aria-label": "Search " }}
                                    // onChange={(e) => this.props.searchCompany(placement.all_companys, e.target.value)}
                                />
                            </Paper>
                        </div>

                        <Table className="" style={{ display: "flex", flexDirection: "row", overflowX: "scroll", width:"300px"}}>
                            <TableHead  >
                                <TableRow>
                                    <TableCell  style={{ fontSize: "12px",  color: "#766C6C" }}>No</TableCell>
                                    <TableCell  style={{width:"200px", fontSize: "12px", color: "#766C6C" }}>Comapny Name</TableCell>
                                    <TableCell  style={{ fontSize: "12px",  color: "#766C6C" }}>Company Email</TableCell>
                                    <TableCell  style={{ fontSize: "12px", color: "#766C6C" }}>Company Website</TableCell>


                                </TableRow>
                            </TableHead>
                            {/* {Array.isArray(placement.search_company) && placement.search_company.map((item, index) => {
                                return ( */}
                                    <tbody>

                                        <Link to={"/companyselect"} style={{ textDecoration: "none", color: "#000" }} className="table-row"
                                            // onClick={() => {

                                            //     this.props.setPlacementCompanyName(item.company_name);
                                            //     this.props.setPlacementCompanyEmail(item.company_email);
                                            //     this.props.setCompanyId(item._id);
                                             
                                            // }}
                                        >

                                            {/* <td>{index + 1}</td>
                                            <td> {item.company_name}</td>
                                            <td>  {item.company_email}</td>
                                            <td>{item.company_website}</td> */}


                                        </Link>


                                    </tbody>
                                {/* );
                            })} */}



                        </Table>



                        <div className='page-button' style={{ display: "flex" }}>



                            {/* <Pagination
                                count={parseInt(this.props.placement.company_length / 10) + 1}
                                size="small"
                                style={{ marginLeft: "85%", marginTop: "3%" }}
                                onChange={handleChangePlacementStudent}
                            /> */}

                        </div>


                    </div>
                    {/* } */}
                    {/* {selectedTab === 'Applied' &&  */}
                    <div>

                        <div style={{ display: "flex" }}>

                            <div className="selectBox">
                           
                                <TextField
                                    style={{
                                        width: "155px",
                                        height: "36px",
                                        borderRadius: "5px",
                                        background: "#FFF",
                                        fontSize: "15px",
                                        marginLeft: "2%",
                                    }}
                                    id="outlined-select-currency-native"
                                    select
                                    // value={this.state.job_Profile}
                                    // onChange={(e) => {
                                    //     this.setState({ job_Profile: e.target.value });
                                    //     this.props.searchApplied(placement.applied_by_student, e.target.value, "course")
                                    // }}
                                >
                                    <MenuItem value={"N"}>{"job Profile"}
                                    </MenuItem>
                                    {/* {Array.isArray(this.props.placement.applied_by_student) && this.props.placement.applied_by_student.map((e) => (
                                        <MenuItem
                                         value={e._id}>{e.placement_details.job_profile}
                                        </MenuItem>
                                    ))} */}
                                </TextField>

                            </div>
                            <Paper
                                component="form"
                                style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "#F8F7F7", boxShadow: "none", marginTop: 12 }}
                            >
                                <IconButton

                                    type="button"
                                    style={{ p: "15px" }}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    style={{ ml: 5, flex: 1, fontSize: "15px" }}
                                    placeholder="Search "
                                    inputProps={{ "aria-label": "Search " }}
                                    // onChange={(e) => this.props.searchApplied(placement.applied_by_student, e.target.value)}
                                    // onChange={(e) =>
                                    //     this.props.searchApplied(placement.applied_by_student, e.target.value, "search"
                                    //     )}
                                />
                            </Paper>
                        </div>
                        <Table className="studentviewTablecontent" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Comapny Name</TableCell>
                                    <TableCell>Profile</TableCell>
                                    <TableCell>Applied On</TableCell>
                                    <TableCell>Application Status</TableCell>
                                </TableRow>
                            </TableHead>

                            {/* {Array.isArray(placement.search_applied) && placement.search_applied.map((item, index) => {
                                return ( */}
                                    {/* <tbody>
                                        <td>{index + 1}</td>
                                        <td>{item.company_details.company_name}</td>
                                        <td>{item.placement_details.job_profile}</td>
                                        <td>{item.placement_details.date}</td>
                                        <td>
                                            <Button style={{
                                                backgroundColor: item.status === 'Applied' ? 'blue' : item.status === 'Hired' ? '#62BC37' : item.status === ' Not Joined' ? '#788931' : 'red',
                                                border: item.status === 'Applied' ? 'blue' : item.status === 'Hired' ? '#62BC37' : item.status === ' Not Joined' ? '#788931' : 'red',
                                                color: "white",
                                                marginTop: "2%"
                                            }}
                                                variant="outlined" >

                                                {item.status}
                                            </Button>
                                        </td>






                                    </tbody> */}
                                {/* );
                            })} */}


                         

                        </Table>
                     





                    </div>
                </div>
             
          

        </div>



{/* studentcourse.......................................................... */}








        {/* ?dialog height width maybe change acc to the mobile screen..... */}

        <Dialog
          style={{ height: "auto", width: "400px" }}

          open={this.state.noticeviewmodaladmin}


        // maxWidth="sm" fullWidth
        >
          <Grid style={{ padding: "10px" }}>
            <DialogTitle>
              <Grid container>
                <Grid item xs={11} >
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => {
                    this.setState({ noticeviewmodaladmin: false });
                  }}>
                    <CloseOutlinedIcon style={{ color: "black", display: "flex", justifyContent: "right", fontSize: "15px", }} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} style={{ marginTop: "-25px" }}>
                  <Typography
                    style={{
                      fontSize: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: 500,
                    }}
                  >
                    Notice
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ flexDirection: "column", padding: "10px 50px 75px 50px" }}>
              <Grid container direction={"column"}>
                <Typography style={{ fontSize: "18px" }}>
                  <strong>{this.state.course_name}-({this.state.batch_number})</strong>
                </Typography>
                <Typography style={{ fontSize: "16px", color: "var(--paragraph, #767278)" }}>
                  {this.state.desc}
                </Typography>
              </Grid>
            </DialogContent>
          </Grid>
        </Dialog>









      </div>
      </center>
    )
  }
}
