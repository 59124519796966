import {
    //Placement
    PLACEMENTS,
    SET_APPLY_LINK,
    SET_LAST_DATE,
    SET_DESCRIPTION,

    //Company
    COMPANYS,
    SET_COMPANY_ID,
    SET_COMPANY_NAME,
    SET_COMPANY_EMAIL,
    SET_COMPANY_WEBSITE,
    SEARCH_COMPANY,
    VIEW_ALL_APPLIED,
    SET_PLACEMENT_ID,
    SET_JOB_PROFILE,
    VIEW_PLACEMENT_BY_STUDENT,
    SEARCH_PLACEMENT,
    VIEW_APPLIED_BY_STUDENT,
    SEARCH_APPLIED,
    SET_PLACEMENT_COMPANY_NAME,
    SET_PLACEMENT_COMPANY_EMAIL,
    SET_PLACEMENT_APPLY_LINK,
    SET_PLACEMENT_LAST_DATE,
    SET_PLACEMENT_JOB_PROFILE,
    SET_PLACEMENT_JOB_DESC,
    VIEW_APPLIED_STATUS_BY_STUDENT,
    SET_ALL_PLACEMENT_APPLY_LINK
} from "./constant";

const initial_state = {
    //Placement
    applied_status_by_student: false,
    all_placements: [],
    placement_length: 0,
    studentplacement_length: 0,
    applied_length: 0,
    studentApplied_length: 0,
    apply_link: '',
    last_date: '',
    description: '',

    //Company
    all_companys: [],
    company_length: 0,
    company_id: '',
    company_name: '',
    company_email: '',
    company_website: '',
    search_company: [],
    all_applied: [],
    placement_id: "",
    job_profile: '',
    search_placement: [],
    applied_by_student: [],
    search_applied: [],
    placement_comp_name: '',
    placement_comp_email: "",
    placement_apply_link: "",
    placement_last_date: "",
    placement_job_profile: "",
    placement_job_desc: "",
    all_placement_link: ""
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        //Placement
        case PLACEMENTS:
            console.log("allplac", action.payload.data)
            return {
                ...state,
                all_placements: action.payload.data, placement_length: action.payload.count, search_placement: action.payload.data
            };
        case SET_APPLY_LINK:
            return {
                ...state,
                apply_link: action.payload
            };
        case SET_LAST_DATE:
            return {
                ...state,
                last_date: action.payload
            };
        case SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload
            };


        //Company
        case COMPANYS:
            console.log("allcompany", action.payload.data)
            return {
                ...state,
                all_companys: action.payload.data, company_length: action.payload.count, search_company: action.payload.data
            };
        case SET_COMPANY_ID:
            console.log("company_id", action.payload)
            return {
                ...state,
                company_id: action.payload
            };
        case SET_COMPANY_NAME:
            return {
                ...state,
                company_name: action.payload
            };
        case SET_COMPANY_EMAIL:
            return {
                ...state,
                company_email: action.payload
            };
        case SET_COMPANY_WEBSITE:
            return {
                ...state,
                company_website: action.payload
            };


        case SEARCH_COMPANY:
            return {
                ...state,
                search_company: action.payload
            };

        // applied student

        case VIEW_ALL_APPLIED:
            return {
                ...state,
                all_applied: action.payload.data, applied_length: action.payload.count
            };

        case SET_PLACEMENT_ID:
            return {
                ...state,
                placement_id: action.payload
            };

        case SET_JOB_PROFILE:
            return {
                ...state,
                job_profile: action.payload
            };



        // ----------STUDENT-------------
        case VIEW_PLACEMENT_BY_STUDENT:
            console.log("viewplacementcompanyy", action.payload.data)
            return {
                ...state,

                all_placements_by_student: action.payload.data, search_placement: action.payload.data, studentplacement_length: action.payload.count
            };
           


        case SEARCH_PLACEMENT:
            return {
                ...state,
                search_placement: action.payload
            };

        case VIEW_APPLIED_BY_STUDENT:
            return {
                ...state,
                applied_by_student: action.payload.data, search_applied: action.payload.data, studentApplied_length: action.payload.count
            };
        case SEARCH_APPLIED:
            return {
                ...state,
                search_applied: action.payload
            };


        case SET_PLACEMENT_COMPANY_NAME:
            return {
                ...state,
                placement_comp_name: action.payload
            };

        case SET_PLACEMENT_COMPANY_EMAIL:
            return {
                ...state,
                placement_comp_email: action.payload
            };


        case SET_PLACEMENT_APPLY_LINK:
            return {
                ...state,
                placement_apply_link: action.payload
            };

        case SET_PLACEMENT_LAST_DATE:
            return {
                ...state,
                placement_last_date: action.payload
            };

        case SET_PLACEMENT_JOB_PROFILE:
            return {
                ...state,
                placement_job_profile: action.payload
            };

        case SET_PLACEMENT_JOB_DESC:
            return {
                ...state,
                placement_job_desc: action.payload
            };



        case VIEW_APPLIED_STATUS_BY_STUDENT:
            console.log("appliedstudentresult", action.payload)
            return {
                ...state,
                applied_status_by_student: action.payload
            };


        case SET_ALL_PLACEMENT_APPLY_LINK:
            console.log("one by one link", action.payload)
            return {
                ...state,
                all_placement_link: action.payload
            };


        default:
            return state;
    }
}
