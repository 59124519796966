import {
    //student
    STUDENTS,
    SEARCH_STUDENT,
    SET_STUDENT_ID,
    SET_STUDENT_IMAGE,
    SET_STUDENT_NAME,
    SET_PHONE,
    SET_EMAIL,

    //student deatils
    STUDENT_DETAIL,
    STUDENT_COURSES,
    STUDENT_ASSIGNMENT,
    STUDENT_PLACEMENT
} from "./constant";

const initial_state = {
    //student
    all_students: [],
    all_courses: [],
    search_student: [],
    student_length: 0,
    student_id: '',
    student_image: '',
    student_name: '',
    phone: '',
    email: '',

    //studentview
    student_details: [],
    student_course: [],
    course_length: 0,
    student_assignment: [],
    assignment_length: 0,
    student_placement: [],
    placement_length: 0,
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        //Student
        case STUDENTS:
            return {
                ...state,
                all_students: action.payload.data, search_student: action.payload.data, student_length: action.payload.count
            };
            
        case SEARCH_STUDENT:
            return {
                ...state,
                search_student: action.payload
            };
        case SET_STUDENT_ID:
            return {
                ...state,
                student_id: action.payload
            };
        case SET_STUDENT_IMAGE:
            return {
                ...state,
                student_image: action.payload
            };
        case SET_STUDENT_NAME:
            return {
                ...state,
                student_name: action.payload
            };
        case SET_PHONE:
            return {
                ...state,
                phone: action.payload
            };
        case SET_EMAIL:
            return {
                ...state,
                email: action.payload
            };

        //Studentview
        case STUDENT_DETAIL:
            return {
                ...state,
                student_details: action.payload
            };
        case STUDENT_COURSES:
            return {
                ...state,
                student_course: action.payload.data, course_length: action.payload.count
            };
        case STUDENT_ASSIGNMENT:
            return {
                ...state,
                student_assignment: action.payload.data, assignment_length: action.payload.count
            };
        case STUDENT_PLACEMENT:
            return {
                ...state,
                student_placement: action.payload.data, placement_length: action.payload.count
            };

        default:
            return state;
    }
}
