import React, { Component } from "react";
import { connect } from "react-redux";
import Student from "../Components/Students";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    uploadImageStudent,
    addStudent,
    viewAllStudent,
    searchStudent,
    studentId,
    studentImage,
    studentname,
    phone,
    email

} from "../action"

import {
    view_all_courses,
    view_all_batch,
    view_all_batch_by_course,
    viewBatchByCourse
} from "../../Course/action";
// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <Student {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        courses: store.courses,
        student: store.student,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,
        placement:store.placement
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        uploadImageStudent: (admin_id, profile_pic, name, phone, email, course_id, batch_id, joining_date, page_number, rowper_page,org_id) => {
            dispatch(uploadImageStudent(admin_id, profile_pic, name, phone, email, course_id, batch_id, joining_date, page_number, rowper_page,org_id));
        },
        addStudent: (admin_id, profile_pic, name, phone, email, course_id, batch_id, joining_date, page_number, rowper_page,org_id) => {
            dispatch(addStudent(admin_id, profile_pic, name, phone, email, course_id, batch_id, joining_date, page_number, rowper_page,org_id));
        },
        viewAllStudent: (page_number, rowper_page, org_id) => {
            dispatch(viewAllStudent(page_number, rowper_page, org_id));
        },
        searchStudent: (all_students, e, type) => {
            dispatch(searchStudent(all_students, e, type));
        },
        studentId: (payload) => {
            dispatch(studentId(payload));
        },
        studentImage: (payload) => {
            dispatch(studentImage(payload));
        },
        studentname: (payload) => {
            dispatch(studentname(payload));
        },
        phone: (payload) => {
            dispatch(phone(payload));
        },
        email: (payload) => {
            dispatch(email(payload));
        },

        view_all_courses: (page_number, rowper_page,org_id) => {
            dispatch(view_all_courses(page_number, rowper_page,org_id))
        },
        view_all_batch: (page_number, rowper_page) => {
            dispatch(view_all_batch(page_number, rowper_page))
        },

        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        view_all_batch_by_course: (course_id) => {
            dispatch(view_all_batch_by_course(course_id));
        },

        viewBatchByCourse: (course_id, page_number, rowper_page) => {
            dispatch(viewBatchByCourse(course_id, page_number, rowper_page));
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);