import { Button, Grid, Card, Avatar, Typography, CardContent, Table, TextField, TableContainer, TableBody } from '@mui/material'
import React, { Component } from 'react'
import { FaGreaterThan } from 'react-icons/fa'
import avatar from "../../Images/Avatar.png";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import "../../Placement/styles/Placement.scss";
import "../styles/Studentview.scss"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DoneIcon from '@mui/icons-material/Done';
import Pagination from '@mui/material/Pagination';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";

export default class Studenview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value1: "",
            value: 0,
            expandopen: "",
            countexp: 0,
            status: "",

            page: 0,
            setPage: 0,
            rowsPerPage: 10,
            setRowsPerPage: "",
            anchorEl: null,
            open: false,

            course: [
                {
                    course_name: "PG Program on Full Stack Devolopement with PHP or Python",
                    batch: "Batch-I",
                    start_date: "11-02-23",
                    status: "Ongoing",
                },
                {
                    course_name: "PG Program on Full Stack Devolopement with PHP or Python",
                    batch: "Batch-I",
                    start_date: "11-02-23",
                    status: "Ongoing",
                },
                {
                    course_name: "PG Program on Full Stack Devolopement with PHP or Python",
                    batch: "Batch-I",
                    start_date: "11-02-23",
                    status: "Ongoing",
                },
                {
                    course_name: "PG Program on Full Stack Devolopement with PHP or Python",
                    batch: "Batch-I",
                    start_date: "11-02-23",
                    status: "Ongoing",
                },
            ],

            assignment: [
                {
                    student_av: "a",
                    student_name: "Suman Mishra",
                    assignment: "Temperature converter website",
                    due_date: "11-02-23",
                    submitted: "Ongoing",
                    status: "Ongoing",
                    score: "04",
                },
                {
                    student_av: "b",
                    student_name: "Suman Mishra",
                    assignment: "Temperature converter website",
                    due_date: "11-02-23",
                    submitted: "Ongoing",
                    status: "Ongoing",
                    score: "03",
                },
                {
                    student_av: "c",
                    student_name: "Suman Mishra",
                    assignment: "Temperature converter website",
                    due_date: "11-02-23",
                    submitted: "Ongoing",
                    status: "Ongoing",
                    score: "01",
                },
                {
                    student_av: "d",
                    student_name: "Suman Mishra",
                    assignment: "Temperature converter website",
                    due_date: "11-02-23",
                    submitted: "Ongoing",
                    status: "Ongoing",
                    score: "02",
                },
            ],

            placement: [
                {
                    Company_name: "Suman Mishra",
                    profile: "UI/UX",
                    applied_on: "11-02-23",
                    status: "hired",
                },
                {
                    Company_name: "Suman Mishra",
                    profile: "UI/UX",
                    applied_on: "11-02-23",
                    status: "not selected",
                },
                {
                    Company_name: "Suman Mishra",
                    profile: "UI/UX",
                    applied_on: "11-02-23",
                    status: "not joined",
                },
                {
                    Company_name: "Suman Mishra",
                    profile: "UI/UX",
                    applied_on: "11-02-23",
                    status: "hired",
                },
            ],

        }
    }

    setIndex = (bkb, index) => {
        if (bkb > 0 && this.state.expandopen === index) {
            this.setState({ expandopen: "" })
        }
    }



    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
        if (newValue === 0) {
            // this.setState({ data: this.props.leads.all_client_details })
        }
    };

    componentDidMount() {
        document.body.style.backgroundColor = "#F9FDFE";
        this.props.viewStudentById(this.props.student.student_id, this.state.page, this.state.rowsPerPage);
        this.props.viewCourseByStudent(this.props.student.student_id, this.state.page, this.state.rowsPerPage);
        this.props.viewAssignmentByStudent(this.props.student.student_id, this.state.page, this.state.rowsPerPage);
        this.props.viewPlacementByStudent(this.props.student.student_id, this.state.page, this.state.rowsPerPage);
        console.log(this.props.student.student_placement)
    }

    render() {

        const { value } = this.state;
        const { student, snackbar, close_snack_bar } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const handleChangePage = (event, newPage) => {
            this.props.viewCourseByStudent(this.props.student.student_id, newPage - 1, this.state.rowsPerPage);
            this.props.viewAssignmentByStudent(this.props.student.student_id, newPage - 1, this.state.rowsPerPage);
            this.props.viewPlacementByStudent(this.props.student.student_id, newPage - 1, this.state.rowsPerPage);
            console.log(newPage)
            this.setState({
                page: newPage,
            });
        };
        return (
            <Grid style={{ marginTop: "8%" }}>
                <div style={{ display: "flex", flexDirection: "row", }}>
                    <Link style={{ textDecoration: "none" }} to="/Students">
                        <Button style={{ color: "#7A7E86" }}>
                            All Students

                        </Button>
                    </Link>
                    <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
                    <Link style={{ textDecoration: "none" }} to="">
                        <Button style={{ color: "black" }}>
                            Student Details

                        </Button>
                    </Link>

                </div>
                <Card style={{ margin: "1% 2.5% 2.5% 0%" }}>
                    <Grid container style={{ display: "flex", flexDirection: "row" }} spacing={2}>
                        <Grid item xs={2.2}>
                            <Avatar
                                alt={student.student_name}
                                src={student.student_image}
                                style={{ fontSize: "30px", backgroundColor: "#0000B3", marginLeft: 30, width: 120, height: 120, marginTop: 40 }} >
                            </Avatar>
                        </Grid>
                        <Grid item xs={9.8} >
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "5%" }}>
                                <Typography style={{ color: "#7A7E86" }}>Name : </Typography>
                                <Typography style={{ marginLeft: "1%" }}>
                                    {student.student_name}
                                </Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                                <Typography style={{ color: "#7A7E86" }}>Phone No : </Typography>
                                <Typography style={{ marginLeft: "1%" }}>
                                    {student.mobile}
                                </Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                                <Typography style={{ color: "#7A7E86" }}>Email ID : </Typography>
                                <Typography style={{ marginLeft: "1%" }}>
                                    {student.email}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <Box style={{ marginTop: "3%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs textColor="inherit"
                                indicatorColor="primary" value={value} onChange={this.handleChange} aria-label="basic tabs example" style={{ marginLeft: "20px", marginRight: "20px" }}>
                                <Tab style={{ fontSize: '16px' }} label="Details" />
                                <Tab style={{ fontSize: '16px' }} label="Courses" />
                                <Tab style={{ fontSize: '16px' }} label="Assignment" />
                                <Tab style={{ fontSize: '16px' }} label="Placement" />
                            </Tabs>
                        </Box>

                        {value === 0 && (
                            <div role="tabpanel" id="simple-tabpanel-0" aria-labelledby="simple-tab-0">


                                <CardContent>
                                    {Array.isArray(student.student_details) && student.student_details.map((item) => {
                                        return (
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                                                    <Typography style={{ color: "#7A7E86" }}>Institute : </Typography>
                                                    <Typography style={{ marginLeft: "1%" }}>
                                                        Skyy Rider Institution
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                                                        <Typography style={{ color: "#7A7E86" }}>Address : </Typography>
                                                        <Typography style={{ marginLeft: "1%" }}>
                                                            {item.registration_details !== undefined && item.registration_details.address}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                                                        <Typography style={{ color: "#7A7E86" }}>Joined Date : </Typography>
                                                        <Typography style={{ marginLeft: "1%" }}>
                                                            {item.joining_date !== undefined && item.joining_date}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", marginTop: "5%" }}>
                                                        <Grid container spacing={2} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <Grid item xs={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <Typography style={{ color: "#7A7E86" }}>Matric % : </Typography>
                                                                <Typography style={{ marginLeft: "1%" }}>
                                                                    {item.registration_details !== undefined && item.registration_details.qulification}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <Typography style={{ color: "#7A7E86" }}>Year of Passing : </Typography>
                                                                <Typography style={{ marginLeft: "1%" }}>
                                                                    {item.registration_details !== undefined && item.registration_details.year_of_passing}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <Typography style={{ color: "#7A7E86" }}>Percentage % : </Typography>
                                                                <Typography style={{ marginLeft: "1%" }}>
                                                                    {item.registration_details !== undefined && item.registration_details.percentage}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    {/* <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                                                        <Grid container spacing={2} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <Grid item xs={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <Typography style={{ color: "#7A7E86" }}>10+2 : </Typography>
                                                                <Typography style={{ marginLeft: "1%" }}>
                                                                    ABCD College
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <Typography style={{ color: "#7A7E86" }}>Year of Passing : </Typography>
                                                                <Typography style={{ marginLeft: "1%" }}>
                                                                    2020
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <Typography style={{ color: "#7A7E86" }}>Percentage % : </Typography>
                                                                <Typography style={{ marginLeft: "1%" }}>
                                                                    85%

                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </div> */}
                                                    {/* <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                                                        <Grid container spacing={2} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <Grid item xs={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <Typography style={{ color: "#7A7E86" }}>Graduation : </Typography>
                                                                <Typography style={{ marginLeft: "1%" }}>
                                                                    ABCD University
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <Typography style={{ color: "#7A7E86" }}>Year of Passing : </Typography>
                                                                <Typography style={{ marginLeft: "1%" }}>
                                                                    2023
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <Typography style={{ color: "#7A7E86" }}>Percentage % : </Typography>
                                                                <Typography style={{ marginLeft: "1%" }}>
                                                                    85%
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </div> */}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </CardContent>

                            </div>
                        )}
                        {value === 1 && (
                            <div role="tabpanel" id="simple-tabpanel-1" aria-labelledby="simple-tab-1">

                                <CardContent>
                                    <div>
                                        <TableContainer style={{ marginTop: "-1.5%", }}>
                                            <Table
                                                aria-label="a dense table"
                                            >
                                                <TableHead>
                                                    <TableRow style={{ backgroundColor: "#F8F7F7", }}>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                                                            Course Name
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                                                            Batch
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                                                            Start Date
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                                                            Status
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {Array.isArray(student.student_course) && student.student_course.map((item) => {
                                                        console.log("student sss",item.batch_details)
                                                        return (
                                                            <TableRow hover={true} className='classtable' style={{ textDecoration: "none", borderBottom: "1px solid #E3E3E3" }}
                                                                onClick={() => { this.props.navigate('/Studentview') }}
                                                            >
                                                                <TableCell
                                                                    style={{
                                                                        textAlign: "center",
                                                                        fontSize: "16px",
                                                                        letterSpacing: 1
                                                                    }}
                                                                >
                                                                    <Typography align="center" style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}>{item.course_details.course_name}</Typography>
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}
                                                                    align="center"
                                                                >
                                                                    {item.batch_details.batch_number !== undefined && item.batch_details.batch_number}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                                                                    }}
                                                                    align="center"
                                                                >
                                                                    {item.batch_details.start_date !== undefined && item.batch_details.start_date}
                                                                </TableCell>
                                                                <TableCell style={{ textAlign: "center", fontSize: "14px", color: "#7A7E86", alignItems: "center", letterSpacing: 1 }} >
                                                                    <span style={{ padding: "0.375rem 0.9375rem", borderRadius: "0.3125rem", background: "#C9FE9F" }}> <DoneIcon style={{ fontSize: "0.8125rem" }} />
                                                                    {item.batch_details.status !== undefined && item.batch_details.status}
                                                                    </span>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </div>
                                </CardContent>

                                <div style={{ display: "flex", justifyContent: "flex-end", margin: "0% 2% 2% 0%" }}>
                                    <Pagination
                                        count={parseInt(student.course_length / 10) + 1}
                                        siblingCount={0}
                                        size="small"
                                        onChange={handleChangePage}
                                    />
                                </div>
                            </div>
                        )}
                        {value === 2 && (
                            <div role="tabpanel" id="simple-tabpanel-2" aria-labelledby="simple-tab-2">
                                <CardContent >
                                    <div>
                                        <TableContainer style={{ marginTop: "-1.5%", }}>
                                            <Table
                                                aria-label="a dense table"
                                            >
                                                <TableHead >
                                                    <TableRow style={{ backgroundColor: "#F8F7F7", }}>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>S No.</TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>Assignment</TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>Due Date</TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>Submitted</TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>Status</TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>Score</TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>

                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {Array.isArray(student.student_assignment) && student.student_assignment.map((item, index) => {
                                                        console.log("aaaaaaaaaaaa",item)
                                                        return (
                                                            <TableRow hover={true} className='classtable' style={{ textDecoration: "none", borderBottom: "1px solid #E3E3E3" }}
                                                            >
                                                                <TableCell
                                                                    style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}
                                                                    align="center"
                                                                >
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}
                                                                    align="center"
                                                                >
                                                                    {item.title}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                                                                    }}
                                                                    align="center"
                                                                >
                                                                    {item.due_date}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        color: "#5F5A5A", fontSize: "15px", letterSpacing: 1, display: "flex", justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <Avatar
                                                                        alt={item.submit_details.attachment !== undefined && item.submit_details.attachment} src={item.submit_details.attachment !== undefined && item.submit_details.attachment}
                                                                        style={{ width: 30, height: 30 }} >
                                                                    </Avatar>
                                                                    <Typography style={{ fontWeight: "600", color: "#3679DC", whiteSpace: "nowrap", marginLeft: "8%" }}><a href={item.submit_details.attachment}><strong>attachment</strong></a> </Typography>
                                                                </TableCell>
                                                                <TableCell style={{ textAlign: "center", fontSize: "0.8125rem", color: "#0000B3", alignItems: "center", letterSpacing: 1 }} >
                                                                    <span style={{ padding: "0.1875rem 0.4375rem", borderRadius: "0.3125rem", background: "#91C8FB" }}>{item.status}</span>
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                                                                    }}
                                                                    align="center"
                                                                >
                                                                    {item.submit_details.score}
                                                                </TableCell>
                                                                <TableCell style={{ textAlign: "center", color: "#766C6C" }}>

                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </CardContent>
                                <div style={{ display: "flex", justifyContent: "flex-end", margin: "0% 2% 2% 0%" }}>
                                    <Pagination
                                        count={parseInt(student.assignment_length / 10) + 1}
                                        siblingCount={0}
                                        size="small"
                                        onChange={handleChangePage}
                                    />
                                </div>
                            </div>
                        )}
                        {value === 3 && (
                            <div role="tabpanel" id="simple-tabpanel-2" aria-labelledby="simple-tab-2">

                                <CardContent >
                                    <div>
                                        <TableContainer style={{ marginTop: "-1.5%", }}>
                                            <Table aria-label="a dense table">
                                                <TableHead >
                                                    <TableRow style={{ backgroundColor: "#F8F7F7", }}>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>No</TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>Company Name</TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>Profile</TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>Applied On</TableCell>
                                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>Application Status</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {Array.isArray(student.student_placement) && student.student_placement.map((item, index) => {
                                                        return (
                                                            <TableRow hover={true} className='classtable' style={{ textDecoration: "none", borderBottom: "1px solid #E3E3E3" }}
                                                            >
                                                                <TableCell
                                                                    style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}
                                                                    align="center"
                                                                >
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}
                                                                    align="center"
                                                                >
                                                                    {item.company_details.company_name}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                                                                    }}
                                                                    align="center"
                                                                >
                                                                    {item.placement_details.job_profile}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                                                                    }}
                                                                    align="center"
                                                                >
                                                                    {item.apply_date}
                                                                </TableCell>
                                                                <TableCell style={{ textAlign: "center", fontSize: "0.9375rem", alignItems: "center", letterSpacing: 1 }} >
                                                                    {item.status === "Hired" &&
                                                                        <span style={{ padding: "0.25rem 0.5rem", borderRadius: "0.125rem", border: "1px solid #62BC37", color: "#62BC37" }}>{item.status}</span>
                                                                    }
                                                                    {item.status === "Not Selected" &&
                                                                        <span style={{ padding: "0.25rem 0.5rem", borderRadius: "0.125rem", border: "1px solid black", }}>{item.status}</span>
                                                                    }
                                                                    {item.status === "Not Joined" &&
                                                                        <span style={{ padding: "0.25rem 0.5rem", borderRadius: "0.125rem", border: "1px solid #788931", color: "#788931" }}>{item.status}</span>
                                                                    }
                                                                    {item.status === "Applied" &&
                                                                        <span style={{ padding: "0.25rem 0.5rem", borderRadius: "0.125rem", border: "1px solid blue", color: "blue" }}>{item.status}</span>
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </CardContent>


                                <div style={{ display: "flex", justifyContent: "flex-end", margin: "0% 2% 2% 0%" }}>
                                    <Pagination
                                        count={parseInt(student.placement_length / 10) + 1}
                                        siblingCount={0}
                                        size="small"
                                        onChange={handleChangePage}
                                    />
                                </div>
                            </div>
                        )}
                    </Box>

                </Card>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
            </Grid>
        )
    }
}
