import React, { Component } from "react";
import { connect } from "react-redux";
import StudentDetails from "../Components/StudentDetails";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    viewStudentById,
    viewCourseByStudent,
    viewAssignmentByStudent,
    viewPlacementByStudent
} from "../action"

// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <StudentDetails {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        student: store.student,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {
        viewStudentById: (student_id) => {
            dispatch(viewStudentById(student_id));
        },
        viewCourseByStudent: (student_id) => {
            dispatch(viewCourseByStudent(student_id));
        },
        viewAssignmentByStudent: (student_id) => {
            dispatch(viewAssignmentByStudent(student_id));
        },
        viewPlacementByStudent: (student_id) => {
            dispatch(viewPlacementByStudent(student_id));
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);