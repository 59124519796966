import React, { Component } from "react";
import { connect } from "react-redux";
import Attendance from '../Components/attendance'
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    viewAllAttendance,
    view_monthly_attendance_student,
    setMonthDays,
    view_monthly_attendance_trainers
} from "../action"
import {
    view_all_course,
    view_all_batch_by_course
} from "../../Course/action"
// import { Controller } from "../../Attendance/container/attendanceConts";

export class Controller extends Component {
    render() {
        return (
            <Attendance {...this.props} />
        );
    }

};

export const mapStateToProps = store => {
    return {
        snackbar: store.snackbar,
        loader: store.loader,
        courses: store.courses,
        login: store.login,
        attendance: store.attendance
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        viewAllAttendance: (admin_id, attendance_type, page_number, rowper_page) => {
            dispatch(viewAllAttendance(admin_id, attendance_type, page_number, rowper_page))

        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        view_all_course: () => {
            dispatch(view_all_course());
        },
        view_all_batch_by_course: (course_id) => {
            dispatch(view_all_batch_by_course(course_id));
        },
        view_monthly_attendance_student: (batch_id, date) => {
            dispatch(view_monthly_attendance_student(batch_id, date) );
        },
        view_monthly_attendance_trainers: (batch_id, date) => {
            dispatch(view_monthly_attendance_trainers(batch_id, date) );
        },
        setMonthDays: (payload) => {
            dispatch(setMonthDays(payload) );
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);