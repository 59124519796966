import React, { Component } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Card,
  Dialog,
  Grid,
  Typography,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
import { Textarea } from "theme-ui";

export default class help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab1: false,
      tab2: false,
      type: "Trainer",
      any_url: "",
      page: 0,
      setPage: 0,
      rowsPerPage: 10,
      setRowsPerPage: "",
      anchorEl: null,
      open: false,
      name: "",
      ticket_id: "",
      statuss: "Completed",
      viewticket: false,
      completeticket: false,

    }
  }

  handleDateChange = (e) => {
    const rawDate = e.target.value; // The raw date string from the input
    const formattedDate = this.formatDate(rawDate); // Format the date
    this.props.dateFilter(this.props.tickets.all_tickets, formattedDate);
  }

  formatDate(rawDate) {
    // Assuming rawDate is in the format 'yyyy-MM-dd'
    const parts = rawDate.split('-');
    if (parts.length === 3) {
      const [year, month, day] = parts;
      return `${day}/${month}/${year}`;
    }
    return rawDate; // Return the original date if it's not in the expected format
  }

  handelToggelBtn = () => {
    this.setState({
      toogelbtnColor: !this.state.toogelbtnColor,
      trainerStudentSetionOpenOff: !this.state.trainerStudentSetionOpenOff,
    });
  };

  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"
    this.props.viewAllTicket(this.props.page, this.props.rowsPerPage,this.props.login.org_id);
  }
  render() {
    const { tickets, snackbar, close_snack_bar } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const handleChangePage = (event, newPage) => {
      this.props.viewAllTicket(newPage - 1, this.state.rowsPerPage,this.props.login.org_id);
      this.setState({
        page: newPage,
      });
    };
    return (

      <Grid style={{ marginTop: "10%" }}>
        <Typography variant="h4" style={{ color: "black", margin: "1% 0% 0% 2%", fontWeight: 450 }}>Help & Support</Typography>
        <Card style={{ margin: "1% 2.5% 2.5% 0%", padding: "20px" }}>
          <div className="header">
            <div className="Tabbutton" style={{ marginLeft: "0%", display: "flex", flexdirection: "row" }}>
              <button
                className={
                  this.state.toogelbtnColor ? `compBtnDisabel` : ` compBtnActive`
                }
                onClick={this.handelToggelBtn}
              >
                Trainer
              </button>
              <button
                className={
                  this.state.toogelbtnColor ? `stuBtnActive` : `stuBtnDisable`
                }
                onClick={this.handelToggelBtn}
              >
                Student
              </button>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 20, alignContent: "center" }}>
            <div style={{ marginTop: "7px" }}>
              <h3>Ticket</h3>
            </div>
            <div>
              <TextField
                type="date"
                sx={{
                  backgroundColor: "#efefef99",
                  borderRadius: "5px",
                  border: "none",
                  width: "247px",
                  marginTop: "20px",
                  '.MuiOutlinedInput-notchedOutline': { border: 0 }
                }}
                onChange={this.handleDateChange}></TextField>
            </div>
          </div>
          {this.state.trainerStudentSetionOpenOff ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F8F7F7", }}>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      NAME
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      DATE
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      COMPLAIN
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      STATUS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tickets.search_ticket.length == "0" &&
                    <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                      <Typography>No Result Found</Typography>
                    </Grid>
                  }
                  {Array.isArray(tickets.search_ticket) && tickets.search_ticket.map((item, index) => {
                    return (
                      <>
                        {item.type == "S" &&
                          <TableRow style={{ textDecoration: "none" }} className="table-row">
                            <TableCell
                              style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}
                              align="center"
                            >
                              {item.user_details.name}
                            </TableCell>
                            <TableCell
                              style={{
                                color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                              }}
                              align="center"
                            >
                              {item.date}
                            </TableCell>
                            <TableCell
                              style={{
                                color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                              }}
                              align="center"
                            >
                              {item.message}
                            </TableCell>
                            {item.status == "Completed" && <TableCell
                              style={{
                                color: "#5F5A5A", fontSize: "15px", letterSpacing: 1, cursor: "pointer"
                              }}
                              align="center"
                              onClick={() => {
                                this.setState({
                                  viewticket: true,
                                  ticket_id: item._id,
                                  name: item.user_details.name,
                                  date: item.date,
                                  complain: item.message,

                                })
                              }}
                            >
                              Completed
                            </TableCell>}
                            {item.status == "InCompleted" && <TableCell
                              style={{
                                color: "red", fontSize: "15px", letterSpacing: 1, cursor: "pointer"
                              }}
                              align="center"
                              onClick={() => {
                                this.setState({
                                  completeticket: true,
                                  ticket_id: item._id,
                                  name: item.user_details.name,
                                  date: item.date,
                                  complain: item.message,
                                })
                              }}
                            >
                              Incompleted
                            </TableCell>}

                          </TableRow>
                        }
                      </>

                    );
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F8F7F7", }}>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      NAME
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      DATE
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      COMPLAIN
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      STATUS
                    </TableCell>
                  </TableRow>
                </TableHead>


                <TableBody>
                  {tickets.search_ticket.length == "0" &&
                    <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                      <Typography>No Result Found</Typography>
                    </Grid>
                  }
                  {Array.isArray(tickets.search_ticket) && tickets.search_ticket.map((e, index) => {
                    return (
                      <>
                        {e.type == "T" &&
                          <TableRow style={{ textDecoration: "none" }}>
                            <TableCell
                              style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}
                              align="center"
                            >
                              {e.user_details.name}
                            </TableCell>
                            <TableCell
                              style={{
                                color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                              }}
                              align="center"
                            >
                              {e.date}
                            </TableCell>
                            <TableCell
                              style={{
                                color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                              }}
                              align="center"
                            >
                              {e.message}
                            </TableCell>
                            {e.status == "Completed" && <TableCell
                              style={{
                                color: "#5F5A5A", fontSize: "15px", letterSpacing: 1, cursor: "pointer"
                              }}
                              align="center"
                              onClick={() => {
                                this.setState({
                                  viewticket: true,
                                  ticket_id: e._id,
                                  name: e.user_details.name,
                                  date: e.date,
                                  complain: e.message,

                                })
                              }}
                            >
                              Completed
                            </TableCell>}
                            {e.status == "InCompleted" && <TableCell
                              style={{
                                color: "red", fontSize: "15px", letterSpacing: 1, cursor: "pointer"
                              }}
                              align="center"
                              onClick={() => {
                                this.setState({
                                  completeticket: true,
                                  ticket_id: e._id,
                                  name: e.user_details.name,
                                  date: e.date,
                                  complain: e.message,
                                })
                              }}
                            >
                              Incompleted
                            </TableCell>

                            }

                          </TableRow>
                        }
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
            <Pagination
              count={parseInt(tickets.ticket_length / 10) + 1}
              siblingCount={0}
              size="small"
              onChange={handleChangePage}
            />
          </div>
        </Card>


        {/*------------------------------------------------ Dialog ---------------------------------------------------------*/}

        {/*Mark as Complete*/}
        <Dialog open={this.state.completeticket} maxWidth="sm" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                    Complaint
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container >
                <Grid item xs={1.5}>

                </Grid>
                <Grid item xs={9} direction='column'>
                  <TextField
                    fullWidth
                    required
                    value={this.state.name}
                    sx={{
                      borderRadius: "5px",
                    }}
                  />
                  <TextField
                    fullWidth
                    sx={{
                      borderRadius: "5px",
                      marginTop: 3,
                    }}
                    value={this.state.date}
                  >
                  </TextField>
                  <Textarea
                    size="lg"
                    required
                    fullWidth
                    sx={{
                      height: "150px",
                      marginTop: 3,
                    }}
                    name="Outlined"
                    value={this.state.complain}
                  />
                  <DialogActions style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 3, marginTop: 10 }} >
                    <Button
                      variant="outlined"
                      style={{
                        fontSize: "15px",
                        height: "38px",
                        width: "120px",
                        color: "#0000B3",
                        border: "1px solid #0000B3",
                      }}
                      onClick={() => {
                        this.setState({ completeticket: false });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#0000B3",
                        fontSize: "15px",
                        height: "38px",
                        width: "160px",
                        color: "white",
                        marginLeft: "2%",

                      }}
                      onClick={() => {
                        this.setState({ completeticket: false })
                        this.props.updateTicketStatusByTicketID(this.state.ticket_id, this.state.statuss, this.state.page, this.state.rowsPerPage,this.props.login.org_id)
                      }}
                    >
                      Mark as complete
                    </Button>
                  </DialogActions>
                </Grid>
                <Grid item xs={1.5}>

                </Grid>
              </Grid>
            </DialogContent>

          </Grid>
        </Dialog>

        {/*View*/}
        <Dialog open={this.state.viewticket} maxWidth="sm" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                    Complaint
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container >
                <Grid item xs={1.5}>

                </Grid>
                <Grid item xs={9} direction='column'>
                  {/* <TextField
                    fullWidth
                    required
                    value={this.state.name}
                    sx={{
                      borderRadius: "5px",
                    }}
                  /> */}
                  <TextField
                    fullWidth
                    sx={{
                      borderRadius: "5px",
                      marginTop: 3,
                    }}
                    value={this.state.date}
                  >
                  </TextField>
                  <Textarea
                    size="lg"
                    required
                    fullWidth
                    sx={{
                      height: "150px",
                      marginTop: 3,
                    }}
                    name="Outlined"
                    value={this.state.complain}
                  />
                  <DialogActions style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 3, marginTop: 10 }} >
                    <Button
                      variant="outlined"
                      style={{
                        fontSize: "15px",
                        height: "38px",
                        width: "120px",
                        color: "#0000B3",
                        border: "1px solid #0000B3",
                      }}
                      onClick={() => {
                        this.setState({ viewticket: false });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#0000B3",
                        fontSize: "15px",
                        height: "38px",
                        width: "120px",
                        color: "white",
                        marginLeft: "2%",

                      }}
                      onClick={() => {
                        this.setState({ viewticket: false })
                      }}
                    >
                      Okay
                    </Button>
                  </DialogActions>
                </Grid>
                <Grid item xs={1.5}>

                </Grid>
              </Grid>
            </DialogContent>

          </Grid>
        </Dialog>
        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </Grid>
    );
  }
}
