import React, { Component } from "react";
import { connect } from "react-redux";
import StudentAssignmentdetails from "../Components/studentAssignmentdetails";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  view_submit_by_assignment,
  uploadImageCourse,
} from "../action"


export class Controller extends Component {
  render() {
    return (
      <StudentAssignmentdetails {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    courses: store.courses,
    login: store.login,
    assignment: store.assignment

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    view_submit_by_assignment: (assignment_id) => {
      dispatch(view_submit_by_assignment(assignment_id))
    },
    uploadImageCourse: (stud_id, assign_id, attachment) => {
      dispatch(uploadImageCourse(stud_id, assign_id, attachment))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);