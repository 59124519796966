import React, { Component } from 'react'
import "../styles/Jobprofileselect.scss"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom'
import { Button, Dialog, Grid, Box, Typography } from '@mui/material';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";

let url = " {this.props.placement.placement_apply_link}";
export default class Jobprofileselect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applidetails: false,
        };
    }



    componentDidMount() {
        console.log(this.props.placement.placement_id)
        this.props.viewAppliedStatusByStudent(this.props.login.user_id, this.props.placement.placement_id);
        // this.props.setLastDate();
    }




    render() {
        const { tickets, snackbar, close_snack_bar } = this.props;
        const { placement, login } = this.props;
        return (
            <>
                <div className="navpath">
                    <Link to="/Placementstudent">
                        Placement Company
                    </Link>
                    <ChevronRightIcon />
                    <Link to='/companyselect'>

                        Company
                    </Link>
                    <ChevronRightIcon />Job profile


                </div>
                <div className="jobProfileCon">
                    <div className="jobProfileConheader">
                        <h2>
                            Job Profile:{this.props.placement.placement_job_profile}
                        </h2>
                        {/* <Button variant="contained" style={{ marginLeft: 10, width: "9%", backgroundColor: "#0000B3" }}
                          onClick={() => {
                            this.props.addStudentApply(this.props.login.user_id,this.props.placement.company_id,this.props.placement.placement_id);
                          }}
                        >
                        Apply
                    </Button> */}



                        {this.props.placement.applied_status_by_student === false ? (
                            <Button variant="contained" style={{ marginLeft: 10, width: "20%", backgroundColor: "#0000B3" }}
                                onClick={() => {
                                    this.props.addStudentApply(this.props.login.user_id, this.props.placement.company_id, this.props.placement.placement_id);
                                    // this.setState({ applidetails: true });
                                    // window.location.href = this.props.placement.placement_apply_link
                                }}
                            >
                                Apply
                            </Button>
                        ) : (
                            <Button variant="contained" style={{ marginLeft: 10, width: "20%", backgroundColor: "green" }}
                                onClick={() => {
                                    this.setState({ applidetails: true });
                                }}
                            >
                                Applied
                            </Button>
                        )}


                    </div>
                    <div className="companyName">

                        <h4>
                            Company Name:
                        </h4>
                        <span>
                            {this.props.placement.placement_comp_name}
                        </span>
                    </div>
                    <div className="companyEmail">

                        <h4>
                            Company Email:
                        </h4>
                        <span>
                            {this.props.placement.placement_comp_email}
                        </span>
                    </div>
                    <div className="LinktoApply">

                        <h4>
                            Link to Apply:
                        </h4>
                        <span>
                            {this.props.placement.all_placement_link}
                        </span>
                    </div>
                    <div className="Lastdateapply">

                        <h4>
                            Last date to apply:
                        </h4>
                        <span>
                            {this.props.placement.last_date}
                        </span>
                    </div>

                    <div className="jobdescription">
                        <div className="jobdescriptionheading">
                            <h4>
                                Job Description:
                            </h4>
                        </div>
                        <div className="para">
                            <p>
                                {this.props.placement.description}
                            </p>
                        </div>
                        {/* <div className="keyrespondlist">
                            <div className="listHeading">
                                Key responsibilities
                            </div>
                            <ol type='1'>
                                <li>Design, develop, implement, and maintain Android applications </li>
                                <li>Develop, test, and support applications and services    </li>
                                <li>Coordinate with teams and other stakeholders to ensure the successful development and deployment of applications</li>
                            </ol>

                        </div> */}
                    </div>

                </div>


                <Dialog open={this.state.applidetails} style={{}}>
                    <Grid style={{ minWidth: "300px", height: "250px" }}>
                        <button
                            style={{

                                backgroundColor: "transparent",
                                border: "none",
                                color: "black",
                                marginLeft: "84%"
                            }}
                        >
                            <CloseOutlinedIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    this.setState({ applidetails: false, cursor: "pointer" });
                                }}
                            />
                        </button>
                        <Box style={{ marginLeft: "5%" }}>

                            <Typography
                                id="modal-modal-description"
                                style={{
                                    marginTop: "2%",
                                    fontSize: "18px",
                                    fontFamily: "Inter",
                                    fontWeight: "600%",
                                    color: "black",
                                }}
                            >
                                To Preview You Applied Status Please Visit The Below Link

                            </Typography>
                            < Typography style={{ textAlign: "center", color: "blue", cursor: "pointer" }}
                                onClick={() => {
                                    window.location.href = this.props.placement.all_placement_link
                                }}
                            > {this.props.placement.all_placement_link}</Typography>

                        </Box>
                    </Grid>
                    <LoaderCon />
                    {/* <Snackbar
                        open={snackbar.response_received}
                        close_snack_bar={close_snack_bar}
                        message={snackbar.message}
                    /> */}
                </Dialog>

            </>
        )
    }
}
