import {
    //student
    STUDENTS,
    SEARCH_STUDENT,
    SET_STUDENT_ID,
    SET_STUDENT_IMAGE,
    SET_STUDENT_NAME,
    SET_PHONE,
    SET_EMAIL,

    //student details
    STUDENT_DETAIL,
    STUDENT_COURSES,
    STUDENT_ASSIGNMENT,
    STUDENT_PLACEMENT
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';

import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

{/*------------------------------------------------------------- STUDENT ------------------------------------------------------------*/ }
// Serach
export function searchStudent(all_students, e, type) {
    return (dispatch) => {
        if (e === "" || e === "N") {
            dispatch({ type: SEARCH_STUDENT, payload: all_students });
        }
        else if (type == "search") {
            const newArray = all_students.filter((el) => {
                return (el.name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_STUDENT, payload: newArray })
        }
        else if (type == "course") {
            const newArray = all_students.filter((el) => {
                return (el.course_id.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_STUDENT, payload: newArray })
        }
        else if (type == "batch") {
            const newArray = all_students.filter((el) => {
                return (el.batch_id.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_STUDENT, payload: newArray })
        }
    };
}

// Firebase upload
export function uploadImageStudent(admin_id, profile_pic, name, phone, email, course_id, batch_id, joining_date, page_number, rowper_page,org_id) {
    console.log("aaaaa",admin_id, profile_pic, name, phone, email, course_id, batch_id, joining_date, page_number, rowper_page,org_id)
    return dispatch => {
        console.log(profile_pic)
        dispatch(setLoader(true));
        if (profile_pic !== "") {
            const storageRef = ref(getStorage(), "/tags/" + profile_pic + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, profile_pic);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (profile_pic) {
                        console.log(profile_pic)
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(addStudent(admin_id, profile_pic, name, phone, email, course_id, batch_id, joining_date, page_number, rowper_page,org_id));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(addStudent(admin_id, profile_pic, name, phone, email, course_id, batch_id, joining_date, page_number, rowper_page,org_id));
        }
    }

}

// Add
export function addStudent(admin_id, profile_pic, name, phone, email, course_id, batch_id, joining_date, page_number, rowper_page,org_id) {
    return (dispatch) => {
        console.log("add studentttt ",admin_id,org_id )
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_student", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id
            },
            body: JSON.stringify({
                profile_pic: profile_pic,
                name: name,
                phone: phone,
                email: email,
                course_id: course_id,
                batch_id: batch_id,
                joining_date: joining_date,
                org_id:org_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllStudent(page_number, rowper_page,org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// Viewall
export function viewAllStudent(page_number, rowper_page, org_id) {
    return (dispatch) => {
        console.log("view all student",page_number, rowper_page, org_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_student", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                org_id: org_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("resstudent", responseJson)
                if (responseJson.status) {
                    dispatch({ type: STUDENTS, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: STUDENTS, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// student_id
export function studentId(payload) {
    return {
        type: SET_STUDENT_ID,
        payload: payload,
    };
}

// student_image
export function studentImage(payload) {
    return {
        type: SET_STUDENT_IMAGE,
        payload: payload,
    };
}

// student_name
export function studentname(payload) {
    return {
        type: SET_STUDENT_NAME,
        payload: payload,
    };
}

// student_phone
export function phone(payload) {
    return {
        type: SET_PHONE,
        payload: payload,
    };
}

// student_email
export function email(payload) {
    return {
        type: SET_EMAIL,
        payload: payload,
    };
}

{/*---------------------------------------------------------- STUDENT VIEW ------------------------------------------------------------*/ }
// view_student_by_id
export function viewStudentById(student_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_student_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                student_id: student_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("resd", responseJson)
                if (responseJson.status) {
                    dispatch({ type: STUDENT_DETAIL, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: STUDENT_DETAIL, payload: [] })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// view_student_course
export function viewCourseByStudent(student_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_course_by_student", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                student_id: student_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("cou", responseJson)
                if (responseJson.status) {
                    dispatch({ type: STUDENT_COURSES, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: STUDENT_COURSES, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// view_student_assignment
export function viewAssignmentByStudent(student_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_assignment_by_student", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                student_id: student_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: STUDENT_ASSIGNMENT, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: STUDENT_ASSIGNMENT, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// view_student_placement
export function viewPlacementByStudent(student_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_placement_by_student", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                student_id: student_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: STUDENT_PLACEMENT, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: STUDENT_PLACEMENT, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

