import React, { Component } from "react";
// import firebase from "firebase";
// import { firebase_config } from "../../constants/ActionTypes";

export default class LoginController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      page: ""
    };
  }

  componentDidMount() {
    console.log(localStorage.getItem("user_id"))
    if (localStorage.getItem("user_id") !== null) {
      console.log(localStorage.getItem("type"))

      // console.log(localStorage.getItem("type"));

      this.props.setAdminLogin({
        _id: localStorage.getItem("user_id"),
        type: localStorage.getItem("type"),
        name: localStorage.getItem("name"),
        email: localStorage.getItem("email"),
        phone: localStorage.getItem("phone"),
        profile_pic: localStorage.getItem("profile_pic"),
       
      });
      // console.log(localStorage._id,type,name,email,phone,profile_pic);


      // if (!firebase.apps.length) {
      //   firebase.initializeApp(firebase_config);
      // }
    }
    if (localStorage.getItem("org_id") !== null) {
      // console.log(localStorage.getItem("type"))
      this.props.setUserType({
        type: localStorage.getItem("type"),
        org_id: localStorage.getItem("org_id"),
      });
      this.props.setAccessResult({
        type: localStorage.getItem("type"),
        org_id: localStorage.getItem("org_id"),
      })
    }


  }

  render() {
    return (
      <div />
    );
  }
}
