import React, { Component } from "react";
import { connect } from "react-redux";
import CourseDetails from "../Components/courseDetails";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  add_subject,
  view_subject_by_courses,
  edit_subject,
  delete_subject,
  addBatch,
  viewBatchByCourse,
  editBatch,
  search_Batch,
  deleteBatch,
  view_all_subject,
  assignTo,
  viewAllAssignTo,
  setCoursename,
  setTrainerName,
  setTrainerProfilePic,
  setBatchId,
  batchLength,
  subjectlength,
  setBatchNumber,
  setSubjectName,
  setBatchName,
  setBatchId2,
  setBatch,
  uploadRecord,
  view_batch_by_courses_trainer,
  view_all_batch,
  view_assign_subject_by_trainer_id,
  delete_assign
} from "../action"
import {
  view_every_trainer,
  viewAllTrainer
} from "../../Trainer/action"

export class Controller extends Component {
  render() {
    return (
      <CourseDetails {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    courses: store.courses,
    user: store.user,
    one_user: store.one_user,
    snackbar: store.snackbar,
    login: store.login,
    loader: store.loader,
    trainer: store.trainer

  };
};
export const mapDispatchToProps = dispatch => {
  return {


    add_subject: (admin_id, course_id, subject_name, duration) => {
      dispatch(add_subject(admin_id, course_id, subject_name, duration))
    },

    view_subject_by_courses: (admin_id, course_id) => {
      dispatch(view_subject_by_courses(admin_id, course_id))
    },

    view_assign_subject_by_trainer_id: (trainer_id, course_id) => {
      dispatch(view_assign_subject_by_trainer_id(trainer_id, course_id))
    },

    edit_subject: (admin_id, course_id, subject_id, subject_name, duration) => {
      dispatch(edit_subject(admin_id, course_id, subject_id, subject_name, duration))
    },
    delete_subject: (subject_id, admin_id, course_id) => {
      dispatch(delete_subject(subject_id, admin_id, course_id))
    },

    view_all_batch: (page_number, rowper_page) => {
      dispatch(view_all_batch(page_number, rowper_page));
    },
    addBatch: (admin_id, course_id, batch_number, start_date, batch_time, duration, page_number, rowper_page,org_id) => {
      dispatch(addBatch(admin_id, course_id, batch_number, start_date, batch_time, duration, page_number, rowper_page,org_id))
    },

    viewBatchByCourse: (course_id, page_number, rowper_page,org_id) => {
      dispatch(viewBatchByCourse(course_id, page_number, rowper_page,org_id))
    },

    editBatch: (batch_number, start_date, batch_time, duration, batch_id, page_number, rowper_page, course_id) => {
      dispatch(editBatch(batch_number, start_date, batch_time, duration, batch_id, page_number, rowper_page, course_id))
    },

    search_Batch: (batch_by_course, e) => {
      dispatch(search_Batch(batch_by_course, e))
    },


    deleteBatch: (batch_id, course_id, page_number, rowper_page,org_id) => {
      dispatch(deleteBatch(batch_id, course_id, page_number, rowper_page,org_id))
    },
    view_all_subject: () => {
      dispatch(view_all_subject())
    },
    viewAllTrainer: (admin_id,page_number, rowper_page,org_id) => {
      dispatch(viewAllTrainer(admin_id, page_number, rowper_page,org_id))
    },


    assignTo: (admin_id, trainer_id, batch_id, subject_id, page_number, rowper_page, course_id) => {
      dispatch(assignTo(admin_id, trainer_id, batch_id, subject_id, page_number, rowper_page, course_id))
    },

    viewAllAssignTo: (course_id, page_number, rowper_page) => {
      dispatch(viewAllAssignTo(course_id, page_number, rowper_page))
    },
    view_every_trainer: (admin_id) => {
      dispatch(view_every_trainer(admin_id))
    },

    setCoursename: (payload) => {
      dispatch(setCoursename(payload))
    },

    setTrainerName: (payload) => {
      dispatch(setTrainerName(payload))
    },
    setSubjectName: (payload) => {
      dispatch(setSubjectName(payload))
  },
    setTrainerProfilePic: (payload) => {
      dispatch(setTrainerProfilePic(payload))
    },
    delete_assign: (course_id, trainer_id) => {
      dispatch(delete_assign(course_id, trainer_id))
    },

    setBatchNumber: (payload) => {
      dispatch(setBatchNumber(payload))
    },

    setBatchId: (payload) => {
      dispatch(setBatchId(payload))
    },
    setBatch: (payload) => {
      dispatch(setBatch(payload))
    },
    setBatchId2: (payload) => {
      dispatch(setBatchId2(payload))
    },
    setBatchName: (payload) => {
      dispatch(setBatchName(payload))
    },
    batchLength: () => {
      dispatch(batchLength())
    },
    subjectlength: () => {
      dispatch(subjectlength())
    },
    view_batch_by_courses_trainer: (course_id, trainer_id) => {
      dispatch(view_batch_by_courses_trainer(course_id, trainer_id))

    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);