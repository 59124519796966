import {
    PLACEMENTS, SET_APPLY_LINK, SET_LAST_DATE, SET_DESCRIPTION, COMPANYS, SET_COMPANY_ID, SET_COMPANY_NAME, SET_COMPANY_EMAIL, SET_COMPANY_WEBSITE, SEARCH_COMPANY, VIEW_ALL_APPLIED, SET_PLACEMENT_ID, SET_JOB_PROFILE, VIEW_PLACEMENT_BY_STUDENT, SEARCH_PLACEMENT, SEARCH_APPLIED, VIEW_APPLIED_BY_STUDENT, SET_PLACEMENT_COMPANY_NAME, SET_PLACEMENT_COMPANY_EMAIL, SET_PLACEMENT_APPLY_LINK, SET_PLACEMENT_LAST_DATE, SET_PLACEMENT_JOB_PROFILE, SET_PLACEMENT_JOB_DESC, VIEW_APPLIED_STATUS_BY_STUDENT, SET_ALL_PLACEMENT_APPLY_LINK
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';

import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

{/*------------------------------------------------------------ JOB PROFILE --------------------------------------------------------------*/ }
// applied student
export function viewAllApplied(placement_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_applied", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                placement_id: placement_id,

            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_APPLIED, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_APPLIED, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

//Update status
export function updateAppliedStatus(placement_id, applied_id, status, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_applied_status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                applied_id: applied_id
            },
            body: JSON.stringify({
                status: status
            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllApplied(placement_id, page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}


{/*------------------------------------------------------------ PLACEMENT --------------------------------------------------------------*/ }

// Add
export function addPlacement(admin_id, company_id, department, job_profile, last_date, description, apply_link, no_vacancy, page_number, rowper_page,org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_placement", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id
            },
            body: JSON.stringify({
                company_id: company_id,
                department: department,
                job_profile: job_profile,
                last_date: last_date,
                description: description,
                apply_link: apply_link,
                no_vacancy: no_vacancy,
                org_id:org_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllPlacement(company_id, org_id,page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// Viewall
export function viewAllPlacement(company_id, page_number, rowper_page, org_id) {
  
    return (dispatch) => {
        console.log("ssssssssssss",company_id, page_number, rowper_page, org_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_placement", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                company_id: company_id,

            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                org_id: org_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("pppppppppppppppppp",responseJson)
                if (responseJson.status) {
                    dispatch({ type: PLACEMENTS, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: PLACEMENTS, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// Edit
export function editPlacement(placement_id, company_id, department, job_profile, last_date, description, apply_link, no_vacancy, page_number, rowper_page, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "edit_placement", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                placement_id: placement_id
            },
            body: JSON.stringify({
                company_id: company_id,
                department: department,
                job_profile: job_profile,
                last_date: last_date,
                description: description,
                apply_link: apply_link,
                no_vacancy: no_vacancy,
                org_id: org_id
            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllPlacement(company_id,page_number, rowper_page,org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}

// Delete
export function deletePlacement(admin_id, placement_id, page_number, rowper_page, company_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_placement", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id
            },
            body: JSON.stringify({
                placement_id: placement_id,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllPlacement(company_id,org_id, page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());

                console.error(error);
            });
    };
}

// apply_link
export function setApplyLink(payload) {
    return {
        type: SET_APPLY_LINK,
        payload: payload,
    };
}

// last_date
export function setLastDate(payload) {
    return {
        type: SET_LAST_DATE,
        payload: payload,
    };
}

// description
export function setDescription(payload) {
    return {
        type: SET_DESCRIPTION,
        payload: payload,
    };
}

export function setJobProfile(payload) {
    return {
        type: SET_JOB_PROFILE,
        payload: payload,
    };
}

// placement_id
export function setPlacementId(payload) {
    console.log("placementid", payload)
    return {
        type: SET_PLACEMENT_ID,
        payload: payload,
    };
}


export function setPerticularPlacemenetApplyLink(payload) {

    return {
        type: SET_ALL_PLACEMENT_APPLY_LINK,
        payload: payload,
    };
}


{/*------------------------------------------------------------ COMPANY --------------------------------------------------------------*/ }

// Add
export function addCompany(admin_id, company_name, company_email, company_website, page_number, rowper_page, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_company", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id
            },
            body: JSON.stringify({
                company_name: company_name,
                company_email: company_email,
                company_website: company_website,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllCompany(page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// Viewall
export function viewAllCompany(page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_company", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                // org_id: org_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: COMPANYS, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: COMPANYS, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// Edit
export function updateCompany(company_id, company_name, company_email, company_website, page_number, rowper_page, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_company", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                company_id: company_id,
                company_name: company_name,
                company_email: company_email,
                company_website: company_website,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllCompany(page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}

// Delete
export function deleteCompany(company_id, page_number, rowper_page, org_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_company", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                company_id: company_id,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllCompany(page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                console.error(error);
            });
    };
}


// search company
export function searchCompany(all_companys, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_COMPANY, payload: all_companys });
        }
        else {
            const newArray = all_companys.filter((el) => {
                return (el.company_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_COMPANY, payload: newArray })

        }
    };
}

// company_id
export function setCompanyId(payload) {
    console.log("comp_id", payload)
    return {
        type: SET_COMPANY_ID,
        payload: payload,
    };
}

// company_name
export function setCompanyName(payload) {
    return {
        type: SET_COMPANY_NAME,
        payload: payload,
    };
}
// company_name
export function setCompanyEmail(payload) {
    return {
        type: SET_COMPANY_EMAIL,
        payload: payload,
    };
}
// company_name
export function setCompanyWebsite(payload) {
    return {
        type: SET_COMPANY_WEBSITE,
        payload: payload,
    };
}


// ----------------------------------------------------------------student Action start----------------------------------------------------

// export function viewPlacementByStudent(company_id,page_number, rowper_page) {
//     return (dispatch) => {
//         console.log("comp_placement",company_id,page_number, rowper_page)
//         dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "view_all_placement", {
//             method: "POST",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//                 company_id : company_id

//             },
//             body: JSON.stringify({

//                 page_number: page_number,
//                 rowper_page: rowper_page
//             })

//         })
//             .then((response) => response.json())
//             .then((responseJson) => {
//                 if (responseJson.status) {
//                     dispatch({ type: VIEW_PLACEMENT_BY_STUDENT, payload: { data: responseJson.result, count: responseJson.length } });
//                     dispatch(unsetLoader());
//                 } else {
//                     dispatch({ type: VIEW_PLACEMENT_BY_STUDENT, payload: { data: [], count: 0 } })
//                     dispatch(unsetLoader());
//                 }
//                 dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }


export function searchPlacement(all_placements_by_student, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_PLACEMENT, payload: all_placements_by_student });
        }
        else {
            const newArray = all_placements_by_student.filter((el) => {
                return (el.company_details.company_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PLACEMENT, payload: newArray })

        }
    };
}



export function searchJobProfile(all_placements, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_PLACEMENT, payload: all_placements });
        }
        else {
            const newArray = all_placements.filter((el) => {
                return (el.job_profile.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PLACEMENT, payload: newArray })

        }
    };
}



export function viewAppliedByStudent(student_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_applied_by_student_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                student_id: student_id,
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_APPLIED_BY_STUDENT, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_APPLIED_BY_STUDENT, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}






export function searchApplied(applied_by_student, e, type) {
    return (dispatch) => {
        if (e === "" || e === "N") {
            dispatch({ type: SEARCH_APPLIED, payload: applied_by_student });
        }
        else if (type == "search") {
            const newArray = applied_by_student.filter((el) => {
                return (el.company_details.company_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_APPLIED, payload: newArray })
        }
        else if (type == "job") {
            const newArray = applied_by_student.filter((el) => {
                return (el.placement_details.job_profile.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_APPLIED, payload: newArray })
        }
    };
}

export function setPlacementCompanyName(payload) {

    return {
        type: SET_PLACEMENT_COMPANY_NAME,
        payload: payload,
    };
}


export function setPlacementCompanyEmail(payload) {
    return {
        type: SET_PLACEMENT_COMPANY_EMAIL,
        payload: payload,
    };
}

export function setPlacementApplyLink(payload) {
    return {
        type: SET_PLACEMENT_APPLY_LINK,
        payload: payload,
    };
}

export function setPlacementLastDate(payload) {
    return {
        type: SET_PLACEMENT_LAST_DATE,
        payload: payload,
    };
}

export function setPlacementJobProfile(payload) {
    return {
        type: SET_PLACEMENT_JOB_PROFILE,
        payload: payload,
    };
}

export function setPlacementJobDesc(payload) {
    return {
        type: SET_PLACEMENT_JOB_DESC,
        payload: payload,
    };
}





export function viewAppliedStatusByStudent(student_id, placement_id) {
    return (dispatch) => {
        console.log("changeplacementstatus", student_id, placement_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_applied_status_by_student", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                student_id: student_id,
                placement_id: placement_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_APPLIED_STATUS_BY_STUDENT, payload: true });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_APPLIED_STATUS_BY_STUDENT, payload: false })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}



export function addStudentApply(student_id, company_id, placement_id) {
    return (dispatch) => {
        console.log("addstudentapply", student_id, company_id, placement_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_student_apply", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                student_id: student_id
            },
            body: JSON.stringify({

                company_id: company_id,
                placement_id: placement_id,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAppliedStatusByStudent(student_id, placement_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}








