import React, { Component } from "react";
import { connect } from "react-redux";
import Companyselect from "../Component/companyselect";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {


    viewAllPlacement,
    searchJobProfile,
    viewAppliedStatusByStudent,
    setPlacementJobProfile,
    setCompanyId,
    setPlacementId,
    setPerticularPlacemenetApplyLink,
    setLastDate,
    setDescription
    // FilterApplied
} from "../action"

// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <Companyselect {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        placement: store.placement,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {

        viewAllPlacement: (company_id, page_number, rowper_page,org_id) => {
            dispatch(viewAllPlacement(company_id, page_number, rowper_page,org_id));
        },

        searchJobProfile: (all_placements_by_student, e) => {
            dispatch(searchJobProfile(all_placements_by_student, e));
        },
        viewAppliedStatusByStudent: (student_id, placement_id) => {
            dispatch(viewAppliedStatusByStudent(student_id, placement_id));
        },

        setPlacementJobProfile: (payload) => {
            dispatch(setPlacementJobProfile(payload));
        },
        setCompanyId: (payload) => {
            dispatch(setCompanyId(payload));
        },
        setLastDate: (payload) => {
            dispatch(setLastDate(payload));
        },
        setDescription: (payload) => {
            dispatch(setDescription(payload))
        },


        setPlacementId: (payload) => {
            dispatch(setPlacementId(payload));
        },

        setPerticularPlacemenetApplyLink: (payload) => {
            dispatch(setPerticularPlacemenetApplyLink(payload));
        },





    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);