import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "../styles/style.scss";
import LoaderCon from "../../common/loader/containers/loader_cont";
import { TextField, Box } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
// import logo from "../../pages/Images/logo.png";
import logo from "../../pages/Images/logo-2.png";
import LMS from "../../pages/Images/LMS.png";
import LMS1 from "../../pages/Images/loginIllustrationofStudySphere.png";
import google from "../../pages/Images/google.png";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import PhoneInput from 'react-phone-input-2';
import OtpInput from 'react-otp-input';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import TextField from '@mui/material/TextField';
import 'react-phone-input-2/lib/style.css';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { useParams } from "react-router-dom";
const defaultTheme = createTheme();


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      otp: "",
      change_num: false,
      enter_num: false,
      bt_type: true,
      time: {},
      seconds: 30,
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }
  resetTimer() {
    clearInterval(this.timer);
    this.setState({
      seconds: 30,
      time: this.secondsToTime(30),
    });
    this.timer = setInterval(this.countDown, 1000)
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    // You can start the timer here if OTP has been received from the backend
    // if (this.props.otpReceived) {
    //   this.startTimer();
    // }
    if (this.props.params.id === undefined) {
      console.log("called")
      const payload = {
        product: "lms",
      };
      fetch('https://sso-backend-zpicrijtna-el.a.run.app/v1/login_called', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if (data.status) {
            this.setState({
              data: data,
              isLoading: false,
              openErrorSnackbar: true,
              message: "Otp Verified",
              org: true,
              orgData: data.result,
            });
            window.location.href = "https://sso.technoboot.co/" + data.result
            console.log(this.state.orgData)
          }
          else {
            this.setState({
              data: data,
              isLoading: false,
              openErrorSnackbar: true,
              message: "Some Error Ocured"
            });
          }

        })
        .catch((error) => {
          console.error(error);
          this.setState({ openErrorSnackbar: true, message: "Soome Error Occured" })
        });
    } else {
      this.props.login_auth(this.props.params.id);
    }
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown = () => {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    if (seconds === 0) {
      clearInterval(this.timer);
      // Enable the "Send OTP" button
      this.setState({ seconds: 30 });
    }
  }

  handleOnChange = (value, data, event, formattedValue, otp) => {
    this.setState({
      phone: value,
    });
    this.setState({ otp });
  }

  handlenumChange = (value) => {
    this.setState({ phone: value });
  }

  handleChange = (otp) => {
    this.setState({ otp });
  }

  handleRestart = () => {
    // Reset the state to the initial values
    this.setState({
      phone: "",
      otp: "",
      change_num: false,
      enter_num: false,
      bt_type: true,
      time: {},
      seconds: 30,
      phoneNumberDisabled: false,
      enterOtpDisabled: true,
      error: null,
    });
  }

  handleVerifyOTP = () => {
    console.log("handleVerifyOTP called");
    // Dispatch your Redux action to verify OTP here
    this.props.verify_otp(this.state.phone, this.state.otp);
  }

  render() {
    const { login } = this.props;

    if (login.user_id !== "") {
      return <Navigate to="/" />;
    }

    return (
      <div>
        <Grid>
          <Stack sx={{ width: '100%', marginTop: "20%", justifyContent: 'center', alignItems: 'center' }} spacing={2}>
            <CircularProgress style={{ width: "100px", height: "100px" }} />
          </Stack>
        </Grid>
      </div>
    );
  }
}




export default withParams(Login);




