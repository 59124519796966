import React, { Component } from "react";
import { Table, Card, Avatar, Typography, Menu, MenuItem, Select, TextField, Grid } from "@mui/material/";
import "../styles/attendance.css";
import moment from "moment";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
export default class attendance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      setPage: 0,
      rowPerPage: 6,
      setRowPerPage: "",
      admin_id: "",
      attendance_type: "",
      tab1: false,
      tab2: false,
      type: "Trainer",
      batch_id: "",
      dateArray: [],
    };
  }

  openaddPlacementBtn = () => {
    this.setState({
      addplacementBtn: true,
    });
  };

  handelToggelBtn = () => {
    this.setState({
      toogelbtnColor: !this.state.toogelbtnColor,
      trainerStudentSetionOpenOff: !this.state.trainerStudentSetionOpenOff,
    });
  };
  handleDateChange = (event) => {
    // const currentDate = event;
    console.log(event)
    const year = parseInt(event.split(' ')[1]);
    const month = parseInt(event.split(' ')[0]);
    const lastDayOfMonth = new Date(year, month, 0).getDate(); // Get the last day of the current month

    // Create an array of date strings for the month
    const dateArray = [];
    let day = 0;
    for (day = 1; day <= lastDayOfMonth; day++) {
      const dateString = `${year}-${(month).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      dateArray.push(dateString);
      if (day === lastDayOfMonth) {
        this.setState({ dateArray: dateArray })
      }
    }

  };
  handleCompanyDeleteModalOpen = () => {
    this.setState({ companydeleteModalOpen: true });
  };

  handleStudentDeleteModalOpen = () => {
    this.setState({ studentdeleteModalOpen: true });
  };
  componentDidMount() {
    //admin_id, attendance_type, page_number, rowper_page)
    document.body.style.backgroundColor = "#F9FDFE"
    this.props.view_all_course()
    this.props.viewAllAttendance(this.state.page, this.state.rowsPerPage);
    console.log("hhh", this.props.attendance.all_attendance)
  }

  render() {
    const { tickets, snackbar, close_snack_bar } = this.props;
    const { companydeleteModalOpen, studentdeleteModalOpen } = this.state;
    const { value } = this.state;
    return (
      <Grid style={{ marginTop: "10%" }}>
        <Card
          style={{ margin: "1% 2.5% 2.5% 0%" }}
        >
          <div className="placement">
            <div className="header" style={{ marginBottom: "6%" }}>
              <Typography style={{ color: "black", fontSize: "29px", margin: "1% 0% 0% 2%", fontWeight: 450 }}>Attendance</Typography>

              <div
                className="Tabbutton"
                style={{
                  marginLeft: "2%",
                  marginTop: "2%",
                  display: "flex",
                  flexdirection: "row",
                }}
              >
                <button
                  className={
                    this.state.toogelbtnColor
                      ? `compBtnDisabel`
                      : ` compBtnActive`
                  }
                  onClick={this.handelToggelBtn}
                >
                  Trainer
                </button>
                <button
                  className={
                    this.state.toogelbtnColor ? `stuBtnActive` : `stuBtnDisable`
                  }
                  onClick={this.handelToggelBtn}
                >
                  Student
                </button>
              </div>

              {/* select batch-year-date */}
              <div
                className="batch-year-date"
                style={{
                  marginLeft: "2%",
                  display: "flex",
                  flexdirection: "row",
                  // position: "relative",
                  left: "39rem",
                  bottom: "4rem",
                  width: "530px",
                }}
              >
                <Select
                  defaultValue={" "}
                  className="selectall"
                  sx={{
                    marginTop: "2%",
                    width: "160px",
                    marginLeft: "5px",

                  }}
                  onChange={(e) => {
                    this.props.view_all_batch_by_course(e.target.value)
                  }}
                >
                  <MenuItem value=" ">Select Course</MenuItem>
                  {Array.isArray(this.props.courses.course) && this.props.courses.course.map((item, index) => {
                    return (
                      <MenuItem value={item._id}>{item.course_name}</MenuItem>
                    )
                  })}
                </Select>
                <Select
                  defaultValue={" "}
                  className="selectall"
                  sx={{
                    marginTop: "2%",
                    width: "160px",
                    marginLeft: "5px",

                  }}
                  onChange={(e) => {
                    this.setState({ batch_id: e.target.value })
                  }}
                ><MenuItem value=" ">Select Batch</MenuItem>
                  {Array.isArray(this.props.courses.batch) && this.props.courses.batch.map((item, index) => {
                    return (
                      <MenuItem value={item._id}>{item.batch_number}</MenuItem>
                    )
                  })}
                </Select>


                <TextField
                  type="month"
                  placeholder="Select Month"
                  className="selectall"
                  sx={{
                    marginTop: "2%",
                    marginLeft: "5px",

                  }}
                  onChange={(e) => {
                    console.log(this.state.dateArray)
                    console.log(moment(e.target.value).format("MM YYYY"))

                    {
                      this.handleDateChange(moment(e.target.value).format("MM YYYY"))
                      this.state.toogelbtnColor ?
                        this.props.view_monthly_attendance_student(this.state.batch_id, moment(e.target.value).format("MM YYYY")) :
                        this.props.view_monthly_attendance_trainers(this.state.batch_id, moment(e.target.value).format("MM YYYY"))
                    }

                  }}
                ></TextField>

                {/* <select
                  style={{
                    fontSize: "15px",
                    height: "30px",
                    marginLeft: "1%",
                    marginTop: "2%",
                    width: "160px",
                    display: "flex",
                    flexdirection: "row",
                  }}
                >
                  <option style={{ marginTop: "2%" }} value="Select">
                    Select Year
                  </option>
                  <option value="2022">2022 onwards</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                </select> */}
                {/* <select
                  style={{
                    fontSize: "15px",
                    height: "30px",
                    marginLeft: "1%",
                    marginTop: "2%",
                    width: "140px",
                    display: "flex",
                    flexdirection: "row",
                  }}
                >
                  <option style={{ marginTop: "2%" }} value="Select">
                    Select month
                  </option>
                  <option value="2022">jan</option>
                  <option value="2023">feb</option>
                  <option value="2024">march</option>
                  <option value="2025">april</option>
                  <option value="2026">may</option>
                  <option value="2027">june</option>
                </select> */}
              </div>


            </div>

            {this.state.trainerStudentSetionOpenOff ? (
              <>
                {/* for student */}
                <div
                  style={{
                    marginLeft: "2%",
                    marginTop: "-3%",
                    overflowX: "auto",
                    overflowY: "hidden",
                  }}
                >
                  <table style={{ width: "95%" }}>
                    <tr style={{ backgroundColor: "#D1D1D1" }}>
                      <th>Name</th>
                      {this.state.dateArray.map((item, index) => {
                        return (
                          <th>{item.split("-")[2]}</th>
                        )
                      })}

                    </tr>
                    {Array.isArray(this.props.attendance.all_attendance) && this.props.attendance.all_attendance.map((item1, index1) => {
                      return (
                        <tr>
                          <td
                            style={{ color: "black", border: " 1px solid black", display: "flex", gap: 10, textAlign: "center" }}
                          >
                            {" "}
                            <Avatar></Avatar>
                            <Typography style={{ marginTop: 10 }}>


                              {item1.name}
                            </Typography>
                          </td>
                          {Array.isArray(this.state.dateArray) && this.state.dateArray.map((item, index) => {

                            if (this.props.attendance.all_attendance[index1].attendance.includes(item)) {
                              // console.log(item)
                              return (
                                <td style={{ color: "green", border: " 1px solid black" }}>p</td>
                              )
                            }
                            else {
                              return (
                                <td style={{ color: "green", border: " 1px solid black" }}>A</td>
                              )
                            }

                          })}
                        </tr>
                      )
                    })}
                  </table>
                </div>
              </>
            ) : (
              <>
                <div style={{
                  marginLeft: "2%",
                  marginTop: "-3%",
                  overflowX: "auto",
                  overflowY: "hidden",
                }}>
                  <table style={{ width: "95%" }}>
                    <tr style={{ backgroundColor: "#D1D1D1" }}>
                      <th>Name</th>
                      {this.state.dateArray.map((item, index) => {
                        return (
                          <th>{item.split("-")[2]}</th>
                        )
                      })}
                    </tr>
                    {Array.isArray(this.props.attendance.all_attendance) && this.props.attendance.all_attendance.map((item1, index1) => {
                      return (
                        <tr>
                          <td
                            style={{ color: "black", border: " 1px solid black", display: "flex", gap: 10, textAlign: "center" }}
                          >
                            {" "}
                            <Avatar></Avatar>
                            <Typography style={{ marginTop: 10 }}>


                              {item1.name}
                            </Typography>
                          </td>
                          {Array.isArray(this.state.dateArray) && this.state.dateArray.map((item, index) => {

                            if (this.props.attendance.all_attendance[index1].attendance.includes(item)) {
                              // console.log(item)
                              return (
                                <td style={{ color: "green", border: " 1px solid black" }}>p</td>
                              )
                            }
                            else {
                              return (
                                <td style={{ color: "green", border: " 1px solid black" }}>A</td>
                              )
                            }

                          })}
                        </tr>
                      )
                    })}

                  </table>
                </div>

              </>
            )}
          </div>
        </Card>
        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </Grid>
    );
  }
}
