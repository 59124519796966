import {
    DISCUSSION,
    DISCUSSION_DETAILS
} from "./constant";

const initial_state = {
    discussion_details: [],
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case DISCUSSION:
            console.log("all discussion", action.payload)
            return {
                ...state,
                discussion_details: action.payload
            };
        case DISCUSSION_DETAILS:
            return {
                ...state,
                all_discussion_details: action.payload
            };
        default:
            return state;

    }

}