import {
  CLASSES,SEARCH_CLASS
   
  } from "./constant";
  
  const initial_state = {
    classes: [],
    search_class:[],
   
  };
  export default function reducer(state = initial_state, action) {
    switch (action.type) {
  
      case CLASSES:
        console.log("viewclass", action.payload)
        return {
          ...state,
          classes: action.payload, search_class: action.payload
        };
      case SEARCH_CLASS:
        console.log("search", action.payload)
        return {
          ...state,
          search_class: action.payload
        };

      default:
        return state;
    }
  }
  