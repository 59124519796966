import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../Components/dashboard";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    addNotice,
    updateNotice,
    viewAllNotice,
    deleteNotice,
    viewNoticeById,
    viewRecordVideoByStudent,
    viewAssignmentStudentLength,
    viewAssignmentCompletedLength,
    searchNotice,
    viewCourseInProcess,
    viewAllScheduleClasses,
    deleteSchedule,
    filterSchedulte,
    filter_course,

} from "../action"

import {
    view_all_courses,
    view_all_batch,
    reschedule,
    view_all_course,
    view_all_batch_by_course
} from "../../Course/action"

import {
    viewAllStudent,

} from "../../Students/action"

// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <Dashboard {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        dashboard: store.dashboard,
        courses: store.courses,
        student: store.student,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {
        addNotice: (admin_id, course_id, batch_id, desc, schedule) => {
            dispatch(addNotice(admin_id, course_id, batch_id, desc, schedule));
        },
        updateNotice: (notice_id, course_id, batch_id, desc, schedule) => {
            dispatch(updateNotice(notice_id, course_id, batch_id, desc, schedule));
        },
        viewAllNotice: () => {
            dispatch(viewAllNotice());
        },
        deleteNotice: (notice_id) => {
            dispatch(deleteNotice(notice_id));
        },
        viewNoticeById: (notice_id) => {
            dispatch(viewNoticeById(notice_id));
        },
        viewRecordVideoByStudent: (student_id) => {
            dispatch(viewRecordVideoByStudent(student_id));
        },
        viewAssignmentStudentLength: (student_id) => {
            dispatch(viewAssignmentStudentLength(student_id));
        },
        viewAssignmentCompletedLength: (student_id) => {
            dispatch(viewAssignmentCompletedLength(student_id));
        },
        filter_course: (course, e) => {
            dispatch(filter_course(course, e))
        },
        searchNotice: (notices, e) => {
            dispatch(searchNotice(notices, e));
        },
        filterSchedulte: (schedule_classes, e) => {
            dispatch(filterSchedulte(schedule_classes, e));
        },
        view_all_batch: () => {
            dispatch(view_all_batch());
        },
        viewCourseInProcess: (org_id) => {
            dispatch(viewCourseInProcess(org_id));
        },
        viewAllStudent: (page_number, rowper_page,org_id) => {
            dispatch(viewAllStudent(page_number, rowper_page,org_id));
        },
        view_all_courses: (page_number, rowper_page, org_id) => {
            dispatch(view_all_courses(page_number, rowper_page, org_id))
        },
        view_all_course: (org_id) => {
            dispatch(view_all_course(org_id))
        },
        viewAllScheduleClasses: () => {
            dispatch(viewAllScheduleClasses());
        },

        deleteSchedule: (class_id) => {
            dispatch(deleteSchedule(class_id));
        },
        reschedule: (class_id, date, time, lesson_id, duration) => {
            dispatch(reschedule(class_id, date, time, lesson_id, duration))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        view_all_batch_by_course: (course_id) => {
            dispatch(view_all_batch_by_course(course_id));
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);