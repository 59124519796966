import React, { Component } from 'react';
import { Box, Card, IconButton, Grid, Typography, TextField, DialogActions, Dialog, Divider, DialogTitle, DialogContent } from '@mui/material';
import Bank from "../../pages/Images/bankk.png"
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import EditIcon from '@mui/icons-material/Edit';

import { Link } from 'react-router-dom';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckIcon from '@mui/icons-material/Check';
import { Textarea } from "theme-ui";
import w1 from "../../pages/Images/Wallet-pana.png";
import w2 from "../../pages/Images/Wallet-bro.png";
import Pay from './payment';
export default class Wallet extends Component {


  constructor(props) {
    super(props);
    this.state = {
      addticket: false,
      value: '1',
      checked: false,
      addmodal: false,
      editm: false,
      delem: false,
      deltab: false,
      deltabexe: false,
      visibilitym: false,
      category: '',
      selectedValue: 'a',
      addexe: false,
      editexe: false,
      delexe: false,
      visibilityexe: false,
      employeedeatils: false,
      wallet: false,
      textFill: false,
      amount: "",
      wallet_id:"",


    };
  }
  componentDidMount() {
    this.props.viewWalletByWalletId(this.props.login.org.wallet_id)
    console.log("ddddddddddddd",this.props.login.org)

  }
  render() {
    const { login } = this.props;
    return (
      <>

        <Grid container>
          <Grid item mt={"5%"} xs={4} >
            <Grid container borderRight={2} >
              <Card sx={{ width: 345, }}>
                <CardActionArea sx={{ pointerEvents: 'none' }}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={w1}
                    alt="green iguana"

                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" fontWeight={800}>
                      Techno Wallet
                    </Typography>
                    <Grid container direction={'row'}>
                      <Grid items xs={5}>
                        <Typography variant="body2" color="text.secondary">
                          Total Bal:-  {500}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}></Grid>
                      <Grid items xs={5}>
                        <Typography variant="body2" color="text.secondary">
                          Minimum Bal:-  125
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Grid container>
                    <Grid item xs={5}>
                      {this.state.textFill ? <Pay org_details={login.org} amount={this.state.amount} /> : <Button color="primary" variant='outlined'
                        onClick={() => {
                          this.setState({ textFill: true })
                        }}
                      >
                        <span style={{ marginRight: '4px' }} class="material-symbols-outlined">
                          account_balance_wallet
                        </span>
                        Add Money
                      </Button>

                      }
                    </Grid>
                    <Grid item xs={2}></Grid>
                    {this.state.textFill &&
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          type='number'
                          onChange={(e) => {
                            this.setState({ amount: parseFloat(e.target.value) * 100 })
                          }}
                        />
                      </Grid>
                    }
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={8}>

          </Grid>
        </Grid>
        {/* <Grid style={{ display: 'flex', flexDirection: "row", justifyContent: "space-evenly", marginTop: "8%",marginRight:'20%' }}>
                <Grid>
                
                <Grid>
                    <img src={Bank} style={{ marginTop: "5%" }} />
                </Grid>
                    <Link to="">
                        <Button 
                        
                        onClick={() => {
                            this.setState({ wallet: true });
                        }}style={{ marginTop: "20px", marginLeft: "30px", fontSize: "14px", fontWeight: 500, backgroundColor: "purple",color:"white", fontFamily: "Lato" }}>
                            Add Wallet
                        </Button>
                    </Link>

                </Grid>
                <Grid style={{ display: 'flex', flexDirection: "row" }}>
                <Typography style={{ marginTop: "20px", marginLeft: "30px", fontSize: "18px", fontWeight: 600, color: "", fontFamily: "Lato" }}>
                       Total Balance:
                        </Typography>
                        <Typography style={{ marginTop: "20px", marginLeft: "10px", fontSize: "18px", fontWeight: 600, color: "", fontFamily: "Lato" }}>
                       0
                        </Typography>
                </Grid>















            </Grid> */}








        <Dialog open={this.state.wallet}
          // maxWidth="sm" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
              height: "auto", width: "300px"

            },
          }}>
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={11}>
                  {/* <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                      Complain
                    </Typography> */}
                </Grid>
                <Grid item xs={1} justifyContent={"center"}>
                  <CloseOutlinedIcon
                    onClick={() => {
                      this.setState({ wallet: false });
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container >
                <Grid item xs={1.5}>
                </Grid>
                <Grid item xs={9} direction={'column'}>

                  <Textarea
                    size="lg"
                    fullWidth
                    placeholder="Enter your complain"
                    sx={{
                      height: "150px",
                      marginTop: 3,
                      backgroundColor: "white",
                      border: "1px solid #ACACAC",
                      borderRadius: "5px",
                    }}
                  // onChange={(e) =>
                  //   this.setState({ message: e.target.value })}
                  />

                  <DialogActions style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 3, marginTop: 10 }} >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#0000B3",
                        fontSize: "15px",
                        height: "38px",
                        width: "120px",
                        color: "white",
                        marginLeft: "2%",

                      }}

                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </DialogContent>


          </Grid>
        </Dialog>










      </>




    )
  }
}
