import { Button, Grid, Card, Avatar, Typography, CardContent, Table } from '@mui/material'
import React, { Component } from 'react'
import { FaGreaterThan } from 'react-icons/fa'
import avatar from "../../Images/Avatar.png";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "../../Placement/styles/Placement.scss";
import "../../Students/styles/Studentview.scss"
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";

export default class Studenview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value1: "",
            value: 0

        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f7fcff";
        this.props.viewPlacementByStudent(this.props.student.student_id);
        console.log("placementbyid", this.props.student.student_id)

    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
        if (newValue === 0) {
            // this.setState({ data: this.props.leads.all_client_details })
        }
    };

    render() {
        const { tickets, snackbar, close_snack_bar } = this.props;
        const { value } = this.state;
        const { placement, student } = this.props;
        return (
            <Grid style={{ marginTop: "7%" }}>
                <div style={{ display: "flex", flexDirection: "row", }}>
                    <Link style={{ textDecoration: "none" }} to="/Placement">
                        <Button style={{ color: "#7A7E86" }}>
                            Placement Company
                        </Button>
                    </Link>
                    <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
                    <Link style={{ textDecoration: "none" }} to="">
                        <Button style={{ color: "black" }}>
                            Student Name
                        </Button>
                    </Link>

                </div>
                <Card style={{ marginTop: "20px", marginRight: "25px", marginLeft: "-15px" }}>
                    <Grid container style={{ display: "flex", flexDirection: "row" }} spacing={2}>
                        <Grid item xs={2}>
                            <Avatar
                                alt={student.student_name}
                                style={{ fontSize: "30px", backgroundColor: "#0000B3", marginLeft: 30, width: 100, height: 100, marginTop: 40 }} >
                                {student.student_name == null ? "" : student.student_name.substring(0, 1).toUpperCase()}
                            </Avatar>
                        </Grid>
                        <Grid item xs={10}>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                <Typography style={{ color: "#7A7E86" }}>Name : </Typography>
                                <Typography style={{ marginLeft: "1%" }}>
                                    {student.student_name}
                                </Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                                <Typography style={{ color: "#7A7E86" }}>Phone No : </Typography>
                                <Typography style={{ marginLeft: "1%" }}>
                                    {student.student_phone}
                                </Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                                <Typography style={{ color: "#7A7E86" }}>Email ID : </Typography>
                                <Typography style={{ marginLeft: "1%" }}>
                                    {student.student_email}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <Box style={{ marginTop: "50px" }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs textColor="inherit"
                                indicatorColor="primary" value={value} onChange={this.handleChange} aria-label="basic tabs example" style={{ marginLeft: "20px", marginRight: "20px" }}>
                                <Tab style={{ fontSize: '16px' }} label="Placement" />
                            </Tabs>
                        </Box>

                        {value === 0 && (
                            <div role="tabpanel" id="simple-tabpanel-2" aria-labelledby="simple-tab-2">

                                <CardContent >
                                    <div className="bottom">
                                        <Table style={{ minWidth: 600, }} size="small" aria-label="a dense table">
                                            <TableHead >
                                                <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                                    <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#7A7E86" }}>No</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#7A7E86" }}>Company Name</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#7A7E86" }}>Profile</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#7A7E86" }}>Applied On</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#7A7E86" }}>Application Status</TableCell>
                                                </TableRow>

                                            </TableHead>


                                            {Array.isArray(this.props.student.placement_by_student_id) && this.props.student.placement_by_student_id.map((item, index) => (
                                                <TableRow hover={true} className='classtable' style={{ borderBottom: "1px solid #E3E3E3" }}>
                                                    <TableCell style={{ textAlign: "center", fontSize: "16px" }} >
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "16px" }} >
                                                        {item.company_details !== undefined && item.company_details.company_name}
                                                    </TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "16px" }} >
                                                        {item.placement_details !== undefined && item.placement_details.job_profile}
                                                    </TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "16px" }} >
                                                        {item.apply_date}
                                                    </TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "16px" }} ></TableCell>
                                                </TableRow>
                                            ))}
                                        </Table>
                                    </div>
                                </CardContent>


                            </div>
                        )}
                    </Box>

                </Card>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
            </Grid>
        )
    }
}
