import {
    TRAINERS, SEARCH_TRAINER
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import 'firebase/storage';
import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

{/*------------------------------------------------------------- SEARCH ------------------------------------------------------------*/ }
export function searchTrainer(all_trainers, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_TRAINER, payload: all_trainers });
        }
        else {
            const newArray = all_trainers.filter((el) => {
                return (el.name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_TRAINER, payload: newArray })
        }
    };
}

{/*---------------------------------------------------------- FIREBASE ADD -----------------------------------------------------------*/ }
export function uploadImageTags(admin_id, name, phone, email, profile_pic, page_number, rowper_page) {
    return dispatch => {
        dispatch(setLoader(true));
        if (profile_pic !== "") {
            const storageRef = ref(getStorage(), "/tags/" + profile_pic + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, profile_pic);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (profile_pic) {
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            dispatch(addTrainer(admin_id, name, phone, email, profile_pic, page_number, rowper_page));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(addTrainer(admin_id, name, phone, email, profile_pic, page_number, rowper_page));
        }
    }

}

{/*---------------------------------------------------------- FIREBASE EDIT ------------------------------------------------------------*/ }
export function editUploadImageTags(trainer_id, name, phone, email, profile_pic, org_id, admin_id) {
    return dispatch => {
        dispatch(setLoader(true));
        if (profile_pic !== "") {
            const storageRef = ref(getStorage(), "/Tags/" + trainer_id + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, profile_pic);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (profile_pic) {
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            dispatch(editTrainer(trainer_id, name, phone, email, profile_pic, org_id, admin_id));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(editTrainer(trainer_id, name, phone, email, profile_pic, org_id, admin_id));
        }
    }
}

{/*------------------------------------------------------------ ADD --------------------------------------------------------------*/ }
export function addTrainer(admin_id, name, phone, email, profile_pic, org_id) {
    return (dispatch) => {
        console.log("trainer add", admin_id, name, phone, email, profile_pic, org_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_trainer", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id
            },
            body: JSON.stringify({
                name: name,
                phone: phone,
                email: email,
                profile_pic: profile_pic,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTrainer(admin_id, 0, 10, org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

{/*------------------------------------------------------------ VIEWALL --------------------------------------------------------------*/ }
export function viewAllTrainer(admin_id, page_number, rowper_page, org_id) {
    return (dispatch) => {
        console.log("view trainer", admin_id, page_number, rowper_page, org_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_trainer", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // admin_id: admin_id
            },
            body: JSON.stringify({
                org_id: org_id,
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: TRAINERS, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: TRAINERS, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_every_trainer(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        console.log("Callll")
        return fetch(UNIVERSAL.BASEURL + "view_every_trainer", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: TRAINERS, payload: { data: responseJson.result } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: TRAINERS, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

{/*------------------------------------------------------------ EDIT --------------------------------------------------------------*/ }
export function editTrainer(trainer_id, name, phone, email, profile_pic, org_id, admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "edit_trainer", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                trainer_id: trainer_id
            },
            body: JSON.stringify({
                name: name,
                phone: '91' + phone,
                email: email,
                profile_pic: profile_pic
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson.status) {
                    console.log(admin_id, name, phone, email, profile_pic, org_id)
                    // dispatch(viewAllTrainer(admin_id, 0, 10, org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

{/*------------------------------------------------------------ DELETE --------------------------------------------------------------*/ }
export function deteteTrainer(admin_id, org_id, trainer_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "detete_trainer", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                trainer_id: trainer_id,
                org_id: org_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTrainer(admin_id, 0, 10, org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());

                console.error(error);
            });
    };
}


