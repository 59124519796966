const UNIVERSAL = {
  // BASEURL: "https://production-backend-7724bttusa-el.a.run.app/",
  //BASEURL: "https://debug-backend-7724bttusa-el.a.run.app/",
  // BASEURL: "https://87a6-45-114-49-103.ngrok-free.app/",
  // BASEURL: "http://localhost:7081/",
  BASEURL: "https://lmstechnobootbackend-nvdsptvo4a-el.a.run.app/",
  // BASEURL: "https://lmstechnobootbackend-nvdsptvo4a-el.a.run.app/",
  // BASEURL: "https://productionbackend-nvdsptvo4a-el.a.run.app/",


};
export default UNIVERSAL;
