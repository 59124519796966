import React, { Component } from "react";
import { connect } from "react-redux";
import Course from "../Components/course";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  uploadImageCourse,
  add_courses,
  view_all_courses,
  editUploadImageCourse,
  delete_course,
  search_course,
  setCoursename,
  setDuration,
  setCourseCode,
  setDESC,
  setCourseId,
  setImage,
  view_trainer_course,
} from "../action"


export class Controller extends Component {
  render() {
    return (
      <Course {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    courses: store.courses,
    user: store.user,
    one_user: store.one_user,
    snackbar: store.snackbar,
    login: store.login,
    loader: store.loader,

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    uploadImageCourse: (admin_id, image, course_name, duration, course_code, desc, page_number, rowper_page,org_id) => {
      dispatch(uploadImageCourse(admin_id, image, course_name, duration, course_code, desc, page_number, rowper_page,org_id))
    },
    add_courses: (admin_id, image, course_name, duration, course_code, desc, page_number, rowper_page, org_id) => {
      dispatch(add_courses(admin_id, image, course_name, duration, course_code, desc, page_number, rowper_page, org_id))
    },
    view_all_courses: (page_number, rowper_page, org_id) => {
      dispatch(view_all_courses(page_number, rowper_page, org_id))
    },
    editUploadImageCourse: (course_id, image, old_image, course_name, duration, course_code, desc, page_number, rowper_page,org_id) => {
      dispatch(editUploadImageCourse(course_id, image, old_image, course_name, duration, course_code, desc, page_number, rowper_page,org_id))
    },

    delete_course: (course_id, page_number, rowper_page, org_id) => {
      dispatch(delete_course(course_id, page_number, rowper_page, org_id))
    },

    search_course: (course, e, type) => {
      dispatch(search_course(course, e, type))
    },
    setCoursename: (payload) => {
      dispatch(setCoursename(payload))
    },
    setImage: (payload) => {
      dispatch(setImage(payload))
    },
    setDuration: (payload) => {
      dispatch(setDuration(payload))
    },
    setCourseCode: (payload) => {
      dispatch(setCourseCode(payload))
    },

    setDESC: (payload) => {
      dispatch(setDESC(payload))
    },

    setCourseId: (payload) => {
      dispatch(setCourseId(payload))
    },
    view_trainer_course: (trainer_id, org_id) => {
      dispatch(view_trainer_course(trainer_id, org_id))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);