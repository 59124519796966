import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "../styles/style.scss";
import LoaderCon from "../../common/loader/containers/loader_cont";
import { TextField, Box, CardContent, Avatar } from "@mui/material";
// import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
// import logo from "../../pages/Images/logo.png";
import logo from "../../pages/Images/logo-2.png";
import LMS from "../../pages/Images/LMS.png";
import LMS1 from "../../pages/Images/loginIllustrationofStudySphere.png";
import google from "../../pages/Images/google.png";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import PhoneInput from 'react-phone-input-2';
import OtpInput from 'react-otp-input';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import TextField from '@mui/material/TextField';
import 'react-phone-input-2/lib/style.css';


import Microsoft from "../../pages/Images/ImgOne.jpg";
import Infosys from "../../pages/Images/infosys.png";
import Tcs from "../../pages/Images/tcs.png";
import Technoboot from "../../pages/Images/technoboot.png";
import Hexaware from "../../pages/Images/hexaware.png";
import Mindtree from "../../pages/Images/mindtree.png";
import Zoho from "../../pages/Images/zoho.jpeg";
import "../components/vieworg.css";
import Wipro from "../../pages/Images/wipro.png";
import Mindfire from "../../pages/Images/mindfire.png";
import Myn from "../../pages/Images/myn.png";
import Dialog from "@mui/material/Dialog";
// import Avatar from "../../pages/Images/Avatar.png";
import CameraAltIcon from '@mui/icons-material/CameraAlt';




const defaultTheme = createTheme();


class Vieworg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            otp: "",
            change_num: false,
            enter_num: false,
            bt_type: true,
            time: {},
            seconds: 30,
            org: '',
            createorg: false,
            logo: "",
        };
    }
    componentDidMount() {
        this.props.viewAccessByUser(this.props.login.user_id)
        console.log("org detailss", this.props.login.all_org)
    }
    render() {
        const { org } = this.state;
        return (
            <div>
                <ThemeProvider theme={defaultTheme}>
                    <Grid container component="main" sx={{ height: '90vh' }}>
                        <Grid
                            item
                            xs={false}
                            sm={4}
                            md={7}
                            sx={{
                                backgroundImage: `url(${LMS1})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundColor: (t) =>
                                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                height: 'auto',
                                width: '100%',
                                borderRadius: '10px',
                            }}
                        />
                        <CssBaseline />

                        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                            {/* <img src={logo} style={{ marginTop: "3%", marginLeft: "8%" }} alt="Logo" /> */}
                            <Box
                                sx={{
                                    my: 8,
                                    mx: 4,
                                    p: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: "30px",
                                }}
                            >
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <center>
                                        <Typography style={{ fontSize: "30px", fontWeight: 500 }}>
                                            Organizations
                                        </Typography>
                                    </center>
                                    <Card style={{ width: "400px", height: "400px", background: "white", boxShadow: "2px 2px 15px rgba(0, 0, 0, 0.2)", }}>
                                        <CardContent>

                                            {Array.isArray(this.props.login.all_org) && this.props.login.all_org.length === 0 ? <center style={{ fontSize: "18px", fontWeight: 800 }}>"No Organization" </center> :
                                                <Grid className="companylist" style={{ height: "300px", overflowY: "scroll", marginTop: "2%" }}>

                                                    {Array.isArray(this.props.login.all_org) && this.props.login.all_org.map((e) => {
                                                        console.log("org",e)
                                                        return (

                                                            <Grid style={{ textAlign: "center", display: "flex", justifyContent: "center", justifyItems: 'center' }}>
                                                                <Button
                                                                    onClick={() => {
                                                                        this.props.viewUserOrgDetails(this.props.login.user_id, e._id)
                                                                    }}
                                                                >
                                                                    <Avatar src={e.org_logo} style={{ marginRight: "10px" }} />
                                                                    {e.org_name}
                                                                </Button>
                                                            </Grid>
                                                        )
                                                    })
                                                    }
                                                </Grid>}
                                        </CardContent>
                                        <center>

                                            <Button
                                                onClick={() => {
                                                    this.setState({ createorg: true });
                                                }}
                                                variant="contained" style={{ backgroundColor: "000066" }}>Create Organizations</Button>
                                        </center>

                                    </Card>
                                </Box>
                            </Box>
                        </Grid>


                    </Grid>
                </ThemeProvider>


                <Dialog
                    onClose={() => this.setState({ createorg: false })}
                    open={this.state.createorg} style={{}}>
                    <Grid style={{ width: "350px", marginBottom: "2%" }}>


                        <Grid style={{ marginTop: "3%", marginLeft: "3%", marginRight: "3%", marginBottom: "5%" }}>

                            {/* <center><img src={Avatar} style={{ marginLeft: "5%" }} /></center> */}
                            <Avatar src={this.state.logo !== "" ? URL.createObjectURL(this.state.logo) : this.state.logo} style={{ marginLeft: "auto", marginRight: "auto" }}>
                                <input style={{ display: "none" }} type="file" id="file" onChange={(e) => { this.setState({ logo: e.target.files[0] }) }} />
                                <label htmlFor="file"><CameraAltIcon style={{ marginTop: "25%" }} /></label>
                            </Avatar>




                            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "7%" }}>
                                <Typography>Name</Typography>
                                <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                    onChange={(e) => {
                                        this.setState({ org_name: e.target.value })
                                    }} />
                            </Grid>

                            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "7%" }}>
                                <Typography>Email</Typography>
                                <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                    onChange={(e) => {
                                        this.setState({ org_email: e.target.value })
                                    }}
                                />
                            </Grid>





                            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "7%" }}>
                                <Typography>Phone No</Typography>
                                <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                    onChange={(e) => {
                                        this.setState({ org_num: e.target.value })
                                    }}
                                />
                            </Grid>

                            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "7%" }}>
                                <Typography>GSTIN</Typography>
                                <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                    onChange={(e) => {
                                        this.setState({ org_gstno: e.target.value })
                                    }}
                                />
                            </Grid>

                            <center><Button
                                onClick={() => {
                                    this.props.uploadOrgLogo(this.state.logo, this.state.org_name, this.state.org_email, this.state.org_num, this.state.org_gstno, this.props.login.user_id)

                                    this.setState({ createorg: false, });
                                }}

                                style={{ marginTop: "5%" }} variant="contained">Create</Button></center>




                        </Grid>

                    </Grid>
                </Dialog>
            </div>
        );
    }
}




export default Vieworg;




