import React, { Component } from 'react';
import { Table, Card, Grid } from '@mui/material/';
import { Typography, Button, Box } from '@material-ui/core';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "../styles/studentassignmentdetails.css"
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from "@mui/material/Dialog";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";

export default class studentAssignmentdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addsubmit: false,
      deletepdf: true,
      attachment: "",
      data: [
        {
          Name: "Priti",
          Assignment: "Week 1-2",
          Due_Date: "12/2/23",
          Submitted: "Project1.pdf",
          Status: "Approved",
          Score: "08"
        },
        {
          Name: "Tanu",
          Assignment: "Week 1-2",
          Due_Date: "12/2/23",
          Submitted: "Project1.pdf",
          Status: "Approved",
          Score: "08"
        },
      ],
    };



  }

  componentDidMount() {
    this.props.view_submit_by_assignment(this.props.courses.assignment_details._id)
  }

  render() {
    const { tickets, snackbar, close_snack_bar } = this.props;
    return (
      <Grid>
        <div style={{ display: "flex", flexDirection: "row", marginTop: "100px", marginLeft: "2%" }}>
          <Link to="/StudentcourseDetails" >
            <Button style={{ marginLeft: "1%", color: "#7A7E86" }}>
              Assignment
            </Button>
          </Link>
          <ArrowForwardIosIcon style={{ marginTop: "0.8%", fontSize: "17px", }} />
          <Typography style={{ marginLeft: "1%", marginTop: "0.6%" }}>
            Assignment details
          </Typography>
        </div>
        <Card style={{ marginTop: "1%", width: "94%", marginLeft: "3%" }}>

          <div style={{ display: "flex", flexDirection: "row", marginTop: "2%", marginLeft: "2%", }}>
            <FiberManualRecordIcon style={{ color: "#24E405", fontSize: "17px" }} />
            <Typography style={{ marginLeft: "1%", color: "#7A7E86" }}>
              {this.props.courses.assignment_details.title}

            </Typography>
            <Button variant="contained"
              onClick={() => {
                this.setState({
                  addsubmit: true,
                })
              }}
              style={{ marginRight: "5%", height: "4vh", backgroundColor: "#0000B3", color: "white", marginLeft: "40%" }}>Submit</Button>


          </div>


          <div style={{ display: "flex", flexDirection: "row", marginLeft: "3%", marginTop: "1%" }}>
            <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>
              Assignment:
            </Typography>
            <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>
              {this.props.courses.assignment_details.description}

            </Typography>

          </div>
          <div style={{ display: "flex", flexDirection: "row", marginLeft: "3%", marginTop: "1%" }}>
            <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>
              Assigned by:
            </Typography>
            <div >
              <img style={{ height: "29px", width: "29px", borderRadius: "50%", marginLeft: "10px" }} src={this.props.courses.assignment_details.trainer_details.profile_pic}></img>
            </div>
            <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>

              {this.props.courses.assignment_details.trainer_details.name}
            </Typography>

          </div>
          <div style={{ display: "flex", flexDirection: "row", marginLeft: "3%", marginTop: "2%" }}>
            <CalendarMonthIcon style={{ color: "#0000B3", fontSize: "20px", marginLeft: "1%", }} />
            <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px", color: "#0000B3" }}>
              Due for {this.props.courses.assignment_details.due_date}

            </Typography>

            <PictureAsPdfIcon style={{ color: "red", fontSize: "20px", marginLeft: "3%", }} />
            <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px", color: "#3679DC" }}>

              <a href={this.props.courses.assignment_details.attachment}>Attchment</a>
            </Typography>

          </div>

          <Table style={{ width: "94%", marginTop: "2%", marginLeft: "3%" }} size="small" aria-label="a dense table">
            <TableHead >
              <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                {/* <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#7A7E86" }}>Name</TableCell> */}
                <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#7A7E86" }}>Assignment</TableCell>
                <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#7A7E86" }}>Submit Date </TableCell>
                <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#7A7E86" }}>Submitted</TableCell>
                <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#7A7E86" }}>Status</TableCell>
                <TableCell style={{ textAlign: "center", fontSize: "16px", color: "#7A7E86" }}>Score</TableCell>
              </TableRow>
            </TableHead>
            {/* <TableRow>

              <TableCell style={{ textAlign: "center", fontSize: "16px",display:"flex" }}>
                <div >
                  <img style={{ height: "29px", width: "29px", borderRadius: "50%", marginRight: "7px" }} src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"></img>
                </div>
                Subh
              </TableCell>
              <TableCell style={{ textAlign: "center", fontSize: "16px" }}>Temperature</TableCell>
              <TableCell style={{ textAlign: "center", fontSize: "16px" }}>10-15-23</TableCell>
              <TableCell style={{ textAlign: "center", fontSize: "16px", display: "flex", flexDirection: 'row', marginLeft: "23%" }}>
                <PictureAsPdfIcon style={{ color: "red", fontSize: "20px" }} />


                Project1.pdf </TableCell>
              <TableCell style={{ textAlign: "center", fontSize: "16px" }}>
                <Typography style={{ color: "#0000B3", backgroundColor: "#91C8FB", width: "100px", marginLeft: "25%" }}>
                  Approved

                </Typography>
              </TableCell>
              <TableCell style={{ textAlign: "center", fontSize: "16px" }}>
                08
              </TableCell>
            </TableRow> */}
            {/* {Array.isArray(this.props.dashboard.search_notice) && this.props.dashboard.search_notice.map((e, index) */}

            {Array.isArray(this.props.assignment.submit_assign) && this.props.assignment.submit_assign.map((e, index) => {
              return (
                <TableRow key={index}>

                  {/* <TableCell style={{ textAlign: "center", fontSize: "16px", display: "flex" }}>
                    <div >
                      <img style={{ height: "29px", width: "29px", borderRadius: "50%", marginRight: "7px" }} src={e.student_pic}></img>
                    </div>
                    {e.stud_name}
                  </TableCell> */}
                  <TableCell style={{ textAlign: "center", fontSize: "16px" }}>{this.props.courses.assignment_details.title}</TableCell>
                  <TableCell style={{ textAlign: "center", fontSize: "16px" }}>{e.date}</TableCell>
                  <TableCell style={{ textAlign: "center", fontSize: "16px", display: "flex", flexDirection: 'row', marginLeft: "23%" }}>
                    <PictureAsPdfIcon style={{ color: "red", fontSize: "20px" }} />


                    <a href={e.attachment}>Assignment</a> </TableCell>
                  <TableCell style={{ textAlign: "center", fontSize: "16px" }}>
                    <Typography style={{ color: "#0000B3", backgroundColor: "#91C8FB", width: "100px", marginLeft: "25%" }}>
                      {e.status}

                    </Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: "center", fontSize: "16px" }}>
                    {e.score}
                  </TableCell>
                </TableRow>

              )
            })}
          </Table>
        </Card>

        <Dialog
          open={this.state.addsubmit}

          style={
            {

            }}
        >
          <Grid style={{ height: "450px", width: "470px", }}>
            <div style={{ marginBottom: "9%" }}>
              {/* <CloseOutlinedIcon onClick={() => {
                this.setState({ addsubmit: false })
              }} style={{ marginLeft: "430px", marginTop: "2%", cursor: "pointer"}} /> */}


            </div>

            <div style={{ display: "flex", flexDirection: "row", marginTop: "2%", marginLeft: "10%", }}>
              <FiberManualRecordIcon style={{ color: "#24E405", fontSize: "17px" }} />
              <Typography style={{ marginLeft: "1%", color: "#7A7E86" }}>
                {this.props.courses.assignment_details.title}

              </Typography>

            </div>
            <Typography style={{ marginLeft: "13%", fontWeight: 500, fontSize: "15px", marginTop: "2%" }}>
              {this.props.courses.assignment_details.description}
            </Typography>

            <div style={{ display: "flex", flexDirection: "row", marginLeft: "13%", marginTop: "4%" }}>
              <CalendarMonthIcon style={{ color: "#0000B3", fontSize: "20px", marginLeft: "1%", }} />
              <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px", color: "#0000B3" }}>
                Due for {this.props.courses.assignment_details.due_date}

              </Typography>
            </div>
            <Typography style={{ marginLeft: "15%", fontWeight: 500, fontSize: "15px", marginTop: "3%" }}>
              Attach File

            </Typography>



            <Box style={{ width: '343px', height: '63px', marginLeft: "15%", border: "1px solid grey", backgroundColor: "#FCFCFC", marginTop: "1%" }}>


              <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}

              >
                <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />


              </label>

              <input style={{ display: "none" }} type="file" id="file"
                onChange={(e) => {
                  this.setState({ attachment: e.target.files[0] })
                }}
              />

            </Box>




            <Box style={{
              width: '343px', height: '43px', border: "1px solid grey", backgroundColor: "#FCFCFC", marginTop: "1%", display: "flex", flexDirection: "row",
              marginLeft: "15%", marginTop: "2%", marginTop: "9%"
            }}>
              <PictureAsPdfIcon style={{ color: "red", fontSize: "25px", marginLeft: "3%", marginTop: "5%" }} />
              <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px", color: "#3679DC", marginTop: "6%" }}>

                {this.state.attachment !== "" ? this.state.attachment.name : ""}
              </Typography>
              <CloseOutlinedIcon onClick={() => {
                this.setState({ attachment: "" })
              }} style={{ fontSize: "20px", marginLeft: "8%", marginTop: "6%", cursor: "pointer" }} />
            </Box>




            <center>
              <Button variant="outlined"
                style={{ fontSize: "15px", height: "38px", width: "120px", color: "#0000B3", marginTop: "8%", border: "1px solid #0000B3", }}
                onClick={() => {
                  this.setState({ addsubmit: false })
                }}>

                Cancel
              </Button>
              <Button variant="contained"
                disabled={this.state.attachment === ""}
                style={{ backgroundColor: "#0000B3", fontSize: "15px", height: "38px", width: "120px", color: "white", marginTop: "8%", marginLeft: "2%" }}
                onClick={() => {
                  this.props.uploadImageCourse(this.props.login.user_id, this.props.courses.assignment_details._id, this.state.attachment)
                  this.setState({ addsubmit: false, attachment: "" })
                }}
              >

                Submit
              </Button>
            </center>





          </Grid>

        </Dialog>
        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}

      </Grid>
    )
  }
}





