import React, { Component } from "react";
import { connect } from "react-redux";
import NotesDiscussion from "../Components/TrainerClassTabAccDetails";
import { close_snack_bar } from "../../../common/snackbar/action";

import {
    viewAllLession,
    setSubjectId,
    setLessonId,
    view_notes_by_batch_id,
    update_upload_notes

    // search_course
} from "../action"

import { view_submit_by_assignment } from '../../Assignment/action'

import { viewAllDiscussion } from "../../Discussion/action"
import { addDiscussion } from "../../Discussion/action"
import { add_notes } from "../action"
import { upload_notes } from "../action"
import { delete_notes } from "../action"
import { update_notes,setBatch } from "../action"

export class Controller extends Component {
    render() {
        return (
            <NotesDiscussion {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        courses: store.courses,
        discussion: store.discussion,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,
        assignment: store.assignment

    };
};
export const mapDispatchToProps = dispatch => {
    return {
        //notes

        viewAllLession: () => {
            dispatch(viewAllLession())
        },

        view_submit_by_assignment: (assignment_id) => {
            dispatch(view_submit_by_assignment(assignment_id))
        },
        setSubjectId: (payload) => {
            dispatch(setSubjectId(payload))
        },
        setLessonId: (payload) => {
            dispatch(setLessonId(payload))
        },

        viewAllDiscussion: () => {
            dispatch(viewAllDiscussion())
        },
        addDiscussion: (student_id, message) => {
            dispatch(addDiscussion(student_id, message))
        },
        add_notes: (trainer_id, course_id, batch_id, subject_id, lesson_id, attachment, title,org_id) => {
            dispatch(add_notes(trainer_id, course_id, batch_id, subject_id, lesson_id, attachment, title,org_id))
        },
        upload_notes: (trainer_id, course_id, batch_id, subject_id, lesson_id, attachment, title,org_id) => {
            dispatch(upload_notes(trainer_id, course_id, batch_id, subject_id, lesson_id, attachment, title,org_id))
        },
        view_notes_by_batch_id: (batch_id, page_number, rowper_page,org_id) => {
            dispatch(view_notes_by_batch_id(batch_id, page_number, rowper_page,org_id))
        },
        delete_notes: (notes_id,page_number,rowper_page) => {
            dispatch(delete_notes(notes_id,page_number,rowper_page))
        },
        update_notes: (notes_id,course_id, batch_id, subject_id, lession_id, attachment, title,old_attachment,org_id) => {
            dispatch(update_notes(notes_id,course_id, batch_id, subject_id, lession_id, attachment, title,old_attachment,org_id))
        },
        update_upload_notes: (notes_id,course_id, batch_id, subject_id, lession_id, attachment, title,old_attachment,org_id) => {
            dispatch(update_upload_notes(notes_id, course_id, batch_id, subject_id, lession_id, attachment, title,old_attachment,org_id ))
        },
        setBatch: (payload) => {
            dispatch(setBatch(payload))
          },













    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);