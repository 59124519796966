import React, { Component } from 'react'
import "../styles/Stuclass.scss"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupIcon from '@mui/icons-material/Group';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Textarea from '@mui/joy/Textarea/Textarea';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
export default class StuClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            joinModalOpen: false,
            feedbackModalOpen: false,
            classesOpen: true,
            NotesOpen: false,
            toggelFirstQue: false


        }
    }
    handelClassopen = () => {
        this.setState({

            classesOpen: true,
            NotesOpen: false
        })
    }
    handelNotesopen = () => {
        this.setState({

            classesOpen: false,
            NotesOpen: true
        })
    }
    handelTogggelFirst = () => {
        this.setState({
            toggelFirstQue: !this.state.toggelFirstQue
        })
    }
    render() {
        const { tickets, snackbar, close_snack_bar } = this.props;
        return (
            <>
                <div className="stuclass">
                    <div className="top">

                        <ul>
                            <li>
                                Full Stack Development With PHP Or Python(Batch-1)

                            </li>
                            <li>
                                <CalendarMonthIcon /> started on 20 April 2023
                            </li>
                        </ul>
                    </div>
                    <div className="bottom">
                        <div className="topHeader">
                            <ul>
                                <li onClick={this.handelClassopen} >Classes</li>
                                <li onClick={this.handelNotesopen} >Notes</li>
                            </ul>
                        </div>
                        {
                            this.state.classesOpen &&
                            <>
                                <div className="box">
                                    <div className="firstSection">

                                        <div className="sction" onClick={this.handelTogggelFirst} style={{ cursor: "pointer" }}>
                                            <div className="sectionLeft">
                                                {this.state.toggelFirstQue ? <KeyboardArrowDownIcon /> : <ArrowForwardIosIcon />}
                                                1. Getting started with Html
                                            </div>
                                            <div className="right">
                                                <Button variant="contained" style={{ background: "#9FFFA3", color: "#25801D" }}>Completed</Button>
                                            </div>

                                        </div>
                                        <div className={this.state.toggelFirstQue ? "ans-active" : "ans"}>
                                            <div className="scetionContent">
                                                <div className="classanduserinfo">

                                                    <div className="left">
                                                        <div className="leftMost">
                                                            <CheckCircleOutlineIcon className='checkIcon' />
                                                            <ul>
                                                                <li>Tables <span>(Session-1) </span></li>
                                                                <li>Time:10am-11AM</li>
                                                            </ul>
                                                        </div>
                                                        <div className="rightMost">
                                                            <span>
                                                                <AccountCircleIcon />
                                                                <p>
                                                                    Sudipta Mukherjee
                                                                </p>
                                                            </span>
                                                        </div>

                                                    </div>


                                                    <Button variant="contained" onClick={() => {
                                                        this.setState({ joinModalOpen: true })
                                                    }} style={{ marginLeft: 10, width: "11%", height: "7vh", backgroundColor: "#0000B3" }}>
                                                        Join
                                                    </Button>
                                                </div>
                                                <div className="feedback">

                                                    <div className="buttom">

                                                        <h2>
                                                            Give FeedBack
                                                        </h2>
                                                        <Button variant="contained" onClick={() => {
                                                            this.setState({ feedbackModalOpen: true })
                                                        }} style={{ marginLeft: 10, width: "11%", height: "7vh", backgroundColor: "#0000B3" }}>
                                                            Feedback
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </>
                        }
                        {
                            this.state.NotesOpen &&
                            <>
                                <table className="student-table" >
                                    <thead>
                                        <tr>

                                            <th>Course Name</th>
                                            <th>Batch Name</th>
                                            <th>Subject</th>
                                            <th>Session</th>
                                            <th>Notes</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='courseName' style={{ marginLeft: "2%" }}>PG Program On Full Stack Development With PHP Or Python</td>
                                            <td>Batch-1</td>
                                            <td>HTMl</td>
                                            <td>Session-1</td>
                                            <td style={{ display: "flex", alignContent: "center", justifyContent: "center", color: "#3679DC" }}> <PictureAsPdfIcon style={{ color: "red" }} />  Project1.pdf</td>
                                        </tr>


                                    </tbody>
                                </table>
                            </>
                        }
                    </div>
                </div>
                <Dialog
                    open={this.state.feedbackModalOpen}

                    style={
                        {

                        }}
                >
                    <Grid style={{ height: "452px", width: "457px", }}>

                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBlock: '10px', marginTop: "21px", marginInline: "22px", fontFamily: 'Inter', display: "flex", justifyContent: "space-between", alignItems: "center", fontWeight: 600, color: " #777676" }}>
                            Feedback
                            <CloseOutlinedIcon onClick={() => {
                                this.setState({ feedbackModalOpen: false })
                            }} style={{ cursor: "pointer" }} />
                        </Typography>






                        <Box>
                            <Textarea
                                minRows={2}
                                placeholder="Type in here…"
                                variant="soft"
                                style={{ background: "#eaeaea", height: "44vh", width: "84%", margin: "auto" }}



                            />

                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: "6%",
                                }}
                            >

                                <Button variant="contained" onClick={() => {
                                    this.setState({ feedbackModalOpen: false })
                                }} style={{ marginLeft: 10, width: "23%", backgroundColor: "#0000B3" }}>
                                    Feedback
                                </Button>
                            </Box>
                        </Box>








                    </Grid>

                </Dialog>

                <Dialog
                    open={this.state.joinModalOpen}

                    style={
                        {

                        }}
                >
                    <Grid style={{ height: "362px", width: "520px", }}>




                        <CloseOutlinedIcon onClick={() => {
                            this.setState({ joinModalOpen: false })
                        }} style={{ marginLeft: "440px", marginTop: "20px", cursor: "pointer" }} />





                        <Box>

                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontFamily: 'Inter', display: "flex", justifyContent: "center", fontWeight: 600, color: " #0000B3" }}>
                                Batch-1
                            </Typography>
                            <Typography id="modal-modal-description" style={{ marginTop: "2%", fontSize: "1rem", fontFamily: 'Inter', display: "flex", flexDirection: "column", justifyContent: "center", color: "#777676", width: "22.375rem", textAlign: "center", marginInline: '87px' }}>
                                (Post Graduation Program On Full Stack Development With PHP Or Python)
                            </Typography>
                            <Typography id="modal-modal-description" style={{ marginTop: "2%", fontSize: "1.25rem", fontFamily: 'Inter', display: "flex", flexDirection: "column", justifyContent: "center", color: "#000", width: "22.375rem", textAlign: "center", marginInline: '87px' }}>
                                HTML (session-1)
                            </Typography>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',

                                }}
                            >
                                <Typography id="modal-modal-description" style={{ marginTop: "2%", fontSize: "1.25rem", fontFamily: 'Inter', display: "flex", justifyContent: "center", color: "#000", width: "22.375rem", textAlign: "center", marginLeft: '115px' }}>

                                    <AccessTimeIcon style={{ color: "#666666" }} /> 1hr30min
                                </Typography>
                                <Typography id="modal-modal-description" style={{ marginTop: "2%", fontSize: "1.25rem", fontFamily: 'Inter', display: "flex", justifyContent: "center", color: "#000", width: "22.375rem", textAlign: "center", marginRight: '119px' }}>

                                    <GroupIcon style={{ color: "#666666" }} /> 130Students
                                </Typography>

                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',

                                }}
                            >
                                <Typography id="modal-modal-description" style={{ marginTop: "2%", fontFamily: 'Inter', display: "flex", justifyContent: "center", color: "#0000B3", width: "22.375rem", textAlign: "center", marginLeft: '115px' }}>

                                    Date-22-04-23
                                </Typography>
                                <Typography id="modal-modal-description" style={{ marginTop: "2%", fontFamily: 'Inter', display: "flex", justifyContent: "center", color: "#0000B3", width: "22.375rem", textAlign: "center", marginRight: '119px' }}>

                                    Time-10AM-11AM
                                </Typography>

                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: "6%",
                                }}
                            >
                                <Button variant="outlined" onClick={() => {
                                    this.setState({ joinModalOpen: false })
                                }} style={{ width: "23%", color: "#0000B3" }}>
                                    cancel
                                </Button>
                                <Button variant="contained" onClick={() => {
                                    this.setState({ joinModalOpen: false })
                                }} style={{ marginLeft: 10, width: "23%", backgroundColor: "#0000B3" }}>
                                    Join now
                                </Button>
                            </Box>
                        </Box>
                    </Grid>

                </Dialog>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
            </>
        )
    }
}
