import React, { Component } from "react";
import { connect } from "react-redux";
import Course from "../Components/studentcoursedetails";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    view_course_by_student,
    setCoursename,
    setDuration,
    setCourseCode,
    setDESC,
    setCourseId,
    view_subject_by_courses,
    view_notes_by_batch_id,
    viewAssignmentByBatch,
    view_record_by_batch,
    setAssignMent,
    view_all_shedule_subject_by_id,
    add_attendance,
    add_student_feedback
} from "../action"


export class Controller extends Component {
  render() {
    return (
      <Course {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    courses: store.courses,
    login: store.login,
   
  };
};
export const mapDispatchToProps = dispatch => {
  return {
   
    view_subject_by_courses: (admin_id,course_id) => {
      dispatch(view_subject_by_courses(admin_id,course_id))
    },
    view_notes_by_batch_id: (batch_id, page_number, rowper_page,org_id) => {
      dispatch(view_notes_by_batch_id(batch_id, page_number, rowper_page,org_id))
    },
    viewAssignmentByBatch: (batch_id, page_number, rowper_page) => {
      dispatch(viewAssignmentByBatch(batch_id, page_number, rowper_page))
    },
    view_record_by_batch: (batch_id, rowsPerPage, page_number) => {
      dispatch(view_record_by_batch(batch_id, rowsPerPage, page_number))
    },
    setAssignMent: (payload) => {
      dispatch(setAssignMent(payload))
    },
    view_all_shedule_subject_by_id: (subject_id) => {
      dispatch(view_all_shedule_subject_by_id(subject_id))
    },
    add_attendance: (student_id, batch_id, subject_id) => {
      dispatch(add_attendance(student_id, batch_id, subject_id))
    },
    add_student_feedback: (student_id, subject_id,  desc, lesson_id) => {
      dispatch(add_student_feedback(student_id, subject_id,  desc, lesson_id))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);