import React, { Component } from "react";
import "../styles/studentcoursedetails.css";
import BookIcon from "@mui/icons-material/Book";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import GroupIcon from "@mui/icons-material/Group";
import { Tabs, TabPanel } from "react-tabs";
import { Tab, TabList } from "react-tabs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useLinkClickHandler, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Box, Dialog } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import Textarea from "@mui/joy/Textarea/Textarea";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from "moment";
import User from "../../Images/User Account.png";
import check from "../../Images/Ok.png"
import DateRangeIcon from "@mui/icons-material/DateRange";
import Calender from "../../Images/Vector (1).png";


import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useLinkClickHandler();

    return (

      <Component
        navigate={navigate}
        {...props}
      />
    );
  };

  return Wrapper;
};
class studentcourseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      joinModalOpen: false,
      feedbackModalOpen: false,
      classesOpen: true,
      NotesOpen: false,
      toggelFirstQue: false,
      setindex: "",
      clicked: "",
      click_count: 0,
      page: 0,
      setPage: 0,
      rowsPerPage: 8,
      setRowsPerPage: "",
      desc: "",
      des: " Lorem ipsum dolor sit amet. Est necessitatibus incidunt qui sunt exercitationem et sapiente aperiam eos culpa   voluptas aut ullam cu piditate qui molestias molestias.In    accusantium consequatur et und e consectetur sit rerum  dolore eos molestias enim a nostrum archite cto et isteneque.Ut repellat corporis eum unde maiores eos accusan tium autem est sunt maxime hic reiciendis consectetur est sunt accusa mus qui excepturi error.Ea sunt ullam aut ratione voluptas non omnis ipsam qui dolorum distinctio  est excepturi sunt aut quidem amet et cu piditate  explicabo.",
      data: [
        {
          list: "Getting Started With Html ",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
      ],
      datarec: [
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: " PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "HTML",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "HTML",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "CSS",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: " With PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full StackP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "volopement With PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack  With PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },

      ],

    };
  }
  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };
  handelClassopen = () => {
    this.setState({
      classesOpen: true,
      NotesOpen: false,
    });
  };
  handelNotesopen = () => {
    this.setState({
      classesOpen: false,
      NotesOpen: true,
    });
  };
  handelTogggelFirst = () => {
    this.setState({
      toggelFirstQue: !this.state.toggelFirstQue,
    });
  };
  viewSomething(bkb, index) {
    // console.log(bkb, index);
    if (bkb > 0 && this.state.setindex === index) {
      this.setState({ setindex: "" })
    }

  }
  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"
    this.props.view_subject_by_courses(this.props.login.user_id, this.props.courses.Course_Id,);
    this.props.view_notes_by_batch_id(this.props.courses.batch_Id,this.state.page, this.state.rowsPerPage , this.props.login.org_id)
    console.log("notesssnotesss",this.props.courses.view_notes_by_batch)
  }
  render() {
    const { courses, login, trainer, student, snackbar, close_snack_bar } = this.props
    const handleChangePage = (event, newPage) => {
      this.props.view_notes_by_batch_id(this.props.courses.batch_Id, newPage - 1, this.state.rowsPerPage , this.props.login.org_id)
      // this.props.viewAllTrainer(this.props.login.user_id, newPage - 1, this.state.rowsPerPage);
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };
    const handleChangePage1 = (event, newPage) => {
      this.props.view_assignment_by_batch(this.props.courses.batch_Id, newPage - 1, this.state.rowsPerPage)
      // this.props.viewAllTrainer(this.props.login.user_id, newPage - 1, this.state.rowsPerPage);
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };
    const handleChangePage2 = (event, newPage) => {
      this.props.view_record_by_batch(this.props.courses.batch_Id, newPage - 1, this.state.rowsPerPage)
      // this.props.viewAllTrainer(this.props.login.user_id, newPage - 1, this.state.rowsPerPage);
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };
    return (
      <Grid style={{ marginTop: "7%" }}>
        <div style={{ display: "flex", flexDirection: "row", }}>
          <Link style={{ textDecoration: "none" }} to="/Studentcourse">
            <Button style={{ color: "#7A7E86" }}>
              Course

            </Button>
          </Link>
          <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
          <Link style={{ textDecoration: "none" }} to="">
            <Button style={{ color: "black" }}>
              Course Details

            </Button>
          </Link>
        </div>


        <Card style={{ marginRight: "30px", marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <img style={{ width: "15.375rem", height: "9.1875rem", borderRadius: "0.625rem", margin: "15px", border: "0.5px solid black" }} src={this.props.courses.image}></img>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={5} marginTop={2}>
              <Grid container direction={"column"} spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "1.125rem", fontWeight: 600 }}>{courses.course_name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "0.8125", fontWeight: 400 }}>Course Code: {this.props.courses.course_code}</Typography>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                  <Grid container direction={"row"}>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: "0.8125", fontWeight: 400 }}>
                        <BookIcon className="icon-cp" style={{ marginRight: "5px", color: "rgb(91, 91, 94)" }} />
                        {courses.view_subject_by_id.length} Subjects
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} marginTop={2} textAlign={"right"}>
              <Typography style={{ fontSize: "0.8125", fontWeight: 400, color: "var(--secondary, #0000B3)" }}>
                ( {this.props.courses.duration} Days )
              </Typography>
            </Grid>
            <Grid item xs={0.5}></Grid>
          </Grid>
        </Card>


        <Card style={{ margin: "2.5% 2.5% 2.5% 0%", padding: "0px 20px 20px 20px" }}>
          <Tabs
            value={this.state.type}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider', height: "65px" }}>
              <TabList style={{ width: "100%", display: "flex" }}>
                <Tab label="Active" className={this.state.type === "Details" ? 'selected-tab' : ''}   >
                  <Button
                    style={this.state.type === "Details" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "2px solid blue", borderRadius: "0px" } : { color: "#7A7E86", fontSize: "20px", borderRadius: "0px" }}
                    id=""
                    onClick={() => {
                      this.setState({ type: "Details" });
                    }}
                  >
                    {" "}
                    Details{" "}
                  </Button>
                </Tab>
                <Tab>
                  <Button
                    className={
                      this.state.type === "Classes" ? "selected-tab" : ""
                    }
                    style={this.state.type === "Classes" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "3px solid blue", borderRadius: "0px" } : { color: "#7A7E86", fontSize: "20px", borderRadius: "0px" }}
                    onClick={() => {
                      this.setState({ type: "Classes" });
                    }}
                    active
                  >
                    {" "}
                    Classes{" "}
                  </Button>
                </Tab>
                <Tab>
                  <Button
                    className={
                      this.state.type === "Notes" ? "selected-tab" : ""
                    }
                    style={this.state.type === "Notes" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "3px solid blue", borderRadius: "0px" } : { color: "#7A7E86", fontSize: "20px", borderRadius: "0px" }}
                    onClick={() => {
                      this.setState({ type: "Notes" });
                      this.props.view_notes_by_batch_id(this.props.courses.batch_Id, this.state.page, this.state.rowsPerPage,this.props.login.org_id)
                    }}
                  >
                    {" "}
                    Notes{" "}
                  </Button>
                </Tab>
                <Tab>
                  <Button
                    className={
                      this.state.type === "Assignments" ? "selected-tab" : ""
                    }
                    style={this.state.type === "Assignments" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "3px solid blue", borderRadius: "0px" } : { color: "#7A7E86", fontSize: "20px", borderRadius: "0px" }}
                    onClick={() => {
                      this.setState({ type: "Assignments" });
                      this.props.viewAssignmentByBatch(this.props.courses.batch_Id, this.state.page, this.state.rowsPerPage)

                    }}
                  >
                    {" "}
                    Assignments{" "}
                  </Button>
                </Tab>
                <Tab>
                  <Button
                    className={
                      this.state.type === "Recorded Class" ? "selected-tab" : ""
                    }
                    style={this.state.type === "Recorded Class" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "3px solid blue", borderRadius: "0px" } : { color: "#7A7E86", fontSize: "20px", borderRadius: "0px" }}
                    onClick={() => {
                      this.setState({ type: "Recorded Class" });
                      this.props.view_record_by_batch(this.props.courses.batch_Id, this.state.page, this.state.rowsPerPage)
                    }}
                  >
                    {" "}
                    Recorded Class{" "}
                  </Button>
                </Tab>
              </TabList>
            </Box>
            {/* Details */}
            <TabPanel>
              <div style={{ marginLeft: "2%", marginTop: "2%" }}>
                <div>
                  <Typography
                    style={{ fontSize: "26px", letterSpacing: 0.5 }}
                  >
                    Description
                  </Typography>
                  <p style={{ fontSize: 15, fontWeight: 400 }}>
                    {this.props.courses.desc}
                  </p>
                </div>
                <div id="subjects-details">
                  <Typography
                    style={{
                      // fontWeight: "500",
                      fontSize: "22px",
                      letterSpacing: 1
                    }}
                  >
                    Subjects
                  </Typography>
                  {Array.isArray(this.props.courses.view_subject_by_id) && this.props.courses.view_subject_by_id.map((item, index) => {
                    return (
                      <div style={{ marginLeft: "-20px" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              style={{
                                // fontFamily: " Poppins",
                                fontWeight: "500",
                                fontSize: "15px",
                              }}
                            >
                              {index + 1}.
                            </Typography>
                            <Typography
                              style={{
                                // fontFamily: " Poppins",
                                marginLeft: "10px",
                                fontWeight: "600",
                                fontSize: "15px",
                              }}
                            >
                              {item.subject_name}
                            </Typography>
                          </div>
                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "10%",

                          }}>
                            <Typography
                              style={{
                                // fontFamily: " Open Sans",
                                fontWeight: "450",
                                fontSize: "15px",
                                color: "#0000B3",
                                marginRight: "3%",
                              }}
                            >
                              {item.duration}
                            </Typography>
                          </div>
                        </div>
                        <Divider
                          style={{
                            width: "100%",
                            borderColor: "#E4E4E4",
                            marginTop: "2%",
                            borderWidth: "0.5px",
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </TabPanel>

            {/* Classes */}
            <TabPanel>
              <div style={{ marginTop: "10px" }}>
                {Array.isArray(this.props.courses.view_subject_by_id) && this.props.courses.view_subject_by_id.map((item, index) => {
                  return (
                    <div style={{ cursor: "pointer", border: "1px solid #D6D6D6", margin: "0px 20px 0px 20px" }}>
                      <div
                        onClick={() => {
                          this.handelTogggelFirst()
                          this.setState({ setindex: index, click_count: this.state.click_count + 1, subject_id: item._id });
                          this.viewSomething(this.state.click_count, index);
                          this.props.view_all_shedule_subject_by_id(item._id)
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row", margin: "2%" }}>
                          <div >
                            {this.state.setindex === index ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <KeyboardArrowRightIcon />
                            )}
                          </div>
                          <div style={{ marginLeft: "2%", width: "100%", fontWeight: 450 }}>
                            {index + 1}. {item.subject_name}
                          </div>
                        </div>

                      </div>
                      {this.state.setindex === index ?
                        <div
                          className={
                            this.state.toggelFirstQue
                              ? "ans-active"
                              : "ans"
                          }
                        >
                          {Array.isArray(this.props.courses.view_schedule_meeting) && this.props.courses.view_schedule_meeting.map((item, index) => {
                            return (
                              <div>
                                <div style={{ marginTop: "1%", marginLeft: "4%", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >

                                  <div className="accordion-det-01-01">
                                    <div>
                                      <img src={check} />
                                    </div>
                                    <div><strong>{item.subject_name}</strong>
                                    </div>
                                    <div >
                                      <span style={{ color: "#878686" }}>({item.session_name})</span>
                                    </div>
                                  </div>

                                  <div style={{ marginRight: "5%" }}>
                                    <Button
                                      disabled={(moment(item.date + " " + item.end_time)).isBefore(moment())}
                                      variant="contained"
                                      onClick={() => {
                                        console.log((moment(item.date + " " + item.end_time)).isBefore(moment()))
                                        this.props.add_attendance(this.props.login.user_id, this.props.courses.batch_Id, this.state.subject_id)
                                        window.location.href = item.class_link
                                      }}
                                      style={(moment(item.date + " " + item.end_time)).isBefore(moment()) ? {
                                        marginLeft: 10,
                                        width: "11%",
                                        height: "7vh",
                                        backgroundColor: "grey",
                                        color: "white"
                                      } : {
                                        marginLeft: 10,
                                        width: "11%",
                                        height: "7vh",
                                        backgroundColor: "#0000B3",
                                      }}
                                      size="small"
                                    >
                                      Join
                                    </Button>


                                  </div>
                                </div>

                                <div style={{ marginLeft: "3%", marginTop: "0.5%" }} className="accordion-det-02">
                                  <div className="accordion-det-02-01">
                                    <div>
                                      <AccessTimeOutlinedIcon
                                        style={{ fontSize: "1rem" }}
                                      />
                                    </div>
                                    <div>{item.time}-{item.end_time}  ({item.duration}min)</div>
                                  </div>
                                  <div className="accordion-det-02-01">
                                    <div>
                                      <DateRangeIcon style={{ fontSize: "1rem" }} />
                                    </div>
                                    <div>{item.date}</div>
                                  </div>
                                </div>
                                <Divider sx={{ background: "#EEEEEE", padding: "0.2px", margin: "10px", borderBottom: "none" }} />
                              </div>
                            )
                          })}
                          <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 30px 10px 30px" }}>
                            <Typography variant="h6">Give FeedBack</Typography>
                            <Button
                              variant="contained"
                              onClick={() => {
                                this.setState({
                                  feedbackModalOpen: true,
                                  lesson_id: item._id,
                                });
                              }}
                              style={{
                                marginLeft: 10,
                                width: "100px",
                                backgroundColor: "#0000B3",
                              }}
                            >
                              Feedback
                            </Button>
                          </div>

                        </div> : this.state.clicked
                      }

                    </div>


                  )
                })}


                <Dialog open={this.state.feedbackModalOpen} style={{}}>
                  <Grid style={{ width: "457px" }}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      style={{
                        marginBlock: "10px",
                        marginTop: "21px",
                        marginInline: "22px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: 600,
                      }}
                    >
                      Feedback
                      <CloseOutlinedIcon
                        onClick={() => {
                          this.setState({ feedbackModalOpen: false });
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </Typography>

                    <Box>
                      <Textarea
                        minRows={2}
                        placeholder="Type in here…"
                        variant="soft"
                        style={{
                          background: "#eaeaea",
                          height: "44vh",
                          width: "84%",
                          margin: "auto",
                        }}
                        onChange={(e) => {
                          this.setState({ desc: e.target.value })
                        }}
                      />

                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "6%",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => {
                            this.props.add_student_feedback(this.props.login.user_id, this.state.subject_id, this.state.desc, this.state.lesson_id)
                            this.setState({ feedbackModalOpen: false });
                          }}
                          style={{
                            marginLeft: 10,
                            width: "23%",
                            backgroundColor: "#0000B3",
                            marginBottom: 20
                          }}
                        >
                          Feedback
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Dialog>
              </div>
            </TabPanel>

            {/* Notes */}
            <TabPanel>
              <div>
                <Table>
                  <TableHead style={{ backgroundColor: "#F8F7F7" }}>
                    <TableRow>
                      <TableCell style={{ width: "20%" }} align="left">
                        Course Name
                      </TableCell>

                      <TableCell style={{ width: "10%" }} align="left">
                        Subject
                      </TableCell>
                      <TableCell style={{ width: "10%" }} align="left">
                        Session
                      </TableCell>
                      <TableCell style={{ width: "20%" }} align="center">
                        Notes
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(this.props.courses.view_notes_by_batch) && this.props.courses.view_notes_by_batch.map((item, index) => {
                      console.log("notesssss",item)
                      return (
                        <TableRow>
                          <TableCell align="left">
                            {item.course_details.course_name}
                          </TableCell>

                          <TableCell align="left">{item.subject_details.subject_name}</TableCell>
                          <TableCell align="left">{item.lession_details.session_name}</TableCell>
                          <TableCell align="right">
                            <div className="table-cell-notes">
                              <div id="table-cell-notes-icon">
                                <PictureAsPdfIcon />
                              </div>
                              <div id="table-cell-notes-text">
                                <strong><a href={item.attachment}>Attachment</a></strong>
                              </div>
                              <div>
                                <Button
                                  onClick={(e) => {
                                    this.handleClick33(e);
                                  }}
                                  variant=""
                                >
                                  {/* <MoreVertIcon id="table-cell-notes-icon2" /> */}
                                </Button>
                                <Menu
                                  anchorEl={this.state.anchorEl1}
                                  open={this.state.menu1}
                                  onClose={this.handleClose33}
                                >
                                  <MenuItem>
                                    <Button
                                      onClick={() => {
                                        this.setState({
                                          editnote: true,
                                        });
                                      }}
                                      style={{ color: "#0000B3" }}
                                    >
                                      Edit
                                    </Button>
                                  </MenuItem>
                                  <MenuItem>
                                    <Button
                                      onClick={() => {
                                        this.setState({
                                          deleteNote: true,
                                        });
                                      }}
                                      style={{ color: "#0000B3" }}
                                    >
                                      Delete
                                    </Button>
                                  </MenuItem>
                                </Menu>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>

                <div className="page-button" style={{ display: "flex" }}>
                  <Pagination

                    count={parseInt(this.props.courses.notes_length / 10) + 1}
                    size="small"
                    style={{ marginLeft: "85%", marginTop: "3%" }}
                    onChange={handleChangePage}

                  />
                </div>
              </div>
            </TabPanel>

            {/* Assignment */}
            <TabPanel>

              {/* <Link style={{ textDecoration: "none" }} to="/studentAssignmentdetails"> */}
              {Array.isArray(this.props.courses.assignment_by_batch) && this.props.courses.assignment_by_batch.map((item, index) => {
                return (
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/studentAssignmentdetails"
                  >
                    <Card style={{ marginTop: "1%", width: "94%", marginLeft: "3%", marginBottom: 10, cursor: "pointer" }}
                      onClick={() => {
                        this.props.navigate('/studentAssignmentdetails')
                        this.props.setAssignMent(item)
                      }}
                    >

                      <div style={{ display: "flex", flexDirection: "row", marginTop: "2%", marginLeft: "2%" }}>
                        <FiberManualRecordIcon style={{ color: "#24E405", fontSize: "17px" }} />
                        <Typography style={{ marginLeft: "1%", color: "#7A7E86", fontSize: "20px" }}>
                          {item.title}

                        </Typography>

                      </div>


                      <div style={{ display: "flex", flexDirection: "row", marginLeft: "3%", marginTop: "1%" }}>
                        <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>
                          Assignment:
                        </Typography>
                        <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>
                          {item.description}
                        </Typography>

                      </div>
                      <div style={{ display: "flex", flexDirection: "row", marginLeft: "3%", marginTop: "1%" }}>
                        <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>
                          Assigned by:
                        </Typography>
                        <div >
                          <img style={{ height: "29px", width: "29px", borderRadius: "50%", marginLeft: "10px" }} src={item.trainer_details.profile_pic}></img>
                        </div>
                        <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>

                          {item.trainer_details.name}
                        </Typography>

                      </div>
                      <div style={{ display: "flex", flexDirection: "row", marginLeft: "3%", marginTop: "2%", marginBottom: 20 }}>
                        <CalendarMonthIcon style={{ color: "#0000B3", fontSize: "20px", marginLeft: "1%", }} />
                        <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px", color: "#0000B3" }}>
                          Due for {item.due_date}

                        </Typography>

                        <PictureAsPdfIcon style={{ color: "red", fontSize: "20px", marginLeft: "3%", }} />
                        <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px", color: "#3679DC" }}>

                          <a href={item.attachment}>Attchment</a>
                        </Typography>

                      </div>
                    </Card>
                  </Link>
                )
              })}
            </TabPanel>

            {/* Recorded class */}
            <TabPanel>
              <Grid
                container
                padding={0.5}
                spacing={2}
              >
                {this.props.courses.batch_record.map((item) => {
                  return (
                    <Grid item xs={3}>
                      <Card sx={{ maxWidth: 250, height: 300, borderRadius: 4 }}>
                        <CardMedia
                          component="video"
                          height={150}
                          controls
                          image={item.record_image}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h6" component="div">
                            {item.session}
                          </Typography>
                          <Typography>
                            {item.title}
                          </Typography>
                        </CardContent>
                        <Typography style={{ marginLeft: "2%" }} className="courseduration-student">

                          <img
                            src={Calender}
                            style={{ marginRight: "5px", marginLeft: "15px" }}
                          />
                          {item.access_end_date}
                        </Typography>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>

              <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
                <Pagination
                  count={parseInt(courses.record_length / 10) + 1}
                  siblingCount={0}
                  size="small"
                  onChange={handleChangePage2}
                />
              </div>
            </TabPanel>
          </Tabs>
        </Card>
        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </Grid>
    );
  }
}
export default withRouter(studentcourseDetails);