import React, { Component } from "react";
import { connect } from "react-redux";
import Company from "../Component/Company";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    addCompany,
    viewAllCompany,
    viewAllPlacement,
    updateCompany,
    deleteCompany,
    setCompanyId,
    setCompanyName,
    setCompanyEmail,
    setCompanyWebsite,
    searchCompany
} from "../action"

// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <Company {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        placement: store.placement,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {
        addCompany: (admin_id, company_name, company_email, company_website, page_number, rowper_page) => {
            dispatch(addCompany(admin_id, company_name, company_email, company_website, page_number, rowper_page));
        },
        viewAllCompany: (page_number, rowper_page) => {
            dispatch(viewAllCompany(page_number, rowper_page));
        },
        updateCompany: (company_id, company_name, company_email, company_website, page_number, rowper_page) => {
            dispatch(updateCompany(company_id, company_name, company_email, company_website, page_number, rowper_page));
        },
        deleteCompany: (company_id, page_number, rowper_page) => {
            dispatch(deleteCompany(company_id, page_number, rowper_page));
        },
        setCompanyId: (payload) => {
            dispatch(setCompanyId(payload))
        },
        setCompanyName: (payload) => {
            dispatch(setCompanyName(payload))
        },
        setCompanyEmail: (payload) => {
            dispatch(setCompanyEmail(payload))
        },
        setCompanyWebsite: (payload) => {
            dispatch(setCompanyWebsite(payload))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        searchCompany: (all_companys, e) => {
            dispatch(searchCompany(all_companys, e));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);