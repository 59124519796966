import React, { Component } from "react";
import Pagination from "@mui/material/Pagination";
import "../styles/courseDetails.css";
import BookIcon from "@mui/icons-material/Book";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import GroupIcon from "@mui/icons-material/Group";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, Dialog, Box, Card } from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Value } from "sass";
import User from "../../Images/User Account.png";
import { ItemMeta } from "semantic-ui-react";
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = ["Ui", "Redux", "Redux mix", "Figma", "Backend"];

const styles = (theme) => ({
  fontWeightRegular: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function getStylessubject(name, subjectName, theme) {
  return {
    fontWeight:
      subjectName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default class courseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      des: " Lorem ipsum dolor sit amet. Est necessitatibus incidunt qui sunt exercitationem et sapiente aperiam eos culpa   voluptas aut ullam cu piditate qui molestias molestias.In    accusantium consequatur et und e consectetur sit rerum  dolore eos molestias enim a nostrum archite cto et isteneque.Ut repellat corporis eum unde maiores eos accusan tium autem est sunt maxime hic reiciendis consectetur est sunt accusa mus qui excepturi error.Ea sunt ullam aut ratione voluptas non omnis ipsam qui dolorum distinctio  est excepturi sunt aut quidem amet et cu piditate  explicabo.",
      data: [
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
        {
          list: "Getting Started With Html",
          week: "Week 1-2",
        },
      ],

      personName: [],
      subjectName: [],
      tab1: false,
      tab2: false,
      tab3: false,
      addSubject: false,
      editSubject: false,
      deleteSubject: false,

      addBatch: false,
      editBatch: false,
      deleteBatch: false,

      assignTrainer: false,
      edittrainer: false,
      deleteTrainer: false,
      type: "Details",

      course_id: "",
      subject_name: "",
      trainer_name: "N",
      select_batch: "",
      select_subject: "",
      duration: "",
      batch_number: "",
      start_date: "",
      batch_time: "",
      batch_size: "30",
      coursedur: "(10months)",
      // coursename: "Post Graduation On Program On Full Stack Development",
      _id: "",
      page: 0,
      setPage: 0,
      rowsPerPage: 10,
      setRowsPerPage: "",

      batch_filter: "N",


      // batchdata: [
      //   {
      //     no: "1",
      //     batch: "batch-1",
      //     time: "10Am-11Am",
      //     progres: "70%",
      //     status: "completed",
      //   },
      //   {
      //     no: "1",
      //     batch: "batch-1",
      //     time: "10Am-11Am",
      //     progres: "70%",
      //     status: "completed",
      //   },
      // ],
      personName: [],
      batch_id: [],
      subject_id: [],
      // trainer_id:"",
      trainer_name: 'N',
      trainername: ""

    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      menu: true,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      menu: false,
    });
  };

  handleClick57 = (event) => {
    this.setState({
      anchorEl9: event.currentTarget,
      menu1: true,
    });
  };

  handleClose57 = () => {
    this.setState({
      anchorE19: null,
      menu1: false,
    });
  };

  handleChange(event) {
    const { value } = event.target;
    console.log(event)
    this.setState({
      personName: typeof value === 'string' ? value.split(',') : value,
    });
  }
  addBatchId(new_value) {
    // Get the current valueArray from state
    const { batch_id } = this.state;

    // Define the new value
    const newValue = new_value;

    // Check if the new value already exists in the array
    if (batch_id.includes(newValue)) {
      // If it exists, remove it from the array
      const newValueArray = batch_id.filter((value) => value !== newValue);
      this.setState({ batch_id: newValueArray });
    } else {
      // If it doesn't exist, add it to the array
      const newValueArray = [...batch_id, newValue];
      this.setState({ batch_id: newValueArray });
    }
  };
  addSubjectId(new_value) {
    // Get the current valueArray from state
    const { subject_id } = this.state;

    // Define the new value
    const newValue = new_value;

    // Check if the new value already exists in the array
    if (subject_id.includes(newValue)) {
      // If it exists, remove it from the array
      const newValueArray = subject_id.filter((value) => value !== newValue);
      this.setState({ subject_id: newValueArray });
    } else {
      // If it doesn't exist, add it to the array
      const newValueArray = [...subject_id, newValue];
      this.setState({ subject_id: newValueArray });
    }
  };


  addTrainerId(new_value) {
    // Get the current valueArray from state
    const { trainer_id } = this.state;

    // Define the new value
    const newValue = new_value;

    // Check if the new value already exists in the array
    if (trainer_id.includes(newValue)) {
      // If it exists, remove it from the array
      const newValueArray = trainer_id.filter((value) => value !== newValue);
      this.setState({ trainer_id: newValueArray });
    } else {
      // If it doesn't exist, add it to the array
      const newValueArray = [...trainer_id, newValue];
      this.setState({ trainer_id: newValueArray });
    }
  };
  handleChangesubject = (event) => {
    const { value } = event.target;
    this.setState({
      subjectName: typeof value === "string" ? value.split(",") : value,
    });
  };
  handleChangetab = (event, newValue) => {
    this.setState({ type: newValue });

  };


  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"
    this.props.view_subject_by_courses(this.props.login.user_id, this.props.courses.Course_Id,);
    if (this.props.login.type !== "T") {
      this.props.viewBatchByCourse(this.props.courses.Course_Id, this.state.page, this.state.rowsPerPage,this.props.login.org_id);
    }
    else if (this.props.login.type === "T") {
      this.props.view_batch_by_courses_trainer(this.props.courses.Course_Id, this.props.login.user_id)
      this.props.view_assign_subject_by_trainer_id(this.props.login.user_id, this.props.courses.Course_Id)
    }
    // this.props.view_all_batch(this.state.page, this.state.rowsPerPage);
    this.props.view_all_subject();
    this.props.viewAllTrainer(this.props.login.admin_id,this.state.page, this.state.rowsPerPage,this.props.login.org_id);
    // this.props.batchLength();
    this.props.subjectlength();
    this.props.viewAllAssignTo(this.props.courses.Course_Id, this.state.page, this.state.rowsPerPage);
    this.props.view_every_trainer(this.props.login.org_id);
    console.log("hell", this.props.courses.Course_Id)
    console.log("trainers",this.props.trainer.all_trainers)
  }


  render() {
    const { personName, subjectName } = this.state;
    // console.log("asillllllaaaa", this.state.type)
    const { courses, login, trainer, student, snackbar, close_snack_bar } = this.props
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const handleChangePage = (event, newPage) => {
      this.props.viewBatchByCourse(this.props.courses.Course_Id, newPage - 1, this.state.rowsPerPage)
      // this.props.viewAllAssignTo(newPage - 1, this.state.rowsPerPage);
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };
    const handleChangeTrainer = (event, newPage) => {
      this.props.viewAllAssignTo(this.props.courses.Course_Id, newPage - 1, this.state.rowsPerPage, this.props.login.org_id);
      // this.props.viewAllAssignTo(newPage - 1, this.state.rowsPerPage);
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };

    return (
      <Grid style={{ marginTop: "7%" }}>
        <div style={{ display: "flex", flexDirection: "row", }}>
          <Link style={{ textDecoration: "none" }} to="/course">
            <Button style={{ color: "#7A7E86" }}>
              Course

            </Button>
          </Link>
          <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
          <Link style={{ textDecoration: "none" }} to="">
            <Button style={{ color: "black" }}>
              Course Details

            </Button>
          </Link>
        </div>

        <Card style={{ marginRight: "30px", marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <img style={{ width: "15.375rem", height: "9.1875rem", borderRadius: "0.625rem", margin: "15px", border: "0.5px solid black" }} src={this.props.courses.image}></img>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={5} marginTop={2}>
              <Grid container direction={"column"} spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "1.125rem", fontWeight: 600 }}>{this.props.courses.course_name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "0.8125", fontWeight: 400 }}>Course Code: {this.props.courses.course_code}</Typography>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                  <Grid container direction={"row"}>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: "0.8125", fontWeight: 400 }}>
                        <BookIcon className="icon-cp" style={{ marginRight: "5px", color: "rgb(91, 91, 94)" }} />
                        {courses.view_subject_by_id.length} Subjects
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: "0.8125", fontWeight: 400, alignItems: "center", marginTop: "6px" }}>
                        <img src={User} style={{ marginRight: "5px", color: "rgb(91, 91, 94)" }} />
                        {courses.batchbycourse_length} Batches
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={4}>
                      <Typography style={{ fontSize: "0.8125", fontWeight: 400 }}>
                        <GroupIcon className="icon-cp" style={{ marginRight: "5px", color: "rgb(91, 91, 94)" }} /> */}
                    {/* {courses.assign_trainer_length} Trainer */}
                    {/* </Typography>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} marginTop={2} textAlign={"right"}>
              <Typography style={{ fontSize: "0.8125", fontWeight: 400, color: "var(--secondary, #0000B3)" }}>
                ( {this.props.courses.duration})
              </Typography>
            </Grid>
            <Grid item xs={0.5}></Grid>
          </Grid>
        </Card>

        <Card style={{ margin: "2.5% 2.5% 2.5% 0%", padding: "0px 20px 20px 20px" }}>
          <Tabs
            value={this.state.type}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider', height: "65px" }}>
              <TabList style={{ width: "100%", display: "flex" }}>
                <Tab label="Active" className={this.state.type === "Details" ? 'selected-tab' : ''}   >
                  <Button
                    style={this.state.type === "Details" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "3px solid blue", borderRadius: "0px"  } : { color: "#7A7E86", fontSize: "20px", borderRadius: "0px"  }}
                    id=""
                    onClick={() => {
                      this.setState({ type: "Details" });
                    }}
                  >
                    {" "}
                    Details{" "}
                  </Button>
                </Tab>
                <Tab className={this.state.type === "Batch" ? 'selected-tab' : ''}>
                  <Button

                    style={this.state.type === "Batch" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "3px solid blue", borderRadius: "0px"  } : { color: "#7A7E86", fontSize: "20px", borderRadius: "0px"  }}
                    id=""
                    onClick={() => {
                      this.setState({ type: "Batch" });
                    }}
                  >
                    {" "}
                    Batches{" "}
                  </Button>
                </Tab>
                {(this.props.login.type === "A" ||this.props.login.type === "Owner") &&
                  <Tab className={this.state.type === "Trainer" ? 'selected-tab' : ''}>
                    <Button

                      style={this.state.type === "Trainer" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "3px solid blue", borderRadius: "0px"  } : { color: "#7A7E86", fontSize: "20px", borderRadius: "0px"  }}
                      id=""
                      onClick={() => {
                        this.setState({ type: "Trainer" });
                      }}
                    >
                      {" "}
                      Trainer{" "}
                    </Button>
                  </Tab>
                }
              </TabList>
            </Box>

            <TabPanel>
              <div style={{ marginLeft: "2%", marginTop: "2%" }}>
                <div>
                  <Typography
                    style={{ fontSize: "26px", letterSpacing: 0.5 }}
                  >
                    Description
                  </Typography>
                  <p style={{ fontSize: 15, fontWeight: 400 }}>
                    {this.props.courses.desc}
                  </p>
                </div>
                <div id="subjects-details">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Typography
                        style={{
                          // fontWeight: "500",
                          fontSize: "22px",
                          letterSpacing: 1
                        }}
                      >
                        Subjects
                      </Typography>
                    </div>
                    {(this.props.login.type === "A" ||this.props.login.type === "Owner") &&
                      <div>
                        <Button
                          className="Subjectadd"
                          onClick={() => {
                            this.setState({ addSubject: true });
                          }}
                          style={{
                            borderColor: "#0000B3",
                            color: "#0000B3",
                            width: "140px",
                          }}
                          variant="outlined"
                        >
                          + Add Subjects
                        </Button>
                      </div>}
                  </div>

                  {Array.isArray(courses.view_subject_by_id) && courses.view_subject_by_id.map((item, index) => {
                    return (
                      <div style={{ marginLeft: "-20px" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              style={{
                                // fontFamily: " Poppins",
                                fontWeight: "500",
                                fontSize: "15px",
                              }}
                            >
                              {index + 1}.
                            </Typography>
                            <Typography
                              style={{
                                marginLeft: "10px",
                                fontWeight: "600",
                                fontSize: "15px",
                              }}
                            >
                              {item.subject_name}
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "10%",

                            }}
                          >
                            <Typography
                              style={{
                                // fontFamily: " Open Sans",
                                fontWeight: "450",
                                fontSize: "15px",
                                color: "#0000B3",
                                marginRight: "3%",
                              }}
                            >
                              {item.duration}
                            </Typography>
                            {(this.props.login.type === "A" ||this.props.login.type === "Owner") &&
                              <div style={{ display: "flex" }}>
                                <EditOutlinedIcon
                                  className="editIcon"
                                  onClick={() => {
                                    this.setState({
                                      editSubject: true,
                                      duration: item.duration,
                                      subject_name: item.subject_name,
                                      subject_id: item._id
                                    });
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "#0000B3",
                                    fontSize: "20px",
                                    marginLeft: "20%",
                                  }}
                                />

                                <DeleteOutlineOutlinedIcon
                                  className="deleteIcon"
                                  onClick={() => {
                                    this.setState({
                                      deleteSubject: true,
                                      subject_id: item._id
                                    });
                                  }}
                                  style={{ cursor: "pointer", color: "red" }}
                                />
                              </div>
                            }
                          </div>
                        </div>
                        <Divider
                          style={{
                            width: "100%",
                            borderColor: "#E4E4E4",
                            marginTop: "2%",
                            borderWidth: "0.5px",
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </TabPanel>
            {/* -------BATCH------------- */}
            <TabPanel>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "right", marginTop: "1%" }}>
                {(this.props.login.type === "A" ||this.props.login.type === "Owner") &&
                  <TextField
                    style={{ width: '253px', }}
                    id="outlined-select-currency-native"
                    select
                    value={this.state.batch_filter}
                    onChange={(e) => {
                      this.setState({ batch_filter: e.target.value });
                      this.props.search_Batch(courses.batch_by_course, e.target.value)
                    }}
                  >
                    <MenuItem value={"N"}>{"All Batch"}</MenuItem>
                    {Array.isArray(this.props.courses.batch_by_course) && this.props.courses.batch_by_course.map((item) => (
                      <MenuItem value={item.batch_number}>{item.batch_number}</MenuItem>
                    ))}
                  </TextField>}


                {(this.props.login.type === "A" ||this.props.login.type === "Owner") &&
                  <Button
                    // className="Subjectadd"
                    onClick={() => {
                      this.setState({ addBatch: true });
                    }}
                    style={{ borderColor: "#0000B3", color: "#0000B3", marginLeft: 10 }}
                    variant="outlined"
                  >
                    + Add Batch
                  </Button>
                }

              </div>
              {Array.isArray(courses.search_batch) && courses.search_batch.map((item, index) => {
                return (

                  <div className="batch-numbering">

                    <Link style={{ textDecoration: "none", color: "black" }}
                      to={this.props.login.type === "T" ? "/trainercourseclasses" : "/batch"}

                    >
                      <Typography style={{ fontSize: 15, }}
                        onClick={() => {
                          this.props.setBatchId(item._id);
                          this.props.setBatch(item);
                          this.props.setBatchName(item.batch_number);
                          this.props.setTrainerName(item.trainer_details!==undefined && item.trainer_details.name);
                          this.props.setTrainerProfilePic(item.trainer_details!==undefined && item.trainer_details.profile_pic);
                          this.props.setBatchNumber(item.batch_number);
                          this.props.setBatch(item);
                          this.props.setSubjectName(item.subject_name!==undefined && item.subject_name);
                        }}
                      ><strong>{index + 1}. &emsp; {item.batch_number}</strong></Typography>
                    </Link>
                    <Link style={{ textDecoration: "none", color: "black" }}
                      to={this.props.login.type === "T" ? "/trainercourseclasses" : "/batch"}
                      onClick={() => {
                        this.props.setBatchId(item._id);
                        this.props.setBatch(item);
                        this.props.setBatchName(item.batch_number);
                        this.props.setTrainerName(item.trainer_details.name);
                        this.props.setTrainerProfilePic(item.trainer_details.profile_pic);
                        this.props.setBatchNumber(item.batch_number);

                        this.props.setSubjectName(item.subject_name);
                      }}
                    >
                      <Typography style={{ fontSize: 15, }}><strong>Start date - </strong>{item.start_date}</Typography>

                    </Link >
                    <Link style={{ textDecoration: "none", color: "black" }}
                      to={this.props.login.type === "T" ? "/trainercourseclasses" : "/batch"}
                      onClick={() => {
                        this.props.setBatchId(item._id);
                        this.props.setBatch(item);
                        this.props.setBatchName(item.batch_number);
                        this.props.setTrainerName(item.trainer_details.name);
                        this.props.setTrainerProfilePic(item.trainer_details.profile_pic);
                        this.props.setBatchNumber(item.batch_number);

                        this.props.setSubjectName(item.subject_name);
                      }}
                    >
                      <Typography style={{ fontSize: 15, whiteSpace: "nowrap" }}><strong>Time - </strong>{item.batch_time}</Typography>


                    </Link>
                    {(this.props.login.type === "A" ||this.props.login.type === "Owner") &&
                      <Button
                        onClick={(e) => {
                          this.handleClick57(e);
                          this.setState({
                            batch_number: item.batch_number,
                            start_date: item.start_date,
                            batch_time: item.batch_time,
                            duration: item.duration,
                            batch_id: item._id,
                          })
                        }
                        }
                      >
                        <MoreVertIcon style={{ color: "#747474", width: "1rem", height: "1rem" }} />
                      </Button>
                    }
                    <Menu
                      anchorEl={this.state.anchorEl9}
                      open={this.state.menu1}
                      onClose={this.handleClose57}
                    >
                      <MenuItem>
                        <Button
                          onClick={() => {

                            this.setState({
                              editBatch: true,

                            })
                          }}

                          style={{ color: "#0000B3" }}
                        >
                          Edit
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          onClick={() => {
                            this.setState({
                              deleteBatch: true,
                            });
                          }}
                          style={{ color: "#0000B3" }}
                        >
                          Delete
                        </Button>
                      </MenuItem>
                    </Menu>
                  </div>
                )
              })}


              {this.props.login.type !== "T" && <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
                <Pagination
                  count={parseInt(courses.batchbycourse_length / 10) + 1}
                  siblingCount={0}
                  size="small"
                  onChange={handleChangePage}
                />
              </div>}
            </TabPanel>
            {/* ---------------------Trainer--------- */}
            <TabPanel>
              <div style={{ float: "right", marginTop: "1%"}}>
                <Button

                  onClick={() => {
                    
                    this.setState({ assignTrainer: true,  trainer_name: "N", batch_id: [], subject_id: []});
                  }}
                  style={{ borderColor: "#0000B3", color: "#0000B3" }}
                  variant="outlined"
                >
                  Assign Trainer
                </Button>
              </div>

              {Array.isArray(courses.assign_trainer) && courses.assign_trainer.map((item, index) => {
                return (
                  <div id="trainer" style={{ marginLeft: "2%", marginTop: "4%" }}>
                    <div class="trainer-numbering">
                      <div>
                        <img src={item.trainer_details.profile_pic}></img>
                      </div>
                      <div>
                        <strong>{item.trainer_details.name}</strong>
                      </div>
                      <div>{item.trainer_details.phone}</div>
                      <div>{item.trainer_details.email}</div>
                      <div>
                        {Array.isArray(item.batch_details) && item.batch_details.map((e) => {
                          return (
                            <div style={{ flexDirection: "column" }}>
                              {e.batch_number}
                            </div>
                          )
                        })}
                      </div>
                      <div>
                        {Array.isArray(item.subject_details) && item.subject_details.map((e) => {
                          return (
                            <div style={{ flexDirection: "column" }}>
                              {e.subject_name}
                            </div>
                          )
                        })}
                      </div>
                      <div>
                        <Button
                          style={{
                            zIndex: "100",
                            position: "relative",
                            top: "40%",
                            left: "-10%",
                          }}
                          onClick={(e) => {
                            this.setState({trainer_id: item._id,})
                            this.handleClick(e);
                          }}
                        >
                          <MoreVertIcon
                            style={{
                              width: "19px",
                              height: "19px",
                              color: "#9c9797",
                            }}
                          />
                        </Button>

                        <Menu
                          anchorEl={this.state.anchorEl}
                          open={this.state.menu}
                          onClose={this.handleClose}
                        >
                          {/* <MenuItem>
                            <Button
                              onClick={(e) => {
                                this.setState({
                                  edittrainer: true,
                                  trainer_id: item._id,
                                  trainer_name: item.trainer_details.name,

                                });
                                console.log("_id", item.trainer_details.name)
                              }}
                            >
                              Edit
                            </Button>
                          </MenuItem> */}

                          <MenuItem>
                            <Button
                              onClick={(e) => {
                                this.setState({ deleteTrainer: true,  });
                              }}
                            >
                              Delete
                            </Button>
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>

                  </div>
                )
              })}

              <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
                <Pagination
                  count={parseInt(courses.assign_trainer_length / 10) + 1}
                  siblingCount={0}
                  size="small"
                  onChange={handleChangeTrainer}
                />
              </div>
            </TabPanel>
          </Tabs>
        </Card>


        {/* add subject */}
        <Dialog
          open={this.state.addSubject}
          maxWidth="xl"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{}}
        >
          <Grid style={{ minWidth: "750px", height: "300px" }}>
            <div id="form-dialog-tt">
              <h2 style={{ position: "relative" }}>Course Details</h2>
            </div>
            <Grid>
              <h3 style={{ marginLeft: "5%" }}>Subject</h3>

              <Typography
                style={{
                  fontSize: "20px",
                  marginLeft: "4%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="details-13-tt">
                  <div className="details-12-tt">
                    <div className="details-11-tt">
                      {/* <Typography className="batch-10-tt">Title:</Typography> */}
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "27rem",
                            height: "2.6rem",
                            backgroundColor: "#EFEFEF99",
                            marginLeft: "0%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Subject Name"
                          onChange={(text) =>
                            this.setState({ subject_name: text.target.value })
                          }
                        />
                      </FormControl>
                    </div>
                    <div className="details-11-tt">
                      {/* <Typography className="batch-10-tt">Course Code:</Typography> */}
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "14rem",
                            backgroundColor: "#EFEFEF99",
                            height: "2.6rem",
                            marginLeft: "0%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Estimated Duration"
                          onChange={(text) =>
                            this.setState({ duration: text.target.value })
                          }
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </Typography>

              <center>
                <Button
                  onClick={() => {
                    this.setState({ addSubject: false });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #0000B3",
                    fontSize: "0.7rem",
                    marginTop: "4%",
                    width: "130px",
                    color: "#0000B3",
                    height: "35px",
                  }}
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ addSubject: false });

                    this.props.add_subject(
                      this.props.login.user_id,
                      this.props.courses.Course_Id,
                      this.state.subject_name,
                      this.state.duration
                    );
                  }}
                  style={{
                    backgroundColor: "#0000B3",
                    fontSize: "0.7rem",
                    marginTop: "4%",
                    width: "130px",
                    marginLeft: "3%",
                    height: "35px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>
              </center>
            </Grid>
          </Grid>
        </Dialog>

        {/* edit subject */}
        <Dialog
          open={this.state.editSubject}
          maxWidth="xl"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{}}
        >
          <Grid style={{ minWidth: "750px", height: "300px" }}>
            <div id="form-dialog-tt">
              <h2 style={{ position: "relative" }}>Course Details</h2>
            </div>
            <Grid>
              <h3 style={{ marginLeft: "5%" }}>Subject</h3>

              <Typography
                style={{
                  fontSize: "20px",
                  marginLeft: "4%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="details-13-tt">
                  <div className="details-12-tt">
                    <div className="details-11-tt">
                      {/* <Typography className="batch-10-tt">Title:</Typography> */}
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "27rem",
                            height: "2.6rem",
                            backgroundColor: "#EFEFEF99",
                            marginLeft: "0%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Subject Name"

                          value={this.state.subject_name}
                          onChange={(e) => { this.setState({ subject_name: e.target.value }) }}
                        />
                      </FormControl>
                    </div>
                    <div className="details-11-tt">
                      {/* <Typography className="batch-10-tt">Course Code:</Typography> */}
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "14rem",
                            backgroundColor: "#EFEFEF99",
                            height: "2.6rem",
                            marginLeft: "0%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Estimated Duration"
                          value={this.state.duration}
                          onChange={(e) => { this.setState({ duration: e.target.value }) }}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </Typography>

              <center>
                <Button
                  onClick={() => {
                    this.setState({ editSubject: false });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #0000B3",
                    fontSize: "0.7rem",
                    marginTop: "4%",
                    width: "130px",
                    color: "#0000B3",
                    height: "35px",
                  }}
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.props.edit_subject(
                      this.props.login.user_id,
                      this.props.courses.Course_Id,
                      this.state.subject_id,
                      this.state.subject_name,
                      this.state.duration,

                    );
                    this.setState({ editSubject: false });
                  }}
                  style={{
                    backgroundColor: "#0000B3",
                    fontSize: "0.7rem",
                    marginTop: "4%",
                    width: "130px",
                    marginLeft: "3%",
                    height: "35px",
                  }}
                  variant="contained"
                >
                  Update
                </Button>
              </center>
            </Grid>
          </Grid>
        </Dialog>

        {/* delete subject */}
        <Dialog
          open={this.state.deleteSubject} style={{}}>
          <Grid style={{ height: "250px", width: "520px" }}>
            <Box>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 600,
                  marginTop: "14%",
                }}
              >
                Are you sure you want to delete ?
              </Typography>
              <Typography
                id="modal-modal-description"
                style={{
                  marginTop: "2%",
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  color: "black",
                }}
              >
                This process will lead to loss of entire data, proceed at
                yours own risk
              </Typography>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ deleteSubject: false });
                  }}
                  style={{ width: "23%", color: "#0000B3" }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.props.delete_subject(
                      this.state.subject_id,
                      this.props.login.user_id,
                      this.props.courses.Course_Id
                    );
                    this.setState({ deleteSubject: false });
                  }}
                  style={{
                    marginLeft: 10,
                    width: "23%",
                    backgroundColor: "#0000B3",
                    color: "white",
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Grid>
        </Dialog>

        {/* add trainer */}
        <Dialog
          open={this.state.assignTrainer}
          maxWidth="xl"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{}}
        >
          <Grid style={{ width: "600px", height: "700px" }}>
            <h2 style={{ marginLeft: "10%" }}>Add Trainer</h2>

            {/* <Typography id="trainer-tab-tt-78">
              Post Graduation Program On Full Stack<br></br> Development With
              PHP Or Python
            </Typography> */}

            <div>
              <h3 style={{ marginLeft: "9rem" }}>Select Trainer</h3>
              {/* <TextField
                  style={{ width: "300px", height: "2vh", marginLeft: "24%" }}
                  id="outlined-select-currency-native"
                  select
                  value={this.state.trainer_name}
                  onChange={(e) => {
                    this.setState({ trainer_name: e.target.value });
                    this.props.searchStudent(this.props.student.all_students, e.target.value, "course")
                  }}
                >
                  <MenuItem value={"N"}>{"Select Trainer"}</MenuItem>
                  {Array.isArray(this.props.trainer.all_trainers) && this.props.trainer.all_trainers.map((e) => (
                    <MenuItem value={e._id}>{e.name}</MenuItem>
                  ))}
                 
                </TextField> */}


              <TextField
                style={{ width: "300px", height: "2vh", marginLeft: "24%" }}
                id="outlined-select-currency-native"
                select
                value={this.state.trainer_name}
                onChange={(e) => {
                  this.setState({ trainer_name: e.target.value })
                }}

              >
                <MenuItem value={"N"}>{"Select Trainer"}</MenuItem>
                {Array.isArray(this.props.trainer.all_trainers) && this.props.trainer.all_trainers.map((e) => (
                  <MenuItem value={e._id}>{e.name}</MenuItem>
                ))}

              </TextField>
              {/* <TextField
                  style={{ width: "300px", height: "2vh", marginLeft: "24%" }}
                  id="outlined-select-currency-native"
                  select
                  value={this.state.trainer_name}
                  onChange={(e) => {
                    this.setState({ trainer_name: e.target.value });
                  }}
                >
                  <MenuItem value={"N"}>{"Select Trainer"}</MenuItem>
                  {Array.isArray(this.props.trainer.all_trainers) &&
                    this.props.trainer.all_trainers.map((e) => (
                  <MenuItem >{e.trainer_name}</MenuItem>
                   ))}
                </TextField> */}
            </div>
            <div>
              <h3 style={{ marginLeft: "9rem", marginTop: 50 }}>Select Batch</h3>
              <Select
                style={{ width: "300px", marginLeft: "24%" }}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={this.state.personName}
                onChange={(e) => {
                  this.handleChange(e)
                  console.log(this.state.batch_id)
                }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {/* {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                    >
                      {name}
                    
                    </MenuItem>
                  ))} */}
                {/* <MenuItem value={"All Batch"}>{"All Batch"}</MenuItem> */}
                {Array.isArray(this.props.courses.batch_by_course) && this.props.courses.batch_by_course.map((item) => (
                  <MenuItem value={item.batch_number}
                    onClick={() => {
                      this.addBatchId(item._id)
                    }}
                  >{item.batch_number}</MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <h3 style={{ marginLeft: "9rem" }}>Select Subject</h3>
              <Select
                style={{ width: "300px", marginLeft: "24%" }}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={this.state.subjectName}
                onChange={this.handleChangesubject}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Array.isArray(this.props.courses.view_subject_by_id) && this.props.courses.view_subject_by_id.map((item) => (
                  <MenuItem value={item.subject_name}
                    onClick={() => {
                      this.addSubjectId(item._id)
                    }}
                  >{item.subject_name}</MenuItem>
                ))}

              </Select>
            </div>

            <Grid>
              <center>
                <Button
                  onClick={() => {
                    this.setState({ assignTrainer: false });
                  }}
                  style={{
                    border: "1px solid #0000B3",
                    fontSize: "0.7rem",
                    marginTop: "8%",
                    width: "130px",
                    color: "#8C8C8C",
                    height: "35px",
                    marginBottom: "10%"
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button

                  onClick={() => {
                    this.setState({ assignTrainer: false, trainer_name: "N", batch_id: [], subject_id: [] });

                    this.props.assignTo(this.props.login.user_id, this.state.trainer_name, this.state.batch_id, this.state.subject_id, this.state.page, this.state.rowsPerPage, this.props.courses.Course_Id)
                  }}
                  style={{
                    color: "white",
                    fontSize: "0.7rem",
                    marginTop: "8%",
                    width: "130px",
                    marginLeft: "3%",
                    height: "35px",
                    backgroundColor: "#0000B3",
                    marginBottom: "10%"
                  }}
                  variant="contained"
                >
                  Add
                </Button>
              </center>
            </Grid>
          </Grid>
        </Dialog>

        {/* edit trainer */}
        <Dialog
          open={this.state.edittrainer}
          maxWidth="xl"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{}}
        >
          <Grid style={{ width: "600px", height: "700px" }}>
            <h2 style={{ marginLeft: "10%" }}>Add Trainer</h2>

            <Typography id="trainer-tab-tt-78">
              {this.props.courses.course_name}
            </Typography>

            <div>
              <h3 style={{ marginLeft: "9rem" }}>Update Trainer</h3>
              <TextField
                style={{ width: "300px", height: "2vh", marginLeft: "24%" }}
                id="outlined-select-currency-native"
                select
                value={this.state.trainer_name}
                onChange={(e) => {
                  this.setState({ trainer_name: e.target.value })
                }}
              >
                <MenuItem value={"N"}>{"Select Trainer"}</MenuItem>
                {Array.isArray(this.props.trainer.all_trainers) && this.props.trainer.all_trainers.map((e) => (
                  <MenuItem value={e._id}>{e.name}</MenuItem>
                ))}

              </TextField>
            </div>
            <div>
              <h3 style={{ marginLeft: "9rem", marginTop: 50 }}>Select Batch</h3>
              <Select
                style={{ width: "300px", marginLeft: "24%" }}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={this.state.personName}
                onChange={(e) => {
                  this.handleChange(e)
                  console.log(this.state.batch_id)
                }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Array.isArray(this.props.courses.batch_by_course) && this.props.courses.batch_by_course.map((item) => (
                  <MenuItem value={item.batch_number}
                    onClick={() => {
                      this.addBatchId(item._id)
                    }}
                  >{item.batch_number}</MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <h3 style={{ marginLeft: "9rem" }}>Select Subject</h3>
              <Select
                style={{ width: "300px", marginLeft: "24%" }}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={this.state.subjectName}
                onChange={this.handleChangesubject}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Array.isArray(this.props.courses.view_all_subject) && this.props.courses.view_all_subject.map((item) => (
                  <MenuItem value={item.subject_name}
                    onClick={() => {
                      this.addSubjectId(item._id)
                    }}
                  >{item.subject_name}</MenuItem>
                ))}

              </Select>
            </div>

            <Grid>
              <center>
                <Button
                  onClick={() => {
                    this.setState({ edittrainer: false });
                  }}
                  style={{
                    border: "1px solid #0000B3",
                    fontSize: "0.7rem",
                    marginTop: "8%",
                    width: "130px",
                    color: "#8C8C8C",
                    height: "35px",
                    marginBottom: "10%"
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button

                  onClick={() => {
                    this.setState({ edittrainer: false,trainer_name:"", batch_id:[],subject_id:[]});
                    this.props.assignTo(this.props.login.user_id, this.state.trainer_name, this.state.batch_id, this.state.subject_id, this.state.page, this.state.rowsPerPage)
                  }}
                  style={{
                    color: "white",
                    fontSize: "0.7rem",
                    marginTop: "8%",
                    width: "130px",
                    marginLeft: "3%",
                    height: "35px",
                    backgroundColor: "#0000B3",
                    marginBottom: "10%"
                  }}
                  variant="contained"
                >
                  Update
                </Button>
              </center>
            </Grid>
          </Grid>
        </Dialog>

        {/* delete trainer */}
        <Dialog
          open={this.state.deleteTrainer} style={{}}>
          <Grid style={{ height: "250px", width: "520px" }}>
            <CloseOutlinedIcon
              onClick={() => {
                this.setState({ deleteTrainer: false });
              }}
              style={{
                marginLeft: "440px",
                marginTop: "20px",
                cursor: "pointer",
              }}
            />
            <Box>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 600,
                }}
              >
                Are you sure you want to delete ?
              </Typography>
              <Typography
                id="modal-modal-description"
                style={{
                  marginTop: "2%",
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  color: "black",
                }}
              >
                This process will lead to loss of entire data, proceed at
                yours own risk
              </Typography>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ deleteTrainer: false });
                  }}
                  style={{ width: "23%", color: "#0000B3" }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.props.delete_assign(this.props.courses.Course_Id, this.state.trainer_id)
                    this.setState({ deleteTrainer: false });
                  }}
                  style={{
                    marginLeft: 10,
                    width: "23%",
                    backgroundColor: "#0000B3",
                    color: "white",
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Grid>
        </Dialog>

        {/* add batch */}
        <Dialog
          open={this.state.addBatch}
          maxWidth="xl"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{}}
        >
          <Grid style={{ width: "650px", height: "400px" }}>
            <div id="form-dialog-tt">
              <h2 style={{ position: "relative", left: "1rem" }}>
                Add Batch
              </h2>
            </div>

            <Grid>
              <Typography
                style={{
                  fontSize: "20px",
                  marginLeft: "4%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="form-13-tt">
                  <div className="form-12-tt">
                    <div className="form-11-tt">
                      <Typography
                        style={{
                          marginLeft: "5%",
                          fontWeight: 600,
                          fontSize: "18px",
                        }}
                        className="form-10-tt"
                      >
                        Batch Number:
                      </Typography>
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "16rem",
                            height: "2.6rem",
                            backgroundColor: "#EFEFEF99",
                            marginLeft: "5%",
                          },
                        }}
                      >
                        <OutlinedInput
                          // type="number"
                          placeholder="Enter Batch Number"
                          onChange={(text) =>
                            this.setState({ batch_number: text.target.value })
                          }
                        />
                      </FormControl>
                    </div>
                    <div className="form-11-tt">
                      <Typography
                        style={{
                          marginLeft: "5%",
                          fontWeight: 600,
                          fontSize: "18px",
                        }}
                        className="form-10-tt"
                      >
                        {" "}
                        Batch Time:{" "}
                      </Typography>
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "16rem",
                            backgroundColor: "#EFEFEF99",
                            height: "2.6rem",
                            marginLeft: "5%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Enter Batch Time"
                          onChange={(text) =>
                            this.setState({ batch_time: text.target.value })
                          }
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-12-tt">
                    <div className="form-11-tt">
                      <Typography
                        style={{
                          marginLeft: "5%",
                          fontWeight: 600,
                          fontSize: "18px",
                        }}
                        className="form-10-tt"
                      >
                        {" "}
                        Start Date:{" "}
                      </Typography>
                      <div>
                        <input
                          type="date"
                          name="begin"
                          min="1997-01-01"
                          max="2030-12-31"
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                            height: "40px",
                            marginLeft: "4%",
                            width: "255px",
                            backgroundColor: "#EFEFEF99",
                          }}
                          onChange={(text) =>
                            this.setState({ start_date: text.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-11-tt">
                      <Typography
                        style={{
                          marginLeft: "5%",
                          fontWeight: 600,
                          fontSize: "18px",
                        }}
                        className="form-10-tt"
                      >
                        Duration:
                      </Typography>
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "16rem",
                            backgroundColor: "#EFEFEF99",
                            height: "2.6rem",
                            marginLeft: "5%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Enter Duration"
                          onChange={(text) =>
                            this.setState({ duration: text.target.value })
                          }
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </Typography>
              <center>
                <Button
                  onClick={() => {
                    this.setState({ addBatch: false });
                  }}
                  style={{
                    fontSize: "0.7rem",
                    border: "1px solid #0000B3",
                    marginTop: "4%",
                    width: "130px",
                    color: "#0000B3",
                    height: "35px",
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ addBatch: false });

                    this.props.addBatch(this.props.login.user_id, this.props.courses.Course_Id, this.state.batch_number, this.state.start_date, this.state.batch_time, this.state.duration, this.state.page, this.state.rowsPerPage,this.props.login.org_id)

                  }}
                  style={{
                    backgroundColor: "#0000B3",
                    color: "#FFFFFF",
                    fontSize: "0.7rem",
                    marginTop: "4%",
                    width: "130px",
                    marginLeft: "3%",
                    height: "35px",
                  }}
                  variant="contained"
                >
                  Add
                </Button>
              </center>
            </Grid>
          </Grid>
        </Dialog>

        {/* edit batch */}
        <Dialog
          open={this.state.editBatch}
          maxWidth="xl"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{}}
        >
          <Grid style={{ width: "650px", height: "400px" }}>
            <div id="form-dialog-tt">
              <h2 style={{ position: "relative", left: "1rem" }}>
                Edit Batch
              </h2>
            </div>

            <Grid>
              <Typography
                style={{
                  fontSize: "20px",
                  marginLeft: "4%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="form-13-tt">
                  <div className="form-12-tt">
                    <div className="form-11-tt">
                      <Typography
                        style={{
                          marginLeft: "5%",
                          fontWeight: 600,
                          fontSize: "18px",
                        }}
                        className="form-10-tt"
                      >
                        Batch Number:
                      </Typography>
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "16rem",
                            height: "2.6rem",
                            backgroundColor: "#EFEFEF99",
                            marginLeft: "5%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Enter Batch Number"
                          value={this.state.batch_number}
                          onChange={(e) => { this.setState({ batch_number: e.target.value }) }}
                        />
                      </FormControl>
                    </div>
                    <div className="form-11-tt">
                      <Typography
                        style={{
                          marginLeft: "5%",
                          fontWeight: 600,
                          fontSize: "18px",
                        }}
                        className="form-10-tt"
                      >
                        {" "}
                        Batch Time:{" "}
                      </Typography>
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "16rem",
                            backgroundColor: "#EFEFEF99",
                            height: "2.6rem",
                            marginLeft: "5%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Enter Batch Time"
                          value={this.state.batch_time}
                          onChange={(e) => { this.setState({ batch_time: e.target.value }) }}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-12-tt">
                    <div className="form-11-tt">
                      <Typography
                        style={{
                          marginLeft: "5%",
                          fontWeight: 600,
                          fontSize: "18px",
                        }}
                        className="form-10-tt"
                      >
                        {" "}
                        Start Date:{" "}
                      </Typography>
                      <div>
                        <input
                          type="date"
                          name="begin"
                          min="1997-01-01"
                          max="2030-12-31"
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                            height: "40px",
                            marginLeft: "4%",
                            width: "255px",
                            backgroundColor: "#EFEFEF99",
                          }}
                          value={this.state.start_date}
                          onChange={(e) => { this.setState({ start_date: e.target.value }) }}
                        />
                      </div>
                    </div>
                    <div className="form-11-tt">
                      <Typography
                        style={{
                          marginLeft: "5%",
                          fontWeight: 600,
                          fontSize: "18px",
                        }}
                        className="form-10-tt"
                      >
                        Duration:
                      </Typography>
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "16rem",
                            backgroundColor: "#EFEFEF99",
                            height: "2.6rem",
                            marginLeft: "5%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Enter Duration"
                          value={this.state.duration}
                          onChange={(e) => { this.setState({ duration: e.target.value }) }}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </Typography>
              <center>
                <Button
                  onClick={() => {
                    this.setState({ editBatch: false });
                  }}
                  style={{
                    fontSize: "0.7rem",
                    border: "1px solid #0000B3",
                    marginTop: "4%",
                    width: "130px",
                    color: "#0000B3",
                    height: "35px",
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button

                  onClick={() => {
                    this.setState({ editBatch: false });
                    this.props.editBatch(this.state.batch_number, this.state.start_date, this.state.batch_time, this.state.duration, this.state.batch_id, this.state.page, this.state.rowsPerPage, this.props.courses.Course_Id)

                  }}
                  style={{
                    backgroundColor: "#0000B3",
                    color: "#FFFFFF",
                    fontSize: "0.7rem",
                    marginTop: "4%",
                    width: "130px",
                    marginLeft: "3%",
                    height: "35px",
                  }}
                  variant="contained"
                >
                  Update
                </Button>
              </center>
            </Grid>
          </Grid>
        </Dialog>

        {/* delete batch */}
        <Dialog
          open={this.state.deleteBatch} style={{}}>
          <Grid style={{ height: "250px", width: "520px" }}>
            <Box>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 600,
                  marginTop: "14%",
                }}
              >
                Are you sure you want to delete ?
              </Typography>
              <Typography
                id="modal-modal-description"
                style={{
                  marginTop: "2%",
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  color: "black",
                }}
              >
                This process will lead to loss of entire data, proceed at
                yours own risk
              </Typography>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ deleteBatch: false });
                  }}
                  style={{
                    width: "23%",
                    color: "#0000B3",
                    borderColor: "0000B3",
                  }}
                >
                  No
                </Button>
                <Button
                  variant="contained"

                  onClick={() => {
                    this.setState({ deleteBatch: false });
                    this.props.deleteBatch(this.state.batch_id, this.props.courses.Course_Id, this.state.page, this.state.rowsPerPage,this.props.login.org_id)
                    console.log("deletebatch",this.state.batch_id, this.props.courses.Course_Id, this.state.page, this.state.rowsPerPage,this.props.login.org_id)
                  }}
                  style={{
                    marginLeft: 10,
                    width: "23%",
                    backgroundColor: "#0000B3",
                    color: "white",
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Grid>
        </Dialog>
        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </Grid>
    );
  }
}
