import React, { Component } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper, Card,
  Rows, DialogActions, DialogContent, DialogTitle,
  Dialog, Grid, Box, Typography, TextField, Divider,
} from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Pagination from "@mui/material/Pagination";

import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import { Textarea } from "theme-ui";
import { Link } from "react-router-dom";
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";

export default class studenthelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab1: false,
      tab2: false,
      type: "Trainer",
      any_url: "",
      addticket: false,
      viewticket: false,
      message: "",
      name: "",
      document: "",
      // statuss: "I"
      page: 0,
      setPage: 0,
      rowsPerPage: 10,
      setRowsPerPage: "",
      anchorEl: null,
      open: false,


    }
  }
  openaddPlacementBtn = () => {
    this.setState({
      addplacementBtn: true,
    });
  };

  handelToggelBtn = () => {
    this.setState({
      toogelbtnColor: !this.state.toogelbtnColor,
      trainerStudentSetionOpenOff: !this.state.trainerStudentSetionOpenOff,
    });
  };

  handleCompanyDeleteModalOpen = () => {
    this.setState({ companydeleteModalOpen: true });
  };

  handleStudentDeleteModalOpen = () => {
    this.setState({ studentdeleteModalOpen: true });
  };
  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"
    this.props.viewTicketByUserId(this.props.login.user_id, this.state.page, this.state.rowsPerPage,this.props.login.org_id);
    console.log("hhh", this.props.tickets.ticket_by_id)
  }
  render() {
    const { tickets, snackbar, close_snack_bar } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const handleChangePage = (event, newPage) => {
      this.props.viewTicketByUserId(this.props.login.user_id, newPage - 1, this.state.rowsPerPage,this.props.login.org_id);
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };
    return (

      <Grid style={{ marginTop: "10%" }}>
        <Typography style={{ color: "black", fontSize: "29px", margin: "1% 0% 0% 2%", fontWeight: 450 }}>Help & Support</Typography>
        <Card style={{ margin: "1% 2.5% 2.5% 0%", padding: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", padding: "1%" }}>
            <Typography variant="h5" fontWeight={500}>Tickets</Typography>
            <Button
              onClick={() => {
                this.setState({
                  addticket: true,
                });
              }}
              style={{
                borderRadius: "5px",
                border: "1px solid var(--secondary, #0000B3)",
                height: "2.25rem",
                marginBottom: "1%",
                backgroundColor: "#0000B3",
              }}
              variant="contained"
            >
              + Raise Ticket
            </Button>
          </div>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#F8F7F7", }}>
                  <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                    NAME
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                    DATE
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                    COMPLAIN
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                    STATUS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(tickets.ticket_by_id) && tickets.ticket_by_id.map((item, index) => {
                  return (
                    <TableRow style={{ textDecoration: "none" }} className="table-row">
                      <TableCell
                        style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}
                        align="center"
                      >
                        {item.user_details.name}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                        }}
                        align="center"
                      >
                        {item.date}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                        }}
                        align="center"
                      >
                        {item.message}
                      </TableCell>
                      {item.status == "Completed" && <TableCell
                        style={{
                          color: "#5F5A5A", fontSize: "15px", letterSpacing: 1, cursor: "pointer"
                        }}
                        align="center"
                        onClick={() => {
                          this.setState({
                            viewticket: true,
                            ticket_id: item._id,
                            name: item.user_details.name,
                            date: item.date,
                            complain: item.message,
                            document: item.document

                          })
                        }}
                      >
                        Completed
                      </TableCell>}
                      {item.status == "InCompleted" && <TableCell
                        style={{
                          color: "red", fontSize: "15px", letterSpacing: 1, cursor: "pointer"
                        }}
                        align="center"
                        onClick={() => {
                          this.setState({
                            viewticket: true,
                            ticket_id: item._id,
                            name: item.user_details.name,
                            date: item.date,
                            complain: item.message,
                            document: item.document
                          })
                        }}
                      >
                        Incompleted
                      </TableCell>}

                    </TableRow>
                  );
                })}

              </TableBody>
            </Table>
          </TableContainer>

          <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
            <Pagination
              count={parseInt(tickets.ticket_length / 10) + 1}
              siblingCount={0}
              size="small"
              onChange={handleChangePage}
            />
          </div>
        </Card>


        {/*------------------------------------------------ Ticket---------------------------------------------------------*/}
        {/*View*/}
        <Dialog open={this.state.viewticket}
          maxWidth="sm" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}>
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                    Complain
                  </Typography>
                </Grid>
                <Grid item xs={1} justifyContent={"center"}>
                  <CloseOutlinedIcon
                    onClick={() => {
                      this.setState({ viewticket: false });
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container >
                <Grid item xs={1.5}>
                </Grid>
                <Grid item xs={9} direction={'column'}>
                  {/* <TextField
                    fullWidth
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      borderRadius: "5px",
                      backgroundColor: "rgba(239, 239, 239, 0.60)"
                    }}
                    value={this.state.name}
                  >
                  </TextField> */}
                  <Textarea
                    size="lg"
                    fullWidth
                    sx={{
                      height: "150px",
                      marginTop: 3,
                      backgroundColor: "white",
                      border: "1px solid #ACACAC",
                      borderRadius: "5px",
                    }}
                    value={this.state.complain}
                  />
                  <Box marginTop={3}>
                    <Button variant="outlined" component="label" style={{ color: "#c4c4c4", border: "1px solid #c4c4c4", height: "50px", flexDirection: "row-reverse", justifyContent: "space-between" }}
                      startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>
                      <a href={(this.state.document).name}>Attachment</a>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>


          </Grid>
        </Dialog>

        {/*Add*/}
        <Dialog open={this.state.addticket}
          maxWidth="sm" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}>
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                    Complain
                  </Typography>
                </Grid>
                <Grid item xs={1} justifyContent={"center"}>
                  <CloseOutlinedIcon
                    onClick={() => {
                      this.setState({ addticket: false });
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container >
                <Grid item xs={1.5}>
                </Grid>
                <Grid item xs={9} direction={'column'}>
                  {/* <TextField
                    fullWidth
                    placeholder="Enter your name"
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      borderRadius: "5px",
                      backgroundColor: "rgba(239, 239, 239, 0.60)"
                    }}
                    onChange={(text) =>
                      this.setState({ name: text.target.value })}
                  >
                  </TextField> */}
                  <Textarea
                    size="lg"
                    fullWidth
                    placeholder="Enter your complain"
                    sx={{
                      height: "150px",
                      marginTop: 3,
                      backgroundColor: "white",
                      border: "1px solid #ACACAC",
                      borderRadius: "5px",
                    }}
                    onChange={(e) =>
                      this.setState({ message: e.target.value })}
                  />
                  <Box marginTop={3}>
                    {this.state.any_url === "" ?
                      <Button fullWidth variant="outlined" component="label" style={{ color: "#c4c4c4", border: "1px solid #c4c4c4", height: "50px", justifyContent: "space-between" }}
                        endIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M16.5002 18.25H14.9902C14.5802 18.25 14.2402 17.91 14.2402 17.5C14.2402 17.09 14.5802 16.75 14.9902 16.75H16.5002C19.1202 16.75 21.2502 14.62 21.2502 12C21.2502 9.38 19.1202 7.25 16.5002 7.25H15.0002C14.5902 7.25 14.2502 6.91 14.2502 6.5C14.2502 6.09 14.5802 5.75 15.0002 5.75H16.5002C19.9502 5.75 22.7502 8.55 22.7502 12C22.7502 15.45 19.9502 18.25 16.5002 18.25Z" fill="#989898" />
                          <path d="M9 18.25H7.5C4.05 18.25 1.25 15.45 1.25 12C1.25 8.55 4.05 5.75 7.5 5.75H9C9.41 5.75 9.75 6.09 9.75 6.5C9.75 6.91 9.41 7.25 9 7.25H7.5C4.88 7.25 2.75 9.38 2.75 12C2.75 14.62 4.88 16.75 7.5 16.75H9C9.41 16.75 9.75 17.09 9.75 17.5C9.75 17.91 9.41 18.25 9 18.25Z" fill="#989898" />
                          <path d="M16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#989898" />
                        </svg>}>
                        {this.state.document !== "" ? (this.state.document).name : "Attachment File"}
                        <input hidden type="file" multiple
                          onChange={(e) => { this.setState({ document: e.target.files[0] }) }}
                        />
                      </Button>
                      :
                      <Button variant="outlined" component="label" style={{ color: "#c4c4c4", border: "1px solid #c4c4c4", height: "50px", flexDirection: "row-reverse", justifyContent: "space-between" }}
                        startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }}
                        />}>
                        {this.state.document !== "" ? (this.state.document).name : "Attachment File"}
                      </Button>
                    }
                  </Box>
                  <DialogActions style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 3, marginTop: 10 }} >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#0000B3",
                        fontSize: "15px",
                        height: "38px",
                        width: "120px",
                        color: "white",
                        marginLeft: "2%",

                      }}
                      onClick={() => {
                        this.setState({ addticket: false, document: "", message: "" })
                        this.props.uploadDocument(this.props.login.user_id, this.state.document, this.state.message, this.state.page, this.state.rowsPerPage,this.props.login.org_id)
                        console.log("sdfgh", this.props.login.user_id, this.state.document, this.state.message, this.state.page, this.state.rowsPerPage,this.props.login.org_id)
                      }}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </DialogContent>


          </Grid>
        </Dialog>
      </Grid>


    );
  }
}
