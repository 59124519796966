import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
export const CLEAR_LOGIN = "CLEAR_LOGIN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_ADMIN_ID = "SET_ADMIN_ID";
export const HANDLEDRAWER = "HANDLEDRAWER";
export const SETMOBILE = "SETMOBILE";
export const SET_ALL_ORG = "SET_ALL_ORG";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_ORG_DETAILS = "SET_ORG_DETAILS";
export const SET_ORG_USER_DEATILS = "SET_ORG_USER_DEATILS";
export const SET_ORG_ID = "SET_ORG_ID";
export const SET_ALL_DATA = "SET_ALL_DATA";
export const SET_USER = "SET_USER";
export const SET_ORG = "SET_ORG";
export const SETLOGINORG = "SETLOGINORG";
export const SET_ORG_USER_DETAILS = "SET_ORG_USER_DETAILS";
export const SET_ORG_DETAILS2 = "SET_ORG_DETAILS2";
export const VIEW_ALL_DEPARTMENT = "VIEW_ALL_DEPARTMENT";
export const VIEW_ALL_PRIORITY = "VIEW_ALL_PRIORITY";
export const VIEW_ALL_STATUS = "VIEW_ALL_STATUS";
export const SET_DOC = "SET_DOC";
export const SET_WALET_BY_ID = "SET_WALET_BY_ID";
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const LOGIN_STATUS = "LOGIN_STATUS";

// export const VERIFY_OTP = "VERIFY_OTP";

// export const VIEW_ALL_PROJECT_TYPE = "VIEW_ALL_PROJECT_TYPE";
const firebaseConfig = {

  apiKey: "AIzaSyArs9B57xLoSpKiVzK84MdfSBvUUMvMG78",

  authDomain: "tb-lms.firebaseapp.com",

  projectId: "tb-lms",

  storageBucket: "tb-lms.appspot.com",

  messagingSenderId: "694258443170",

  appId: "1:694258443170:web:acbe312cab2d7f3edac8fb",

  measurementId: "G-ETQYE290PM"

};

export const app = initializeApp(firebaseConfig);
// export const fb = firebase.initializeApp(firebaseConfig);