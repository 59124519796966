import React, { Component } from "react";
import { connect } from "react-redux";
import Help from "../Components/help";
import { close_snack_bar } from "../../../common/snackbar/action";
import {
    viewAllTicket,
    dateFilter,
    updateTicketStatusByTicketID
} from "../action";

export class Controller extends Component {
    render() {
        return (
            <Help {...this.props} />
        );
    }
}

export const mapStateToProps = store => {
    return {
        tickets: store.tickets,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        viewAllTicket: (page_number, rowper_page,org_id) => {
            dispatch(viewAllTicket(page_number, rowper_page,org_id));
        },
        dateFilter: (all_tickets, e) => {
            dispatch(dateFilter(all_tickets, e));
        },
        updateTicketStatusByTicketID: (ticket_id, status, page_number, rowper_page,org_id) => {
            dispatch(updateTicketStatusByTicketID(ticket_id, status, page_number, rowper_page,org_id));
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
