import React, { Component } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import {
  Table,
  Card,
  Grid,
  Select,
  MenuItem,
  DialogActions, TableContainer, DialogTitle, DialogContent,
  Box, TableBody
} from "@mui/material/";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Typography } from "@material-ui/core";
import "../styles/trainer.css";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Menu from "@mui/material/Menu";
import Pagination from '@mui/material/Pagination';
import Camera from "../../Images/camera.png";
import {
  Navigate,
  Redirect,
  redirect,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";

const options = ["Edit", "Delete"];

// const navigation=useNavigation();

export default function TrainerMain(props) {
  const navigation = useNavigate();
  return <Trainer {...props} navigate={navigation} />;
}

class Trainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      traineradd: false,
      traineredit: false,
      trainerdelete: false,
      menu: false,
      admin_course: "N",
      admin_subject: "N",
      admin_id: "",
      name: "",
      phone: "",
      email: "",
      joining_date: "",
      trainer_id: "",
      page_number: "",
      profile_pic: "",
      old_profile_pic: '',
      page: 0,
      setPage: 0,
      rowsPerPage: 10,
      setRowsPerPage: "",
      anchorEl: null,
      open: false,

      trainerdata: [
        {
          name: "Ronald Richards",
          phoneNo: "+918555286528",
          emailid: "georgia.young@example.com",
        },
        {
          name: "Ronald Richards",
          phoneNo: "+918555286528",
          emailid: "georgia.young@example.com",
        },
        {
          name: "Ronald Richards",
          phoneNo: "+918555286528",
          emailid: "georgia.young@example.com",
        },
        {
          name: "Ronald Richards",
          phoneNo: "+918555286528",
          emailid: "georgia.young@example.com",
        },
        {
          name: "Ronald Richards",
          phoneNo: "+918555286528",
          emailid: "georgia.young@example.com",
        },
      ]

    };
    this.anchorRef = React.createRef();

  }

  handleClick1 = (event) => {
    this.setState({
      anchorETRAINER: event.currentTarget,
      menu: true,
    });
  };

  handleClose1 = () => {
    this.setState({
      anchorETRAINER: null,
      menu: false,
    });
  };

  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"
    this.props.viewAllTrainer(this.props.login.user_id, this.state.page, this.state.rowsPerPage, this.props.login.org_id);
  }

  render() {
    const { trainer, snackbar, close_snack_bar } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const handleChangePage = (event, newPage) => {
      this.props.viewAllTrainer(this.props.login.user_id, newPage - 1, this.state.rowsPerPage, this.props.login.org_id);
      this.setState({
        page: newPage,
      });
    };

    return (
      <Grid style={{ marginTop: "10%" }}>
        <Card
          style={{ margin: "1% 2.5% 2.5% 0%" }}
        >
          <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
            <Typography style={{ color: "black", fontSize: "29px", margin: "1% 0% 0% 2%", fontWeight: 450 }}>
              Trainer
            </Typography>
            <Paper
              component="form"
              style={{
                width: "436px",
                height: "36px",
                marginLeft: "auto",
                marginTop: "1%",
                backgroundColor: "#F3F3F3",
                borderRadius: "10px"
              }}
            >
              <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                <SearchIcon/>
              </IconButton>
              <InputBase
                style={{ ml: 5, flex: 1, fontSize: "15px" }}
                placeholder="Search "
                inputProps={{ "aria-label": "Search " }}
                onChange={(e) => this.props.searchTrainer(trainer.all_trainers, e.target.value)}

              />
            </Paper>
            <Button
              onClick={() => {
                this.setState({
                  traineradd: true,
                });
              }}
              style={{
                borderRadius: "5px",
                border: "1px solid var(--secondary, #0000B3)",
                height: "2.25rem",
                margin: "1% 1.5% 0% 1%",
                color: "#0000B3",
              }}
              variant="outlined"
            >
              Add Trainer
            </Button>
          </div>

          <div>
            <TableContainer>
              <Table
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F8F7F7", }}>
                    <TableCell style={{ color: "#766C6C" }}>
                      <span style={{ marginLeft: "17%" }}>Name</span>
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      Phone No
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      Email ID
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>

                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {trainer.search_trainer.length === 0 &&
                    <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                      <Typography>No Result Found</Typography>
                    </Grid>
                  }
                  {Array.isArray(trainer.search_trainer) && trainer.search_trainer.map((item, index) => {
                    // console.log("ttttttttttt",trainer.search_trainer)
                    return (
                      <TableRow style={{ textDecoration: "none", borderBottom: "1px solid #E3E3E3" }}
                      >
                        <TableCell
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Avatar
                            alt={item.name} src={item.profile_pic}
                            style={{ width: 30, height: 30 }} >
                          </Avatar>
                          <Typography style={{ fontWeight: "600", color: "black", whiteSpace: "nowrap", marginLeft: "8%" }}>{item.name}</Typography>
                        </TableCell>

                        <TableCell
                          style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}
                          align="center"
                        >
                          +{item.mobile}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                          }}
                          align="center"
                        >
                          {item.email_id}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Button

                            onClick={(e) => {
                              this.handleClick1(e);
                              this.setState({
                                trainer_id: item._id,
                                name: item.name,
                                phone: item.mobile,
                                email: item.email_id,
                                old_profile_pic: item.profile_pic,


                              })

                            }
                            }
                          >
                            <MoreVertIcon style={{ color: "#747474", width: "1rem", height: "1rem" }} />
                          </Button>

                          <Menu
                            anchorEl={this.state.anchorETRAINER}
                            open={this.state.menu}
                            onClose={this.handleClose1}
                          >
                            <MenuItem
                              style={{ color: "var(--secondary, #0000B3)", fontWeight: "500", fontSize: "15px" }}
                              onClick={() => {
                                this.setState({
                                  traineredit: true,
                                  menu: false
                                });
                              }}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem
                              style={{ color: "var(--secondary, #0000B3)", fontWeight: "500", fontSize: "15px" }}
                              onClick={() => {
                                this.setState({
                                  trainerdelete: true,
                                  menu: false
                                });
                              }}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>

                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
            <Pagination
              count={parseInt(trainer.trainer_length / 10) + 1}

              siblingCount={0}
              size="small"
              onChange={handleChangePage}
            />
          </div>
        </Card>

        {/*------------------------------------------------ Trainer Dialog ---------------------------------------------------------*/}

        {/*Add*/}
        <Dialog open={this.state.traineradd} maxWidth="xs" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                    Add Trainer
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container >
                <Grid item xs={12} direction='column'>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                    <Avatar variant="soft"
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%"
                      }}
                    >
                      <center>
                        <input style={{ display: "none", cursor: "pointer" }} type="file" id="file"
                          accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"
                          onChange={(e) => {
                            this.setState({
                              profile_pic: e.target.files[0]
                            })
                          }}
                        />
                        <label htmlFor="file">
                          <img
                            src={this.state.profile_pic !== "" ? URL.createObjectURL(this.state.profile_pic) : Camera}
                            style={{
                              height: "60px",
                              width: "60px",
                              alignItems: "center",
                              borderRadius: "50%"

                            }}
                          />
                        </label>
                      </center>
                    </Avatar>
                    <span style={{ color: "var(--primery, #006)", fontSize: "0.85rem" }}>
                      {this.state.profile_pic !== "" ? (this.state.profile_pic).name : "Upload"}
                    </span>
                  </div>
                  <div>
                    <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Full Name</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter Name"
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        borderRadius: "5px",
                        backgroundColor: "rgba(239, 239, 239, 0.60)"
                      }}
                      onChange={(text) =>
                        this.setState({ name: text.target.value })}
                    >
                    </TextField>
                  </div>
                  <Grid container >
                    <Grid item xs={5.5} direction='column'>
                      <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Phone No</Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter Phone No"
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)"
                        }}
                        onChange={(text) =>
                          this.setState({ phone: text.target.value })}
                      >
                      </TextField>
                    </Grid>
                    <Grid item xs={1}> </Grid>
                    <Grid item xs={5.5} direction='column'>
                      <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Email Id</Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter Email ID"
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)"
                        }}
                        onChange={(text) =>
                          this.setState({ email: text.target.value })}
                      >
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: "center", marginBottom: 8, marginTop: 8 }} >
              <Button
                variant="outlined"
                style={{
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "#0000B3",
                  border: "1px solid #0000B3",
                }}
                onClick={() => {
                  this.setState({ traineradd: false });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  height: "38px",
                  width: "160px",
                  color: "white",
                  marginLeft: "2%",

                }}
                disabled={this.state.name == "" || this.state.phone == ""}
                onClick={() => {
                  this.setState({ traineradd: false, profile_pic: "", name: "", phone: "", email: "" });
                  this.props.uploadImageTags(this.props.login.user_id, this.state.name, this.state.phone, this.state.email, this.state.profile_pic, this.props.login.org_id)
                }}
              >
                Add
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>

        {/*Edit*/}
        <Dialog open={this.state.traineredit} maxWidth="xs" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                    Edit Trainer
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container >
                <Grid item xs={12} direction='column'>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                    <Avatar variant="soft"
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%"
                      }}
                    >
                      <center>
                        <input style={{ display: "none", cursor: "pointer" }} type="file" id="file"
                          accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"
                          onChange={(e) => {
                            this.setState({
                              profile_pic: e.target.files[0]
                            })
                          }}
                        />
                        <label htmlFor="file">
                          <img
                            src={this.state.profile_pic !== "" ? URL.createObjectURL(this.state.profile_pic) : this.state.old_profile_pic}
                            style={{
                              height: "60px",
                              width: "60px",
                              alignItems: "center",
                              borderRadius: "50%"

                            }}
                          />
                        </label>
                      </center>
                    </Avatar>
                    <span style={{ color: "var(--primery, #006)", fontSize: "0.85rem" }}>
                      {this.state.profile_pic !== "" ? (this.state.profile_pic).name : "Change"}
                    </span>
                  </div>
                  <div>
                    <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Full Name</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter Name"
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        borderRadius: "5px",
                        backgroundColor: "rgba(239, 239, 239, 0.60)"
                      }}
                      value={this.state.name}
                      onChange={(e) => {
                        this.setState({ name: e.target.value })
                      }}
                    >
                    </TextField>
                  </div>
                  <Grid container >
                    <Grid item xs={5.5} direction='column'>
                      <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Phone No</Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter Phone No"
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)"
                        }}
                        value={this.state.phone}
                        onChange={(e) => {
                          this.setState({ phone: e.target.value })
                        }}
                      >
                      </TextField>
                    </Grid>
                    <Grid item xs={1}> </Grid>
                    <Grid item xs={5.5} direction='column'>
                      <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Email Id</Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter Email ID"
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)"
                        }}
                        value={this.state.email}
                        onChange={(e) => {
                          this.setState({ email: e.target.value })
                        }}
                      >
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: "center", marginBottom: 8, marginTop: 8 }} >
              <Button
                variant="outlined"
                style={{
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "#0000B3",
                  border: "1px solid #0000B3",
                }}
                onClick={() => {
                  this.setState({ traineredit: false });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  height: "38px",
                  width: "160px",
                  color: "white",
                  marginLeft: "2%",

                }}
                disabled={this.state.name == "" || this.state.phone == "" || this.state.email == ""}
                onClick={() => {
                  this.setState({
                    traineredit: false, name: "", phone: "", email: ""
                  });
                  this.props.editUploadImageTags( this.state.trainer_id, this.state.name, this.state.phone, this.state.email, this.state.profile_pic, this.state.old_profile_pic, this.props.login.org_id,this.props.login.user_id)
                }}
              >
                Update
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>

        {/*Delete*/}
        <Dialog open={this.state.trainerdelete} maxWidth="sm" fullWidth
          sx={{


            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={12} >
                  <Typography style={{ fontSize: "22px", fontWeight: "600", color: "#222222" }}>
                    Delete
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ flexDirection: "row", textAlign: "center" }}>
              <Grid container direction={"column"} spacing={1}>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "18px", fontWeight: 600, fontFamily: "" }}>Are you sure you want to delete ?</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>This process will lead to loss of entire data, proceed at yours
                    own risk</Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center', margin: '5% 0% 5% 0%' }}>
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({ trainerdelete: false });
                }}
                style={{
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "#0000B3",
                  border: "1px solid #0000B3",
                }}
              >
                No
              </Button>
              <Button
                variant="contained"

                onClick={() => {
                  this.setState({ trainerdelete: false });
                  this.props.deteteTrainer(this.props.login.user_id, this.props.login.org_id, this.state.trainer_id)
                }}
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "white",
                  marginLeft: "2%",
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>

        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </Grid >
    );
  }
}
