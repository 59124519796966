import React, { Component } from "react";
// import Counter from "../components/Counter";
import { connect } from "react-redux";
import DrawerOptions from "../components/Drawer2";

import { handleDrawerToggle, updateUserProfile, uploadImage } from "../../auth/actions";

export class Drawer_option_con extends Component {

  render() {
    return (
      <DrawerOptions {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {

    login: store.login
  };
};

export const mapDispatchToProps = dispatch => {
  return {

    handleDrawerToggle: (mobileOpen) => {
      dispatch(handleDrawerToggle(mobileOpen));
    },
    updateUserProfile: (admin_id, name, profile_pic) => {
      dispatch(updateUserProfile(admin_id, name, profile_pic));
    },
    uploadImage: (admin_id, name, profile_pic) => {
      dispatch(uploadImage(admin_id, name, profile_pic));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer_option_con);