import React, { Component } from "react";
import { FaGreaterThan } from "react-icons/fa";
import "../styles/Companyview.scss";
import { ImCross } from "react-icons/im";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Button from "@mui/material/Button";
import Pagination from '@mui/material/Pagination';
import {
  Typography,
  Dialog,
  FormControl,
  Grid,
  Box,
  Card,
  DialogActions, DialogTitle, DialogContent
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";

export default class Companyview extends Component {
  constructor(props) {
    super(props);
    this.state = {

      deletecompanyview: false,
      editcompanyview: false,

      student_name: "",
      apply_date: "",
      status: "",
      student_id: "",
      statuss: "",

      page: 0,
      setPage: 0,
      rowsPerPage: 10,
      setRowsPerPage: "",
      anchorEl: null,
      open: false,


      companyviewdata: [
        {
          num: "1",
          studentname: "Rani Das",
          appliedon: "20 feb 23",
          applicationstatus: "Not Joined"
        },
        {
          num: "1",
          studentname: "Rani Das",
          appliedon: "20 feb 23",
          applicationstatus: "Hired"
        },
        {
          num: "1",
          studentname: "Rani Das",
          appliedon: "20 feb 23",
          applicationstatus: "Not Selected"
        },
      ]

    };
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"
    this.props.viewAllApplied(this.props.placement.placement_id, this.state.page, this.state.rowsPerPage);
  }


  render() {
    const { placement, snackbar, close_snack_bar } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const handleChangePage = (event, newPage) => {
      this.props.viewAllApplied(this.props.placement.placement_id, newPage - 1, this.state.rowsPerPage);
      this.setState({
        page: newPage,
      });
    };

    return (
      <Grid style={{ marginTop: "10%" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Link style={{ textDecoration: "none" }} to="/Company">
            <Button style={{ color: "#7A7E86" }}>Placement Company</Button>
          </Link>
          <ArrowForwardIosIcon
            style={{ fontSize: "20px", marginTop: "10px" }}
          />
          <Link style={{ textDecoration: "none" }} to="/Placement">
            <Button style={{ color: "#7A7E86" }}>Company Name</Button>
          </Link>
          <ArrowForwardIosIcon
            style={{ fontSize: "20px", marginTop: "10px" }}
          />
          <Link style={{ textDecoration: "none" }} to="">
            <Button style={{ color: "black" }}>Job Profile</Button>
          </Link>
        </div>

        <Card
          style={{ margin: "1% 2.5% 2.5% 0%" }}
        >
          <div style={{ marginLeft: "2%" }}>

            <Typography variant="h4"
              style={{ marginTop: "3%" }}
            >
              Job Profile: {this.props.placement.job_profile}
            </Typography>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}
            >
              <Typography style={{ fontWeight: "bolder" }}>
                Company Name: {this.props.placement.company_name}
              </Typography>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}
            >
              <Typography style={{ fontWeight: "bolder" }}>
                Company Email: {this.props.placement.company_email}
              </Typography>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}
            >
              <Typography style={{ fontWeight: "bolder" }}>
                Link to apply:
                <Link style={{ textDecoration: "none" }}> {this.props.placement.apply_link}</Link>
              </Typography>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}
            >
              <Typography style={{ fontWeight: "bolder" }}>
                Last Date to Apply: {this.props.placement.last_date}
              </Typography>
            </div>
            <div style={{ marginTop: "2%" }}>
              <Typography style={{ fontWeight: "bolder" }}>
                Job Description:
              </Typography>
              <Typography style={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "18px" }}>
                {this.props.placement.description}
              </Typography>
            </div>
          </div>

          <div>
            <Typography variant="h6" fontWeight={500} margin={"2%"}>Students Applied</Typography>
            <Table>
              <TableHead style={{ backgroundColor: "#F8F7F7" }}>
                <TableRow >
                  <TableCell style={{ width: "10%" }} align="center">
                    No
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    Student Name
                  </TableCell>
                  <TableCell style={{ width: "15%" }} align="center">
                    Applied On
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    Application Status
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(placement.all_applied) && placement.all_applied.map((item, index) => {
                  return (
                    <TableRow>
                      <TableCell style={{ width: "10%" }} align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ width: "10%" }} align="center">
                        {item.student_details.name}
                      </TableCell>
                      <TableCell style={{ width: "10%" }} align="center">
                        {item.apply_date}
                      </TableCell>
                      <TableCell style={{ textAlign: "center", fontSize: "0.9375rem", alignItems: "center", letterSpacing: 1 }} >
                        {item.status === "Hired" &&
                          <span style={{ padding: "0.25rem 0.5rem", borderRadius: "0.125rem", border: "1px solid #62BC37", color: "#62BC37" }}>{item.status}</span>
                        }
                        {item.status === "Not Selected" &&
                          <span style={{ padding: "0.25rem 0.5rem", borderRadius: "0.125rem", border: "1px solid black", }}>{item.status}</span>
                        }
                        {item.status === "Not Joined" &&
                          <span style={{ padding: "0.25rem 0.5rem", borderRadius: "0.125rem", border: "1px solid #788931", color: "#788931" }}>{item.status}</span>
                        }
                        {item.status === "Applied" &&
                          <span style={{ padding: "0.25rem 0.5rem", borderRadius: "0.125rem", border: "1px solid blue", color: "blue" }}>{item.status}</span>
                        }
                      </TableCell>
                      <TableCell align="center">
                        <span>
                          <EditOutlinedIcon
                            onClick={() => {
                              this.setState({
                                editcompanyview: true,
                                applied_id: item._id,
                                statuss: item.placement_details.status

                              });
                            }}
                            style={{
                              cursor: "pointer",
                              color: "#3B3BC4",
                              marginRight: 10
                            }}
                          />
                        </span>

                        <span>
                          <DeleteOutlineOutlinedIcon
                            onClick={() => {
                              this.setState({
                                deletecompanyview: true,

                              });
                            }}
                            style={{ cursor: "pointer", color: "C43232" }}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>



            <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
              <Pagination
                count={parseInt(placement.applied_length / 10) + 1}
                siblingCount={0}
                size="small"
                onChange={handleChangePage}
              />
            </div>
          </div>
        </Card>


        {/*---------------------------------------------------------- Edit ------------------------------------------------------------- */}
        <Dialog
          open={this.state.editcompanyview} maxWidth="xs" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                    Application Status
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container >
                <Grid item xs={12} direction='column'>
                  <div>
                    <TextField
                      fullWidth
                      id="outlined-select-currency-native"
                      select
                      value={this.state.statuss}
                      onChange={(e) => {
                        this.setState({ statuss: e.target.value });
                      }}
                    >
                      <MenuItem value="Hired">Hired</MenuItem>
                      <MenuItem value="Not Joined">Not Joined</MenuItem>
                      <MenuItem value="Not Selected">Not Selected</MenuItem>
                    </TextField>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: "center", marginBottom: 8, marginTop: 8 }} >
              <Button
                variant="outlined"
                style={{
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "#0000B3",
                  border: "1px solid #0000B3",
                }}
                onClick={() => {
                  this.setState({ editcompanyview: false });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  height: "38px",
                  width: "160px",
                  color: "white",
                  marginLeft: "2%",

                }}
                onClick={() => {
                  this.setState({ editcompanyview: false})
                  this.props.updateAppliedStatus(this.props.placement.placement_id, this.state.applied_id, this.state.statuss, this.state.page, this.state.rowsPerPage)
                }}
              >
                Update
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>


        {/* Delete company Modal */}
        <Dialog
          open={this.state.deletecompanyview} maxWidth="sm" fullWidth
          sx={{


            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={12} >
                  <Typography style={{ fontSize: "22px", fontWeight: "600", color: "#222222" }}>
                    Delete
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ flexDirection: "row", textAlign: "center" }}>
              <Grid container direction={"column"} spacing={1}>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "18px", fontWeight: 600, fontFamily: "" }}>Are you sure you want to delete ?</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>This process will lead to loss of entire data, proceed at yours
                    own risk</Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center', margin: '5% 0% 5% 0%' }}>
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({ deletecompanyview: false });
                }}
                style={{
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "#0000B3",
                  border: "1px solid #0000B3",
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({ deletecompanyview: false });
                }}
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "white",
                  marginLeft: "2%",
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>

        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </Grid>
    );
  }
}
