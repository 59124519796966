import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { green, pink } from '@mui/material/colors';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link, useLocation } from "react-router-dom";
import Icon from "@mui/material/Icon";
import LG_Cont from "../containers/Lg_Cont";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import InputBase from "@mui/material/InputBase";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SearchIcon from "@mui/icons-material/Search";
import "../styles/drawer.css";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ListItemButton from '@mui/material/ListItemButton';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Bell from "../../pages/Images/Bell.png";
import Chat from "../../pages/Images/Chat.png";
import logo from "../../pages/Images/logo-2.png";
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
// import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from "@mui/material/Tooltip";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  Rows,

  Dialog, TextField, Divider, IconButton, Card, DialogContent, DialogActions, DialogTitle
} from "@mui/material";
import { Textarea } from "theme-ui";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// import Logo from "../../pages/admin/images/logo.png"
import {
  all_admin_option,
  all_students_options,
  all_trainer_options,
  // all_tourism_options
  // all_manager_options,

} from "../constant";
import { red } from '@mui/material/colors';
import { Avatar, Badge, Grid } from '@mui/material';
const drawerWidth = 220;
const ITEM_HEIGHT = 48;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 50,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    // backgroundColor:'red',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const useStyles = (theme) => ({
  root: {
    display: "flex",

  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: "#FF7000"
    background: "#fadde1"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    backgroundColor: "#000000",
    // #11101d


  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#11101d",


    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    // flexzDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    // backgroundColor: '#FF7000',
    backgroundColor: "#11101d",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  iconStyle: {
    position: "relative",
    left: 8,
    top: 3,
    fontSize: 12,
    marginRight: 5,
    //   color:'red'
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  grow: {
    flexGrow: 1,
  },
  Icon: {
    //   color:"red",
    position: "relative",
    left: "10px"
  },

  // ListItem: {
  //   border: "1px solid blue",

  //   "&:hover": {
  //     borderRadius: "3px",
  //     backgroundColor: "red",
  //     // color:'red'
  //   },
  //   "&:active": {
  //     backgroundColor:"blue",
  //   }
  // }



});

const options = [
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
  {
    profile_pic: "https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg",
    name: "Rasmiranja mohanty",
    message: "sent you a attachment",
    time: "6 Hours ago",
  },
];

export default function MiniDrawer(props) {

  const { login, uploadImage } = props;
  const [add, setAdd] = React.useState(false);
  const [profile_pic, setProfilePic] = React.useState("");

  const handleAddClick = () => {
    setAdd(false);
    uploadImage(login.user_id, name, image);
    closeDialog()
    setImage(null)
    setName(null)
  };
  const [image, setImage] = React.useState(null);
  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };
  const [name, setName] = React.useState('');
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const classes = useTheme();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [addticket, setAddticket,] = React.useState(false);
  const [navreport, setNavreport,] = React.useState(false);
  const [img, setimagess] = React.useState(null);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [expandopen, setExpand] = React.useState("");
  const [openValue, setValue] = React.useState(true);
  const [countexp, setExp] = React.useState(0);

  const opens = Boolean(anchorEl);
  const handleClick = () => {
    setAnchorEl(true);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };
  const hiddenFileInputRef = React.createRef();

  const openFileInput = () => {
    hiddenFileInputRef.current.click();
  };
  const closeDialog = () => {
    setAddticket(false);
  };
  const openDialog = () => {
    setAddticket(true);
  };


  const setIndex = (bkb, index) => {
    if (bkb > 0 && expandopen === index) {
      setExpand("")
    }
  };


  const handleClickOpen1 = () => {
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose11 = () => {
    setOpen2(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function sideBar(type, open) {

 




   

    if (type === "A"||type === "Owner") {
      return (
        <div style={{ marginTop: 1 }}  >

          {all_admin_option.map((option) => (
            location.pathname === option.link ? <center className='selected-drawer' style={{ background: '#0000B3', verticalAlign: 'middle' }}>


              <ListItem button key={option.name} style={{ paddingBottom: "20px", backgroundColor: "" }} className="all_admin_option-mm_sec" >

                <ListItemIcon >
                  <Icon className="drawer_icon_select">{option.icon}</Icon></ListItemIcon>

                {option.name}
              </ListItem>


            </center> :
              <Link key={option.link} to={option.link} style={{ textDecoration: "none", color: "#7A7E86", fontSize: "1rem" }}   >
                <ListItem button key={option.name} style={{ paddingBottom: "20px", backgroundColor: "" }} className="all_admin_option-mm" >

                  <ListItemIcon ><Icon className="drawer_icon">{option.icon}</Icon></ListItemIcon>

                  {option.name}
                </ListItem>

              </Link>
          ))}
        </div>
      );
    }
    else if (type === "S") {
      return (
        <div style={{ marginTop: 1 }}  >

          {all_students_options.map((option) => (
            location.pathname === option.link ? <center className='selected-drawer' style={{ background: '#0000B3', verticalAlign: 'middle' }}>


              <ListItem button key={option.name} style={{ paddingBottom: "20px", backgroundColor: "" }} className="all_admin_option-mm_sec" >

                <ListItemIcon ><Icon className="drawer_icon_select">{option.icon}</Icon></ListItemIcon>

                {option.name}
              </ListItem>
              {/* <Divider/> */}

            </center> :
              <Link key={option.link} to={option.link} style={{ textDecoration: "none", color: "#7A7E86", fontSize: "1rem" }}   >
                <ListItem button key={option.name} style={{ paddingBottom: "20px", backgroundColor: "" }} className="all_admin_option-mm" >

                  <ListItemIcon ><Icon className="drawer_icon">{option.icon}</Icon></ListItemIcon>

                  {option.name}
                </ListItem>
                {/* <Divider/> */}
              </Link>
          ))}
        </div>
      );
    }
    else if (type === "T") {
      return (
        <div style={{ marginTop: 1 }}  >

          {all_trainer_options.map((option) => (
            location.pathname === option.link ? <center className='selected-drawer' style={{ background: '#0000B3', verticalAlign: 'middle' }}>


              <ListItem button key={option.name} style={{ paddingBottom: "20px", backgroundColor: "" }} className="all_admin_option-mm_sec" >

                <ListItemIcon ><Icon className="drawer_icon_select">{option.icon}</Icon></ListItemIcon>

                {option.name}
              </ListItem>
              {/* <Divider/> */}

            </center> :
              <Link key={option.link} to={option.link} style={{ textDecoration: "none", color: "#7A7E86", fontSize: "1rem" }}   >
                <ListItem button key={option.name} style={{ paddingBottom: "20px", backgroundColor: "" }} className="all_admin_option-mm" >

                  <ListItemIcon ><Icon className="drawer_icon">{option.icon}</Icon></ListItemIcon>

                  {option.name}
                </ListItem>
                {/* <Divider/> */}
              </Link>
          ))}
        </div>
      );
    }





  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer variant="permanent" open={true} style={{ backgroundColor: 'white', }} >
        <AppBar position="fixed" open={true} style={{ backgroundColor: 'white' }}>
          <Toolbar style={{ justifyContent: "end", marginRight: "2%" }}>
            {/* {props.type === "T" ? <div style={{ display: "flex", gap: 10 }}>
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={opens ? 'long-menu' : undefined}
                  aria-expanded={opens ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                ><NotificationsNoneIcon style={{ marginLeft: "2%", color: "gray" }} /> </IconButton>

              </div>


              <Link to={"/Chat"}>
                <img src={Chat} style={{ marginLeft: "2%", marginTop: 8 }} />
              </Link></div> :
              (props.type === "S" ? <div style={{ display: "flex", gap: 10 }}>


                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={opens ? 'long-menu' : undefined}
                  aria-expanded={opens ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                ><NotificationsNoneIcon style={{ marginLeft: "2%", color: "gray" }} /> </IconButton>


                <Link to={"/Chat"}>
                  <img src={Chat} style={{ marginLeft: "2%", marginTop: 8 }} />
                </Link></div> : "")

            } */}





            <img src={login.org.org_logo} style={{ marginLeft: "2%",width:'10%'}} />



{/* 
            <Tooltip>

              <ReportProblemIcon
                onClick={handleClickOpen2}

                style={{ color: "red", marginLeft: "1%", fontSize: 30, marginTop: "7px", cursor: "pointer" }} />

            </Tooltip> */}

            {/* <div className={classes.sectionDesktop}>
            </div> */}
          </Toolbar>
        </AppBar>
        {/* <DrawerHeader style={{display:'flex',flexDirection:"column"}} > */}
        <Grid
          container
          justifyContent={"space-between"}
          direction={"column"}
          height={'100%'}
        >
          <Grid item lg={2}>
            <center>

              <Avatar
                onClick={openDialog}
                alt={name} src={localStorage.getItem("profile_pic")}
                style={{ marginTop: "14px", marginLeft: 0, width: 85, height: 85, marginTop: "5%" }} >
                {localStorage.getItem("name") === null ? "" : localStorage.getItem("name").substring(0, 1).toUpperCase()}
                <IconButton>
                  <EditIcon style={{ marginLeft: "60", marginTop: "50px", color: "#0000B3", fontSize: "18px", backgroundColor: "white", borderRadius: "15px" }} />
                </IconButton>
              </Avatar>
              <Typography style={{ fontWeight: 500, fontSize: "20px" }}>{localStorage.getItem("name")}</Typography>
              {(props.type == "A" || props.type == "Owner") ? "Administrator" : (props.type == "T" ? "Trainer" : "Student")}


            </center>
          </Grid>


          <Grid item lg={2}>
            <center>
              <List   >
                {sideBar(props.type, open)}

              </List>
            </center>
          </Grid>
          <Grid item lg={0}>
            <center>
              <LG_Cont />

            </center>
          </Grid>
        </Grid>
      </Drawer>
      {/* <SwipeableDrawer variant="temporary" open={anchorEl} anchor='right' style={{ width: 230 }}>
        <DrawerHeader style={{display:'flex',flexDirection:"column"}} >
        <Grid


        >


          <center >

            <Typography style={{ fontFamily: "popines", fontSize: "30px", fontWeight: 500 }}>Notifications


            </Typography>
            <IconButton onClick={handleClose} style={{ marginLeft: 450, marginTop: -70 }}>
              <CloseIcon />
            </IconButton>
          </center>












          <Paper
            component="form"
            style={{
              display: "flex",
              marginLeft: "4%",
              width: "500px",
              height: "33px",
              marginTop: "-2%",
              boxShadow: "none",
              border: "1px solid black",
              borderRadius: "20px",
            }}
          >
            <IconButton
              type="button"
              style={{ p: "150px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              style={{ flex: 1, fontSize: "15px" }}
              placeholder="Search here"
              inputProps={{ "aria-label": "Search here" }}
            />
          </Paper>

          {options.map((option) => (
            <Card style={{ display: "flex", flexDirection: "row", padding: "10px 0 10px 10px", width: "550px", justifyContent: "space-between" }}>
              <Avatar src={option.profile_pic} style={{ height: 40, width: 40 }}></Avatar>
              <Typography style={{ marginLeft: 10, fontSize: 15, fontFamily: "Popines" }}>
                {option.name}
              </Typography>
              <Typography style={{ marginLeft: 10, fontSize: 15, fontFamily: "Popines" }}>
                {option.message}
              </Typography>
              <Typography style={{ marginLeft: 10, fontSize: 15, fontFamily: "Popines", marginRight: 20 }}>
                {option.time}
              </Typography>
            </Card>

          ))}
        </Grid>



      </SwipeableDrawer> */}


      <Dialog open={addticket} maxWidth="xs" fullWidth
        sx={{
          // 👇 Another option to style Paper
          "& .MuiDialog-paper": {
            borderRadius: "10px",
          },
        }}>
        <Grid>
          <DialogTitle style={{ marginBottom: "1.5%" }}>
            <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
              Edit Profile
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container >
              <Grid item xs={0.5}>

              </Grid>
              <Grid item xs={11}>
                <center>
                  <Avatar
                    src={image !== null ? URL.createObjectURL(image) : ""}
                    sx={{ width: 64, height: 64, margin: "2%" }}
                  />
                  <Button onClick={openFileInput} style={{ backgroundColor: "#F5F5F5", color: "#000" }}>Upload<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                    <g clip-path="url(#clip0_301_5021)">
                      <path d="M22.5 6.75H13.5L11.25 4.5H4.5C3.2625 4.5 2.26125 5.5125 2.26125 6.75L2.25 20.25C2.25 21.4875 3.2625 22.5 4.5 22.5H22.5C23.7375 22.5 24.75 21.4875 24.75 20.25V9C24.75 7.7625 23.7375 6.75 22.5 6.75ZM22.5 20.25H4.5V9H22.5V20.25ZM9 14.6363L10.5863 16.2225L12.375 14.445V19.125H14.625V14.445L16.4137 16.2338L18 14.6363L13.5113 10.125L9 14.6363Z" fill="#8A8A8A" />
                    </g>
                    <defs>
                      <clipPath id="clip0_301_5021">
                        <rect width="27" height="27" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                    <input hidden type="file"
                      ref={hiddenFileInputRef}
                      onChange={(e) => {
                        setImage(e.target.files[0])
                      }}

                    /></Button>
                </center>
                <label style={{ marginTop: 3 }}>Enter Name</label>
                <TextField
                  required
                  id="outlined-required"
                  placeholder="Enter Your Name"
                  fullWidth
                  style={{ marginTop: 3, }}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    borderRadius: "5px",
                    backgroundColor: "rgba(239, 239, 239, 0.60)"
                  }}
                  value={name}
                  onChange={handleNameChange}
                />
              </Grid>
              <Grid item xs={0.5}>

              </Grid>
            </Grid>
            <DialogActions style={{ justifyContent: "center", marginBottom: 3, marginTop: 10 }} >
              <Button
                variant="outlined"
                onClick={() => {
                  closeDialog()
                  setImage(null)
                  setName("")
                }}
                style={{
                  fontSize: "15px",
                  height: "38px",
                  width: "160px",
                  margin: "2%",

                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  height: "38px",
                  width: "160px",
                  color: "white",
                  margin: "2%",
                }}
                onClick={handleAddClick}
              >
                Add
              </Button>
            </DialogActions>
          </DialogContent>
        </Grid>
      </Dialog>















      <Dialog
        open={open2}
        onClose={handleClose11}
        //  style={{ height: "300px", width: "1000px" }}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <Grid style={{ minWidth: "400px", height: "500px" }}>




          <Typography style={{ fontWeight: 400, fontSize: "18px", fontFamily: "", marginTop: "10%", marginLeft: "10%" }}>
            Problem<strong style={{ color: "red" }}>*</strong>:-
          </Typography>
          <TextField
            // variant="standard"
            InputLabelProps={{ shrink: true, required: true }}
            style={{ width: "320px", marginLeft: "10%" }}
          // value={this.state.battery_model}
          // onChange={(e) => { this.setState({ battery_model: e.target.value }) }}
          />

          <Typography style={{ fontWeight: 400, fontSize: "18px", fontFamily: "", marginTop: "5%", marginLeft: "10%" }}>
            Description<strong style={{ color: "red" }}>*</strong>:-
          </Typography>
          <Textarea


            sx={{
              height: "150px",
              marginTop: 3,
              width: "320px", marginLeft: "10%"
            }}
            name="Outlined"
          // value={this.state.complain}
          />

          <Typography style={{ fontWeight: 400, fontSize: "18px", fontFamily: "", marginTop: "5%", marginLeft: "10%" }}>
            Attachment<strong style={{ color: "red" }}>*</strong>:-
          </Typography>
          <Box
            style={{
              width: "323px",
              height: "50px",
              marginLeft: "10%",
              backgroundColor: "#eaeaea",
              marginTop: "1%",
            }}
          >
            <label
              htmlFor="file"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <AttachFileIcon
                style={{ marginLeft: "3%", marginTop: "3%" }}
              />
            </label>

            <input style={{ display: "none" }} type="file" id="file" />
          </Box>

          <center style={{ marginTop: "8%" }}>
            <Button variant="contained" style={{ backgroundColor: "#0000B3" }} onClick={handleClose11}>Submit</Button>
            <Button variant="outlined" style={{ borderColor: "#0000B3",color:"#0000B3", marginLeft: "2%" }} onClick={handleClose11} autoFocus>
              Cancel
            </Button>
          </center>

        </Grid>
      </Dialog>
    </Box >
  );
}
