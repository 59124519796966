import React, { Component } from "react";
import { connect } from "react-redux";
import Trainer from "../Components/Trainer";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    uploadImageTags,
    addTrainer,
    viewAllTrainer,
    editUploadImageTags,
    editTrainer,
    deteteTrainer,
    searchTrainer
} from "../action"

// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <Trainer {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        trainer: store.trainer,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {
        uploadImageTags: (admin_id, name, phone, email, profile_pic, org_id) => {
            dispatch(uploadImageTags(admin_id, name, phone, email, profile_pic, org_id));
        },
        addTrainer: (admin_id, name, phone, email, profile_pic, org_id) => {
            dispatch(addTrainer(admin_id, name, phone, email, profile_pic, org_id));
        },
        viewAllTrainer: (admin_id, page_number, rowper_page,org_id) => {
            dispatch(viewAllTrainer(admin_id, page_number, rowper_page,org_id));
        },
        editUploadImageTags: (trainer_id, name, phone, email, profile_pic,org_id,admin_id) => {
            dispatch(editUploadImageTags(trainer_id, name, phone, email, profile_pic,org_id,admin_id));
        },
        editTrainer: (trainer_id, name, phone, email, profile_pic,org_id,admin_id) => {
            dispatch(editTrainer( trainer_id, name, phone, email, profile_pic,org_id,admin_id));
        },
        deteteTrainer: (admin_id, org_id, trainer_id) => {
            dispatch(deteteTrainer(admin_id, org_id, trainer_id))
        },
        searchTrainer: (all_trainers, e) => {
            dispatch(searchTrainer(all_trainers, e));
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);