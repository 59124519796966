import React, { Component } from "react";
import { connect } from "react-redux";
import TrainerClass from "../Component/trainerclass";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    viewScheduleClassesByTrainer,
    searchClass,
    filterClass,
} from "../action"

import {
    view_all_courses,
    view_all_batch,
    add_attendance,
} from "../../Course/action"

// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <TrainerClass {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        classes: store.classes,
        dashboard: store.dashboard,
        courses: store.courses,
        student: store.student,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {

        view_all_courses: () => {
            dispatch(view_all_courses());
        },
        view_all_batch: () => {
            dispatch(view_all_batch());
        },
        viewScheduleClassesByTrainer: (trainer_id) => {
            dispatch(viewScheduleClassesByTrainer(trainer_id));
        },
        searchClass: (classes, e, type) => {
            dispatch(searchClass(classes, e, type));
        },
        filterClass: (classes, e) => {
            dispatch(filterClass(classes, e));
        },
        add_attendance: (student_id) => {
            dispatch(add_attendance(student_id))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },



    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);