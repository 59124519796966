import {
    Box, Button, IconButton, InputBase,
    MenuItem, TextField,
    Paper, Select, Typography
} from '@mui/material'
import React, { Component } from 'react'
import SearchIcon from "@mui/icons-material/Search";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Dialog from "@mui/material/Dialog";
import CardActions from '@mui/material/CardActions';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Card, Grid, } from "@mui/material";
import GroupIcon from '@mui/icons-material/Group';
import FormControl from "@mui/material/FormControl";
import Calendar from "../../Images/CalendarWeekFill.png"
import Person from "../../Images/PersonFill.png"
import Clock from "../../Images/ClockFill.png"
import moment from "moment";
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";


export default class trainerclass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            joinclass: false,
            data: [
                {
                    coursename: "PG Program On Full Stack Development With PHP Or Python",
                    batch: "(Batch IV)",
                    subject: "Html",
                    session: "Session 1",
                    date: "Monday, 28 June 2021",
                    trainer: "Ankit Das",
                    time: "02.00 -03.30 PM"
                },
                {
                    coursename: "PG Program On Full Stack Development With PHP Or Python",
                    batch: "(Batch IV)",
                    subject: "Html",
                    session: "Session 1",
                    date: "Monday, 28 June 2021",
                    trainer: "Ankit Das",
                    time: "02.00 -03.30 PM"
                },
                {
                    coursename: "PG Program On Full Stack Development With PHP Or Python",
                    batch: "(Batch IV)",
                    subject: "Html",
                    session: "Session 1",
                    date: "Monday, 28 June 2021",
                    trainer: "Ankit Das",
                    time: "02.00 -03.30 PM"
                },
                {
                    coursename: "PG Program On Full Stack Development With PHP Or Python",
                    batch: "(Batch IV)",
                    subject: "Html",
                    session: "Session 1",
                    date: "Monday, 28 June 2021",
                    trainer: "Ankit Das",
                    time: "02.00 -03.30 PM"
                },
                {
                    coursename: "PG Program On Full Stack Development With PHP Or Python",
                    batch: "(Batch IV)",
                    subject: "Html",
                    session: "Session 1",
                    date: "Monday, 28 June 2021",
                    trainer: "Ankit Das",
                    time: "02.00 -03.30 PM"
                },
                {
                    coursename: "PG Program On Full Stack Development With PHP Or Python",
                    batch: "(Batch IV)",
                    subject: "Html",
                    session: "Session 1",
                    date: "Monday, 28 June 2021",
                    trainer: "Ankit Das",
                    time: "02.00 -03.30 PM"
                },
                {
                    coursename: "PG Program On Full Stack Development With PHP Or Python",
                    batch: "(Batch IV)",
                    subject: "Html",
                    session: "Session 1",
                    date: "Monday, 28 June 2021",
                    trainer: "Ankit Das",
                    time: "02.00 -03.30 PM"
                },
                {
                    coursename: "PG Program On Full Stack Development With PHP Or Python",
                    batch: "(Batch IV)",
                    subject: "Html",
                    session: "Session 1",
                    date: "Monday, 28 June 2021",
                    trainer: "Ankit Das",
                    time: "02.00 -03.30 PM"
                },
                {
                    coursename: "PG Program On Full Stack Development With PHP Or Python",
                    batch: "(Batch IV)",
                    subject: "Html",
                    session: "Session 1",
                    date: "Monday, 28 June 2021",
                    trainer: "Ankit Das",
                    time: "02.00 -03.30 PM"
                },
                {
                    coursename: "PG Program On Full Stack Development With PHP Or Python",
                    batch: "(Batch IV)",
                    subject: "Html",
                    session: "Session 1",
                    date: "Monday, 28 June 2021",
                    trainer: "Ankit Das",
                    time: "02.00 -03.30 PM"
                },
                {
                    coursename: "PG Program On Full Stack Development With PHP Or Python",
                    batch: "(Batch IV)",
                    subject: "Html",
                    session: "Session 1",
                    date: "Monday, 28 June 2021",
                    trainer: "Ankit Das",
                    time: "02.00 -03.30 PM"
                },
                {
                    coursename: "PG Program On Full Stack Development With PHP Or Python",
                    batch: "(Batch IV)",
                    subject: "Html",
                    session: "Session 1",
                    date: "Monday, 28 June 2021",
                    trainer: "Ankit Das",
                    time: "02.00 -03.30 PM"
                },
            ],
            batch_filter: "N",
            course_filter: "N"
        };
    }

    handleDateChange = (e) => {
        const rawDate = e.target.value; // The raw date string from the input
        const formattedDate = this.formatDate(rawDate); // Format the date
        this.props.filterClass(this.props.classes.classes, formattedDate);
    }

    formatDate(rawDate) {
        // Assuming rawDate is in the format 'yyyy-MM-dd'
        const parts = rawDate.split('-');
        if (parts.length === 3) {
            const [year, month, day] = parts;
            return `${year}-${month}-${day}`;
        }
        return rawDate; // Return the original date if it's not in the expected format
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F9FDFE"
        this.props.viewScheduleClassesByTrainer(this.props.login.user_id);
        console.log("sdc", this.props.courses.batch_by_course)
    }


    render() {
        const { classes, courses, login, trainer, student, snackbar, close_snack_bar } = this.props

        return (
            <Grid>
                <Card style={{ marginTop: 100, marginRight: "30px", padding: "0px 30px", }}>
                    <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }} >
                        <div>
                            <Typography style={{ fontSize: "24px", fontWeight: 600, }}>
                                Class Schedules
                            </Typography>
                        </div>
                        <div>
                            <TextField
                                type="date"
                                name="begin"
                                min="1997-01-01"
                                max="2030-12-31"
                                style={{
                                    border: "1px solid #D3D2D2",
                                    borderRadius: "0.3125rem",
                                    width: "150px",
                                    marginLeft: "auto",
                                    marginTop: "2%",
                                }}
                                onChange={this.handleDateChange}
                            />

                        </div>
                    </Grid>
                    <Grid style={{ display: "flex", gap: 10, marginTop: 10, justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ display: "flex", gap: 5 }}>
                            <TextField
                                style={{ width: '150px' }}
                                id="outlined-select-currency-native"
                                select
                                value={this.state.course_filter}
                                onChange={(e) => {
                                    this.setState({ course_filter: e.target.value });
                                    this.props.searchClass(classes.classes, e.target.value, "course")
                                }}
                            >
                                <MenuItem value={"N"}>{"All Course"}</MenuItem>
                                {Array.isArray(this.props.courses.course) && this.props.courses.course.map((item) => (
                                    <MenuItem value={item.course_name}>{item.course_name}</MenuItem>
                                ))}


                            </TextField>

                            <TextField
                                style={{ width: '150px', }}
                                id="outlined-select-currency-native"
                                select
                                value={this.state.batch_filter}
                                onChange={(e) => {
                                    this.setState({ batch_filter: e.target.value });
                                    this.props.searchClass(classes.classes, e.target.value, "batch")
                                }}
                            >
                                <MenuItem value={"N"}>{"All Batch"}</MenuItem>
                                {Array.isArray(this.props.courses.batch_by_course) && this.props.courses.batch_by_course.map((item) => (
                                    <MenuItem value={item.batch_number}>{item.batch_number}</MenuItem>
                                ))}


                            </TextField>
                        </div>

                        <div >
                            <Paper
                                component="form"
                                style={{ width: "27.25rem", height: "2.25rem", backgroundColor: "#EFEFEF99", boxShadow: "none" }}
                            >
                                <IconButton
                                    type="button"
                                    style={{ p: "15px" }}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    style={{ ml: 5, flex: 1, fontSize: "15px" }}
                                    placeholder="Search "
                                    inputProps={{ "aria-label": "Search " }}
                                    onChange={(e) =>
                                        this.props.searchClass(classes.classes, e.target.value, "search"
                                        )}
                                />
                            </Paper>
                        </div>

                    </Grid>


                    <Grid style={{ marginTop: "20px", marginBottom: "20px" }}>
                        {classes.search_class.length == "0" &&
                            <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                                <Typography>No Result Found</Typography>
                            </Grid>
                        }
                        {Array.isArray(classes.search_class) && classes.search_class.map((e, index) => {
                            console.log("sheduleclasss",e)
                            return (
                                <Grid container border={"1px solid #EDECEC"} padding={"20px"} letterSpacing={1}>
                                    <Grid item xs={10.7}>
                                        <Grid container direction={"column"}>
                                            <Grid item xs={12}>
                                                <Typography>{e.course_name} ({e.batch_name})</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>{e.subject_name}-{e.session_name}</Typography>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <CardActions
                                                    disableSpacing
                                                    style={{ justifyContent: "start", fontSize: "10px", gap: 5 }}
                                                >
                                                    <div style={{ display: "flex", marginLeft: "-15px" }}>
                                                        <IconButton
                                                            className="date-sec"
                                                            aria-label="add to favorites">
                                                            <img src={Calendar} style={{ fontSize: "15px" }} className="date-sec" />

                                                        </IconButton>
                                                        <Typography className="date-sec" style={{ fontSize: "14px", marginTop: "5px", color: "gray" }}>
                                                            {moment(e.date).format('dddd, D MMM , YYYY')}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <IconButton

                                                            style={{ marginLeft: "1%" }}
                                                            aria-label="share"
                                                        >
                                                            <img src={Clock} style={{ fontSize: "15px" }} className="date-sec" />


                                                        </IconButton>
                                                        <Typography className="date-sec" style={{ fontSize: "14px", marginTop: "5px", color: "gray", width: "130px" }}>
                                                            {e.time}-{e.end_time}
                                                        </Typography>
                                                    </div>
                                                </CardActions>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1.3}>
                                        <Button
                                            disabled={(moment(e.date + " " + e.end_time)).isBefore(moment())}
                                            variant="contained"
                                            onClick={() => {
                                                console.log((moment(e.date + " " + e.end_time)).isBefore(moment()))
                                                this.props.add_attendance(this.props.login.user_id)
                                                window.location.href = e.class_link
                                            }}
                                            style={{ height: "4.5vh", backgroundColor: "#0000B3", width: "6.375rem", top: "35%" }}>
                                            Join Class
                                        </Button>
                                    </Grid>
                                </Grid>)
                        })}
                    </Grid>
                </Card >

                <Dialog
                    open={this.state.joinclass}

                    style={
                        {

                        }}
                >
                    <Grid style={{ height: "362px", width: "520px", }}>




                        {/* <CloseOutlinedIcon onClick={() => {
                            this.setState({ joinclass: false })
                        }} style={{ marginLeft: "440px", marginTop: "20px",cursor: "pointer" }} /> */}



                        <div style={{ marginBottom: "6%" }}></div>

                        <Box>

                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontFamily: 'Inter', display: "flex", justifyContent: "center", fontWeight: 600, color: " #0000B3", marginBottom: "3%" }}>
                                Batch-1
                            </Typography>
                            <Typography id="modal-modal-description" style={{ marginTop: "4%", fontSize: "1rem", fontFamily: 'Inter', display: "flex", flexDirection: "column", justifyContent: "center", color: "#777676", width: "22.375rem", textAlign: "center", marginInline: '87px' }}>
                                (Post Graduation Program On Full Stack Development With PHP Or Python)
                            </Typography>
                            <Typography id="modal-modal-description" style={{ marginTop: "2%", fontSize: "1.25rem", fontFamily: 'Inter', display: "flex", flexDirection: "row", justifyContent: "center", color: "#000", width: "22.375rem", textAlign: "center", marginInline: '87px' }}>
                                <strong>HTML</strong>  (session-1)
                            </Typography>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',

                                }}
                            >
                                <Typography id="modal-modal-description" style={{ marginTop: "2%", fontSize: "1.25rem", fontFamily: 'Inter', display: "flex", justifyContent: "center", color: "#000", width: "22.375rem", textAlign: "center", marginLeft: '115px' }}>

                                    <AccessTimeIcon style={{ color: "#666666" }} /> 1hr 30min
                                </Typography>
                                <Typography id="modal-modal-description" style={{ marginTop: "2%", fontSize: "1.25rem", fontFamily: 'Inter', display: "flex", justifyContent: "center", color: "#000", width: "22.375rem", textAlign: "center", marginRight: '119px' }}>

                                    <GroupIcon style={{ color: "#666666" }} /> 130 Students
                                </Typography>

                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',

                                }}
                            >
                                <Typography id="modal-modal-description" style={{ marginTop: "2%", fontFamily: 'Inter', display: "flex", justifyContent: "center", color: "#0000B3", width: "22.375rem", textAlign: "center", marginLeft: '115px' }}>

                                    Date-22-04-23
                                </Typography>
                                <Typography id="modal-modal-description" style={{ marginTop: "2%", fontFamily: 'Inter', display: "flex", justifyContent: "center", color: "#0000B3", width: "22.375rem", textAlign: "center", marginRight: '119px' }}>

                                    Time-10AM-11AM
                                </Typography>

                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: "6%",
                                }}
                            >
                                <Button variant="outlined" onClick={() => {
                                    this.setState({ joinclass: false })
                                }} style={{ width: "23%", color: "#0000B3" }}>
                                    cancel
                                </Button>
                                <Button variant="contained" onClick={() => {
                                    this.setState({ joinclass: false })
                                }} style={{ marginLeft: 10, width: "23%", backgroundColor: "#0000B3" }}>
                                    Start now
                                </Button>
                            </Box>
                        </Box>








                    </Grid>

                </Dialog>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}




            </Grid>
        )
    }
}
