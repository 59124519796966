import React, { Component } from "react";
import { connect } from "react-redux";
import StudentHelp from "../Components/studenthelp";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    viewTicketByUserId,
    addTicket,
    uploadDocument
} from "../action"; // Assuming you have action creators defined in an "action.js" file

export class Controller extends Component {
    render() {
        return (
            <StudentHelp {...this.props} />
        );
    }
}

export const mapStateToProps = store => {
    return {
        tickets: store.tickets,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        viewTicketByUserId: (user_id, page_number, rowper_page,org_id) => {
            dispatch(viewTicketByUserId(user_id, page_number, rowper_page,org_id));
        },
        addTicket: (user_id, document, message, page_number, rowper_page, org_id) => {
            dispatch(addTicket(user_id, document, message, page_number, rowper_page, org_id));
        },
        uploadDocument: (user_id, document, message, page_number, rowper_page,org_id) => {
            dispatch(uploadDocument(user_id, document, message, page_number, rowper_page,org_id));
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
