import React, { Component } from "react";
import { connect } from "react-redux";
import Discussion from "../Components/discussion";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    addDiscussion,
    viewAllDiscussionById,
    viewAllDiscussion

} from "../action"

// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <Discussion {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        discussion: store.discussion,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {
        addDiscussion: (student_id, message) => {
            dispatch(addDiscussion(student_id, message));
        },
        viewAllDiscussionById: (discussion_id) => {
            dispatch(viewAllDiscussion(discussion_id));
        },
        viewAllDiscussion: () => {
            dispatch(viewAllDiscussion());
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },



    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);