import React, { Component } from 'react'
// import React, { Component,ReactDOM  } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { Link } from 'react-router-dom';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import Accounts from '../../pages/image/accounts.png'
// import Facebook from '../../pages/image/facebook.png';
// import Google from '../../pages/image/google.png';
// import Twitter from '../../pages/image/twitter.png';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import OtpInput from 'react-otp-input';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import Logoacc from '../../pages/image/logoacc.png';
// import Welcome from '../../pages/image/welcome.png'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
// import LoaderCon from "../../common/loader/containers/loader_cont";
// import Logo from '../../pages/image/logo.svg';
// import os from '../../pages/image/os.png';
// import validator from 'validator';
// import "../../auth/components/login.scss"
// import Logogif from "../../pages/image/logogif.gif";
// import Logoname from "../../pages/image/logo name.png";
import logo from "../../pages/Images/logo-2.png"

export default class organization extends Component {


    constructor(props) {
        super(props);
        this.state = {
            tab1: false,
            tab2: false,
            type: "Organization ",
            logo: "",
            gstin_no: "",
            org_name: "",
            last_name: "",
            first_name: "",
            email: "",
            value: '1',
            isEmail: false,
            dis: true,
        };
    }
    // validateEmail = () => {
    //     const { email } = this.state;
    //     const isValid = validator.isEmail(email);
    //     this.setState({ isEmail: isValid });
    //     console.log(this.state.isEmail)
    // };

    // handlename = (event) => {
    //     const { value } = event.target;
    //     if (/^[A-Za-z]*$/.test(value)) {
    //         this.setState({
    //             value: value,
    //             first_name: value
    //         });
    //     }
    // };
    // handlenamee = (event) => {
    //     const { value } = event.target;
    //     if (/^[A-Za-z]*$/.test(value)) {
    //         this.setState({
    //             value: value,
    //             last_name: value
    //         });
    //     }
    // };
    render() {

        const { value }
            = this.state
        // console.log("this.props.login.email", this.props.login.email)

        return (

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop: "30px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                        <img
                            src={logo}
                            alt="logo_
                            img"
                            style={{ height: 80, marginTop: "18%", width: 250 }}
                        />
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "center" }}>
                        <img
                            src={logo}
                            alt="logo_img"
                            style={{ height: 30, width: 135 }}
                        />
                    </div> */}
                </div>

                <div>
                    <Card style={{ marginLeft: "auto", marginRight: "auto", width: "500px", marginTop: "90px", height: "520px", background: "rgba(235, 240, 255, 0.71)", boxShadow: "2px 2px 15px rgba(0, 0, 0, 0.2)" }}>
                        <CardContent>

                            <Avatar
                            //  src={this.state.logo !== "" ? URL.createObjectURL(this.state.logo) : this.state.logo} 
                             style={{ height: "5vw", width: "5vw", marginLeft: "auto", marginRight: "auto" }}>
                                <input style={{ display: "none" }} type="file" id="file" 
                            //     onChange={(e) => { this.setState({ logo: e.target.files[0] })
                            //  }}
                                 />
                                <label htmlFor="file"><CameraAltIcon style={{ marginTop: "25%" }} /></label>
                            </Avatar>

                            {/* {
                            this.props.login.mobile !== "" && this.props.login.mobile !== undefined && this.props.login.mobile !== null ?  */}
                            <Typography style={{ fontSize: "17px", marginTop: "2%", textAlign: "center", fontWeight: 500, }}>
                                Namaskar +
                                {/* {this.props.login.mobile} */}
                            </Typography> : 
                            
                            {/* <Typography style={{ fontSize: "17px", marginTop: "2%", textAlign: "center", fontWeight: 500, }}>
                                Namaskar 
                                {this.props.login.email}
                            </Typography> */}
                            {/* // } */}

                            {/* {this.props.login.email!==""&&this.props.login.email!==undefined&&this.props.login.email!==null&&<Typography style={{ fontSize: "17px", marginTop: "2%", textAlign: "center", fontWeight: 500, }}>
                                            Namaskar {this.props.login.email}
                                        </Typography>} */}
                            <div>
                                <TextField style={{ marginTop: "2%", marginLeft: "10%", width: "80%", borderRadius: "10px", backgroundColor: "rgba(206, 206, 206, 0.67)" }} id="outlined-basic" variant="outlined" type="text"
                                    placeholder="Organization Name*"
                                    // onChange={(e) => this.setState({ org_name: e.target.value })}
                                >

                                </TextField>
                               
                                <TextField style={{ marginTop: "2%", marginLeft: "10%", width: "80%", borderRadius: "10px", backgroundColor: "rgba(206, 206, 206, 0.67)" }} id="outlined-basic" variant="outlined" type="email"
                                    placeholder="Email id"
                                    // onChange={(e) => {
                                        // this.setState({ email: e.target.value })
                                    //     this.validateEmail(e)
                                    // }}
                                >

                                </TextField>

                                <TextField style={{ marginTop: "2%", marginLeft: "10%", width: "80%", borderRadius: "10px", backgroundColor: "rgba(206, 206, 206, 0.67)" }} id="outlined-basic" variant="outlined" type="text"
                                    placeholder="GSTIN No"
                                    // onChange={(e) => {
                                    //     this.setState({ gstin_no: e.target.value })
                                    // }}
                                >

                                </TextField>
                                <Link style={{ textDecoration: "none" }} to="/"
                                >
                                    <Button
                                        // disabled={this.state.first_name === "" || this.state.org_name === ""}
                                        variant="contained"
                                        style={{ backgroundColor: '#1F2535', width: '150px', color: 'white', marginLeft: "32%", marginTop: "4%" }}
                                        // onClick={() => {
                                        //     console.log(this.state.dis)
                                        //     this.props.picCreateOrg(this.state.logo, this.props.login.mobile, this.state.org_name, this.state.first_name, this.state.last_name, this.state.email, this.state.gstin_no, "Organization");

                                        // }}
                                    >
                                        SUBMIT
                                    </Button>
                                </Link>
                                {/* <Link style={{ textDecoration: "none" }} to=""
                                >
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: '#1F2535', width: '150px', color: 'white', marginTop: "4%", }}
                                        onClick={() => {
                                        }}
                                    >
                                        CANCLE
                                    </Button>
                                </Link> */}




                            </div>


                        </CardContent>

                    </Card>
                </div>

                {/* <LoaderCon /> */}

            </div >
        )
    }
}
