import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Controller from "../containers/controller_cont";
import Login from "../../auth/container/superadminCont";
import Attendance from "../../pages/Attendance/Container/attendanceConts";
import Course from "../../pages/Course/Container/courseCont";
import CourseDetails from "../../pages/Course/Container/courseDetailsCont";
import Batch from "../../pages/Course/Container/batchCont";
import AssignmentDetails from "../../pages/Course/Container/assignmentDetailsCont"
import Company from "../../pages/Placement/Container/CompanyCont";
import Placement from "../../pages/Placement/Container/placementCont";
import Jobprofile from "../../pages/Placement/Container/JobprofileCont";
import StudentDetails from "../../pages/Students/Container/StudentDetailsCont";
import Trainer from "../../pages/Trainer/Container/trainerCont";
import Help from "../../pages/HelpandSupport/Container/helpCont"
import Dashboard from "../../pages/Dashboard/Container/dashboardconts";
import Students from "../../pages/Students/Container/studentCont";
import PlaceStudent from "../../pages/Placement/Component/PlaceStudent";
import Recordedclass from "../../pages/RecordedClass/Component/Recordedclass";
import Drawer_option_con from "../containers/drawer_cont";
import Error from "../../404";
import Vieworg from "../../auth/container/VieworgCont";
import Organization from "../../auth/components/Organization";
import Pay from "../../pages/Payment/Components/Pay";
import Setting from "../../pages/Settings/Components/Setting";
import Wallet from "../../auth/container/WalletCont";


//student
import Recordclass from "../../pages/RecordedClass/Component/Recordedclass";
import Placementstudent from "../../pages/Placement/Container/placementstudentCont";
import StudentAssignments from "../../pages/Assignment/Container/SassignmentCont";
import StudentAssignmentdetails from "../../pages/Assignment/Container/SassignDetailsCont";
import Discussion from "../../pages/Discussion/Container/discussionconts";
import Studentcourse from "../../pages/Course/Container/studentCourseCont";
import StudentcourseDetails from "../../pages/Course/Container/studentCourseDetailsCont";
import Studashboard from "../../pages/Dashboard/Container/StudashboardCont"
import StuClass from "../../pages/Classes/Component/StuClass";
import Studenthelp from "../../pages/HelpandSupport/Container/studenthelpCont";
import Companyselect from "../../pages/Placement/Container/companyselectCont";
import Jobprofileselect from "../../pages/Placement/Container/JobprofileselectCont";
import Chat from "../../pages/Dashboard/Components/Chat";
import Editprofile from "./editprofile";


//----------------------------------------------------STUDENT-----------------------------------//
//Trainer
import Trainerdashboard from "../../pages/Dashboard/Container/trainerdashboardconts";
import Trainerassignment from "../../pages/Course/Components/trainerassignment";
import Trainerassigncompleted from "../../pages/Course/Components/trainerassigncompleted"
import TrainerCourse from "../../pages/Course/Components/trainerCourse";
// import CourseDetailsTrainer from "../../pages//Course/Components/courseDetailsTrainer";
import TrainerBatch from "../../pages/Course/Components/trainerBatch";
// import Trainerassigncompleted from "../../pages/TrainerPortal/TrainerAssignment/Components/trainerassigncompleted";
import Approve from "../../pages/Assignment/Components/approve";
import Trainerclass from "../../pages/Classes/Container/trainerclassCont"
import Trainerrecordedclass from "../../pages/RecordedClass/Component/Trainerrecordedclass";
import TrainerDiscussionNotes from "../../pages/Course/Container/trainerclasstabaccDetailsCont";
import Trainercourseclasses from "../../pages/Course/Container/trainercourseclassesCont";
import Accdash from "../../pages/Accdash";



import Loginpage from "../../pages/Login/Container/LoginpageCont";



//Help

class Routers extends Component {
  render() {
    const { login, onLogout } = this.props;
    let body;
    console.log("isHome", login.isHome)
    console.log("orgHome", login.orgHome)
    if (login.isHome) {
      body = (
        <div>
          <main
            style={{
              flexGrow: 1,
            }}
          >
            <Controller />
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/verify/:id" element={<Login />} />
              {/* <Route exact path="/" element={<Login />} /> */}
              <Route exact path="/Organization" element={<Organization />} />
              {/* <Route exact path="/loginotp" element={<Loginotp />} /> */}
              <Route exact path="*" element={<Error />} />


            </Routes>

          </main>
        </div>
      );
    }
    else if (login.orgHome) {

      console.log("login type", login.type)
      if (login.type === "A" ||login.type === "Owner") {
        // Admin
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: 250
              }}
            >
              <Controller />
              {/* <Route exact path="/" element={<Login />} /> */}
              <Routes>
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/verify/:id" element={<Dashboard />} />
                <Route exact path="/attendance" element={<Attendance />} />
                <Route exact path="/course" element={<Course />} />
                <Route exact path="/batch" element={<Batch />} />
                <Route exact path="/courseDetails" element={<CourseDetails />} />
                <Route exact path="/assignmentDetails" element={<AssignmentDetails />} />
                <Route exact path="/Company" element={<Company />} />
                <Route exact path="/Placement" element={<Placement />} />
                <Route exact path="/placestudent" element={<PlaceStudent />} />
                <Route exact path="/Jobprofile" element={<Jobprofile />} />
                <Route exact path="/StudentDetails" element={<StudentDetails />} />
                <Route exact path="/Recordedclass" element={<Recordedclass />} />
                <Route exact path="/Trainer" element={<Trainer />} />
                <Route exact path="/Students" element={<Students />} />
                <Route exact path="/Help" element={<Help />} />
                <Route exact path="*" element={<Error />} />
                <Route exact path="/Pay" element={<Pay />} />
                <Route exact path="/Setting" element={<Setting />} />
                <Route exact path="/Organization" element={<Organization />} />
                <Route exact path="/Wallet" element={<Wallet />} />





              </Routes>
            </main>

          </div>
        );
      }
      else if (login.type == "S") {
        //Student
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />

            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: 250
              }}
            >
              <Controller />
              <Routes>
                {/* <Route exact path="/" element={<Login />} /> */}
                {/* <Route exact path="/" element={<><Empnav /><Empdrawer /><Empdashboard /></>} /> */}
                {/* <Route exact path="/empnav" element={<Empnav />} />
              <Route exact path="/empdrawer" element={<Empdrawer />} /> */}
                {/* <Route exact path="/Empdashboard" element={<><Empnav /><Empdrawer /><Empdashboard /></>} /> */}
                <Route exact path="/" element={<Studashboard />} />
                <Route exact path="/verify/:id" element={<Studashboard />} />
                <Route exact path="/recordclass" element={<Recordclass />} />
                <Route exact path="/Placementstudent" element={<Placementstudent />} />
                <Route exact path="/companyselect" element={<Companyselect />} />
                <Route exact path="/Jobprofileselect" element={<Jobprofileselect />} />
                <Route exact path="/StudentAssignments" element={<StudentAssignments />} />
                <Route exact path="/studentAssignmentdetails" element={<StudentAssignmentdetails />} />
                <Route exact path="/discussion" element={<Discussion />} />
                <Route exact path="/studentcourse" element={<Studentcourse />} />
                <Route exact path="/studentcourseDetails" element={<StudentcourseDetails />} />
                {/* <Route exact path="/assignmentDetails" element={<AssignmentDetails />} /> */}
                <Route exact path="/assignmentDetails" element={<AssignmentDetails />} />
                <Route exact path="/StuClass" element={<StuClass />} />
                <Route exact path="/course" element={<Course />} />
                <Route exact path="/batch" element={<Batch />} />
                <Route exact path="/courseDetails" element={<CourseDetails />} />
                <Route exact path="/Help" element={<Help />} />
                <Route exact path="/Studenthelp" element={<Studenthelp />} />
                <Route exact path="/Chat" element={<Chat />} />
                <Route exact path="/Editprofile" element={<Editprofile />} />
                <Route exact path="/Accdash" element={<Accdash />} />
                <Route exact path="*" element={<Error />} />
                {/* <Route exact path="/logout" element={<Logout />} /> */}
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      else if (login.type == "T") {
        //Trainer
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: 250
              }}
            >
              {/* <Controller /> */}
              <Routes>
                <Route exact path="/" element={<Trainerdashboard />} />
                <Route exact path="/verify/:id" element={<Trainerdashboard />} />
                {/* <Route exact path="/trainerassignment" element={<Trainerassignment />} />
              <Route exact path="/trainerassigncompleted" element={<Trainerassigncompleted />} />
              <Route exact path="/trainerCourse" element={<TrainerCourse />} /> */}
                {/* <Route exact path="/courseDetailsTrainer" element={<CourseDetailsTrainer />} /> */}
                {/* <Route exact path="/trainerBatch" element={<TrainerBatch />} /> */}
                <Route exact path="/course" element={<Course />} />
                <Route exact path="/batch" element={<Batch />} />
                <Route exact path="/courseDetails" element={<CourseDetails />} />
                <Route exact path="/trainerassignment" element={<Trainerassignment />} />
                {/* <Route exact path="/trainerassigncompleted" element={<Trainerassigncompleted />} /> */}
                <Route exact path="/assignmentDetails" element={<AssignmentDetails />} />
                {/* <Route exact path="/approve" element={<Approve />} /> */}
                <Route exact path="/trainerclass" element={<Trainerclass />} />
                {/* <Route exact path="/trainerrecordedclass" element={<Trainerrecordedclass />} /> */}
                <Route exact path="/trainerdiscussionnotes" element={<TrainerDiscussionNotes />} />
                {/* <Route exact path="/Chat" element={<Chat />} /> */}
                {/* <Route exact path="/Help" element={<Help />} /> */}
                <Route exact path="/Trainercourseclasses" element={<Trainercourseclasses />} />
                <Route exact path="*" element={<Error />} />
                <Route exact path="/Studenthelp" element={<Studenthelp />} />
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
    }
    else {
      body = (
        <div
        >
          <main>
            <Controller />
            <Routes>
              <Route exact path="/" element={<Vieworg />} />
            </Routes>
          </main>
        </div>
      );
    }
    return <Router>{body}</Router>;
  }
}

export default Routers;