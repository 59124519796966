import React, { Component } from "react";
import "../styles/TrainerClassTabAccDetails.css";
import Button from "@mui/material/Button";
import { Avatar } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Divider, OutlinedInput, Card } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import {
  Grid,
  cardContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Box,
} from "@mui/material";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
import check from "../../Images/Ok.png"


export default class TrainerClassTabAccDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
      uploadNotes: false,
      openJoin: false,
      noteEdit: false,
      noteDelete: false,
      message: "",
      attachment: "",
      title: "",
      page: 0,
      setPage: 0,
      rowsPerPage: 10,
      setRowsPerPage: "",
      anchorEl: null,
      open: false,
      old_attachment:"",

      type: "Discussion",
    };
  }

  handleClick181 = (event) => {
    this.setState({
      anchorEschedule: event.currentTarget,
      menuEschedule: true,
    });
  };

  handleClose181 = () => {
    this.setState({
      anchorEschedule: null,
      menuEschedule: false,
    });
  };

  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"
    this.props.viewAllDiscussion();
    this.props.view_notes_by_batch_id(this.props.courses.batch_details._id, this.state.page, this.state.rowsPerPage,this.props.login.org_id);
    console.log("hhhhhh", this.props.courses.view_notes_by_batch)
    // console.log("hhhhhh", this.props.courses.setSubjectId)
    console.log("aaaaaaaaaa",this.props.courses.trainer_profile_in_assignment)

  }

  render() {
    const { snackbar, close_snack_bar } = this.props;
    const handleChangePage = (event, newPage) => {
      this.props.view_notes_by_batch_id(this.props.courses.batch_details._id, newPage - 1, this.state.rowsPerPage,this.props.login.org_id)
      // this.props.viewAllAssignTo(newPage - 1, this.state.rowsPerPage);
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };
    return (
      <Grid style={{ marginTop: "7%" }}>
        <div style={{ display: "flex", flexDirection: "row", }}>
          <Link style={{ textDecoration: "none" }} to="/course">
            <Button style={{ color: "#7A7E86" }}>
              Course

            </Button>
          </Link>
          <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
          <Link style={{ textDecoration: "none" }} to="/courseDetails">
            <Button style={{ color: "black" }}>
              Course Details

            </Button>
          </Link>
          <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
          <Link style={{ textDecoration: "none" }} to="/trainercourseclasses">
            <Button style={{ color: "black" }}>
              Classes
            </Button>
          </Link>
          <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
          <Link style={{ textDecoration: "none" }} to="">
            <Button style={{ color: "black" }}>
              Discussion

            </Button>
          </Link>
        </div>
        <Card style={{ margin: "2.5% 2.5% 2.5% 0%", padding: "0px 20px 20px 20px" }}>
          <div className="pgprogram-section-nn">
            <div style={{ display: "flex", justifyContent: "space-between" }} >
              <div>
                <Typography variant="h5" fontWeight={500}>{this.props.courses.course_name}</Typography>
              </div>
              <div style={{ fontWeight: 500, fontSize: "18px" }}>
                <Button
                  // disabled={(moment(dashboard.schedule_by_trainer.date + " " + dashboard.schedule_by_trainer.end_time)).isBefore(moment())}
                  variant="contained"
                  onClick={() => {
                    // console.log((moment(dashboard.schedule_by_trainer.date + " " + dashboard.schedule_by_trainer.end_time)).isBefore(moment()))
                    // this.props.add_attendance(this.props.login.user_id)
                    // window.location.href = dashboard.schedule_by_trainer.class_link
                  }}
                  style={{
                    width: "120px",
                    backgroundColor: "#0000B3",
                  }}
                >
                  Join Class
                </Button>
              </div>
            </div>

            <div className="pg-cal-icon-section-nn">
              <div className='pg-cal-nn'>
                <strong style={{ color: "#0000B3", fontSize: "0.9rem" }}>Time: {this.props.courses.batch_details.batch_time} </strong>
              </div>
              <div className='pg-assigned-img-nn'>
                <span><strong>Trainer : </strong></span>
                <img src={this.props.courses.trainer_profile_in_assignment}></img>
                <span><strong>{this.props.courses.trainer_name_in_assignment}</strong></span>
              </div>
            </div>
          </div>
          <div >
            <Tabs value={this.state.type}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: 'divider', height: "65px" }}>
                <TabList style={{ width: "100%", display: "flex" }}>
                  <Tab label="Active" className={this.state.type === "Discussion" ? 'selected-tab' : ''}   >
                    <Button

                      style={{ color: "#7A7E86", fontSize: "20px" }}
                      id=""
                      onClick={() => {
                        this.setState({ type: "Discussion" });
                      }}
                    >
                      {" "}
                      Discussion{" "}
                    </Button>
                  </Tab>
                  <Tab className={this.state.type === "Notes" ? 'selected-tab' : ''}>
                    <Button

                      style={{ color: "#7A7E86", fontSize: "20px" }}
                      id=""
                      onClick={() => {
                        this.setState({ type: "Notes" });
                      }}
                    >
                      {" "}
                      Notes{" "}
                    </Button>
                  </Tab>
                </TabList>
              </Box>

              <TabPanel>
                {Array.isArray(this.props.discussion.discussion_details) && this.props.discussion.discussion_details.map((item) => {
                  return (
                    <div style={{ padding: "1rem" }}>
                      <Typography className="trainer-tab-acc-548">
                        <div>
                          <img
                            src={item.user_details.profile_pic}
                          />
                        </div>
                        <div>
                          <strong>{item.user_details.name}</strong>
                        </div>
                        <div style={{ color: "#0000B3" }}>
                          <strong>{item.user_details.type == "S" ? "( Student )" : "( Trainer )"}</strong>
                        </div>
                      </Typography>
                      <Typography>
                        {item.message}
                      </Typography>
                      <Typography className="trainer-tab-acc-549">
                        <div style={{ color: "#777676" }}>
                          {moment().startOf(item.date).fromNow()}
                        </div>
                        {/* <div style={{ color: "#0000B3" }}>
                      <strong>Reply</strong>
                    </div> */}
                      </Typography>

                    </div>
                  )
                })}
                <Typography style={{ marginTop: "5rem" }}>
                  <div>
                    <input
                      style={{
                        width: "50rem",
                        height: "4rem",
                        border: "none",
                        borderRadius: "15px",
                        backgroundColor: "#D8D8D8",
                      }}
                      type="text"
                      onChange={(text) =>
                        this.setState({ message: text.target.value })}

                    />
                    <Button
                      style={{
                        backgroundColor: "#0000B3",
                        marginLeft: "2rem",
                        width: "7rem",
                        height: "2rem",
                      }}
                      variant="contained"
                      onClick={() => {
                        this.setState({ addDiscussion: false, message: "" });
                        this.props.addDiscussion(this.props.login.user_id, this.state.message)
                        console.log("dis", this.props.login.user_id, this.state.message)

                      }}
                    >
                      Send
                    </Button>
                  </div>
                </Typography>
              </TabPanel>

              <TabPanel>
                <div>
                  <Button
                    id="batch-add-mk"
                    style={{
                      textTransform: "capitalize",
                      position: "relative",
                      float: "right",
                      top: "-3rem",
                      color: "#0000B3",
                      border: "1px solid #0000B3",
                      fontSize: "0.9rem",
                    }}
                    onClick={(e) => {
                      this.setState({ uploadNotes: true });
                    }}
                    variant="outlined"
                  >
                    Upload Notes
                  </Button>
                  <Table>
                    <TableHead style={{ backgroundColor: "#F8F7F7" }}>
                      <TableRow>
                        <TableCell style={{ textAlign: "center", width: "10%" }}>
                          Session
                        </TableCell>
                        <TableCell style={{ textAlign: "center", width: "20%" }}>
                          Notes
                        </TableCell>
                        <TableCell style={{ textAlign: "center", width: "20%" }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(this.props.courses.view_notes_by_batch) && this.props.courses.view_notes_by_batch.map((item) => {
                        return (
                          <TableRow>
                            <TableCell align="center">{item.title}</TableCell>
                            <TableCell align="center">
                              <div className="table-cell-notes">
                                <div id="table-cell-notes-icon">
                                  <PictureAsPdfIcon />
                                </div>
                                <div id="table-cell-notes-text" style={{ width: "50px" }}>
                                  <a href={item.attachment}><strong>attachment</strong></a>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell align="right">
                              <div>
                                <Button
                                  onClick={(e) => {
                                    this.handleClick181(e);
                                  }}
                                >
                                  <MoreVertIcon
                                    style={{ color: "black", fontSize: "20px" }}
                                  />
                                </Button>

                                <Menu
                                  anchorEl={this.state.anchorEschedule}
                                  open={this.state.menuEschedule}
                                  onClose={this.handleClick181}
                                >
                                  <MenuItem
                                    style={{ color: "#0000B3" }}
                                    onClick={(e) => {
                                      this.setState({ noteEdit: true,
                                      notes_id:item._id,
                                    Course_Id:item.course_id,
                                    batch_id:item.batch_id,
                                    subject_id:item.subject_id,
                                    lession_id:item.lession_id,
                                    old_attachment:item.attachment,
                                    title:item.title,
                                   });
                                    }}
                                  >
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    style={{ color: "#0000B3" }}
                                    onClick={(e) => {
                                      this.setState({ noteDelete: true });
                                    }}
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </div>
                            </TableCell>

                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </div>


                <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
                  <Pagination
                    count={parseInt(this.props.courses.notes_length / 8) + 1}
                    siblingCount={0}
                    size="small"
                    onChange={handleChangePage}
                  />
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </Card>

        <Dialog open={this.state.openJoin}>
          <Grid style={{ height: "", width: "500px" }}>
            <div
              style={{
                display: "flex",
                paddingLeft: "10px",
                flexDirection: "row",
              }}
            >
              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ openJoin: false });
                }}
                style={{ marginLeft: "446px", marginTop: "20px" }}
              />
            </div>
            <Box>
              <div id="join-class-dialog-trainer-nnm">
                <div style={{ color: "#0000B3", fontSize: "22px" }}>
                  <h3>Batch-1</h3>
                </div>
                <div style={{ color: "#777676", fontSize: "14px" }}>
                  <p>
                    (Post Graduate Program On Full Stack <br></br> Development
                    With PHP Or Python)
                  </p>
                </div>
                <div>
                  <div id="join-dialog-text-html">
                    <h3>HTML</h3> <span>(Session-1)</span>
                  </div>
                </div>
                <di id="join-dialog-tr-nn1">
                  <div id="join-dialog-tr-nn11">
                    <span>
                      <AccessTimeOutlinedIcon className="join-d-icon-34" />
                      1hr 30min
                    </span>
                  </div>
                  <div>
                    <span>
                      <SupervisorAccountIcon className="join-d-icon-34" />
                      130 Students
                    </span>
                  </div>
                </di>
                <div id="join-dialog-time-nn32">
                  <div>Date-22-04-23</div>
                  <div>Time-10AM-11AM</div>
                </div>
              </div>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "6%",
                position: "relative",
                bottom: "1rem",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({ openJoin: false });
                }}
                style={{ width: "23%", color: "#0000B3" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({ openJoin: false });
                }}
                style={{
                  marginLeft: 10,
                  width: "23%",
                  backgroundColor: "#0000B3",
                  color: "white",
                }}
              >
                Start Now
              </Button>
            </Box>
          </Grid>
        </Dialog>

        <Dialog open={this.state.uploadNotes} style={{}}>
          <Grid style={{ height: "500px", width: "600px" }}>
            <div id="trainer-tab-dialog-tt">
              <h2 style={{ position: "relative" }}>Upload Notes</h2>
              <Box
                onClick={() => {
                  this.setState({ uploadNotes: false });
                }}
              >
                <CloseOutlinedIcon />
              </Box>
            </div>
            <center>
              <Typography id="acc-tab-2111">{this.props.courses.batch_details.batch_number}</Typography>
            </center>
            <Typography id="trainer-tab-tt-78">
              {this.props.courses.course_name}
            </Typography>
            <Box>
              <Typography
                style={{ position: "relative", right: "3rem", lineHeight: "1" }}
              >
                <div>
                  <h3 style={{ marginLeft: "9rem" }}>Session</h3>
                  <OutlinedInput
                    className="class-tab-233"
                    placeholder="Enter Session Name"
                    type="text"
                    value={this.state.title}
                    onChange={(text) =>
                      this.setState({ title: text.target.value })
                    }

                  ></OutlinedInput>
                </div>
                <div>
                  <h3 style={{ marginLeft: "9rem" }}>Attach File</h3>
                  <div
                    style={{
                      border: "1px solid #C8C8C8",
                      width: "26rem",
                      height: "2.5rem",
                      borderRadius: "5px",
                      marginLeft: "9rem",
                    }}
                  >

                    <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                      <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                      >
                        {this.state.attachment !== "" ? (this.state.attachment).name : "Attach"}
                      </Typography>
                      <AttachFileIcon style={{ marginTop: "3.5%" }} />
                    </label>
                    <input style={{ display: "none" }} type="file" id="file"
                      onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }} />

                  </div>
                </div>
              </Typography>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ uploadNotes: false });
                  }}
                  style={{ width: "23%", color: "#0000B3" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.props.upload_notes(this.props.login.user_id, this.props.courses.Course_Id, this.props.courses.batch_details._id, this.props.courses.setSubjectId, this.props.courses.setLessonId.lession_id, this.state.attachment, this.state.title,this.props.login.org_id);
                    this.setState({ uploadNotes: false, attachment: "", title: "" });
                  }}
                  style={{
                    marginLeft: 10,
                    width: "23%",
                    backgroundColor: "#0000B3",
                    color: "white",
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Grid>
        </Dialog>

        <Dialog open={this.state.noteEdit} style={{}}>
          <Grid style={{ height: "500px", width: "600px" }}>
            <div id="trainer-tab-dialog-tt">
              <h2 style={{ position: "relative" }}>Update Notes</h2>
              <Box
                onClick={() => {
                  this.setState({ noteEdit: false });
                }}
              >
                <CloseOutlinedIcon />
              </Box>
            </div>
            <center>
              <Typography id="acc-tab-2111">{this.props.courses.batch_details.batch_number}</Typography>
            </center>
            <Typography id="trainer-tab-tt-78">
              {this.props.courses.course_name}
            </Typography>
            <Box>
              <Typography
                style={{ position: "relative", right: "3rem", lineHeight: "1" }}
              >
                <div>
                  <h3 style={{ marginLeft: "9rem" }}>Session</h3>
                  <OutlinedInput
                    className="class-tab-233"
                    placeholder="Enter Session Name"
                    type="text"
                    value={this.state.title}
                    onChange={(text) =>
                      this.setState({ title: text.target.value })
                    }

                  ></OutlinedInput>
                </div>
                <div>
                  <h3 style={{ marginLeft: "9rem" }}>Attach File</h3>
                  <div
                    style={{
                      border: "1px solid #C8C8C8",
                      width: "26rem",
                      height: "2.5rem",
                      borderRadius: "5px",
                      marginLeft: "9rem",
                    }}
                  >

                    <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                      <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                      >
                        {this.state.attachment !== "" ? (this.state.attachment).name : "Change Attach"}
                      </Typography>
                      <AttachFileIcon style={{ marginTop: "3.5%" }} />
                    </label>
                    <input style={{ display: "none" }} type="file" id="file"
                      onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }} />

                  </div>
                </div>
              </Typography>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ noteEdit: false });
                  }}
                  style={{ width: "23%", color: "#0000B3" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.props.update_upload_notes(this.state.notes_id, this.state.Course_Id, this.state.batch_id, this.props.courses.setSubjectId, this.state.lession_id, this.state.attachment, this.state.title,this.state.old_attachment,this.props.login.org_id);
                    this.setState({ noteEdit: false, attachment: "", title: "" });
                    console.log("Notesssssssss",this.state.notes_id, this.state.Course_Id, this.state.batch_id, this.props.courses.setSubjectId, this.state.lession_id, this.state.attachment, this.state.title, this.state.old_attachment,this.props.login.org_id)
                  }}
                  style={{
                    marginLeft: 10,
                    width: "23%",
                    backgroundColor: "#0000B3",
                    color: "white",
                  }}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </Grid>
        </Dialog>

        <Dialog open={this.state.noteDelete} style={{}}>
          <Grid style={{ height: "250px", width: "520px" }}>
            <CloseOutlinedIcon
              onClick={() => {
                this.setState({ noteDelete: false });
              }}
              style={{ marginLeft: "440px", marginTop: "20px" }}
            />
            <Box>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 600,
                }}
              >
                Are you sure you want to delete ?
              </Typography>
              <Typography
                id="modal-modal-description"
                style={{
                  marginTop: "2%",
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  color: "black",
                }}
              >
                This process will lead to loss of entire data, proceed at yours
                own risk
              </Typography>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ noteDelete: false });
                  }}
                  style={{ width: "23%", color: "#0000B3" }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.setState({ noteDelete: false });
                  }}
                  style={{
                    marginLeft: 10,
                    width: "23%",
                    backgroundColor: "#0000B3",
                    color: "white",
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Grid>
        </Dialog>
        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </Grid >
    );
  }
}
