import React, { Component } from 'react'
import "../styles/Placement.scss"
import add from "../../Images/add.png"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import { Box, Modal, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Pagination from '@mui/material/Pagination';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from "@mui/icons-material/Search";
import { AiOutlineSearch } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { FaGreaterThan } from "react-icons/fa";
// import avatar from "../../../Placement/Admin/Images/Avatar.png"
import EditIcon from '@mui/icons-material/Edit';

import {
    Card, FormControl, Grid, IconButton, InputBase, Table,
    MenuItem, OutlinedInput, Paper, Select, TextareaAutosize, TableBody, TableContainer,
    DialogActions, DialogTitle, DialogContent,

} from '@mui/material';
import { Link } from "react-router-dom";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupIcon from '@mui/icons-material/Group';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Dialog from "@mui/material/Dialog";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";


export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();

        return (
            <Component
                navigate={navigate}
                {...props}
            />
        );
    };

    return Wrapper;
};
class Placement extends Component {
    constructor(props) {
        super(props);
        this.state = {

            editcompany: false,
            addcopmany: false,
            deletecompany: false,

            company_name: "",
            company_email: "",
            company_website: "",
            filter_company: "N",
            admin_course: "N",

            page: 0,
            setPage: 0,
            rowsPerPage: 10,
            setRowsPerPage: "",
            anchorEl: null,
            open: false,

            placementdata: [
                {
                    companyname: "ABCD",
                    companyemail: "abcd@abcd.com",
                    companywebsite: "ABCD.com"
                },
                {
                    companyname: "ABCD",
                    companyemail: "abcd@abcd.com",
                    companywebsite: "ABCD.com"
                },

            ]

        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F9FDFE"
        this.props.viewAllCompany(this.state.page, this.state.rowsPerPage);
    }


    render() {
        const { placement, snackbar, close_snack_bar } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const handleChangePage = (event, newPage) => {
            this.props.viewAllCompany(newPage - 1, this.state.rowsPerPage);
            this.setState({
                page: newPage,
            });
        };
        return (

            <Grid style={{ marginTop: "10%" }}>
                <Card
                    style={{ margin: "1% 2.5% 2.5% 0%" }}
                >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography style={{ color: "black", fontSize: "29px", margin: "1% 0% 0% 2%", fontWeight: 450 }}>
                            Placement
                        </Typography>
                        <Button
                            onClick={() => {
                                this.setState({
                                    addcopmany: true,
                                });
                            }}
                            style={{
                                height: "2.25rem",
                                marginLeft: "auto",
                                marginTop: "1.5%",
                                marginRight: "2.5%",
                                color: "#0000B3",
                            }}
                            variant="text"
                        >
                            <img src={add} alt="" srcset="" />
                            Add Company
                        </Button>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", margin: "2%" }}>
                        <Button disabled variant="contained" style={{ color: "#FFF", backgroundColor: "#0000B3", fontSize: "18px", fontWeight: "400" }}>Company</Button>
                        <Paper
                            component="form"
                            style={{
                                width: "436px",
                                height: "36px",
                                marginLeft: "auto",
                                marginTop: "1%",
                                backgroundColor: "#F3F3F3",
                                borderRadius: "10px"
                            }}
                        >
                            <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                style={{ ml: 5, flex: 1, fontSize: "15px" }}
                                placeholder="Search "
                                inputProps={{ "aria-label": "Search " }}
                                onChange={(e) => this.props.searchCompany(placement.all_companys, e.target.value)}
                            />
                        </Paper>
                    </div>

                    <div>
                        <TableContainer>
                            <Table
                                aria-label="a dense table"
                            >
                                <TableHead>
                                    <TableRow style={{ backgroundColor: "#F8F7F7", }}>
                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                                            No
                                        </TableCell>
                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                                            Company Name
                                        </TableCell>
                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                                            Company Email
                                        </TableCell>
                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                                            Comapany Website
                                        </TableCell>
                                        <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {placement.search_company.length == "0" &&
                                        <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                                            <Typography>No Result Found</Typography>
                                        </Grid>
                                    }
                                    {Array.isArray(placement.search_company) && placement.search_company.map((item, index) => {
                                        return (
                                            <TableRow style={{ textDecoration: "none", borderBottom: "1px solid #E3E3E3", cursor: "pointer" }}
                                                onClick={() => {
                                                    this.props.setCompanyId(item._id);
                                                    this.props.setCompanyName(item.company_name);
                                                    this.props.setCompanyEmail(item.company_email);
                                                }}
                                            >
                                                <TableCell
                                                    style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}
                                                    align="center"
                                                    onClick={() => { this.props.navigate('/Placement') }}
                                                >
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell
                                                    style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}
                                                    align="center"
                                                    onClick={() => { this.props.navigate('/Placement') }}
                                                >
                                                    {item.company_name}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                                                    }}
                                                    align="center"
                                                    onClick={() => { this.props.navigate('/Placement') }}
                                                >
                                                    {item.company_email}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                                                    }}
                                                    align="center"
                                                    onClick={() => { this.props.navigate('/Placement') }}
                                                >
                                                    {item.company_website}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: "16px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <span className='editIcon'>
                                                        <EditIcon onClick={() => {
                                                            this.setState({
                                                                editcompany: true,
                                                                company_id: item._id,
                                                                company_name: item.company_name,
                                                                company_email: item.company_email,
                                                                company_website: item.company_website

                                                            })
                                                        }}

                                                            style={{ cursor: "pointer", marginRight: 10, color: "#3B3BC4" }} />
                                                    </span>
                                                    <span className='deleteIcon'>
                                                        <DeleteOutlineOutlinedIcon onClick={() => {
                                                            this.setState({
                                                                deletecompany: true,
                                                                company_id: item._id
                                                            })
                                                        }}

                                                            style={{ cursor: "pointer", color: "#C43232" }} />
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
                        <Pagination
                            count={parseInt(placement.company_length / 10) + 1}
                            siblingCount={0}
                            size="small"
                            onChange={handleChangePage}
                        />
                    </div>


                </Card>

                {/*------------------------------------------------ Company Dialog ---------------------------------------------------------*/}

                {/*Add*/}
                <Dialog open={this.state.addcopmany} maxWidth="xs" fullWidth
                    sx={{
                        // 👇 Another option to style Paper
                        "& .MuiDialog-paper": {
                            borderRadius: "10px",
                        },
                    }}
                >
                    <Grid>
                        <DialogTitle style={{ marginBottom: "1.5%" }}>
                            <Grid container>
                                <Grid item xs={11}>
                                    <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                                        Add Company
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container >
                                <Grid item xs={1}> </Grid>
                                <Grid item xs={10} direction='column'>
                                    <div>
                                        <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Company Name</Typography>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter Company Name"
                                            sx={{
                                                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                                borderRadius: "5px",
                                                backgroundColor: "rgba(239, 239, 239, 0.60)"
                                            }}
                                            onChange={(text) =>
                                                this.setState({ company_name: text.target.value })}
                                        >
                                        </TextField>
                                    </div>
                                    <div>
                                        <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Comapany Email Id</Typography>
                                        <TextField
                                        type="email"
                                            fullWidth
                                            placeholder="Enter Comapany Email Id"
                                            sx={{
                                                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                                borderRadius: "5px",
                                                backgroundColor: "rgba(239, 239, 239, 0.60)"
                                            }}
                                            onChange={(text) =>
                                                this.setState({ company_email: text.target.value })}
                                        >
                                        </TextField>
                                    </div>
                                    <div>
                                        <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Company Website</Typography>
                                        <TextField
                                         type="email"
                                            fullWidth
                                            placeholder="Enter Company Website"
                                            sx={{
                                                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                                borderRadius: "5px",
                                                backgroundColor: "rgba(239, 239, 239, 0.60)"
                                            }}
                                            onChange={(text) =>
                                                this.setState({ company_website: text.target.value })}
                                        >
                                        </TextField>
                                    </div>
                                </Grid>
                                <Grid item xs={1}> </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions style={{ display: 'flex', justifyContent: "center", marginBottom: 8, marginTop: 8 }} >
                            <Button
                                variant="outlined"
                                style={{
                                    fontSize: "15px",
                                    height: "38px",
                                    width: "120px",
                                    color: "#0000B3",
                                    border: "1px solid #0000B3",
                                }}
                                onClick={() => {
                                    this.setState({ addcopmany: false });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#0000B3",
                                    fontSize: "15px",
                                    height: "38px",
                                    width: "160px",
                                    color: "white",
                                    marginLeft: "2%",

                                }}
                                disabled={this.state.company_name == "" || this.state.company_email == "" || this.state.company_website == ""}
                                onClick={() => {
                                    this.setState({
                                        addcopmany: false, company_name: "", company_email: "", company_website: ""
                                    });
                                    this.props.addCompany(this.props.login.user_id, this.state.company_name, this.state.company_email, this.state.company_website, this.state.page, this.state.rowsPerPage)
                                }}
                            >
                                Add
                            </Button>
                        </DialogActions>
                    </Grid>
                </Dialog>

                {/*Delete*/}
                <Dialog
                    open={this.state.deletecompany} maxWidth="sm" fullWidth
                    sx={{


                        // 👇 Another option to style Paper
                        "& .MuiDialog-paper": {
                            borderRadius: "10px",
                        },
                    }}
                >
                    <Grid>
                        <DialogTitle style={{ marginBottom: "1.5%" }}>
                            <Grid container>
                                <Grid item xs={12} >
                                    <Typography style={{ fontSize: "22px", fontWeight: "600", color: "#222222" }}>
                                        Delete
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ flexDirection: "row", textAlign: "center" }}>
                            <Grid container direction={"column"} spacing={1}>
                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: "18px", fontWeight: 600, fontFamily: "" }}>Are you sure you want to delete ?</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>This process will lead to loss of entire data, proceed at yours
                                        own risk</Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'center', margin: '5% 0% 5% 0%' }}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ deletecompany: false });
                                }}
                                style={{
                                    fontSize: "15px",
                                    height: "38px",
                                    width: "120px",
                                    color: "#0000B3",
                                    border: "1px solid #0000B3",
                                }}
                            >
                                No
                            </Button>
                            <Button
                                variant="contained"

                                onClick={() => {
                                    this.setState({ deletecompany: false });
                                    this.props.deleteCompany(this.state.company_id, this.state.page, this.state.rowsPerPage, this.props.login.org_id)
                                }}
                                style={{
                                    backgroundColor: "#0000B3",
                                    fontSize: "15px",
                                    height: "38px",
                                    width: "120px",
                                    color: "white",
                                    marginLeft: "2%",
                                }}
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Grid>
                </Dialog>

                {/*Edit*/}
                <Dialog
                    open={this.state.editcompany} maxWidth="xs" fullWidth
                    sx={{
                        // 👇 Another option to style Paper
                        "& .MuiDialog-paper": {
                            borderRadius: "10px",
                        },
                    }}
                >
                    <Grid>
                        <DialogTitle style={{ marginBottom: "1.5%" }}>
                            <Grid container>
                                <Grid item xs={11}>
                                    <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                                        Edit Company
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container >
                                <Grid item xs={1}> </Grid>
                                <Grid item xs={10} direction='column'>
                                    <div>
                                        <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Company Name</Typography>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter Company Name"
                                            sx={{
                                                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                                borderRadius: "5px",
                                                backgroundColor: "rgba(239, 239, 239, 0.60)"
                                            }}
                                            value={this.state.company_name}
                                            onChange={(e) => {
                                                this.setState({ company_name: e.target.value })
                                            }}
                                        >
                                        </TextField>
                                    </div>
                                    <div>
                                        <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Comapany Email Id</Typography>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter Comapany Email Id"
                                            sx={{
                                                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                                borderRadius: "5px",
                                                backgroundColor: "rgba(239, 239, 239, 0.60)"
                                            }}
                                            value={this.state.company_email}
                                            onChange={(e) => {
                                                this.setState({ company_email: e.target.value })
                                            }}
                                        >
                                        </TextField>
                                    </div>
                                    <div>
                                        <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Company Website</Typography>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter Company Website"
                                            sx={{
                                                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                                borderRadius: "5px",
                                                backgroundColor: "rgba(239, 239, 239, 0.60)"
                                            }}
                                            value={this.state.company_website}
                                            onChange={(e) => {
                                                this.setState({ company_website: e.target.value })
                                            }}
                                        >
                                        </TextField>
                                    </div>
                                </Grid>
                                <Grid item xs={1}> </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions style={{ display: 'flex', justifyContent: "center", marginBottom: 8, marginTop: 8 }} >
                            <Button
                                variant="outlined"
                                style={{
                                    fontSize: "15px",
                                    height: "38px",
                                    width: "120px",
                                    color: "#0000B3",
                                    border: "1px solid #0000B3",
                                }}
                                onClick={() => {
                                    this.setState({ editcompany: false });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#0000B3",
                                    fontSize: "15px",
                                    height: "38px",
                                    width: "160px",
                                    color: "white",
                                    marginLeft: "2%",

                                }}
                                disabled={this.state.company_name == "" || this.state.company_email == "" || this.state.company_website == ""}
                                onClick={() => {
                                    this.setState({
                                        editcompany: false, company_name: "", company_email: "", company_website: ""
                                    });
                                    this.props.updateCompany(this.state.company_id, this.state.company_name, this.state.company_email, this.state.company_website, this.state.page, this.state.rowsPerPage, this.props.login.org_id)
                                }}
                            >
                                Update
                            </Button>
                        </DialogActions>
                    </Grid>
                </Dialog>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}

            </Grid >
        )
    }
}
export default withRouter(Placement);
