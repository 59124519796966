import React, { Component } from "react";
import { connect } from "react-redux";
import Course from "../Components/studentcourse";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    view_course_by_student,
    setCoursename,
    setDuration,
    setCourseCode,
    setDESC,
    setCourseId,
    setBatchId,
    setImage
} from "../action"


export class Controller extends Component {
  render() {
    return (
      <Course {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    courses: store.courses,
    login: store.login,
   
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    view_course_by_student: (student_id, page_number, rowper_page) => {
      dispatch(view_course_by_student(student_id, page_number, rowper_page))
    },
    setCoursename: (payload) => {
      dispatch(setCoursename(payload))
    },
    setImage: (payload) => {
      dispatch(setImage(payload))
    },
    setDuration: (payload) => {
      dispatch(setDuration(payload))
    },
    setCourseCode: (payload) => {
      dispatch(setCourseCode(payload))
    },

    setDESC: (payload) => {
      dispatch(setDESC(payload))
    },

    setCourseId: (payload) => {
      dispatch(setCourseId(payload))
    },
    setBatchId: (payload) => {
      dispatch(setBatchId(payload))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);