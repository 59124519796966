import React, { Component } from 'react';
import { Card, } from '@mui/material/';
import { Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";

export default class trainerassignment extends Component {
    render() {
        const { tickets, snackbar, close_snack_bar } = this.props;
        return (
            <Card style={{ marginTop: "8%", marginLeft: "15%", width: "80%", height: "70vh", }}>


                <div style={{}}>
                    <h3 style={{ color: "black", fontSize: "27px", marginLeft: "2%" }}>
                        Assignment
                    </h3>

                </div>



                <Card style={{ marginTop: "1%", width: "99%", height: "5vh", backgroundColor: "#F9FDFE", paddingTop: "0.5%", boxShadow: "none" }}>

                    <Typography style={{ marginLeft: "5%", color: "#7A7E86" }}>

                        Course Name
                    </Typography>



                </Card>
                <Link style={{ textDecoration: "none" }} to="/trainerassigncompleted">
                    <Card style={{ marginTop: "1%", width: "99%", height: "6vh", boxShadow: "none" }}>
                        <Typography style={{ marginLeft: "5%", fontWeight: 600 }}>

                            Post Graduaation Program On Full Stack Development With PHP or Python(Batch-1)
                        </Typography>
                    </Card>
                </Link>
                <Divider style={{ color: "gray" }} />

                <Card style={{ marginTop: "1%", width: "99%", height: "6vh", boxShadow: "none" }}>
                    <Typography style={{ marginLeft: "5%", fontWeight: 600 }}>

                        Post Graduaation Program On Full Stack Development With PHP or Python(Batch-1)
                    </Typography>
                </Card>

                <Divider style={{ color: "gray" }} />


                <Card style={{ marginTop: "1%", width: "99%", height: "6vh", boxShadow: "none" }}>
                    <Typography style={{ marginLeft: "5%", fontWeight: 600 }}>

                        Post Graduaation Program On Full Stack Development With PHP or Python(Batch-1)
                    </Typography>
                </Card>

                <Divider style={{ color: "gray" }} />










                {/* <Link style={{textDecoration:"none"}} to ="/studentAssignmentdetails">
                <Card style={{ marginTop: "1%", width: "94%", height: "25vh", marginLeft: "3%" }}>

                    <div style={{ display: "flex", flexDirection: "row", marginTop: "2%", marginLeft: "2%" }}>
                        <FiberManualRecordIcon style={{ color: "#24E405", fontSize: "17px" }} />
                        <Typography style={{ marginLeft: "1%", color: "#7A7E86" }}>
                            Post Graduation Program On Full Stack Development With PHP Or Python

                        </Typography>

                    </div>


                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "3%", marginTop: "1%" }}>
                        <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>
                            Assignment:
                        </Typography>
                        <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>
                            Temperature Converter Website
                        </Typography>

                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "3%", marginTop: "1%" }}>
                        <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>
                            Assigned by:
                        </Typography>
                        <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>

                            Ankit Das
                        </Typography>

                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "3%", marginTop: "2%" }}>
                    <CalendarMonthIcon style={{color:"#0000B3",fontSize:"20px", marginLeft: "1%",}}/>
                        <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" ,color:"#0000B3"}}>
                            Due for 10 May 2023

                        </Typography>

                        <PictureAsPdfIcon style={{color:"red",fontSize:"20px", marginLeft: "3%",}}/>
                        <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px",color:"#3679DC" }}>
                          
                            Project1.pdf
                        </Typography>

                    </div>
                </Card>
                </Link> */}
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}


            </Card>


        )
    }
}
