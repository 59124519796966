import React, { Component } from "react";
import { connect } from "react-redux";
import AssignmentDetails from "../Components/assignmentDetails";
import { close_snack_bar } from "../../../common/snackbar/action";

import {  
    viewAllLession, 
    update_score
    // search_course
} from "../action"

import {view_submit_by_assignment} from '../../Assignment/action'
export class Controller extends Component {
    render() {
        return (
            <AssignmentDetails {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        courses: store.courses,
        login: store.login,
        assignment:store.assignment,
        

    };
};
export const mapDispatchToProps = dispatch => {
    return {
        //notes
       
        viewAllLession: () => {
            dispatch(viewAllLession())
        },
 
        view_submit_by_assignment: (assignment_id) => {
            dispatch(view_submit_by_assignment(assignment_id))
        },

        update_score: ( submit_id,student_id,assigement_id,status,score) => {
            dispatch(update_score(submit_id,student_id,assigement_id,status,score))
        },
        
       

        

      
      






    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);