import {
    TICKETS,
    SEARCH_TICKETS,
    TICKET_BY_ID
} from "./constant";

const initial_state = {
    all_tickets: [],
    search_ticket: [],
    ticket_length: 0,
    ticket_by_id: []
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case TICKETS:
            return {
                ...state,
                all_tickets: action.payload.data, search_ticket: action.payload.data, ticket_length: action.payload.count
            };
        case SEARCH_TICKETS:
            return {
                ...state,
                search_ticket: action.payload
            };
        case TICKET_BY_ID:
            return {
                ...state,
                ticket_by_id: action.payload.data, ticket_length: action.payload.count
            };
        default:
            return state;
    }
}
