import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import store from "./store";
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from "react-redux";

// import * as serviceWorker from './serviceWorker';
import store from './store';
// const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//   <React.StrictMode>
    <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
        <App/>
        </PersistGate>
    </Provider>
//   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.register();
