import React, { Component } from "react";
import { connect } from "react-redux";
import Placementstudent from "../Component/Placementstudent";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    // viewPlacementByStudent,
    searchPlacement,
    viewAppliedByStudent,
    searchApplied,
    setPlacementCompanyName,
    setPlacementCompanyEmail,
    setPlacementApplyLink,
    setPlacementLastDate,
    setPlacementJobProfile,
    setPlacementJobDesc,
    setPlacementId,
    viewAllCompany,
    searchCompany,
    setCompanyId
    // FilterApplied
} from "../action"

// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <Placementstudent {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        placement: store.placement,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {
       
        // viewPlacementByStudent: (student_id,page_number, rowper_page) => {
        //     dispatch(viewPlacementByStudent(student_id,page_number, rowper_page));
        // },
        searchPlacement: (all_placements_by_student, e) => {
            dispatch(searchPlacement(all_placements_by_student, e));
        },

        viewAppliedByStudent: (student_id,page_number, rowper_page) => {
            dispatch(viewAppliedByStudent(student_id,page_number, rowper_page));
        },

        searchApplied: (applied_by_student, e, type) => {
            dispatch(searchApplied(applied_by_student, e, type));
        },

        setPlacementCompanyName: (payload) => {
            dispatch(setPlacementCompanyName(payload));
        },

        setPlacementCompanyEmail: (payload) => {
            dispatch(setPlacementCompanyEmail(payload));
        },

        setPlacementApplyLink: (payload) => {
            dispatch(setPlacementApplyLink(payload));
        },

        setPlacementLastDate: (payload) => {
            dispatch(setPlacementLastDate(payload));
        },

        setPlacementJobProfile: (payload) => {
            dispatch(setPlacementJobProfile(payload));
        },

        setPlacementJobDesc: (payload) => {
            dispatch(setPlacementJobDesc(payload));
        },

        setPlacementId: (payload) => {
            dispatch(setPlacementId(payload));
        },
        viewAllCompany: (page_number, rowper_page) => {
            dispatch(viewAllCompany(page_number, rowper_page));
        },

        searchCompany: (all_companys, e) => {
            dispatch(searchCompany(all_companys, e));
        },

        setCompanyId: (payload) => {
            dispatch(setCompanyId(payload));
        },
       


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);