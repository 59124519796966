import {
    VIEW_SUBMIT_ASSIGN, ASSIGNMENT_DETAIL, STUDENT_ASSIGNMENTS, ASSIGNMENT_ID_DETAIL
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import 'firebase/storage';
import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


export function view_submit_by_assignment(assignment_id) {
    console.log("assignmentidddd",assignment_id)
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_submit_by_assignment", {
            method: "POST",  // Corrected to GET
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                assignment_id: assignment_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("assignres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_SUBMIT_ASSIGN, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_SUBMIT_ASSIGN, payload: [] });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function submit(stud_id, assign_id, attachment) {
    console.log(stud_id, assign_id, attachment)
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "submit", {
            method: "POST",  // Corrected to GET
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                student_id: stud_id
            },
            body: JSON.stringify({
                assigement_id: assign_id,
                attachment: attachment,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson.status) {
                    dispatch(view_submit_by_assignment(assign_id));
                    dispatch(unsetLoader());
                } else {
                    dispatch(view_submit_by_assignment(assign_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function uploadImageCourse(stud_id, assign_id, attachment) {
    return dispatch => {
        console.log("courseadd", stud_id, assign_id, attachment)
        dispatch(setLoader(true));
        if (attachment !== "") {
            const storageRef = ref(getStorage(), "/Assignment/" + attachment + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, attachment);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (attachment) {
                        console.log(attachment)
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(submit(stud_id, assign_id, attachment));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(submit(stud_id, assign_id, attachment));
        }
    }
}

//-------------------------view assignment-----------//

export function addAssignment(trainer_id, course_id, batch_id, title, description, due_date, attachment, page_number, rowper_page, status) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_assignment", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content_Type": "application/json",
                trainer_id: trainer_id,

            },
            body: JSON.stringify({
                course_id: course_id,
                batch_id: batch_id,
                title: title,
                description: description,
                due_date: due_date,
                attachment: attachment,
                status: status,
                rowper_page,
                page_number
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    //dispatch(viewAllDiscussion());
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };

};



//---------------------------update assignment------------------------//

export function updateAssignment(assignment_id, title, description, due_date, attachment, student_id, trainer_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_assignment", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content_Type": "application/json",


            },
            body: JSON.stringify({
                assignment_id,
                title,
                description,
                due_date,
                attachment,
                student_id,
                trainer_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    //dispatch(viewAllDiscussion());
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };

}

//-------------------------delete assignment----------------//
export function deleteAssignment(assignment_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_assignment", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content_Type": "application/json",


            },
            body: JSON.stringify({
                assignment_id: assignment_id,
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    //dispatch(viewAllDiscussion());
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };

}
//------------------------view assignment by id ------------------------------//
export function viewAssignmentByBatch(batch_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_assigement_by_batch", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                batch_id: batch_id
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: ASSIGNMENT_DETAIL, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: ASSIGNMENT_DETAIL, payload: [] })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

//--------------------------------VIEW ASSIGNMENT BY ID-----------------//
export function viewAssignmentById(assigement_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_assigement_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                assigement_id: assigement_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: ASSIGNMENT_ID_DETAIL, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: ASSIGNMENT_ID_DETAIL, payload: [] })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

//--------------------------view assignment by  student-----------------------------------------------//
export function viewAssignmentByStudent(student_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_assigement_by_batch", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",


            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                student_id: student_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: STUDENT_ASSIGNMENTS, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: STUDENT_ASSIGNMENTS, payload: [] })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    }
}