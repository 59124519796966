import React, { Component } from "react";
import Box from "@mui/material/Box";
import "../styles/dashboard.css";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import TimelineIcon from "@mui/icons-material/Timeline";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VideocamIcon from "@mui/icons-material/Videocam";
import { Card, MenuItem, Grid, Select, Menu, Divider, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Paper from "@mui/material/Paper";
import "../../Trainer/styles/trainer.css";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import TextField from "@mui/material/TextField";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import InputBase from "@mui/material/InputBase";
import MenuList from "@mui/material/MenuList";
import Dialog from "@mui/material/Dialog";
import SearchIcon from "@mui/icons-material/Search";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Textarea from "@mui/joy/Textarea";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CombinedShape from "../../Images/CombinedShape.png";
import keydown from "../../Images/keydown.png";
import { styled } from "@mui/material/styles";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from '@mui/material/Paper';
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Calendar from "../../Images/CalendarWeekFill.png"
import Person from "../../Images/PersonFill.png"
import Clock from "../../Images/ClockFill.png"
import CamVideo from "../../Images/CameraVideoFill.png"
import MoreVertIcon from "../../Images/Menu Vertical.png"
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';


// import LoaderCon from "../../../common/loader/containers/loader_cont";

const options = ["Reschedule", "Delete"];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "80%",
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#4C6FFF" : "#4C6FFF",
  },
}));
const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  width: "80%",
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#ffe925" : "#ffe925",
  },
}));
export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {

      addnotice: false,
      editnotice: false,
      deletenotice: false,
      noticeviewmodaladmin: false,

      addschedule: false,
      deleteschedule: false,


      menu: false,
      anchorEl: null,

      open: false,
      selectedIndex: 1,

      addNoticeschedule: false,
      editNoticeschedule: false,
      deleteNoticeschedule: false,
      desc: "",
      schedule: '',
      // menunoticeboardmoreicon: false,
      notice_id: "",
      course_name: "",
      batch_number: "",
      desc: "",
      course_id: "N",
      batch_id: "N",
      course_filter: "N",
      lession_name: "",
      subject_name: "",
      time: "",
      duration: "",
      lession_id: ''


    };
    this.anchorRef = React.createRef();
  }

  handleDateChange = (e) => {
    const rawDate = e.target.value; // The raw date string from the input
    const formattedDate = this.formatDate(rawDate); // Format the date
    console.log("date", formattedDate)
    this.props.filterSchedulte(this.props.dashboard.schedule_classes, formattedDate);
  }

  formatDate(rawDate) {
    // Assuming rawDate is in the format 'yyyy-MM-dd'
    const parts = rawDate.split('-');
    if (parts.length === 3) {
      const [year, month, day] = parts;
      return `${year}-${month}-${day}`;
    }
    return rawDate; // Return the original date if it's not in the expected format
  }

  handleClicknotice = (event) => {
    this.setState({
      anchorElnotice: event.currentTarget,
      menunotice: true,
    });
  };

  handleClosenotice = () => {
    this.setState({
      anchorElnotice: null,
      menunotice: false,
    });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  handleClose = (event) => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"
    this.props.viewAllNotice();
    this.props.view_all_course(this.props.login.org_id)
    // this.props.view_all_courses(0, 6);
    this.props.view_all_batch();
    this.props.viewCourseInProcess(this.props.login.org_id);
    this.props.viewAllStudent(this.state.page, this.state.rowsPerPage,this.props.login.org_id)
    this.props.viewAllScheduleClasses();

  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { selectedIndex } = this.state;
    const { dashboard, student, courses, snackbar, close_snack_bar } = this.props;

    return (
      <Grid>
        <Grid style={{ marginTop: "8%" }}>
          <div className="task-nav">
            <Box className="completed">
              <div className="tasknavTop">
                <div id="tick-icon" className="icon">
                  <LibraryBooksIcon />
                </div>
                <div className="task-nav-line-space">
                  <h2>{dashboard.in_progress_length}/{courses.course_length}</h2>
                  <h6 style={{ color: "var(--Paragraph, #767278)" }}>Courses Completed</h6>
                </div>
              </div>
              <div className="bottomTaskNav">
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "18px",
                    justifyContent: "space-between",
                    paddingInlineStart: "0px",
                    marginBlock: "8px",
                    marginInline: "7px",
                  }}
                >
                  <BorderLinearProgress className="brdr1"
                    variant="determinate"
                    value={(dashboard.in_progress_length) / (courses.course_length) * 100}
                    style={{
                      width: "150px",
                      marginLeft: "13%",
                      color: "#4C6FFF",
                      marginTop: "5%",
                    }}
                  />
                  {parseInt((dashboard.in_progress_length) / (courses.course_length) * 100)}%
                </Box>
              </div>
            </Box>
            <Box className="completed">
              <div className="tasknavtopaccessSection">
                <div id="access-icon" className="icon">
                  <TimelineIcon />
                </div>
                <div className="task-nav-line-space">
                  <h2>{(courses.course_length) - (dashboard.in_progress_length)}/{courses.course_length}</h2>
                  <h6 style={{ color: "var(--Paragraph, #767278)" }}>Courses in progress</h6>
                </div>
              </div>
              <div className="bottomTaskNav">
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "18px",
                    justifyContent: "space-between",
                    paddingInlineStart: "0px",
                    marginBlock: "12px",
                    marginInline: "7px",
                  }}
                >
                  <BorderLinearProgress2 className="brdr2"
                    variant="determinate"
                    value={((courses.course_length) - (dashboard.in_progress_length)) / (courses.course_length) * 100}
                    style={{
                      width: "150px",
                      marginLeft: "13%",
                      color: "FBBC28",
                      marginTop: "2%",
                    }}
                  />
                  {parseInt(((courses.course_length) - (dashboard.in_progress_length)) / (courses.course_length) * 100)}%
                </Box>
              </div>
            </Box>
            <Box className="completed">
              <div className="tasknavtopstudentsection">
                <div id="hours-icon" className="icon">
                  {/* <HourglassEmptyOutlinedIcon /> */}
                </div>
                <div className="task-nav-line-space">
                  <h2>{student.student_length}</h2>
                  <h6 style={{ color: "var(--Paragraph, #767278)" }}>Students</h6>
                </div>
              </div>
            </Box>
          </div>

          <Card className="my-projects">
            <div className="my-projects-bar">
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  marginTop: "2%",
                  marginLeft: "2%",
                }}
              >
                Schedules
              </Typography>

              <TextField
                type="date"
                name="begin"
                min="1997-01-01"
                max="2030-12-31"
                style={{
                  border: "1px solid #D3D2D2",
                  borderRadius: "0.3125rem",
                  width: "150px",
                  marginLeft: "auto",
                  marginTop: "2%",
                }}
                onChange={this.handleDateChange}
              />
            </div>
            <div style={{ overflow: "auto", height: 687 }}>
              {dashboard.search_schedule.length == "0" &&
                <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                  <Typography>No Result Found</Typography>
                </Grid>
              }
              {Array.isArray(dashboard.search_schedule) && dashboard.search_schedule.sort((a, b) =>
                (moment(a.time + " " + a.date).format) > (moment(b.time + " " + b.date).format) ? -1 : 1).map((item, index) => {
                  // {Array.isArray(dashboard.schedule_classes) && dashboard.schedule_classes.map((item, index) => {
                  return (
                    <Card
                      style={{
                        margin: "2.5%",
                        width: "95%",
                      }}
                    >
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "2%",
                            marginTop: "2%",
                            justifyContent: "space-between",
                            letterSpacing: 1,
                            fontWeight: 500
                          }}
                        >
                          {" "}
                          {item.course_details.course_name}

                        </Typography>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          // onClick={this.handleClick}
                          onClick={(e) => {
                            this.handleClick(e);
                            this.setState({
                              class_id: item._id,
                              course_name: item.course_details.course_name,
                              batch_number: item.batch_details.batch_number,
                              subject_name: item.lession_details.subject_name,
                              lession_name: item.lession_details.session_name,
                              date: item.date,
                              trainer_name: item.trainer_details.name,
                              time: item.time,
                              end_time: item.end_time,
                              duration: item.duration,
                              lession_id: item.lession_id

                            })
                            console.log("time", item)
                          }}
                        >
                          <img src={MoreVertIcon} />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={this.handleClose}
                          PaperProps={{
                            style: {
                              width: "20ch",
                              border: "none"
                            },
                          }}
                        >
                          <MenuItem

                            style={{ color: "var(--secondary, #0000B3)", fontWeight: "500", fontSize: "15px" }}
                            onClick={() => {
                              this.setState({
                                anchorEl: null,
                                addschedule: true,


                              });
                              console.log("hehe", item._id)
                            }}
                          >
                            Reschedule
                          </MenuItem>
                          <MenuItem

                            style={{ color: "var(--secondary, #0000B3)", fontWeight: "500", fontSize: "15px" }}
                            onClick={() => {
                              this.setState({
                                anchorEl: null,
                                deleteschedule: true,
                                // class_id:item._id
                              });
                            }}
                          >
                            Delete

                          </MenuItem>
                        </Menu>
                      </div>

                      <Typography style={{ marginLeft: "2%" }}>
                        <span style={{ fontWeight: 500 }}>{item.lession_details.subject_name !== undefined && item.lession_details.subject_name}</span> {item.lession_details.session_name}
                      </Typography>

                      <CardActions
                        disableSpacing
                        style={{ justifyContent: "start", fontSize: "10px", gap: 5 }}
                      >
                        <div style={{ display: "flex" }}>
                          <IconButton
                            className="date-sec"
                            aria-label="add to favorites">
                            <img src={Calendar} style={{ fontSize: "15px" }} className="date-sec" />

                          </IconButton>
                          <Typography className="date-sec" style={{ fontSize: "14px", marginTop: "5px", color: "gray" }}>{moment(item.date).format('dddd, D MMM , YYYY')}</Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <IconButton

                            style={{ marginLeft: "1%" }}
                            aria-label="share"
                          >
                            <img src={Person} style={{ fontSize: "15px" }} className="date-sec" />


                          </IconButton>
                          <Typography className="date-sec" style={{ fontSize: "14px", marginTop: "5px", color: "gray" }}>{item.trainer_details == "No Records" || item.trainer_details == undefined ? "***" : item.trainer_details.name}</Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <IconButton

                            style={{ marginLeft: "1%" }}
                            aria-label="share"
                          >
                            <img src={Clock} style={{ fontSize: "15px" }} className="date-sec" />


                          </IconButton>
                          <Typography className="date-sec" style={{ fontSize: "14px", marginTop: "5px", color: "gray", width: "130px" }}>{item.time}-{item.end_time}</Typography>
                        </div>
                        {/* <div style={{ display: "flex" }}>
                          <IconButton

                            style={{ marginLeft: "1%" }}
                            aria-label="share"
                          >
                            <img src={CamVideo} style={{ fontSize: "15px" }} className="date-sec" />

                          </IconButton>
                          <Typography className="date-sec" style={{ fontSize: "14px", marginTop: "5px", color: "gray" }}>Zoom</Typography>
                        </div> */}
                      </CardActions>
                    </Card>
                  );
                })}
            </div>
          </Card>


          {/* Right Part */}
          <Grid>

            {/* Notice Board Part */}
            <Card className="adminDashboardnotifications" style={{ height: "400px" }}>
              <div className="notification-bar">
                <div className="side-notification-text">Notice Board</div>

                <div className="flex-line">

                  <Paper
                    component="form"
                    style={{
                      borderRadius: "8px",
                      border: "0.5px solid #D2D2D2",
                      display: "flex",
                      marginLeft: "5px",
                      width: "35%",
                      height: "30px",
                    }}
                  >
                    <IconButton
                      type="button"
                      style={{ p: "100px" }}
                      aria-label="search"
                    >
                      <SearchIcon style={{ fontSize: "15px" }} />
                    </IconButton>
                    <InputBase
                      style={{ ml: 5, flex: 1, fontSize: "15px" }}
                      // placeholder="Search here"
                      inputProps={{ "aria-label": "Search here" }}
                      onChange={(e) => this.props.searchNotice(dashboard.notices, e.target.value)}
                    />
                  </Paper>

                  <div style={{ display: "flex", flexDirection: "row", gap: "1%", justifyContent: "right", marginLeft: "10px" }}>
                    <div >
                      <Button
                        className="cbtn"
                        onClick={() => {
                          this.setState({
                            addnotice: true,
                          });
                        }}
                        style={{
                          fontSize: "14px",
                          // padding: "6.759px 8.787px",
                          height: "30px",
                          color: "#0000B3",
                          border: "1px solid #0000B3",
                        }}
                        variant="outlined"
                      >
                        Create
                      </Button>
                    </div>
                    <div >
                      <Button
                        onClick={() => {
                          this.setState({
                            addNoticeschedule: true,
                          });
                        }}
                        style={{
                          fontSize: "14px",
                          // padding: "6.759px 8.787px",
                          height: "30px",
                          color: "#0000B3",
                          border: "1px solid #0000B3",
                          marginRight: "15%"
                        }}
                        variant="outlined"
                      >
                        Schedule
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ overflowY: "scroll", height: "305px" }}>
                {dashboard.search_notice.length == "0" &&
                  <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                    <Typography>No Result Found</Typography>
                  </Grid>
                }
                {Array.isArray(dashboard.search_notice) && dashboard.search_notice.map((item) => {
                  return (
                    <div className="noticeBoardContent">
                      <Typography className="noticeBoardContentHeader">{item.course_details !== undefined && item.course_details.course_name}<br /> <Typography style={{ fontWeight: 400 }} >{item.batch_details.batch_number}</Typography></Typography>
                      <div className="noticeBoardContentBottom">
                        <Typography style={{ marginLeft: "10px", fontSize: 14, fontWeight: 400, color: "var(--secondary, #0000B3)" }}>
                          {item.schedule}
                        </Typography>
                        <Button
                          onClick={() => {
                            this.setState({
                              noticeviewmodaladmin: true,
                              notice_id: item._id,
                              course_name: item.course_details.course_name,
                              batch_number: item.batch_details.batch_number,
                              desc: item.desc,
                              schedule: item.schedule

                            });
                          }}
                          style={{
                            color: "#0000B3",
                            fontSize: 14,
                            marginTop: "-10px"
                          }}
                        >
                          View
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Card>

            {/* course progress page */}

            <Card className="courseProgress" style={{ height: "478px" }}>
              <div className="top">
                <div className="side-notification-text">Course Progress</div>
                <Button
                  style={{
                    marginTop: "12px",
                    width: "115px",
                    height: "3.5vh",
                    justifyContent: "space-around",
                    boxShadow:
                      "0px 1.8417266607284546px 1.8417266607284546px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <img src={CombinedShape} alt="" srcset="" />

                  <select className="filter"
                    value={this.state.course_filter}
                    onChange={(e) => {
                      this.setState({ course_filter: e.target.value });
                      this.props.filter_course(this.props.courses.course, e.target.value)
                    }}
                  >
                    <option value="N">Filter</option>
                    <option value="Completed">Completed</option>
                    <option value="On-going">On-going</option>
                    <option value="Upcoming">Upcoming</option>
                  </select>
                </Button>
              </div>
              <div style={{ overflow: "scroll", height: 427 }}>
                {courses.search_course.length == "0" &&
                  <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                    <Typography>No Result Found</Typography>
                  </Grid>
                }
                {Array.isArray(courses.search_course) && courses.search_course.map((item, index) => {
                  return (
                    <div className="courseProgressBottom">
                      <ul>
                        <li style={{ display: "flex", justifyContent: "space-between" }}>
                          <span>{item.course_name}</span>
                          <span style={{
                            color: item.status === "Upcomig" ? 'blue' : item.status === 'On-going' ? 'green' : 'red'
                          }}>
                            {item.status}
                          </span>
                        </li>
                        <li>{item.course_code}</li>
                      </ul>
                      <ul>
                        <Box
                          sx={{ flexGrow: 1 }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBlock: "8px",
                          }}
                        >
                          <BorderLinearProgress
                            variant="determinate"
                            value={70}
                          />
                          70%
                        </Box>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </Card>

          </Grid>
        </Grid>

        {/*------------------------------------------------------ Notice -------------------------------------------------------*/}

        {/*Add*/}
        <Dialog open={this.state.addnotice} maxWidth="sm" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle>
            <Grid container marginTop={1}>
              <Grid item xs={.5}>

              </Grid>
              <Grid item>
                <h4> Notice</h4>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container >
              <Grid item xs={2}>

              </Grid>
              <Grid item xs={8} direction='column'>
                <Typography style={{ fontWeight: 600, }}>
                  Select Course
                </Typography>
                <TextField
                  fullWidth
                  select
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    borderRadius: "5px",
                    backgroundColor: "rgba(239, 239, 239, 0.60)"
                  }}
                  value={this.state.course_id}
                  onChange={(e) => {
                    this.setState({ course_id: e.target.value });
                    this.props.view_all_batch_by_course(e.target.value)
                  }}
                >
                  <MenuItem value={"N"}>{"Select Course"}</MenuItem>
                  {Array.isArray(this.props.courses.course) &&
                    this.props.courses.course.map((e) => (
                      <MenuItem value={e._id} >{e.course_name}</MenuItem>
                    ))}
                </TextField>

                <Typography style={{ fontWeight: 600, marginTop: 15 }}>
                  Select Batch
                </Typography>

                <TextField
                  placeholder="Enter Name"
                  fullWidth
                  sx={{
                    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#EBEBEB' },
                    '.MuiOutlinedInput-notchedOutline': { border: 0 }
                  }}
                  select
                  value={this.state.batch_id}
                  onChange={(e) => {
                    this.setState({ batch_id: e.target.value });

                  }}
                >
                  <MenuItem value={"N"}>{"Select Batch"}</MenuItem>
                  {Array.isArray(this.props.courses.batch) && this.props.courses.batch.map((e) => (
                    <MenuItem value={e._id}>{e.batch_number}</MenuItem>
                  ))}
                </TextField>
                <Textarea

                  size="lg"
                  sx={{
                    height: "150px",
                    marginTop: 3,
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    backgroundColor: "#EBEBEB",
                  }}
                  name="Outlined"
                  placeholder="Enter Text"
                  onChange={(e) => {
                    this.setState({ desc: e.target.value });

                  }}
                />
                <TextField
                  fullWidth
                  type="date"
                  placeholder="Reschedule Time"
                  sx={{
                    backgroundColor: "#efefef99",
                    borderRadius: "5px",
                    border: "none",
                    marginTop: "20px",
                    '.MuiOutlinedInput-notchedOutline': { border: 0 }

                  }}
                  onChange={(e) => {
                    this.setState({ schedule: e.target.value });

                  }}
                >
                </TextField>
              </Grid>
              <Grid item xs={2}>

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%' }} >
            <Button
              variant="outlined"
              style={{
                fontSize: "15px",
                height: "38px",
                width: "120px",
                color: "#0000B3",
                border: "1px solid #0000B3",
              }}
              onClick={() => {
                this.setState({ addnotice: false });
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.course_id === "N" || this.state.batch_id === "N"}
              variant="contained"
              style={{
                backgroundColor: "#0000B3",
                fontSize: "15px",
                height: "38px",
                width: "120px",
                color: "white",
                marginLeft: "2%",

              }}


              onClick={() => {
                this.setState({
                  addnotice: false,
                  course_id: "N",
                  batch_id: "N",
                  desc: "",
                  schedule: "",
                });
                this.props.addNotice(this.props.login.user_id, this.state.course_id, this.state.batch_id, this.state.desc, this.state.schedule)
              }}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog >

        {/*View*/}
        <Dialog open={this.state.noticeviewmodaladmin} maxWidth="sm" fullWidth>
          <Grid style={{ padding: "10px" }}>
            <DialogTitle>
              <Grid container>
                <Grid item xs={11} >
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => {
                    this.setState({ noticeviewmodaladmin: false });
                  }}>
                    <CloseOutlinedIcon style={{ color: "black", display: "flex", justifyContent: "right" }} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} style={{ marginTop: "-25px" }}>
                  <Typography
                    style={{
                      fontSize: "32px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: 500,
                    }}
                  >
                    Notice
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ flexDirection: "column", padding: "10px 50px 75px 50px" }}>
              <Grid container direction={"column"}>
                <Typography style={{ fontSize: "18px" }}>
                  <strong>{this.state.course_name}-({this.state.batch_number})</strong>
                </Typography>
                <Typography style={{ fontSize: "16px", color: "var(--paragraph, #767278)" }}>
                  {this.state.desc}
                </Typography>
              </Grid>
            </DialogContent>
          </Grid>
        </Dialog>

        {/*View Schedule Notice*/}
        <Dialog
          open={this.state.addNoticeschedule}
          maxWidth="sm"
          fullWidth
          sx={{


            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Create Date</TableCell>
                    <TableCell>Notice</TableCell>
                    <TableCell>Post Date</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {Array.isArray(dashboard.search_notice) && dashboard.search_notice.map((item) => {
                    return (
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {item.date}
                        </TableCell>
                        <TableCell>{item.desc}</TableCell>
                        <TableCell>{item.schedule}</TableCell>
                        {/* <TableCell align="right">jogn kooper</TableCell> */}
                        <TableCell align="right">
                          <EditIcon
                            onClick={() => {
                              this.setState({
                                editNoticeschedule: true,
                                notice_id: item._id,
                                course_name: item.course_details.course_name,
                                batch_number: item.batch_details.batch_number,
                                desc: item.desc,
                                schedule: item.schedule
                              });
                              // console.log("sdfghj", batch_number)
                            }}
                            style={{
                              color: "#3B3BC4",
                            }}
                          />
                          <DeleteOutlineOutlinedIcon
                            style={{
                              color: "#C43232",
                            }}
                            onClick={() => {
                              this.setState({
                                deleteNoticeschedule: true,
                                notice_id: item._id,
                              });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <center>
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({ addNoticeschedule: false });
                }}
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "#0000B3",
                  border: "1px solid #0000B3",
                }}
              >
                Exit
              </Button>
            </center>

          </Grid>
        </Dialog>

        {/*Edit Schedule Notice*/}
        <Dialog open={this.state.editNoticeschedule} maxWidth="sm" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle>
            <Grid container marginTop={1}>
              <Grid item xs={.5}>

              </Grid>
              <Grid item>
                <h4>Edit Notice</h4>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container >
              <Grid item xs={2}>

              </Grid>
              <Grid item xs={8} direction='column'>
                <Typography style={{ fontWeight: 600, }}>
                  Select Course
                </Typography>
                <Select
                  placeholder="Enter Name"
                  fullWidth
                  sx={{
                    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#EBEBEB' },
                    '.MuiOutlinedInput-notchedOutline': { border: 0 }
                  }}
                  value={this.state.course_id}
                  onChange={(e) => {
                    this.setState({ course_id: e.target.value });
                    this.props.view_all_batch_by_course(e.target.value)
                  }}
                >
                  <MenuItem value={"N"}>{"Select Course"}</MenuItem>
                  {Array.isArray(this.props.courses.course) &&
                    this.props.courses.course.map((e) => (
                      <MenuItem value={e._id} >{e.course_name}</MenuItem>
                    ))}
                </Select>

                <Typography style={{ fontWeight: 600, marginTop: 15 }}>
                  Select Batch
                </Typography>

                <Select
                  placeholder="Enter Name"
                  fullWidth
                  sx={{
                    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#EBEBEB' },
                    '.MuiOutlinedInput-notchedOutline': { border: 0 }
                  }}
                  value={this.state.batch_id}
                  onChange={(e) => {
                    this.setState({ batch_id: e.target.value });

                  }}
                >
                  <MenuItem value={"N"}>{"Select Batch"}</MenuItem>
                  {Array.isArray(this.props.courses.batch) && this.props.courses.batch.map((e) => (
                    <MenuItem value={e._id}>{e.batch_number}</MenuItem>
                  ))}
                </Select>
                <Textarea

                  size="lg"
                  sx={{
                    height: "150px",
                    marginTop: 3,
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    backgroundColor: "#EBEBEB",
                  }}
                  name="Outlined"
                  placeholder="Enter Text"
                  value={this.state.desc}
                  onChange={(e) => {
                    this.setState({ desc: e.target.value });

                  }}
                />
                <TextField
                  fullWidth
                  type="date"
                  placeholder="Reschedule Time"
                  sx={{
                    backgroundColor: "#efefef99",
                    borderRadius: "5px",
                    border: "none",
                    marginTop: "20px",
                    '.MuiOutlinedInput-notchedOutline': { border: 0 }

                  }}
                  value={this.state.schedule}
                  onChange={(e) => {
                    this.setState({ schedule: e.target.value });

                  }}
                >
                </TextField>
              </Grid>
              <Grid item xs={2}>

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%' }} >
            <Button
              variant="outlined"
              style={{
                fontSize: "15px",
                height: "38px",
                width: "120px",
                color: "#0000B3",
                border: "1px solid #0000B3",
              }}
              onClick={() => {
                this.setState({ editNoticeschedule: false });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#0000B3",
                fontSize: "15px",
                height: "38px",
                width: "120px",
                color: "white",
                marginLeft: "2%",

              }}


              onClick={() => {
                this.setState({
                  editNoticeschedule: false
                });
                this.props.updateNotice(this.state.notice_id, this.state.course_id, this.state.batch_id, this.state.desc, this.state.schedule)
              }}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog >

        {/*Delete Schedule Notice*/}
        <Dialog open={this.state.deleteNoticeschedule} maxWidth="sm" fullWidth
          sx={{


            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={12} >
                  <Typography style={{ fontSize: "22px", fontWeight: "600", color: "#222222" }}>
                    Delete
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ flexDirection: "row", textAlign: "center" }}>
              <Grid container direction={"column"} spacing={1}>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "18px", fontWeight: 600, fontFamily: "" }}>Are you sure you want to delete ?</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>This process will lead to loss of entire data, proceed at yours
                    own risk</Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center', margin: '5% 0% 5% 0%' }}>
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({ deleteNoticeschedule: false });
                }}
                style={{
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "#0000B3",
                  border: "1px solid #0000B3",
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({ deleteNoticeschedule: false });
                  this.props.deleteNotice(this.state.notice_id)
                }}
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "white",
                  marginLeft: "2%",
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>


        {/*------------------------------------------------------ Schedule -------------------------------------------------------*/}

        {/*Add*/}
        <Dialog open={this.state.addschedule} maxWidth="sm" fullWidth
          sx={{


            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid >
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={11} >
                  <Typography style={{ fontSize: "22px", fontWeight: "600", color: "#222222" }}>
                    Reschedule
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => {
                    this.setState({ addschedule: false });
                  }}>
                    <CloseOutlinedIcon style={{ color: "black", display: "flex", justifyContent: "right" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent style={{ flexDirection: "row" }}>
              <Grid container direction={"column"}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                  <div style={{ margin: "10px" }}>
                    <Typography>
                      {this.state.course_name} ({this.state.batch_number})
                    </Typography>

                    <Typography>{this.state.subject_name} {this.state.lession_name}</Typography>
                  </div>

                  <div
                    style={{ justifyContent: "space-around", fontSize: "10px", display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ display: "flex" }}>
                      <IconButton
                        className="date-sec"
                        aria-label="add to favorites">
                        <img src={Calendar} style={{ fontSize: "15px" }} className="date-sec" />

                      </IconButton>
                      <Typography className="date-sec" style={{ fontSize: "14px", marginTop: "5px", color: "gray" }}>{moment(this.state.date).format('dddd, D MMM , YYYY')}</Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      <IconButton
                        aria-label="share"
                      >
                        <img src={Person} style={{ fontSize: "15px" }} className="date-sec" />


                      </IconButton>
                      <Typography className="date-sec" style={{ fontSize: "14px", marginTop: "5px", color: "gray" }}>{this.state.trainer_name}</Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      <IconButton
                        aria-label="share"
                      >
                        <img src={Clock} style={{ fontSize: "15px" }} className="date-sec" />


                      </IconButton>
                      <Typography className="date-sec" style={{ fontSize: "14px", marginTop: "5px", color: "gray", width: "130px" }}>{this.state.time}-{this.state.end_time}</Typography>
                    </div>
                    {/* <div style={{ display: "flex" }}>
                      <IconButton
                        aria-label="share"
                      >
                        <img src={CamVideo} style={{ fontSize: "15px" }} className="date-sec" />

                      </IconButton>
                      <Typography className="date-sec" style={{ fontSize: "14px", marginTop: "5px", color: "gray" }}>Zoom</Typography>
                    </div> */}
                  </div>
                  <div style={{ justifyContent: "center", display: "flex", marginTop: "20px" }}>
                    <TextField
                      type="date"
                      placeholder="Reschedule Time"
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        width: "404px",
                        backgroundColor: "#efefef99",
                        borderRadius: "5px",
                        border: "none"
                      }}
                      value={this.state.date}
                      onChange={(e) => {
                        this.setState({ date: e.target.value });
                      }}
                    >
                    </TextField>
                  </div>
                  <div style={{ justifyContent: "center", display: "flex", marginTop: "20px" }}>
                    <TextField
                      type="time"
                      placeholder="Reschedule Time"
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        width: "404px",
                        backgroundColor: "#efefef99",
                        borderRadius: "5px",
                        border: "none"
                      }}
                      value={this.state.time}
                      onChange={(e) => {
                        this.setState({ time: e.target.value });
                      }}
                    >
                    </TextField>
                  </div>
                  <div style={{ justifyContent: "center", display: "flex", marginTop: "20px" }}>
                    <TextField
                      placeholder="Duration"
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        width: "404px",
                        backgroundColor: "#efefef99",
                        borderRadius: "5px",
                        border: "none"
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">mins</InputAdornment>,
                      }}
                      value={this.state.duration}
                      onChange={(e) => {
                        this.setState({ duration: e.target.value });
                      }}
                    >
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </DialogContent>

            <DialogActions style={{ justifyContent: "center", display: "flex" }}>
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({ addschedule: false });
                  this.props.reschedule(this.state.class_id, this.state.date, this.state.time, this.state.lession_id, this.state.duration)
                  console.log("com", this.state.class_id, this.state.date, this.state.time, this.state.lession_id, this.state.duration)
                }}
                style={{
                  fontSize: "15px",
                  color: "white",
                  borderRadius: "5px",
                  background: "var(--secondary, #0000B3)",
                  width: "102px",
                  padding: "7px 21px 7px 18px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "10px",
                  marginBottom: "20px"
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>

        {/*Delete*/}
        <Dialog open={this.state.deleteschedule} maxWidth="sm" fullWidth
          sx={{


            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={12} >
                  <Typography style={{ fontSize: "22px", fontWeight: "600", color: "#222222" }}>
                    Delete
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ flexDirection: "row", textAlign: "center" }}>
              <Grid container direction={"column"} spacing={1}>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "18px", fontWeight: 600, fontFamily: "" }}>Are you sure you want to delete ?</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>This process will lead to loss of entire data, proceed at yours
                    own risk</Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center', margin: '5% 0% 5% 0%' }}>
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({ deleteschedule: false });
                }}
                style={{
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "#0000B3",
                  border: "1px solid #0000B3",
                }}
              >
                No
              </Button>
              <Button
                variant="contained"


                onClick={() => {
                  this.setState({
                    deleteschedule: false
                  });
                  this.props.deleteSchedule(this.state.class_id)
                }}
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "white",
                  marginLeft: "2%",
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>
        {/* <LoaderCon /> */}

      </Grid>
    );
  }
}
