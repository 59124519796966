import React, { Component } from "react";
import { connect } from "react-redux";
import Jobprofileselect from "../Component/Jobprofileselect";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    // viewPlacementByStudent,
    addStudentApply,
    viewAppliedStatusByStudent
   
    // FilterApplied
} from "../action"

// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <Jobprofileselect {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        placement: store.placement,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {
       
        // viewPlacementByStudent: (student_id,page_number, rowper_page) => {
        //     dispatch(viewPlacementByStudent(student_id,page_number, rowper_page));
        // },

        addStudentApply: (student_id, company_id, placement_id) => {
            dispatch(addStudentApply(student_id, company_id, placement_id));
        },
        viewAppliedStatusByStudent: (student_id,placement_id) => {
            dispatch(viewAppliedStatusByStudent(student_id,placement_id));
        },

        // viewAppliedStatusByStudent: (student_id,placement_id) => {
        //     dispatch(viewAppliedStatusByStudent(student_id,placement_id));
        // },
       

        
       


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);