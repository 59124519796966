import React, { Component } from 'react';
import { Card, } from '@mui/material/';
import { Typography, Button } from '@material-ui/core';
import FilterListIcon from '@mui/icons-material/FilterList';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Link } from 'react-router-dom';
import "../styles/studentassignmentdetails.css"
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";

export default class StudentAssignments extends Component {
    render() {
        const { tickets, snackbar, close_snack_bar } = this.props;
        return (
            <Card style={{ marginTop: "8%", width: "97%", height: "70vh", }}>


                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h3 className="assign" style={{ marginLeft: "5%", fontWeight: 600, fontSize: "30px" }}>

                        Assignment
                    </h3>

                    {/* <Button variant="text" style={{ color: "#7A7E86", marginRight: "5%" }} startIcon={<FilterListIcon style={{ color: "#7A7E86" }} />}>Filters</Button> */}

                    <select style={{ fontSize: "15px", marginTop: "2%", height: "30px", marginRight: "5%", padding: "3px 9px", display: "flex", flexdirection: "row", }}>

                        <option style={{}} value="Select">Select Course</option>
                        <option value="All Projects">Course Name</option>
                        <option value="All Projects">Course Name</option>
                        <option value="All Projects">Course Name</option>
                        <option value="All Projects">Course Name</option>


                    </select>

                </div>

                <Link style={{ textDecoration: "none" }} to="/studentAssignmentdetails">
                    <Card style={{ marginTop: "1%", width: "94%", height: "25vh", marginLeft: "3%" }}>

                        <div style={{ display: "flex", flexDirection: "row", marginTop: "2%", marginLeft: "2%" }}>
                            <FiberManualRecordIcon style={{ color: "#24E405", fontSize: "17px" }} />
                            <Typography style={{ marginLeft: "1%", color: "#7A7E86", fontSize: "20px" }}>
                                Post Graduation Program On Full Stack Development With PHP Or Python

                            </Typography>

                        </div>


                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "3%", marginTop: "1%" }}>
                            <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>
                                Assignment:
                            </Typography>
                            <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>
                                Temperature Converter Website
                            </Typography>

                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "3%", marginTop: "1%" }}>
                            <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>
                                Assigned by:
                            </Typography>
                            <div >
                                <img style={{ height: "29px", width: "29px", borderRadius: "50%", marginLeft: "10px" }} src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"></img>
                            </div>
                            <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px" }}>

                                Ankit Das
                            </Typography>

                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "3%", marginTop: "2%" }}>
                            <CalendarMonthIcon style={{ color: "#0000B3", fontSize: "20px", marginLeft: "1%", }} />
                            <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px", color: "#0000B3" }}>
                                Due for 10 May 2023

                            </Typography>

                            <PictureAsPdfIcon style={{ color: "red", fontSize: "20px", marginLeft: "3%", }} />
                            <Typography style={{ marginLeft: "1%", fontWeight: 500, fontSize: "15px", color: "#3679DC" }}>

                                Project1.pdf
                            </Typography>

                        </div>
                    </Card>
                </Link>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}


            </Card>
        )
    }
}
