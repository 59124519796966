import React, { Component } from "react";
import { connect } from "react-redux";
import Batch from "../Components/trainercourseclasses";
import { close_snack_bar } from "../../../common/snackbar/action";

import {

  viewAllLession,
  add_lesson,
  update_lesson,
  delete_lesson,
  view_notes_by_batch_id,
  delete_notes,
  addSchedule,
  reschedule,
  view_subject_by_courses,
  viewAssignmentByBatch,
  view_lession_by_subject_id,
  view_shedule_by_lesson,
  uploadRecord,
  view_record_by_batch,
  view_all_feedback,
  setTrainerNameInAssignment,
  setTrainerProfileInAssignment,
  setTrainerTitleInAssignment,
  setTrainerDuedateInAssignment,
  editUploadRecord,
  delete_record,
  search_Assignment,
  view_all_shedule_subject_by_id,
  add_attendance,
  add_assignment,
  uploadAssignment,
  editUploadAssignment,
  update_assignment,
  delete_assignment,
  setAttachment,
  setAssignmentId,
  setSubjectId,
  setLessonId
  // search_course


} from "../action"


export class Controller extends Component {
  render() {
    return (
      <Batch {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    courses: store.courses,
    user: store.user,
    one_user: store.one_user,
    snackbar: store.snackbar,
    login: store.login,
    loader: store.loader,

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    //notes

    viewAllLession: () => {
      dispatch(viewAllLession())
    },

    add_lesson: (admin_id, course_id, batch_id, subject_id, subject_name, session_name) => {
      dispatch(add_lesson(admin_id, course_id, batch_id, subject_id, subject_name, session_name))
    },


    update_lesson: (lession_id, admin_id, course_id, batch_id, subject_id, subject_name, session_name) => {
      dispatch(update_lesson(lession_id, admin_id, course_id, batch_id, subject_id, subject_name, session_name))
    },

    delete_lesson: (lession_id) => {
      dispatch(delete_lesson(lession_id))
    },

    delete_notes: (notes_id, batch_id) => {
      dispatch(delete_notes(notes_id, batch_id))
    },

    view_notes_by_batch_id: (batch_id, page_number, rowper_page) => {
      dispatch(view_notes_by_batch_id(batch_id, page_number, rowper_page))
    },

    addSchedule: (course_id, batch_id, subject_id, lession_id, duration, class_link, date, time) => {
      dispatch(addSchedule(course_id, batch_id, subject_id, lession_id, duration, class_link, date, time))
    },

    reschedule: (class_id, date, time, lession_id, duration) => {
      dispatch(reschedule(class_id, date, time, lession_id, duration))
    },

    view_subject_by_courses: (admin_id, course_id) => {
      dispatch(view_subject_by_courses(admin_id, course_id))
    },

    viewAssignmentByBatch: (batch_id, page_number, rowper_page) => {
      dispatch(viewAssignmentByBatch(batch_id, page_number, rowper_page))
    },
    view_lession_by_subject_id: (batch_id) => {
      dispatch(view_lession_by_subject_id(batch_id))
    },
    view_shedule_by_lesson: (subject_id) => {
      dispatch(view_shedule_by_lesson(subject_id))
    },
    view_shedule_by_lesson: (subject_id) => {
      dispatch(view_shedule_by_lesson(subject_id))
    },
    uploadRecord: (admin_id, record_image, course_id, batch_id, title, session, access_end_date, page_number, rowper_page) => {
      dispatch(uploadRecord(admin_id, record_image, course_id, batch_id, title, session, access_end_date, page_number, rowper_page))
    },
    uploadRecord: (admin_id, record_image, course_id, batch_id, title, session, access_end_date, page_number, rowper_page) => {
      dispatch(uploadRecord(admin_id, record_image, course_id, batch_id, title, session, access_end_date, page_number, rowper_page))
    },

    setAttachment: (payload) => {
      dispatch(setAttachment(payload))
    },

    setTrainerNameInAssignment: (payload) => {
      dispatch(setTrainerNameInAssignment(payload))
    },

    setTrainerProfileInAssignment: (payload) => {
      dispatch(setTrainerProfileInAssignment(payload))
    },
    setTrainerDuedateInAssignment: (payload) => {
      dispatch(setTrainerDuedateInAssignment(payload))
    },
    setTrainerTitleInAssignment: (payload) => {
      dispatch(setTrainerTitleInAssignment(payload))
    },

    setAssignmentId: (payload) => {
      dispatch(setAssignmentId(payload))
    },

    view_all_feedback: (page_number, rowper_page) => {
      dispatch(view_all_feedback(page_number, rowper_page))
    },

    editUploadRecord: (record_id, record_image, course_id, batch_id, title, session, access_end_date) => {
      dispatch(editUploadRecord(record_id, record_image, course_id, batch_id, title, session, access_end_date))
    },

    delete_record: (record_id, batch_id) => {
      dispatch(delete_record(record_id, batch_id))
    },

    search_Assignment: (assignment_by_batch, e) => {
      dispatch(search_Assignment(assignment_by_batch, e))
    },

    view_all_shedule_subject_by_id: (subject_id) => {
      dispatch(view_all_shedule_subject_by_id(subject_id))
    },
    add_attendance: (student_id) => {
      dispatch(add_attendance(student_id))
    },
    add_assignment: (trainer_id, course_id, batch_id, title, description, due_date, attachment) => {
      dispatch(add_assignment(trainer_id, course_id, batch_id, title, description, due_date, attachment))
    },
    uploadAssignment: (trainer_id, course_id, batch_id, title, description, due_date, attachment) => {
      dispatch(uploadAssignment(trainer_id, course_id, batch_id, title, description, due_date, attachment))
    },
    update_assignment: (batch_id, assigement_id, title, description, due_date, attachment) => {
      dispatch(update_assignment(batch_id, assigement_id, title, description, due_date, attachment))
    },
    editUploadAssignment: (batch_id, assigement_id, title, description, due_date, old_attachment,attachment,) => {
      dispatch(editUploadAssignment(batch_id, assigement_id, title, description, due_date,old_attachment, attachment,))
    },
    delete_assignment: (batch_id, assigement_id) => {
      dispatch(delete_assignment(batch_id, assigement_id))
    },
    view_record_by_batch: (batch_id, rowsPerPage, page_number) => {
      dispatch(view_record_by_batch(batch_id, rowsPerPage, page_number))
    },
    setSubjectId: (payload) => {
      dispatch(setSubjectId(payload))
    },
    setLessonId: (payload) => {
      dispatch(setLessonId(payload))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },




  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);