import {
  COURSES,
  COURSES_BY_ID,
  SEARCH_COURSES,
  SET_COURSE_ID,
  SET_COURSE_NAME,
  SET_DURATION,
  SET_IMAGE,
  SET_TITLE,
  SET_DESC,
  SET_STATUS,
  SET_SUBJECT_ID,

  VIEW_ALL_SUBJECT,
  SUBJECT_BY_ID,
  SUBJECT_LENGTH,

  VIEW_ALL_BATCH,
  BATCH_BY_ID,

  VIEW_ALL_NOTES,
  NOTES_BY_ID,
  NOTES_BY_BATCH_ID,

  VIEW_ALL_LESSON,
  LESSON_BY_ID,

  VIEW_ALL_ASSIGNMENT,
  ASSIGNMENT_BY_ID,
  ASSIGNMENT_BY_STUDENT_ID,

  VIEW_ALL_FEEDBACK,
  FEEDBACK_BY_ID,

  VIEW_ALL_CLASSRECODER,
  CLASSRECODER_BY_ID,

  VIEW_ALL_SCORE,
  VIEW_ALL_SUBMIT,
  VIEW_SUBMIT_BY_ASSIGNMENT,
  VIEW_SUBMIT_BY_STUDENT,
  VIEW_BY_ASSIGNMENT,

  SET_COURSE_CODE,
  VIEW_BATCH_BY_COURSES,
  VIEW_ALL_SUBMIT_DETAILS,

  VIEW_ALL_ASSIGN_TO_TRAINER,
  VIEW_ALL_ASSIGN_TO_TRAINER_LENGTH,
  SEARCH_BATCH,
  STUDENT_COURSES,
  SET_BATCH_ID,
  SET_BATCH_ID2,
  VIEW_BATCH_RECORD,
  SET_ASSIGNMENT_DETAILS,
  VIEW_ALL_LESSION,
  SET_TRAINER_NAME,
  SET_TRAINER_PROFILE,
  BATCH_LENGTH,
  SET_BATCH_NUMBER,
  SET_SUBJECT_NAME,
  VIEW_ASSIGNMENT_BY_BATCH,
  LESSON_BY_SUBJECT,
  SET_BATCH_NAME,
  SET_BATCH,
  LESSON_MEETING,
  SET_ATTACHMENT,
  SET_TRAINER_NAME_IN_ASSIGNMENT,
  SET_TRAINER_PROFILE_IN_ASSIGNMENT,
  SET_TRAINER_TITLE_IN_ASSIGNMENT,
  SET_TRAINER_DUEDATE_IN_ASSIGNMENT,
  SET_ASSIGNMENT_ID,
  SEARCH_ASSIGNMENT,
  VIEW_SCHEDULE_MEETING,
  SET_LESSON_ID



} from "./constant";

const initial_state = {
  assignment_by_batch: [],
  search_assignment: [],
  assignment_trainer_length: 0,
  course_length: [],
  course_by_id: [],
  placement_length: 0,
  batch_length: 0,
  notes_length: 0,
  assign_trainer_length: 0,

  view_all_subject: [],
  view_subject_by_id: [],

  batch: [],
  view_batch_by_id: [],
  batch_by_course: [],

  //ADD
  view_all_notes: [],
  view_notes_by_id: [],
  view_notes_by_batch: [],

  view_all_lesson: [],
  view_lesson_by_id: [],
  view_all_assignment: [],
  view_assignment_by_id: [],
  view_assignment_by_batch_id: [],
  view_all_feedback: [],
  view_feedback_by_id: [],
  view_all_classrecoder: [],
  view_classrecoder_by_id: [],

  view_all_score: [],
  view_all_submit: [],
  view_all_submit_by_student: [],
  view_all_submit_by_assignment: [],
  view_by_assignment: [],
  view_all_submit_details: [],
  assign_trainer: [],


  //ASSIGN
  view_all_assign_to_trainer: [],
  view_all_assign_to_trainer_length: [],

  setCourseId: "",
  setBatchId: "",
  setCoursename: "",
  setDuration: "",
  subject_name: "",
  setImage: "",
  setTitle: "",
  setStatus: "",
  setDESC: "",
  search_course: "",
  Course_Id: "",
  course_name: "",
  duration: "",
  image: "",
  setTitle: "",
  setStatus: "",
  desc: "",
  search_course: "",
  course_code: "",
  search_batch: [],
  student_course: [],
  batch_Id: "",
  notes_length: 0,
  record_length: 0,
  batch_record: [],
  assignment_details: {},
  batch_id: "",
  trainer_name: '',
  trainer_pic: "",
  batch_length: "",
  subject_length: "",
  batch_number: "",
  view_all_lesson_subject: [],
  batch_Name: "",
  batchbycourse_length: 0,
  batch_details: {},
  lesson_meeting: {},
  feed_length: 0,
  attachment: "",
  trainer_name_in_assignment: "",
  trainer_profile_in_assignment: "",
  trainer_title_in_assignment: '',
  trainer_duedate_in_assignment: "",
  assignment_id: '',
  view_schedule_meeting: [],
  batchlength: 0,
  setSubjectId: "",
  setLessonId: ""


};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case COURSES:
      console.log("viewcourse", action.payload)
      return {
        ...state,
        course: action.payload.data, course_length: action.payload.count, search_course: action.payload.data
      };
    case VIEW_BATCH_RECORD:
      console.log("viewcourse", action.payload.data)
      return {
        ...state,
        batch_record: action.payload.data, record_length: action.payload.count
      };
    case STUDENT_COURSES:
      console.log("actionpayload", action.payload.data)
      return {
        ...state,
        student_course: action.payload
      };

    case SEARCH_COURSES:
      return {
        ...state,
        search_course: action.payload
      };

    case SET_COURSE_CODE:
      return {
        ...state,
        course_code: action.payload
      }

    case SEARCH_COURSES:
      return {
        ...state,
        search_course: action.payload
      };

    case COURSES_BY_ID:
      return {
        ...state,
        course_by_id: action.payload
      };
    case SET_COURSE_ID:
      return {
        ...state,
        Course_Id: action.payload
      };
    case SET_BATCH_ID:
      console.log("batch", action.payload)
      return {

        ...state,
        batch_Id: action.payload
      };
    case SET_BATCH:
      console.log("batchdetails", action.payload)
      return {
        ...state,
        batch_details: action.payload
      };
    case SET_BATCH_ID2:
      console.log(action.payload)
      return {

        ...state,
        setBatchId: action.payload
      };
    case SET_BATCH_NAME:
      return {
        ...state,
        batch_Name: action.payload
      };
    case SET_ASSIGNMENT_DETAILS:
      return {
        ...state,
        assignment_details: action.payload
      };

    case SET_COURSE_NAME:
      console.log(action.payload)
      return {
        ...state,
        course_name: action.payload
      };
    case SET_DURATION:
      return {
        ...state,
        duration: action.payload
      };
    case SET_IMAGE:
      return {
        ...state,
        image: action.payload
      };
    case SET_TITLE:
      return {
        ...state,
        setTitle: action.payload
      };
    case SET_STATUS:
      return {
        ...state,
        setStatus: action.payload
      };
    case SET_DESC:
      return {
        ...state,
        desc: action.payload
      };

    //subject
    case VIEW_ALL_SUBJECT:
      console.log(action.payload)
      return state = { ...state, view_all_subject: action.payload };

    case SUBJECT_LENGTH:
      console.log("hehe", action.payload)
      return state = { ...state, subject_length: action.payload.count };


    case SUBJECT_BY_ID:
      console.log("viewsubjectbyid", action.payload)
      return {
        ...state,
        view_subject_by_id: action.payload
      };
    case SET_SUBJECT_ID:
      console.log("subject_id", action.payload)
      return {
        ...state,
        setSubjectId: action.payload
      };


    //batch
    case VIEW_ALL_BATCH:
      console.log("view batch page", action.payload.data, action.payload.count)
      return {
        ...state,
        batch: action.payload.data, search_batch: action.payload.data, batchlength: action.payload.count
      };

    case BATCH_LENGTH:
      console.log("dfghj", action.payload)
      return {
        ...state,
        batch_length: action.payload
      };

    case BATCH_BY_ID:
      return {
        ...state,
        course_by_id: action.payload
      };
    //add

    case VIEW_ALL_NOTES:
      console.log(action.payload.data)
      return {
        ...state,
        view_all_notes: action.payload

      };

    case VIEW_SCHEDULE_MEETING:
      console.log(action.payload.data)
      return {
        ...state,
        view_schedule_meeting: action.payload

      };

    case NOTES_BY_ID:
      return {
        ...state,
        view_notes_by_id: action.payload
      };


    case NOTES_BY_BATCH_ID:
      console.log("vvvvvvvvvvv", action.payload.data)
      return {
        ...state,
        view_notes_by_batch: action.payload.data, notes_length: action.payload.count
      };


    case VIEW_ALL_LESSION:
      console.log("viewall lession", action.payload)
      return {
        ...state,
        view_all_lesson: action.payload

      };
    case LESSON_BY_SUBJECT:
      console.log("viewall lession", action.payload)
      return {
        ...state,
        view_all_lesson_subject: action.payload

      };

    case LESSON_BY_ID:
      return {
        ...state,
        view_lesson_by_id: action.payload
      };
    case SET_LESSON_ID:
      // console.log("lesson", action.payload)
      return {
        ...state,
        setLessonId: action.payload
      };

    case VIEW_ALL_ASSIGNMENT:
      console.log(action.payload.data)
      return {
        ...state,
        view_all_assignment: action.payload.data, assign_length: action.payload.count

      };
    case ASSIGNMENT_BY_ID:
      return {
        ...state,
        view_assignment_by_id: action.payload
      };
    case ASSIGNMENT_BY_STUDENT_ID:
      return {
        ...state,
        view_assignment_by_batch_id: action.payload
      };
    case VIEW_ALL_FEEDBACK:
      console.log(action.payload.data)
      return {
        ...state,
        view_all_feedback: action.payload.data, feed_length: action.payload.count

      };
    case FEEDBACK_BY_ID:
      return {
        ...state,
        view_feedback_by_id: action.payload
      };
    case VIEW_ALL_CLASSRECODER:
      console.log(action.payload.data)
      return {
        ...state,
        view_all_classrecoder: action.payload

      };
    case CLASSRECODER_BY_ID:
      return {
        ...state,
        view_classrecoder_by_id: action.payload
      };
    case VIEW_BATCH_BY_COURSES:
      console.log("viewbatch", action.payload.count)
      return {
        ...state,
        batch_by_course: action.payload.data, search_batch: action.payload.data, batchbycourse_length: action.payload.count
      };
    case VIEW_ALL_SUBMIT_DETAILS:
      console.log("viewbatch", action.payload.data)
      return {
        ...state,
        view_all_submit_details: action.payload.data, search_batch: action.payload.data, batch_length: action.payload.count
      };

    //assigment submit
    case VIEW_ALL_SCORE:
      return {
        ...state,
        view_all_score: action.payload
      };
    case VIEW_ALL_SUBMIT:
      return {
        ...state,
        view_all_submit: action.payload
      };

    case VIEW_BY_ASSIGNMENT:
      return {
        ...state,
        view_by_assignment: action.payload
      };

    case VIEW_SUBMIT_BY_ASSIGNMENT:
      return {
        ...state,
        view_all_submit_by_student: action.payload
      };
    case VIEW_SUBMIT_BY_STUDENT:
      return {
        ...state,
        view_all_submit_by_assignment: action.payload
      };




    case VIEW_ALL_ASSIGN_TO_TRAINER:
      console.log("allassigntrainer", action.payload.data)
      return {
        ...state,
        assign_trainer: action.payload.data, assign_trainer_length: action.payload.count
      };

    case LESSON_MEETING:
      return {
        ...state,
        lesson_meeting: action.payload
      };


    case SEARCH_BATCH:
      return {
        ...state,
        search_batch: action.payload
      };

    // case SET_BATCH_ID:
    //   return {
    //     ...state,
    //     batch_id: action.payload
    //   };


    case SET_TRAINER_NAME:
      console.log("abcd", action.payload)
      return {
        ...state,
        trainer_name: action.payload
      };

    case SET_TRAINER_PROFILE:
      return {
        ...state,
        trainer_pic: action.payload
      };

    case SET_BATCH_NUMBER:
      console.log("setbatchname", action.payload)
      return {
        ...state,
        batch_number: action.payload
      };


    case SET_SUBJECT_NAME:
      console.log("setsubjectname", action.payload)
      return {
        ...state,
        subject_name: action.payload
      };



    case VIEW_ASSIGNMENT_BY_BATCH:
      console.log("viewassignment", action.payload.data)
      return {
        ...state,
        assignment_by_batch: action.payload.data, assignment_trainer_length: action.payload.count, search_assignment: action.payload.data
      };




    case SET_ATTACHMENT:
      console.log("sc", action.payload)
      return {
        ...state,
        attachment: action.payload
      };

    case SET_TRAINER_NAME_IN_ASSIGNMENT:
      return {
        ...state,
        trainer_name_in_assignment: action.payload
      };

    case SET_TRAINER_PROFILE_IN_ASSIGNMENT:
      return {
        ...state,
        trainer_profile_in_assignment: action.payload
      };

    case SET_TRAINER_TITLE_IN_ASSIGNMENT:
      return {
        ...state,
        trainer_title_in_assignment: action.payload
      };

    case SET_TRAINER_DUEDATE_IN_ASSIGNMENT:
      console.log("res", action.payload)
      return {
        ...state,
        trainer_duedate_in_assignment: action.payload
      };

    case SET_ASSIGNMENT_ID:
      return {
        ...state,
        assignment_id: action.payload
      };
    case SEARCH_ASSIGNMENT:
      return {
        ...state,
        search_assignment: action.payload
      };








    default:
      return state;
  }
}
