import React, { Component } from 'react'
import "../styles/batch.css";
import { Card, DialogTitle, DialogContent, TextField, DialogActions } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Calender from "../../Images/Vector (1).png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DoneIcon from "@mui/icons-material/Done";
import { Divider, Textarea } from "theme-ui";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from 'moment';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import check from "../../Images/Ok.png"


import {
  FormControl,
  InputBase,
  InputLabel,
  OutlinedInput,
  Grid,
  Dialog,
  Box,
} from "@mui/material";


export default class trainercourseclasses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setindex: "",
      clicked: "",
      click_count: 0,
      data: [
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },


      ],
      traineraddAssignment: false,
      trainerdeleteassignment: false,
      trainereditassignment: false,
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
      type: "Classes",
      expanded: false,
      menubatch: false,
      deleteTopic: false,
      deleteRecordedTopic: false,
      editTopic: false,
      addAssignment: false,
      addVideo: false,
      anchorEbatch: null,
      rescheduledSession: false,
      scheduledSession: false,
      editassignment: false,
      deleteassignment: false,
      deleteNote: false,

      createclass: false,
      notstartedcreateclass: false,
      notstartedscheduleadd: false,
      notstartedscheduleadit: false,
      notstartedscheduledelete: false,
      batch_id: "",
      admin_id: "",
      course_id: "",
      subject_name: "",
      session_name: "",

      menu: false,
      anchorEl: null,


      menu1: false,
      anchorEl1: null,
      attachment: "",
      due_date: '',
      description: "",
      title: "",
      old_attachment: "",
      type: "Classes"
    };
    this.anchorRef = React.createRef();
  }

  handleClick12 = (event) => {
    this.setState({
      anchorEassign: event.currentTarget,
      menuEassign: true,
    });
  };

  handleClose12 = () => {
    this.setState({
      anchorEassign: null,
      menuEassign: false,
    });
  };



  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };
  viewSomething(bkb, index) {
    // console.log(bkb, index);
    if (bkb > 0 && this.state.setindex === index) {
      this.setState({ setindex: "" })
    }

  }

  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"
    this.props.viewAssignmentByBatch(this.props.courses.batch_details._id, this.state.page, this.state.rowper_page)
    this.props.view_record_by_batch(this.props.courses.batch_details._id, this.state.page_number, this.state.rowper_page)
    // console.log("uuuujh", this.props.courses.view_schedule_meeting)
  }

  render() {
    const { courses, login, snackbar, close_snack_bar } = this.props
    const handleChangePage = (event, newPage) => {
      this.props.viewAssignmentByBatch(this.props.login.user_id, newPage - 1, this.state.rowsPerPage);
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };
    return (
      <div>


        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "80px" }}
        >
          <Link to="/course">
            <Button style={{ color: "#7A7E86" }}>Courses</Button>
          </Link>
          <ArrowForwardIosIcon
            style={{ marginTop: "0.8%", color: "#7A7E86", fontSize: "17px" }}
          />
          <Link to="/courseDetails">
            <Button style={{ marginLeft: "0.5%", color: "#7A7E86", marginTop: "0.5%" }}>
              Batches
            </Button>
          </Link>
          <ArrowForwardIosIcon
            style={{ marginTop: "0.8%", fontSize: "17px" }}
          />
          <Typography style={{ marginLeft: "0.5%", marginTop: "0.5%" }}>
            Classes
          </Typography>
        </div>


        <div>
          <Card style={{ margin: "2.5% 2.5% 2.5% 0%", padding: "0px 20px 20px 20px" }}>
            <h2 style={{ marginLeft: "2%" }}>
              {courses.course_name} ({courses.batch_details.batch_number})
            </h2>
            <Typography style={{ marginLeft: "2%" }} className="courseduration-student">

              <img
                src={Calender}
                style={{ marginRight: "5px" }}
              />
              Started On {courses.course[0].date}
            </Typography>

            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
              <Tabs style={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', height: "48px", marginBottom: "20px" }}>
                  <TabList style={{ display: "flex", flexDirection: "row" }}>
                    <TabList style={{ width: "100%", display: "flex" }}>
                      <Tab label="Active" className={this.state.type === "Classes" ? 'selected-tab' : ''}   >
                        <Button

                          style={{ color: "#7A7E86", fontSize: "20px" }}
                          id=""
                          onClick={() => {
                            this.setState({ type: "Classes" });
                          }}
                        >
                          {" "}
                          Classes{" "}
                        </Button>
                      </Tab>
                      <Tab className={this.state.type === "Assignment" ? 'selected-tab' : ''}>
                        <Button

                          style={{ color: "#7A7E86", fontSize: "20px" }}
                          id=""
                          onClick={() => {
                            this.setState({ type: "Assignment" });
                          }}
                        >
                          {" "}
                          Assignment{" "}
                        </Button>
                      </Tab>
                      {/* {this.props.login.type === "A" && */}
                      <Tab className={this.state.type === "Recorded Class" ? 'selected-tab' : ''}>
                        <Button

                          style={{ color: "#7A7E86", fontSize: "20px" }}
                          id=""
                          onClick={() => {
                            this.setState({ type: "Recorded Class" });
                          }}
                        >
                          {" "}
                          Recorded Class{" "}
                        </Button>
                      </Tab>
                    </TabList>
                  </TabList>
                </Box>

                <TabPanel>
                  {Array.isArray(courses.view_subject_by_id) && courses.view_subject_by_id.map((item, index) => {
                    return (
                      <div style={{ cursor: "pointer", border: "1px solid #E3E3E3", margin: "0px 20px 0px 20px" }}>

                        <div
                          expanded={this.state.expanded === "panel1"}
                          // onChange={this.handleChange("panel1")}
                          onClick={() => {
                            this.setState({ setindex: index, click_count: this.state.click_count - 1, subject_id: item._id });
                            this.viewSomething(this.state.click_count, index);
                            this.props.view_all_shedule_subject_by_id(item._id)
                            // console.log("abc",item._id,item.subject_id)
                          }}
                        >

                          <div style={{ display: "flex", flexDirection: "row", margin: "2%" }}>
                            <div >
                              {this.state.setindex === index ? (
                                <KeyboardArrowDownIcon />
                              ) : (
                                <KeyboardArrowRightIcon />
                              )}
                            </div>
                            <div style={{ marginLeft: "2%", width: "100%", fontWeight: 450 }}>
                              {index + 1}. {item.subject_name}
                            </div>
                          </div>
                        </div>
                        {this.state.setindex === index ?
                          <div>
                            {
                              Array.isArray(this.props.courses.view_schedule_meeting) && this.props.courses.view_schedule_meeting.map((item, index) => {
                                return (
                                  <div>
                                    <div style={{ marginTop: "1%", marginLeft: "4%", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >

                                      <div className="accordion-det-01-01">
                                        <div>
                                          <img src={check} />
                                        </div>
                                        <div>
                                          <Link style={{ textDecoration: "none", color: "black" }}
                                            to="/trainerdiscussionnotes"
                                            onClick={() => {
                                              // this.props.setTrainerNameInAssignment(item.trainer_details.name);
                                              // this.props.setTrainerProfileInAssignment(item.trainer_details.profile_pic);
                                              this.props.setSubjectId(item.subject_id);
                                              this.props.setLessonId(item);
                                        
                                            }}
                                          >
                                            <strong>{item.subject_name}</strong>
                                          </Link>
                                        </div>
                                        <div >
                                          <Link style={{ textDecoration: "none", color: "black" }}
                                            to="/trainerdiscussionnotes"
                                            onClick={() => {
                                              // this.props.setTrainerNameInAssignment(item.trainer_details.name);
                                              // this.props.setTrainerProfileInAssignment(item.trainer_details.profile_pic);
                                              this.props.setSubjectId(item.subject_id);
                                              this.props.setLessonId(item);
                                            }}
                                          ><span style={{ color: "#878686" }}>({item.session_name})</span>
                                          </Link>
                                        </div>
                                      </div>

                                      <div style={{ marginRight: "5%" }}>
                                        <Button
                                          disabled={(moment(item.date + " " + item.end_time)).isBefore(moment())}
                                          variant="contained"
                                          onClick={() => {
                                            console.log((moment(item.date + " " + item.end_time)).isBefore(moment()))
                                            this.props.add_attendance(this.props.login.user_id)
                                            window.location.href = item.class_link
                                          }}
                                          style={(moment(item.date + " " + item.end_time)).isBefore(moment()) ?
                                            {
                                              backgroundColor: "grey",
                                              color: "white",
                                              width: "150px"


                                            } : {
                                              backgroundColor: "#0000B3",
                                              color: "white",
                                              width: "150px"


                                            }}
                                          size="small"
                                        >
                                          Join
                                        </Button>


                                      </div>
                                    </div>

                                    <div style={{ marginLeft: "3%", marginTop: "0.5%" }} className="accordion-det-02">
                                      <div className="accordion-det-02-01">
                                        <div>
                                          <AccessTimeOutlinedIcon
                                            style={{ fontSize: "1rem" }}
                                          />
                                        </div>
                                        <div>{item.time}-{item.end_time}  ({item.duration}min)</div>
                                      </div>
                                      <div className="accordion-det-02-01">
                                        <div>
                                          <DateRangeIcon style={{ fontSize: "1rem" }} />
                                        </div>
                                        <div>{item.date}</div>
                                      </div>
                                    </div>
                                    <Divider sx={{ background: "#dbdbdb", padding: "0.2px", margin: "10px", borderBottom: "none" }} />
                                  </div>)
                              })
                            }</div> : ""}


                      </div>
                    )
                  })}

                </TabPanel>

                <TabPanel>
                  <div>
                    <Button
                      id="batch-add-mk"
                      style={{
                        textTransform: "capitalize",
                        position: "relative",
                        float: "right",
                        top: "-2rem",
                        color: "#0000B3",
                        border: "1px solid #0000B3",
                        fontSize: "0.9rem",
                      }}
                      onClick={(e) => {
                        this.setState({ traineraddAssignment: true });
                      }}
                      variant="outlined"
                      startIcon={<AddIcon />}
                    >
                      Add Assignment
                    </Button>
                  </div>


                  <div style={{ marginTop: "-30px" }}>
                    <Table>
                      <TableHead style={{ backgroundColor: "#F8F7F7" }}>
                        <TableRow>
                          <TableCell style={{ textAlign: "left" }}>
                            Assignment Title
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            Due Date
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Attachment
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Status
                          </TableCell>
                          {/* <TableCell style={{ textAlign: "center" }}>
                            Score
                          </TableCell> */}
                          <TableCell style={{ textAlign: "center" }}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* <Link style={{textDecoration:"none", color:"black"}} to="/assignmentDetails"> */}
                        {Array.isArray(this.props.courses.assignment_by_batch) && this.props.courses.assignment_by_batch.map((item, index) => {
                          return (
                            <TableRow>
                              <TableCell style={{ textAlign: "left" }}>
                                <Link style={{ textDecoration: "none", color: "black" }}
                                  to="/assignmentDetails"
                                  onClick={() => {
                                    this.props.setAttachment(item.attachment);
                                    this.props.setTrainerNameInAssignment(item.trainer_details.name);
                                    this.props.setTrainerProfileInAssignment(item.trainer_details.profile_pic);
                                    this.props.setTrainerDuedateInAssignment(item.due_date);
                                    this.props.setTrainerTitleInAssignment(item.title);

                                    this.props.setAssignmentId(item._id);

                                  }}
                                >
                                  <strong>{item.title}</strong>
                                </Link>
                              </TableCell>

                              <TableCell style={{ textAlign: "center" }}>
                                <Link style={{ textDecoration: "none", color: "black" }}
                                  to="/assignmentDetails"
                                  onClick={() => {
                                    this.props.setAttachment(item.attachment);
                                    this.props.setTrainerNameInAssignment(item.trainer_details.name);
                                    this.props.setTrainerProfileInAssignment(item.trainer_details.profile_pic);
                                    this.props.setTrainerDuedateInAssignment(item.due_date);
                                    this.props.setTrainerTitleInAssignment(item.title);

                                    this.props.setAssignmentId(item._id);

                                  }}
                                >
                                  <strong>{item.due_date}</strong>
                                </Link></TableCell>
                              <TableCell style={{ justifyContent: "center" }}>
                                <div className="table-cell-assignment">
                                  <div id="table-cell-notes-icon">
                                    <PictureAsPdfIcon />
                                  </div>
                                  <strong><a href={item.attachment}>Attachment</a></strong>
                                </div>

                              </TableCell>
                              <TableCell >
                                <Link style={{ textDecoration: "none", color: "black" }}
                                  to="/assignmentDetails"
                                  onClick={() => {
                                    this.props.setAttachment(item.attachment);
                                    this.props.setTrainerNameInAssignment(item.trainer_details.name);
                                    this.props.setTrainerProfileInAssignment(item.trainer_details.profile_pic);
                                    this.props.setTrainerDuedateInAssignment(item.due_date);
                                    this.props.setTrainerTitleInAssignment(item.title);

                                    this.props.setAssignmentId(item._id);

                                  }}
                                >
                                  <center><div id="status-show">{item.status}</div></center>
                                </Link>
                              </TableCell>
                              <TableCell style={{ textAlign: "right" }}>
                                <div className="table-cell-assignment">


                                  <div>
                                    <Button
                                      onClick={(e) => {
                                        this.handleClick12(e);
                                        this.setState({
                                          assigement_id: item._id,
                                          title: item.title,
                                          description: item.description,
                                          due_date: item.due_date,
                                          old_attachment: item.attachment
                                        });
                                      }}
                                    >
                                      <MoreVertIcon style={{ color: "black" }} />
                                    </Button>

                                    <Menu
                                      anchorEl={this.state.anchorEassign}
                                      open={this.state.menuEassign}
                                      onClose={this.handleClose12}
                                    >
                                      <MenuItem
                                        style={{ color: "#0000B3" }}
                                        onClick={() => {
                                          this.setState({
                                            trainereditassignment: true,
                                          });
                                        }}
                                      >
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        style={{ color: "#0000B3" }}
                                        onClick={() => {
                                          this.setState({
                                            trainerdeleteassignment: true,
                                          });
                                        }}
                                      >
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        }
                        )}
                        {/* </Link> */}


                      </TableBody>
                    </Table>
                  </div>
                </TabPanel>

                <TabPanel>
                  <Grid
                    container
                    padding={0.5}
                    spacing={2}
                  >
                    {Array.isArray(this.props.courses.batch_record) && this.props.courses.batch_record.map((item, index) => {
                      return (
                        <Grid item xs={3}>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/courseDetailsTrainer"
                          >
                            <Card sx={{ maxWidth: 250, height: 300, borderRadius: 4 }}>
                              <CardMedia
                                component="video"
                                height={150}
                                controls
                                image={item.record_image}
                              />
                              <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                  {item.session}
                                </Typography>
                                <Typography>
                                  {item.title}
                                </Typography>
                              </CardContent>
                              <Typography style={{ marginLeft: "2%" }} className="courseduration-student">

                                <img
                                  src={Calender}
                                  style={{ marginRight: "5px", marginLeft: "15px" }}
                                />
                                {item.date}
                              </Typography>
                            </Card>
                          </Link>
                        </Grid>
                      );
                    })}
                  </Grid>

                </TabPanel>
              </Tabs>
            </div>
          </Card>
        </div>

        {/*----------------------------------------------------------------------------------------------------------*/}
        {/*----------------------------------------------------- Assignment ----------------------------------------------*/}
        <Dialog
          open={this.state.traineraddAssignment}
          maxWidth="sm" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                    Assignment
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container >
                <Grid item xs={1.5}></Grid>
                <Grid item xs={9} direction='column' spacing={2}>
                  <Typography style={{ fontSize: "18px", letterSpacing: 1 }}>
                    Title
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter Assignment Title"
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      borderRadius: "5px",
                      backgroundColor: "rgba(239, 239, 239, 0.60)"
                    }}
                    onChange={(e) =>
                      this.setState({ title: e.target.value })
                    }
                  />
                  <Typography style={{ fontSize: "18px", letterSpacing: 1, marginTop: "20px" }}>
                    Description
                  </Typography>
                  <Textarea
                    fullWidth
                    placeholder="Enter Description"
                    sx={{
                      height: "150px",
                      marginTop: 2,
                      backgroundColor: "rgba(239, 239, 239, 0.60)",
                      border: "none",
                      borderRadius: "5px",
                    }}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                  />
                  <Grid container>
                    <Grid xs={5.5}>
                      <Typography style={{ fontSize: "18px", letterSpacing: 1, marginTop: "20px" }}>
                        Due Date
                      </Typography>
                      <TextField
                        fullWidth
                        type="date"
                        placeholder="Enter Assignment Title"
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)"
                        }}
                        onChange={(text) =>
                          this.setState({ due_date: text.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid xs={5.5}>
                      <Typography style={{ fontSize: "18px", letterSpacing: 1, marginTop: "20px" }}>
                        Attach File
                      </Typography>
                      <Box
                        fullWidth
                        height={"40px"}
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)"
                        }}
                      >
                        <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                          <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                          >
                            {this.state.attachment !== "" ? "File Uploaded" : "Attach"}
                          </Typography>
                          <AttachFileIcon style={{ marginTop: "3.5%" }} />
                        </label>
                        <input style={{ display: "none" }} type="file" id="file"
                          onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1.5}></Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center", marginBottom: 3, marginTop: 10 }} >
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({ traineraddAssignment: false });
                }}
                style={{
                  border: "1px solid #0000B3",
                  fontSize: "15px",
                  width: "130px",
                  color: "#0000B3",
                  borderRadius: "5px",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.setState({ traineraddAssignment: false, attachment: "", due_date: "", description: "", title: "" });
                  this.props.uploadAssignment(this.props.login.user_id, courses.Course_Id, courses.batch_details._id, this.state.title, this.state.description, this.state.due_date, this.state.attachment)
                  console.log("sadgyj", this.props.login.user_id, courses.Course_Id, courses.batch_details._id, this.state.title, this.state.description, this.state.due_date, this.state.attachment)
                }}
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  width: "130px",
                  marginLeft: "2%",
                  color: "white",
                  borderRadius: "5px",
                }}
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>

        <Dialog
          open={this.state.trainereditassignment}
          maxWidth="sm" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                    Edit Assignment
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container >
                <Grid item xs={1.5}></Grid>
                <Grid item xs={9} direction='column' spacing={2}>
                  <Typography style={{ fontSize: "18px", letterSpacing: 1 }}>
                    Title
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter Assignment Title"
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      borderRadius: "5px",
                      backgroundColor: "rgba(239, 239, 239, 0.60)"
                    }}
                    value={this.state.title}
                    onChange={(e) =>
                      this.setState({ title: e.target.value })
                    }
                  />
                  <Typography style={{ fontSize: "18px", letterSpacing: 1, marginTop: "20px" }}>
                    Description
                  </Typography>
                  <Textarea
                    fullWidth
                    placeholder="Enter Description"
                    sx={{
                      height: "150px",
                      marginTop: 2,
                      backgroundColor: "rgba(239, 239, 239, 0.60)",
                      border: "none",
                      borderRadius: "5px",
                    }}
                    value={this.state.description}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                  />
                  <Grid container>
                    <Grid xs={5.5}>
                      <Typography style={{ fontSize: "18px", letterSpacing: 1, marginTop: "20px" }}>
                        Due Date
                      </Typography>
                      <TextField
                        fullWidth
                        type="date"
                        placeholder="Enter Assignment Title"
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)"
                        }}
                        value={this.state.due_date}
                        onChange={(text) =>
                          this.setState({ due_date: text.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid xs={5.5}>
                      <Typography style={{ fontSize: "18px", letterSpacing: 1, marginTop: "20px" }}>
                        Attach File
                      </Typography>
                      <Box
                        fullWidth
                        height={"40px"}
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)"
                        }}
                      >
                        <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                          <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                          >
                            {this.state.attachment !== "" ? "File Uploaded" : "Attach"}
                          </Typography>
                          <AttachFileIcon style={{ marginTop: "3.5%" }} />
                        </label>
                        <input style={{ display: "none" }} type="file" id="file"
                          accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"
                          onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1.5}></Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center", marginBottom: 3, marginTop: 10 }} >
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({ trainereditassignment: false });
                }}
                style={{
                  border: "1px solid #0000B3",
                  fontSize: "15px",
                  width: "130px",
                  color: "#0000B3",
                  borderRadius: "5px",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.setState({ trainereditassignment: false });
                  this.props.editUploadAssignment(courses.batch_details._id, this.state.assigement_id, this.state.title, this.state.description, this.state.due_date, this.state.old_attachment, this.state.attachment)
                  console.log("sadgyj", courses.batch_details._id, this.state.assigement_id, this.state.title, this.state.description, this.state.due_date, this.state.attachment, this.state.old_attachment,this.state.attachment)
                }}
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  width: "130px",
                  marginLeft: "2%",
                  color: "white",
                  borderRadius: "5px",
                }}
                variant="contained"
              >
                Update
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>

        <Dialog
          open={this.state.trainerdeleteassignment}

          style={
            {

            }}
        >
          <Grid style={{ height: "250px", width: "520px", }}>







            <Box>

              <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontFamily: 'Inter', display: "flex", justifyContent: "center", fontWeight: 600, marginTop: "14%" }}>
                Are you sure you want to delete ?
              </Typography>
              <Typography id="modal-modal-description" style={{ marginTop: "2%", fontSize: "1rem", fontFamily: 'Inter', display: "flex", justifyContent: "center", color: "black" }}>
                This process will lead to loss of entire data, proceed at yours own risk
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: "6%",
                }}
              >
                <Button variant="outlined" onClick={() => {
                  this.setState({ trainerdeleteassignment: false })
                }} style={{ width: "23%", color: "#0000B3", borderColor: "#0000B3" }}>
                  No
                </Button>
                <Button variant="contained" onClick={() => {
                  this.setState({ trainerdeleteassignment: false })
                  this.props.delete_assignment(courses.batch_details._id, this.state.assigement_id)
                }} style={{ marginLeft: 10, width: "23%", backgroundColor: "#0000B3" }}>
                  Yes
                </Button>
              </Box>
            </Box>








          </Grid>

        </Dialog>
        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </div>


    )
  }
}
