import React, { Component } from "react";
import { connect } from "react-redux";
import Companyview from "../Component/Jobprofile";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    viewAllApplied,
    updateAppliedStatus
} from "../action"

// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <Companyview {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        placement: store.placement,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {

        updateAppliedStatus: (placement_id, applied_id, status, page_number, rowper_page) => {
            dispatch(updateAppliedStatus(placement_id, applied_id, status, page_number, rowper_page))
        },
        viewAllApplied: (placement_id,page_number, rowper_page) => {
            dispatch(viewAllApplied(placement_id,page_number, rowper_page))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);