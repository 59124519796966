import React, { Component } from "react";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import first from "../../Images/first.png";
import second from "../../Images/second.png";
import third from "../../Images/third.png";
// import { TextArea } from 'semantic-ui-react';
import { Textarea } from "theme-ui";
import river from "../../Images/river.mp4";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Third from "../../Images/third.png"
import Calender from "../../Images/Vector (1).png";

import "../styles/Studashboard.scss";
import { Box, Button, Dialog, Divider, Grid, Typography, DialogTitle, DialogContent } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import AddIcon from "@mui/icons-material/Add";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import TimelineIcon from "@mui/icons-material/Timeline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VideocamIcon from "@mui/icons-material/Videocam";
import PersonIcon from "@mui/icons-material/Person";
import { AiOutlineCalendar } from "react-icons/ai";
import CardActions from "@mui/material/CardActions";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
// import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import PersonIcon from "@mui/icons-material/Person";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import img1 from "../../../admin/Recordedclass/Component/Images/online-event.jpg"
import img1 from "../../Images/online-event.jpg";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import Textarea from "@mui/joy/Textarea/Textarea";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardHeader from "@mui/material/CardHeader";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Avatar from "@mui/joy/Avatar";
import AvatarGroup from "@mui/joy/AvatarGroup";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import moment from "moment";


const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
  width: "80%",
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#4C6FFF" : "#4C6FFF",
  },
}));
const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  width: "80%",
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#FBBC28" : "#FBBC28",
  },
}));
const BorderLinearProgress3 = styled(LinearProgress)(({ theme }) => ({
  width: "80%",
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#FF7373" : "#FF7373",
  },
}));

export default class Studashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticeviewmodal: false,
      noticeviewmodaladmin: false,
      assigementModalOpen: false,
      openStudntDetailsForm: false,
      modalclosed: false,
      data: [
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "submit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "notsubmit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "notsubmit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "notsubmit",
        },
        {
          coursename: "PG Program On Full Stack Development With PHP Or Python",
          batch: "(Batch IV)",
          subject: "Html",
          session: "Session 1",
          date: "Monday, 28 June 2021",
          trainer: "Ankit Das",
          time: "02.00 -03.30 PM",
          status: "notsubmit",
        },
      ],
      datas: [
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: " PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "HTML",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "HTML",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "CSS",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: " With PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full StackP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "volopement With PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack  With PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },

      ],
    };
  }
  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"

    this.props.viewAllNotice();
    this.props.subjectlength();
    this.props.viewRecordVideoByStudent(this.props.login.user_id);
    this.props.viewAssignmentStudentLength(this.props.login.user_id);
    this.props.viewAssignmentList(this.props.login.user_id);
    this.props.viewAssignmentCompletedLength(this.props.login.user_id);
    this.props.viewOngoingCourseByStudent(this.props.login.user_id);
    this.props.viewScheduleClassByStudent(this.props.login.user_id);
    this.props.view_course_by_student(this.props.login.user_id, 0, 10)
    console.log("ss", this.props.dashboard.completed_assignment_length)
    console.log("aaaaaaaaaa",this.props.dashboard.schedule_class_of_student)


    const modalCanceled = localStorage.getItem('modalCanceled');

    if (modalCanceled) {
      this.setState({ openStudntDetailsForm: false }); // Don't show the modal if it was canceled
    }
  }

  closeModal = () => {
    // Store in local storage that the user canceled the modal
    localStorage.setItem('modalCanceled', 'true');

    this.setState({ openStudntDetailsForm: false });
  };

  render() {
    const { dashboard, assignment, courses } = this.props
    return (
      <Grid>
        <Grid style={{ marginTop: "8%" }}>
          <div className="task-nav">
            <Box className="completed">
              <div className="tasknavtopaccessSection">
                <div id="access-icon" className="icon">
                  <TrendingUpIcon />
                </div>
                <div className="task-nav-line-space">
                  <h2>{(courses.student_course.length) - (dashboard.in_progress_length)}/{courses.student_course.length}</h2>
                  <h6 style={{ color: "var(--Paragraph, #767278)" }}>Courses in progress</h6>
                </div>
              </div>
              <div className="bottomTaskNav">
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "18px",
                    justifyContent: "space-between",
                    paddingInlineStart: "0px",
                    marginBlock: "12px",
                    marginInline: "7px",
                  }}
                >
                  <BorderLinearProgress2 className="brdr2"
                    variant="determinate"
                    value={((courses.student_course.length) - (dashboard.in_progress_length)) / (courses.student_course.length) * 100}
                    style={{
                      width: "150px",
                      marginLeft: "13%",
                      color: "FBBC28",
                      marginTop: "2%",
                    }}
                  />
                  {parseInt(((courses.student_course.length) - (dashboard.in_progress_length)) / (courses.student_course.length) * 100)}%
                </Box>
              </div>
            </Box>
            <Box className="completed">
              <div className="tasknavTop">
                <div id="tick-icon" className="icon">
                  <ArticleOutlinedIcon />
                </div>
                <div className="task-nav-line-space">
                  <h2>{((dashboard.assignment_length) - (dashboard.completed_assignment_length))}/{dashboard.assignment_length}</h2>
                  <h6 style={{ color: "var(--Paragraph, #767278)" }}>Assignment Completed</h6>
                </div>
              </div>
              <div className="bottomTaskNav">
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "18px",
                    justifyContent: "space-between",
                    paddingInlineStart: "0px",
                    marginBlock: "8px",
                    marginInline: "7px",
                  }}
                >
                  <BorderLinearProgress1 className="brdr1"
                    variant="determinate"
                    value={((dashboard.assignment_length) - (dashboard.completed_assignment_length)) / (dashboard.assignment_length) * 100}
                    style={{
                      width: "150px",
                      marginLeft: "13%",
                      color: "#4C6FFF",
                      marginTop: "5%",
                    }}
                  />
                  {parseInt(((dashboard.assignment_length) - (dashboard.completed_assignment_length)) / (dashboard.assignment_length) * 100)}%
                </Box>
              </div>
            </Box>
            <Box className="completed">
              <div className="tasknavtopaccessSection" style={{ marginTop: "-10px" }}>
                <div id="hours-icon" className="icon">
                  <img src={Third} width={50} />{" "}
                </div>
                <div className="task-nav-line-space">
                  <h2>{courses.view_subject_by_id.length}</h2>
                  <h6>Total Subjects</h6>
                </div>{" "}
              </div>
              {/* <div className="bottomTaskNav">
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "18px",
                    justifyContent: "space-between",
                    paddingInlineStart: "0px",
                    marginBlock: "12px",
                    marginInline: "7px",
                  }}
                >
                  <BorderLinearProgress2 variant="determinate" value={70} />
                  20%
                </Box>
              </div> */}
            </Box>
          </div>

          <Card style={{ width: "65%", marginTop: "2%" }}>
            <div style={{ padding: "20px" }}>
              <div>
                <Typography variant="h6" fontWeight={"400"}>{dashboard.schedule_class_of_student !== undefined && (dashboard.schedule_class_of_student?.course_name)}..</Typography>
                <div style={{ padding: "10px", color: "#828282" }}>
                  <Grid container>
                    <Grid item xs={4} display={"flex"}>
                      <CalendarMonthIcon style={{ fontSize: "1.5rem" }} />
                      {moment(dashboard.schedule_class_of_student?.date).format('dddd, D MMM , YYYY')}
                    </Grid>
                    <Grid item xs={4} display={"flex"}>
                      <AccessTimeIcon style={{ fontSize: "1.5rem" }} />
                      {dashboard.schedule_class_of_student !== undefined && dashboard.schedule_class_of_student?.time} - {dashboard.schedule_class_of_student !== undefined && dashboard.schedule_class_of_student?.end_time}
                    </Grid>
                  </Grid>
                </div>
                <div style={{ float: "right", marginBottom: "20px", }}>
                  Redirect zoom :-
                  <Button
                    variant="contained"
                    onClick={() => {
                      console.log((moment(dashboard.schedule_class_of_student?.date + " " + dashboard.schedule_class_of_student?.end_time)).isBefore(moment()))
                      this.props.add_attendance(this.props.login.user_id,this.props.dashboard.schedule_class_of_student.batch_id,this.props.dashboard.schedule_class_of_student.subject_id)
                      window.location.href = dashboard.schedule_class_of_student?.class_link
                    }}
                    style={{
                      width: "150px",
                      backgroundColor: "#0000B3",
                    }}
                  >
                    Join class
                  </Button>
                </div>
              </div>
            </div>
            <div>


            </div>
          </Card>

          <Card style={{ width: "65%", marginTop: "2%", height: "710px" }}>
            <div style={{ padding: "20px" }}>
              <span style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5" fontWeight={"500"}>Recorded class</Typography>
                <Link to="/StudentcourseDetails">
                  <Button>
                    <Typography color={"black"}>View All</Typography>
                  </Button>
                </Link>
              </span>
              <Typography>Recently uploaded HTML Class Video</Typography>

              <Grid
                container
                padding={0.5}
                spacing={2}
              >
                {Array.isArray(dashboard.record_details) && dashboard.record_details.map((item, index) => {
                  return (
                    <Grid item xs={4}>
                      <Card sx={{ maxWidth: 250, height: 300, borderRadius: 4 }}>
                        <CardMedia
                          component="video"
                          height={150}
                          controls
                          image={item.record_image}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h6" component="div">
                            {item.session}
                          </Typography>
                          <Typography>
                            {item.title}
                          </Typography>
                        </CardContent>
                        <Typography style={{ marginLeft: "2%" }} className="courseduration-student">

                          <img
                            src={Calender}
                            style={{ marginRight: "5px", marginLeft: "15px" }}
                          />
                          {item.date}
                        </Typography>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Card>
          <Grid style={{ display: "flex", flexDirection: "column" }}>

            {/* Notice Board Part */}
            <Card className="adminDashboardnotifications" style={{ height: "370px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="side-notification-text">Notice Board</div>

                <div className="flex-line">

                  <Paper
                    component="form"
                    style={{
                      borderRadius: "8px",
                      border: "0.5px solid #D2D2D2",
                      display: "flex",
                      marginLeft: "5px",
                      width: "90%",
                      height: "30px",
                    }}
                  >
                    <IconButton
                      type="button"
                      style={{ p: "100px" }}
                      aria-label="search"
                    >
                      <SearchIcon style={{ fontSize: "15px" }} />
                    </IconButton>
                    <InputBase
                      style={{ ml: 5, flex: 1, fontSize: "15px" }}
                      // placeholder="Search here"
                      inputProps={{ "aria-label": "Search here" }}
                      onChange={(e) => this.props.searchNotice(dashboard.notices, e.target.value)}
                    />
                  </Paper>
                </div>
              </div>

              <div style={{ overflowY: "scroll", height: "305px" }}>
                {dashboard.search_notice?.length == "0" &&
                  <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                    <Typography>No Result Found</Typography>
                  </Grid>
                }
                {Array.isArray(dashboard.search_notice) && dashboard?.search_notice?.map((item) => {
                  return (
                    <div className="noticeBoardContent">
                      <Typography className="noticeBoardContentHeader">{item.course_details !== undefined && item.course_details.course_name}<br /> <Typography style={{ fontWeight: 400 }} >{item.batch_details.batch_number}</Typography></Typography>
                      <div className="noticeBoardContentBottom">
                        <Typography style={{ marginLeft: "10px", fontSize: 14, fontWeight: 400, color: "var(--secondary, #0000B3)" }}>
                          {item.date}
                        </Typography>
                        <Button
                          onClick={() => {
                            this.setState({
                              noticeviewmodaladmin: true,
                              notice_id: item._id,
                              course_name: item.course_details.course_name,
                              batch_number: item.batch_details.batch_number,
                              desc: item.desc,
                              schedule: item.schedule

                            });
                          }}
                          style={{
                            color: "#0000B3",
                            fontSize: 14,
                            marginTop: "-10px"
                          }}
                        >
                          View
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Card>

            <Card className="rightSection">
              <div className="top">
                <div className="side-notification-text" style={{ marginBottom: "10px" }}>Assignment</div>
              </div>

              {Array.isArray(dashboard.student_assignment_length) && dashboard.student_assignment_length?.map((item, index) => {
                return (
                  <div style={{ padding: "5px 15px 5px 15px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <span style={{ flexDirection: "column" }}>
                        <Typography style={{ fontWeight: 450 }}>{item.title}</Typography>
                        <Typography style={{ fontWeight: 350, fontSize: "12px", color: "#9E9E9E" }}>
                          {this.state.status !== "In-Completed" ? "Due " : "Submited"}
                          {moment(item.due_date).format('D MMM')}
                        </Typography>
                      </span>
                      <Link to="/studentAssignmentdetails">
                        <Button
                          disabled={this.state.state == "Completed"}
                          style={this.state.status == "In-Completed" ?
                            {
                              backgroundColor: "gray",
                              color: "white",
                              width: "120px",
                              height: "25px"


                            } : {
                              backgroundColor: "#0000B3",
                              color: "white",
                              width: "120px",
                              height: "25px"


                            }}>{this.state.status == "In-Completed" ? "Submited" : "Submit"}</Button>
                      </Link>
                    </div>
                    <ul>
                      <li>{item.course_code}</li>
                    </ul>
                  </div>
                );
              })}
            </Card>

          </Grid>
        </Grid>

        <Dialog open={this.state.assigementModalOpen} style={{}}>
          <Grid style={{ height: "372px", width: "427px" }}>
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // marginInline: '30px',
                  marginBlock: "10px",
                  paddingLeft: "21px",
                  alignItems: "baseline",
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 600,
                    color: " #777676",
                  }}
                >
                  <FiberManualRecordIcon style={{ color: "#24E405" }} /> Full
                  Stack Development
                </Typography>

                {/* <CloseOutlinedIcon
                  onClick={() => {
                    this.setState({ assigementModalOpen: false, marginRight: "10%" });
                  }}
                  style={{ marginTop: "15px", cursor: "pointer" }}
                /> */}
              </Box>


              <Typography
                id="modal-modal-description"
                style={{
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  color: "#777676",
                  marginInline: "21px",
                }}
              >
                Temperature Converter Website.

              </Typography>





              <Typography
                id="modal-modal-description"
                style={{
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  display: "flex",
                  alignItems: "center",
                  color: "#0000b3",
                  marginInline: "29px",
                  marginTop: "9px",
                }}
              >
                <AiOutlineCalendar /> Due for 01 July 2023
              </Typography>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginTop: "23px",
                }}
              >
                <Typography
                  id="modal-modal-description"
                  style={{ marginLeft: "21px", fontWeight: 600 }}
                >
                  Attach File
                </Typography>
                <Box
                  style={{
                    width: "343px",
                    height: "50px",
                    marginLeft: "6%",
                    backgroundColor: "#eaeaea",
                    marginTop: "1%",
                  }}
                >
                  <label
                    htmlFor="file"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <AttachFileIcon
                      style={{ marginLeft: "3%", marginTop: "3%" }}
                    />
                  </label>

                  <input style={{ display: "none" }} type="file" id="file" />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "163px",
                    height: "45px",
                    marginLeft: "6%",
                    backgroundColor: "#eaeaea",
                    marginTop: "3%",
                    padding: "2%",
                  }}
                >
                  <PictureAsPdfIcon
                    style={{ marginLeft: "3%", marginTop: "1%", color: "red" }}
                  />

                  <Typography
                    id="modal-modal-description"
                    style={{ color: "#3679DC", fontWeight: 400 }}
                  >
                    Project1.pdf
                  </Typography>
                  <CloseOutlinedIcon
                    style={{
                      cursor: "pointer",
                      color: "gray",
                      marginLeft: "3%",
                      fontSize: "22px",
                    }}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "13%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ assigementModalOpen: false });
                  }}
                  style={{
                    width: "23%",
                    color: "#0000B3",
                    borderColor: "#0000B3",
                  }}
                >
                  cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.setState({ assigementModalOpen: false });
                  }}
                  style={{
                    marginLeft: 10,
                    width: "23%",
                    backgroundColor: "#0000B3",
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
        </Dialog>


        {/* Fill the form */}
        <Dialog
          maxWidth="xl"
          open={this.state.openStudntDetailsForm}
          style={{}}
        >
          <Grid style={{ width: "900px", overflowY: "auto" }}>
            <h3 style={{ marginLeft: "8.5%" }}>Fill the Form</h3>
            <div style={{ marginLeft: "8.5%" }}>
              <Typography style={{ fontSize: "17px", fontWeight: 500 }}>
                Upload Image<strong style={{ color: "red" }}>*</strong>
              </Typography>

              <Box
                style={{
                  width: "120px",
                  height: "70px",
                  border: "2px solid grey",
                  backgroundColor: "#FCFCFC",
                  marginTop: "1%",
                }}
              >
                <center>
                  <label
                    htmlFor="file"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <AddIcon style={{ marginLeft: "40%", marginTop: "16%" }} />
                  </label>
                </center>
                <input style={{ display: "none" }} type="file" id="file" />
              </Box>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginTop: "2%",
                }}
              >
                <div>
                  <Typography style={{ fontSize: "17px", fontWeight: 500 }}>
                    First Name<strong style={{ color: "red" }}>*</strong>
                  </Typography>

                  <OutlinedInput
                    type="text"
                    style={{
                      background: "#F5F5F5",
                      borderRadius: " 0.125rem",
                      height: "5vh",
                      width: "300px",
                      marginBottom: "11px",
                    }}
                    placeholder="Enter First Name "
                  />
                </div>

                <div>
                  <Typography style={{ fontSize: "17px", fontWeight: 500 }}>
                    Last Name<strong style={{ color: "red" }}>*</strong>
                  </Typography>

                  <OutlinedInput
                    type="text"
                    style={{
                      background: "#F5F5F5",
                      borderRadius: " 0.125rem",
                      height: "5vh",
                      width: "300px",
                      marginBottom: "11px",
                    }}
                    placeholder="Enter Last Name"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontWeight: 500,
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <Typography style={{ fontSize: "17px" }}>
                    Phone<strong style={{ color: "red" }}>*</strong>
                  </Typography>

                  <OutlinedInput
                    type="number"
                    style={{
                      background: "#F5F5F5",
                      borderRadius: " 0.125rem",
                      height: "5vh",
                      width: "300px",
                      marginBottom: "11px",
                    }}
                    placeholder="Enter Phone Number "
                  />
                </div>

                <div>
                  <Typography style={{ fontSize: "17px", fontWeight: 500 }}>
                    Email<strong style={{ color: "red" }}>*</strong>
                  </Typography>

                  <OutlinedInput
                    type="email"
                    style={{
                      background: "#F5F5F5",
                      borderRadius: " 0.125rem",
                      height: "5vh",
                      width: "300px",
                      marginBottom: "11px",
                    }}
                    placeholder="Enter Email Id"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <Typography style={{ fontSize: "17px", fontWeight: 500 }}>
                    Date of Birth<strong style={{ color: "red" }}>*</strong>
                  </Typography>

                  <input
                    type="date"
                    name="begin"
                    min="1997-01-01"
                    max="2030-12-31"
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      backgroundColor: "#EFEFEF99",
                      height: "6vh",
                      width: "300px",
                    }}
                  />
                </div>

                <div>
                  <Typography style={{ fontSize: "17px", fontWeight: 500 }}>
                    Address<strong style={{ color: "red" }}>*</strong>
                  </Typography>

                  <Textarea
                    style={{
                      width: "300px",
                      height: "80px",
                      backgroundColor: "#EFEFEF99",
                      border: "none",
                      borderRadius: "none",
                    }}
                    placeholder="Enter Address"
                  />
                </div>
              </div>

              <Typography
                style={{
                  fontSize: "17px",
                  marginLeft: "8.5%",
                  fontWeight: 500,
                }}
              >
                Gender <strong style={{ color: "red" }}>*</strong>
              </Typography>

              <div style={{ display: "flex", flexDirection: "row", marginLeft: "8.5%" }}>
                <RadioGroup style={{ marginTop: "2%" }}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="female" control={<Radio style={{ marginTop: "-10%" }} />} label="Female" />
                  <FormControlLabel value="male" control={<Radio style={{ marginTop: "-10%" }} />} label="Male" />
                  <FormControlLabel value="other" control={<Radio style={{ marginTop: "-10%" }} />} label="Other" />
                </RadioGroup>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}
            >
              <div style={{ marginLeft: "8.5%" }}>
                <Typography style={{ fontSize: "17px", fontWeight: 500 }}>
                  Matric <strong style={{ color: "red" }}>*</strong>
                </Typography>

                <OutlinedInput
                  type="number"
                  style={{
                    background: "#F5F5F5",
                    borderRadius: " 0.125rem",
                    height: "5vh",
                    width: "200px",
                    marginBottom: "11px",
                  }}
                  placeholder="Enter School Name"
                />
              </div>

              <div style={{ marginLeft: "6%" }}>
                <Typography style={{ fontSize: "17px", fontWeight: 500 }}>
                  Year Of Passing<strong style={{ color: "red" }}>*</strong>
                </Typography>

                <OutlinedInput
                  type="number"
                  style={{
                    background: "#F5F5F5",
                    borderRadius: " 0.125rem",
                    height: "5vh",
                    width: "200px",
                    marginBottom: "11px",
                  }}
                  placeholder="Enter Last Name"
                />
              </div>
              <div style={{ marginLeft: "6%" }}>
                <Typography style={{ fontSize: "17px", fontWeight: 500 }}>
                  Percentage<strong style={{ color: "red" }}>*</strong>
                </Typography>

                <OutlinedInput
                  type="number"
                  style={{
                    background: "#F5F5F5",
                    borderRadius: " 0.125rem",
                    height: "5vh",
                    width: "200px",
                    marginBottom: "11px",
                  }}
                  placeholder="Enter Percentage"
                />
              </div>
              <div>

                <DeleteIcon style={{ color: "red", marginTop: 30 }} />
              </div>
            </div>
            <Button
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              style={{
                marginLeft: "65%",
                width: "22.3%",
                backgroundColor: "#000066",
              }}
            >
              Add More Education
            </Button>

            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2%",
                paddingBottom: "3%",
              }}
            >
              <Button
                variant="outlined"
                onClick={this.closeModal}
                style={{
                  width: "15%",
                  color: "#0000B3",
                  borderColor: "#0000B3",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={this.closeModal}
                style={{
                  marginLeft: 10,
                  width: "15%",
                  backgroundColor: "#0000B3",
                }}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Dialog>

        <Dialog open={this.state.noticeviewmodaladmin} maxWidth="sm" fullWidth>
          <Grid style={{ padding: "10px" }}>
            <DialogTitle>
              <Grid container>
                <Grid item xs={11} >
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => {
                    this.setState({ noticeviewmodaladmin: false });
                  }}>
                    <CloseOutlinedIcon style={{ color: "black", display: "flex", justifyContent: "right" }} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} style={{ marginTop: "-25px" }}>
                  <Typography
                    style={{
                      fontSize: "32px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: 500,
                    }}
                  >
                    Notice
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ flexDirection: "column", padding: "10px 50px 75px 50px" }}>
              <Grid container direction={"column"}>
                <Typography style={{ fontSize: "18px" }}>
                  <strong>{this.state.course_name}-({this.state.batch_number})</strong>
                </Typography>
                <Typography style={{ fontSize: "16px", color: "var(--paragraph, #767278)" }}>
                  {this.state.desc}
                </Typography>
              </Grid>
            </DialogContent>
          </Grid>
        </Dialog>
      </Grid>
    );
  }
}
