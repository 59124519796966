import React, { Component } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import {
  Table,
  Card,
  Grid,
  MenuItem,
  Box,
  TableBody, DialogTitle, DialogContent, DialogActions
} from "@mui/material/";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import { TableContainer, Typography } from "@material-ui/core";
import "../styles/Student.css";
import TextField from "@mui/material/TextField";
import "../../Placement/styles/Placement.scss";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Camera from "../../Images/camera.png";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
import moment from "moment";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();

    return <Component navigate={navigate} {...props} />;
  };

  return Wrapper;
};

class Students extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentssadd: false,
      studentssedit: false,
      studentssdelete: false,
      page: 0,
      setPage: 0,
      rowsPerPage: 10,
      setRowsPerPage: "",
      anchorEl: null,
      open: false,
      profile_pic: "",
      name: "",
      phone: "",
      email: "",
      joining_date: "",
      course_id: "N",
      batch_id: "N",
      filter_course: "N",
      filter_batch: "N",
    };
    this.anchorRef = React.createRef();

  }

  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"
    this.props.viewAllStudent(this.state.page, this.state.rowsPerPage, this.props.login.org_id);
    this.props.view_all_courses(this.state.page, this.state.rowsPerPage, this.props.login.org_id);
    this.props.view_all_batch(this.state.page_number, this.state.rowsPerPage);
    this.props.viewBatchByCourse(this.props.courses.Course_Id, this.state.page, this.state.rowsPerPage);
    console.log("batch", this.props.courses.batch)
  }

  render() {
    const { student, snackbar, close_snack_bar, placement } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const handleChangePage = (event, newPage) => {
      this.props.viewAllStudent(newPage - 1, this.state.rowsPerPage, this.props.login.org_id);
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };

    return (
      <Grid style={{ marginTop: "10%" }}>
        <Card
          style={{ margin: "1% 2.5% 2.5% 0%" }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography style={{ color: "black", fontSize: "29px", margin: "1% 0% 0% 2%", fontWeight: 450 }}>
              All Students
            </Typography>
            <Paper
              component="form"
              style={{
                width: "436px",
                height: "36px",
                marginLeft: "auto",
                marginTop: "1%",
                backgroundColor: "#F3F3F3",
                borderRadius: "10px"
              }}
            >
              <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                style={{ ml: 5, flex: 1, fontSize: "15px" }}
                placeholder="Search "
                inputProps={{ "aria-label": "Search " }}
                onChange={(e) =>
                  this.props.searchStudent(student.all_students, e.target.value, "search"
                  )}
              />
            </Paper>
            <Button
              onClick={() => {
                this.setState({
                  studentssadd: true,
                });
              }} style={{
                borderRadius: "5px",
                border: "1px solid var(--secondary, #0000B3)",
                height: "2.25rem",
                margin: "1% 1.5% 0% 1%",
                color: "#0000B3",
              }}
              variant="outlined"
            >
              Add Student
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              flexdirection: "row",
              margin: "2.5% 0% 2.5% 0%"

            }}
          >

            <TextField
              style={{
                width: "155px",
                height: "36px",
                borderRadius: "5px",
                background: "#FFF",
                fontSize: "15px",
                marginLeft: "2%",
              }}
              id="outlined-select-currency-native"
              select
              value={this.state.filter_course}
              onChange={(e) => {
                this.setState({ filter_course: e.target.value });
                this.props.searchStudent(student.all_students, e.target.value, "course")
                this.props.view_all_batch_by_course(e.target.value)
              }}
            >
              <MenuItem value={"N"}>{"Select Course"}</MenuItem>
              {Array.isArray(this.props.courses.course) && this.props.courses.course.map((e) => (
                <MenuItem value={e._id}>{e.course_name}</MenuItem>
              ))}
            </TextField>

            <TextField
              style={{
                width: "155px",
                height: "36px",
                borderRadius: "5px",
                background: "#FFF",
                fontSize: "15px",
                marginLeft: "1%",
              }}
              id="outlined-select-currency-native"
              select
              value={this.state.filter_batch}
              onChange={(e) => {
                this.setState({ filter_batch: e.target.value });
                this.props.searchStudent(student.all_students, e.target.value, "batch")
              }}
            >
              <MenuItem value={"N"}>{"Select Batch"}</MenuItem>
              {Array.isArray(this.props.courses.batch) && this.props.courses.batch.map((e) => (
                <MenuItem value={e._id}>{e.batch_number}</MenuItem>
              ))}            
            </TextField>
             
          </div>

          <div>
            <TableContainer>
              <Table
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F8F7F7", }}>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      Name
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      Phone No
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      Email ID
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      Course Enrolled
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C" }}>
                      Batch
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: "#766C6C", width: "115px" }}>
                      Joining Date
                    </TableCell>
                  </TableRow>
                </TableHead>


                <TableBody>
                  {student.search_student?.length == "0" &&
                    <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                      <Typography>No Result Found</Typography>
                    </Grid>
                  }
                  {Array.isArray(student.search_student) && student.search_student?.sort((a, b) =>
                    (new Date(a.joining_date) > new Date(b.joining_date) ? -1 : 1))?.map((item, index) => {
                      return (
                        <TableRow style={{ textDecoration: "none", borderBottom: "1px solid #E3E3E3", cursor: "pointer" }}
                          onClick={() => {
                            this.props.navigate('/StudentDetails');
                            this.props.studentId(item._id);
                            this.props.studentImage(item.profile_pic);
                            this.props.studentname(item.name);
                            this.props.phone(item.phone);
                            this.props.email(item.email_id);
                          }}
                        >
                          <TableCell
                            style={{
                              textAlign: "center",
                              fontSize: "16px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <Avatar
                              alt={item.name} src={item.profile_pic}
                              style={{ width: 30, height: 30 }} >
                            </Avatar>
                            <Typography style={{ fontWeight: "600", color: "black", whiteSpace: "nowrap", marginLeft: "8%" }}>{item.name}</Typography>
                          </TableCell>
                          <TableCell
                            style={{ color: "#5F5A5A", fontSize: "15px", letterSpacing: 1 }}
                            align="center"
                          >
                            +{item.mobile}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#5F5A5A", fontSize: "15px", letterSpacing: 1
                            }}
                            align="center"
                          >
                            {item.email_id}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "black", fontSize: "15px", letterSpacing: 1
                            }}
                            align="center"
                          >
                            {item.course_name}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              fontSize: "16px",
                              cursor: "pointer",
                              color: "black", whiteSpace: "nowrap"
                            }}
                          >{item.batch_number}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              fontSize: "16px",
                              color: "#7A7E86",
                              cursor: "pointer",
                            }}
                          >{moment(item.joining_date).format('D-MM-YYYY')}
                          </TableCell>
                        </TableRow>
                      );
                    })}

                </TableBody>
              </Table>
            </TableContainer>

          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
            <Pagination
              count={parseInt(student.student_length / 10) + 1}
              siblingCount={0}
              size="small"
              onChange={handleChangePage}
            />
          </div>
        </Card>



        {/*------------------------------------------------ Student Dialog ---------------------------------------------------------*/}

        {/*Add*/}
        <Dialog open={this.state.studentssadd} maxWidth="sm" fullWidth
          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <Grid>
            <DialogTitle style={{ marginBottom: "1.5%" }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography style={{ fontSize: "22px", fontWeight: "500", color: "#222222", letterSpacing: 1 }}>
                    Add Student
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container >
                <Grid item xs={12} direction='column'>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                    <Avatar variant="soft"
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%"
                      }}
                    >
                      <center>
                        <input style={{ display: "none", cursor: "pointer" }} type="file" id="file"
                          accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"
                          onChange={(e) => {
                            this.setState({
                              profile_pic: e.target.files[0]
                            })
                          }}
                        />
                        <label htmlFor="file">
                          <img
                            src={this.state.profile_pic !== "" ? URL.createObjectURL(this.state.profile_pic) : Camera}
                            style={{
                              height: "60px",
                              width: "60px",
                              alignItems: "center",
                              borderRadius: "50%"
                            }}
                          />
                        </label>
                      </center>
                    </Avatar>
                    <span style={{ color: "var(--primery, #006)", fontSize: "0.85rem" }}>
                      {this.state.profile_pic !== "" ? (this.state.profile_pic).name : "Upload"}
                    </span>
                  </div>

                  <div>
                    <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Full Name</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter Name"
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        borderRadius: "5px",
                        backgroundColor: "rgba(239, 239, 239, 0.60)"
                      }}
                      onChange={(text) =>
                        this.setState({ name: text.target.value })}
                    >
                    </TextField>
                  </div>
                  <Grid container >
                    <Grid item xs={5.5} direction='column'>
                      <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600}}>Phone No</Typography>
                      <TextField
                      type ="number"
                        fullWidth
                        placeholder="Enter Phone No"
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)",
                          
                        }}

                        onChange={(text) =>
                          this.setState({ phone: text.target.value })}
                      >
                      </TextField>
                    </Grid>
                    <Grid item xs={1}> </Grid>
                    <Grid item xs={5.5} direction='column'>
                      <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Email Id</Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter Email ID"
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)"
                        }}
                        onChange={(text) =>
                          this.setState({ email: text.target.value })}
                      >
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container >
                    <Grid item xs={5.5} direction='column'>
                      <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Course</Typography>
                      <TextField
                        fullWidth
                        select
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)"
                        }}
                        value={this.state.course_id}
                        onChange={(e) => {
                          this.setState({ course_id: e.target.value });
                          this.props.view_all_batch_by_course(e.target.value)
                        }}
                      >
                        <MenuItem value={"N"}>{"Select Course"}</MenuItem>
                        {Array.isArray(this.props.courses.course) &&
                          this.props.courses.course.map((e) => (
                            <MenuItem value={e._id} >{e.course_name}</MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={1}> </Grid>
                    <Grid item xs={5.5} direction='column'>
                      <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Batch</Typography>
                      {/* <TextField
                        fullWidth
                        select
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)"
                        }}
                        value={this.state.batch_id}
                        onChange={(e) => {
                          this.setState({ batch_id: e.target.value });
                        }}
                      >
                        <MenuItem value={"N"}>{"Select Batch"}</MenuItem>
                        {Array.isArray(this.props.courses.batch) &&
                          this.props.courses.batch.map((e) => (
                            <MenuItem value={e._id} >{e.batch_number}</MenuItem>
                          ))}
                      </TextField> */}
                      <TextField
                    style={{ width: '253px', }}
                    id="outlined-select-currency-native"
                    select
                    value={this.state.batch_id}
                    onChange={(e) => {
                      this.setState({ batch_id: e.target.value });
                    }}
                  >
                    <MenuItem value={"N"}>{"All Batch"}</MenuItem>
                    {Array.isArray(this.props.courses.batch) && this.props.courses.batch!== undefined && this.props.courses.batch.map((item) => (
                      <MenuItem value={item._id}>{item.batch_number}</MenuItem>
                    ))}
                  </TextField>
                    </Grid>
                  </Grid>
                  <Grid container >
                    <Grid item xs={4} direction='column'>
                      <Typography style={{ margin: "5% 0% 0% 2%", fontWeight: 600 }}>Date</Typography>
                      <TextField
                        fullWidth
                        type="date"
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          borderRadius: "5px",
                          backgroundColor: "rgba(239, 239, 239, 0.60)"
                        }}
                        value={this.state.joining_date}
                        onChange={(e) => {
                          this.setState({ joining_date: e.target.value });
                        }}
                      >
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: "center", marginBottom: 8, marginTop: 8 }} >
              <Button
                variant="outlined"
                style={{
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "#0000B3",
                  border: "1px solid #0000B3",
                }}
                onClick={() => {
                  this.setState({ studentssadd: false });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  height: "38px",
                  width: "160px",
                  color: "white",
                  marginLeft: "2%",
                }}
                disabled={this.state.name ==="" || this.state.phone ==="" || this.state.email ==="" || this.state.joining_date ===""|| this.state.batch_id==="N" || this.state.batch_id === ""||this.state.course_id === "N"||this.state.course_id === ""}
                onClick={() => {
                  this.setState({ studentssadd: false, profile_pic: "", name: "", phone: "", email: "", course_id: "", batch_id: "", joining_date: "" })
                  this.props.uploadImageStudent(this.props.login.user_id, this.state.profile_pic, this.state.name, this.state.phone, this.state.email, this.state.course_id, this.state.batch_id, this.state.joining_date, this.state.page, this.state.rowsPerPage, this.props.login.org_id)
                }}
              >
                Add
              </Button>
            </DialogActions>
          </Grid>
        </Dialog >
        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </Grid >
    );
  }
}

export default withRouter(Students);
