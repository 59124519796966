import React, { Component } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "../styles/companyselect.scss";
import { Button, IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
export default class companyselect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      setPage: 0,
      rowsPerPage: 10,
      setRowsPerPage: "",
      company_id: "",
      companyselectdata: [
        {
          vacancy: "10",
          jobprofile: "Android App Devoloper",
          lastdate: "12-05-23",
          apply: "Apply",
        },
        {
          vacancy: "10",
          jobprofile: "Android App Devoloper",
          lastdate: "12-05-23",
          apply: "Apply",
        },
        {
          vacancy: "10",
          jobprofile: "Android App Devoloper",
          lastdate: "12-05-23",
          apply: "Apply",
        },
      ],
    };
  }

  componentDidMount() {
    console.log(this.props.placement.company_id)
    this.props.viewAllPlacement(this.props.placement.company_id, this.state.page, this.state.rowsPerPage, this.props.login.org_id);
  }

  render() {
    const { placements, snackbar, close_snack_bar } = this.props;
    const { placement, login } = this.props;


    const handleChangeComapny = (event, newPage) => {
      this.props.viewPlacementByStudent(newPage - 1, this.state.rowsPerPage)
    this.props.viewAllPlacement(this.props.placement.company_id, newPage - 1, this.state.rowsPerPage, this.props.login.org_id);

      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };


    return (
      <>
        <div className="navpath">
          <Link to="/Placementstudent">Placement Company</Link>
          <ChevronRightIcon />
          Company name
        </div>
        <div className="companySection">
          <div className="companySectionHeader">
            <h2>{this.props.placement.placement_comp_name}</h2>
            <Paper
              component="form"
              style={{
                display: "flex",
                marginLeft: "auto",
                width: "367px",
                height: "43px",
                backgroundColor: "#F3F3F3",
                boxShadow: "none",
                borderRadius: "0.625rem",
              }}
            >
              <IconButton
                type="button"
                style={{ p: "15px" }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                style={{ ml: 5, flex: 1, fontSize: "15px" }}
                placeholder="Search "
                inputProps={{ "aria-label": "Search " }}

                onChange={(e) => this.props.searchJobProfile(placement.all_placements, e.target.value)}

              />
            </Paper>
          </div>
          <table className="companyTablecontent">
            <thead>
              <tr>
                <th>No</th>
                <th>Vacancy</th>
                <th>Job Profile</th>
                <th>Last date</th>
                <th>Apply</th>
              </tr>
            </thead>
            {Array.isArray(placement.search_placement) && placement.search_placement.map((item, index) => {
              console.log("dfghjghhhhhhhhhh",item)
              return (
                <tbody>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.no_vacancy}</td>
                    <td>{item.job_profile}</td>
                    <td>{item.last_date || "N/A"}</td>
                    <td>
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/Jobprofileselect"
                      >
                        {/* <Button
                      variant="contained"
                      style={{
                        marginLeft: 10,
                        width: "44%",
                        backgroundColor: "#0000B3",
                      }}
                   
                    >
                     Apply
                    </Button> */}

                        <Button variant="contained" style={{ marginLeft: 10, width: "44%", backgroundColor: "#0000B3" }}


                          onClick={() => {
                            this.props.viewAppliedStatusByStudent(this.props.login.user_id, item._id);
                            this.props.setPlacementId(item._id)
                            this.props.setPerticularPlacemenetApplyLink(item.apply_link);
                            this.props.setLastDate(item.last_date);
                            this.props.setDescription(item.description);
                            // this.props.setLastDate(item._id);

                          }}
                        >
                          Apply
                        </Button>


                      </Link>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>

          <div
            className="page-button"
            style={{ display: "flex", marginBottom: 20 }}
          >
            <Pagination
              count={parseInt(this.props.placement.studentplacement_length / 10) + 1}
              size="small"
              style={{ marginLeft: "85%", marginTop: "3%" }}
              onChange={handleChangeComapny}
            />
          </div>
          <LoaderCon />
          {/* <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          /> */}
        </div>
      </>
    );
  }
}
