//student
export const STUDENTS="STUDENTS";
export const SEARCH_STUDENT="SEARCH_STUDENT";
export const SET_STUDENT_ID="SET_STUDENT_ID";
export const SET_STUDENT_IMAGE="SET_STUDENT_IMAGE";
export const SET_STUDENT_NAME="SET_STUDENT_NAME";
export const SET_PHONE="SET_PHONE";
export const SET_EMAIL="SET_EMAIL";

//studentview
export const STUDENT_DETAIL="STUDENT_DETAIL";
export const STUDENT_COURSES="STUDENT_COURSES";
export const STUDENT_ASSIGNMENT="STUDENT_ASSIGNMENT";
export const STUDENT_PLACEMENT="STUDENT_PLACEMENT";

