import React, { Component } from 'react';
import Dialog from "@mui/material/Dialog";
import { Typography } from '@mui/material';
import { Grid, TextField, Box, Button, CardContent, Avatar } from "@mui/material";
import Logo from "../../Images/technoboot.png";
import Checkbox from '@mui/material/Checkbox';
import Google from "../../Images/google.png";
import { Navigate } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneInput from 'react-phone-input-2';

import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
// import EmailOutlinedIcon from "../pages/Images/emailIcon.png";
// import SmartphoneOutlinedIcon from "../pages/Images/mobileIcon.png";
import "../../Login/Component/Loginpage.css";




class Loginpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            otp: "",
            change_num: false,
            enter_num: false,
            TermCondition: false,
            isHidden: true,
            email: false,
            isHidden2: true,
            number: false,

            email: "",
            checked: false
        };
        this.handleCheck = this.handleCheck.bind(this);


    }
    toggle = () => {
        this.setState((prevState) => ({
            email: !prevState.email,
        }));
    }
    toggleElement = () => {
        this.setState((prevState) => ({
            isHidden: !prevState.isHidden,
        }));
    };

    toggle2 = () => {
        this.setState((prevState) => ({
            number: !prevState.number,
        }));
    }
    toggleElement2 = () => {
        this.setState((prevState) => ({
            isHidden2: !prevState.isHidden2,
        }));
    };


    resetTimer() {
        clearInterval(this.timer);
        this.setState({
            seconds: 30,
            time: this.secondsToTime(30),
        });
        this.timer = setInterval(this.countDown, 1000)
    }



    handleOnChange = (value, data, event, formattedValue, otp) => {
        this.setState({
            phone: value,
        });
        this.setState({ otp });
    }

    handlenumChange = (value) => {
        this.setState({ phone: value });
    }

    handleChange = (otp) => {
        this.setState({ otp });
    }



    handleRestart = () => {
        // Reset the state to the initial values
        this.setState({
            phone: "",
            otp: "",
            change_num: false,
            enter_num: false,
            bt_type: true,
            time: {},
            seconds: 30,
            phoneNumberDisabled: false,
            enterOtpDisabled: true,
            error: null,
        });
    }

    handleVerifyOTP = () => {
        console.log("handleVerifyOTP called");
        // Dispatch your Redux action to verify OTP here
        this.props.verify_otp(this.state.phone, this.state.otp);
    }


    handleCheck(event) {
        this.setState({ checked: event.target.checked });
    }

    render() {
        // const { login } = this.props;
        // if (login.user_id !== "") {
        //     return <Navigate to="/" />;
        //   }
        const { email } = this.state;
        const { number } = this.state;
        const { checked } = this.state;

        return (
            <div style={{ background: "#FAF9F6", display: "flex", justifyContent: "center", alignItems: 'center', height: "100vh" }}>



                <Grid style={{ background: "white", boxShadow: "0px 2px 5px 1px #0000001A", width: "500px", marginBottom: "0%", padding: "26px" }}>

                    <Grid >
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
                            <img src={Logo} style={{ marginTop: "2%", height: "70px", width: "50%" }} />
                        </Box>


                        <Box
                            onClick={() => {
                                this.setState({
                                    number: false,
                                    email: true
                                })
                            }}
                            style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", justifyContent: "center", marginTop: "6%" }}>
                            <Button variant='outlined' style={{ boxShadow: "0px 2px 5px 1px #0000001A", cursor: "pointer", gap: "30px", width: "70%", paddingLeft: "10px", paddingRight: "10px", borderRadius: "50px", color: "black", fontSize: "17px" }} >
                                {/* <EmailOutlinedIcon style={{fill:"red"}} /> */}
                                <EmailIcon style={{ fill: "#BA0021" }} />
                                Continue with Email
                            </Button>
                        </Box>

                        <Box style={{ display: email ? "block" : "none" }}>

                            <Box style={{ width: "100%", display: "flex", marginTop: "3%", flexDirection: "column", gap: "10px", alignItems: "center", justifyContent: "center" }}>
                                {this.state.isHidden ?
                                    <>
                                        <Box style={{ width: "100%", marginLeft: "139px", }}>
                                            <Typography style={{ fontSize: "14px" }}>Enter Email</Typography>
                                            <TextField variant='outlined' placeholder='Email Id' style={{ width: "70%", borderRadius: "100px" }}
                                                onChange={(e) => { this.setState({ email: e.target.value }) }}
                                            >
                                            </TextField>
                                            <Button variant='contained' style={{ marginTop: "3%", background: "#ED7014", width: "70%", borderRadius: "50px" }}
                                                disabled={this.state.email === ""}
                                                onClick={() => {
                                                    this.toggleElement()
                                                    this.props.email_login(this.state.email)
                                                }}
                                            >Continue</Button>
                                        </Box>
                                        {/* <Typography>or</Typography> */}
                                    </>
                                    :
                                    <>
                                        <Box style={{ marginLeft: "139px", width: "100%" }}>
                                            <Typography style={{ fontSize: "14px" }}>Enter OTP</Typography>

                                            <TextField variant='outlined' placeholder='OTP' style={{ width: "70%", borderRadius: "50px" }}
                                                onChange={(e) => { this.setState({ otp: e.target.value }) }}
                                            >

                                            </TextField>
                                            <Typography style={{ color: "blue", fontSize: "12px", cursor: "pointer" }}>Resend otp ?  <AccessTimeIcon style={{ height: "12px" }} /></Typography>

                                            <Button variant='contained' style={{ marginTop: "3%", background: "#ED7014", width: "70%", borderRadius: "50px" }}
                                                disabled={this.state.otp === ""}

                                                onClick={() => {
                                                    this.toggleElement()
                                                    this.props.verify_Email_Otp(this.state.email, this.state.otp)
                                                }}


                                            >Continue</Button>
                                        </Box>
                                    </>
                                }
                                {/* <Button variant='contained' style={{ background: "#ED7014", width: "70%", borderRadius: "50px" }}
        onClick={() => {
            this.setState({ TermCondition: true });
            this.toggleElement()
        }}
   

    >Continue</Button> */}

                            </Box>

                        </Box>



                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1%" }}>
                            <Button variant='outlined' style={{ boxShadow: "0px 2px 5px 1px #0000001A", cursor: "pointer", gap: "20px", width: "70%", paddingLeft: "10px", marginTop: "6%", paddingRight: "10px", borderRadius: "50px", color: "black", fontSize: "17px" }}
                                onClick={() => {
                                    this.props.googleLogin();
                                    this.setState({
                                        number: false,
                                        email: false
                                    })
                                }}
                            >
                                <img src={Google} style={{ height: "30px" }} />
                                Continue with Google
                            </Button>
                        </Box>


                        <Box onClick={() => {
                            this.setState({
                                email: false,
                                number: true
                            })
                        }} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "6%" }}>
                            <Button variant='outlined' style={{ boxShadow: "0px 2px 5px 1px #0000001A", cursor: "pointer", gap: "15px", width: "70%", paddingLeft: "10px", paddingRight: "10px", borderRadius: "50px", color: "black", fontSize: "17px" }} >
                                {/* <img src={Google} style={{height:"30px"}}/> */}
                                <SmartphoneOutlinedIcon style={{ marginLeft: "5px" }} />
                                Continue with Mobile
                            </Button>
                        </Box>

                        <Box style={{ display: number ? "block" : "none" }}>
                            <Box style={{ width: "100%", display: "flex", marginTop: "3%", flexDirection: "column", gap: "10px", alignItems: "center", justifyContent: "center" }}>
                                {this.state.isHidden2 ?
                                    <>
                                        <Box style={{ width: "100%", marginLeft: "139px", }}>

                                            <Box style={{ marginRight: "200px" }}>
                                                <PhoneInput
                                                    country={'in'}
                                                    onChange={this.handlenumChange}
                                                    disabled={this.state.change_num}
                                                    style={{ width: "125%", marginLeft: "90px", marginTop: "3%", borderRadius: "10px" }}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', }}
                                                />
                                            </Box>
                                            {/* <Typography style={{ fontSize: "14px" }}>Enter Number</Typography> */}
                                            {/* <TextField variant='outlined' placeholder='Email Id' style={{ width: "70%", borderRadius: "100px" }}></TextField> */}
                                            <Button variant='contained' style={{ marginTop: "3%", background: "#ED7014", width: "70%", borderRadius: "50px" }}
                                                disabled={this.state.phone === ""}

                                                onClick={() => {
                                                    this.toggleElement2()
                                                    this.props.phone_login(this.state.phone)

                                                }}


                                            >Continue</Button>


                                        </Box>
                                    </>
                                    :
                                    <>
                                        <Box style={{ marginLeft: "139px", width: "100%" }}>
                                            <Typography style={{ fontSize: "14px" }}>Enter OTP</Typography>

                                            <TextField
                                                value={this.state.otp}
                                                onChange={(e) => {
                                                    this.handleChange(e.target.value)
                                                    console.log("status", this.props.login.login_status)
                                                }}
                                                disabled={this.state.enterOtpDisabled}
                                                variant='outlined' placeholder='OTP' style={{ width: "70%", borderRadius: "50px" }}></TextField>

                                            <Box style={{ display: "flex", justifyContent: "space-around", marginRight: "120px" }}>
                                                <Typography style={{ fontSize: "12px", marginTop: "10px", fontWeight: 500 }}>
                                                    Didn’t get OTP
                                                    <Button
                                                        disabled={this.state.otp === ""}
                                                        onClick={(e) => {
                                                            this.resetTimer();
                                                            this.setState({
                                                                change_num: !this.state.change_num,
                                                                enter_num: !this.state.enter_num,
                                                                bt_type: !this.state.bt_type,
                                                            })
                                                        }}
                                                    >
                                                        <strong style={{ color: "#7900FF", fontSize: '12px' }}>Change Number</strong>
                                                    </Button>
                                                </Typography>
                                                <Button style={{ paddingTop: "10px" }}
                                                // onClick={() => {
                                                //     this.resetTimer();
                                                //     this.props.phone_login(this.state.phone)
                                                // }
                                                // } disabled={this.state.time.s !== 0} 
                                                >
                                                    <strong style={{ color: "rgba(173, 49, 19, 1)", fontSize: "12px" }}>Resend OTP <AccessTimeIcon style={{ height: "12px" }} /> <span>
                                                        {/* {this.state.time.s} */}
                                                    </span></strong>
                                                </Button>

                                            </Box>

                                            <Box>
                                                <Button variant='contained' style={{ marginTop: "3%", background: "#ED7014", width: "70%", borderRadius: "50px" }}
                                                    onClick={() => {
                                                        this.toggleElement2()
                                                        this.props.verify_otp(this.state.phone, this.state.otp)
                                                    }}

                                                >
                                                    Continue</Button>
                                            </Box>
                                        </Box>
                                    </>
                                }


                            </Box>

                        </Box>




                    </Grid>
                    <hr style={{ marginTop: "70px", marginBottom: "20px" }}></hr>

                    <Grid style={{ textAlign: "center" }}>
                        <img src={Logo} style={{ marginTop: "2%", marginBottom: "10px", height: "40px", width: "30%" }} />
                        {/* , filter: "grayscale(100%)"  */}

                        <Typography style={{ width: "60%", marginLeft: "70px", fontSize: "13px", padding: "20px", alignItems: "center" }}>
                            By signing up you agree to <span
                                onClick={(e) => {
                                    this.setState({
                                        TermCondition: true,
                                    })
                                }} style={{ color: "blue", cursor: "pointer" }} >our Terms of service </span> and acknowledge that our  <br /> <span style={{ color: "blue", cursor: "pointer" }}>Privacy Policy</span>  applies to you.</Typography>
                    </Grid>


                </Grid>













                {/* terms and condition dialog box here */}
                <Dialog
                    open={this.state.TermCondition} style={{}}>
                    <Grid style={{ height: "auto", width: "490px", padding: "26px", marginBottom: "10px", cursor: "pointer" }}>
                        <Typography style={{ fontSize: "30px", color: "#ED7014", marginBottom: "6%" }}>Terms And Condition</Typography>
                        <Box style={{ marginTop: "4%", overflowY: "scroll", height: "400px" }} className="scroll-hid">
                            <Typography style={{ marginTop: "4%" }}>
                                Help protect your website and its users with clear and fair website terms and conditions. These terms and conditions for a website set out key issues such as acceptable use, privacy, cookies, registration and passwords, intellectual property, links to other sites, termination and disclaimers of responsibility. Terms and conditions are used and necessary to protect a website owner from liability of a user relying on the information or the goods provided from the site then suffering a loss.
                            </Typography>
                            <Typography style={{ marginTop: "5%" }}>
                                Making your own terms and conditions for your website is hard, not impossible, to do. It can take a few hours to few days for a person with no legal background to make. But worry no more; we are here to help you out.
                            </Typography>
                            All you need to do is fill up the blank spaces and then you will receive an email with your personalized terms and conditions.
                            <Typography style={{ marginTop: "4%" }}>
                                Looking for a Privacy Policy? Check out Privacy Policy Generator.
                            </Typography>
                            <Typography style={{ marginTop: "4%" }}>
                                The accuracy of the generated document on this website is not legally binding. Use at your own risk.
                            </Typography>
                        </Box>

                        <Box style={{ marginTop: "5%" }}>
                            {/* <Button variant='outlined' style={{ color: "#ED7014", border: "1px solid #ED7014" }}>
                                More
                            </Button> */}
                            <Button variant="contained" style={{ background: "#ED7014", float: "right" }}
                                onClick={() => {
                                    this.setState({ TermCondition: false })
                                }}
                            >
                                I Agree
                            </Button>
                        </Box>
                    </Grid>
                </Dialog>



            </div >
        );
    }
}

export default Loginpage;