import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "../styles/studentcourse.css";
import { Link } from "react-router-dom";
import { Grid, Paper } from "@mui/material";
import { Flex } from "theme-ui";

import { styled, alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default class Studentcourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
      ],

      addcourse: false,
    };
  }
  componentDidMount() {
    this.props.view_course_by_student(this.props.login.user_id, 0, 10)
  }

  render() {
    const { tickets, snackbar, close_snack_bar } = this.props;
    return (
      <Grid style={{ marginTop: "8%" }}>
        <Typography style={{ color: "black", fontSize: "29px", margin: "1% 0% 0% 0%", fontWeight: 450 }}>
          Courses
        </Typography>
        {/*category  */}
        <Grid
          container
          display={"flex"}
          direction="row"
          spacing={2}
          mt={2}
        >
          {Array.isArray(this.props.courses.student_course) && this.props.courses.student_course.map((item, index) => {
            return (
              <Grid item xs={3}>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/StudentcourseDetails"
                  onClick={() => {
                    this.props.setImage(item.course_details.image);
                    this.props.setCoursename(item.course_details.course_name);
                    this.props.setDuration(item.course_details.duration);
                    this.props.setCourseCode(item.course_details.course_code);
                    this.props.setDESC(item.course_details.desc);
                    this.props.setCourseId(item.course_details._id);
                    this.props.setBatchId(item.batch_details._id);
                  }}
                >

                  <Card sx={{ height: 260, borderRadius: 4 }}>

                    <CardMedia
                      component="img"
                      alt="green iguana"
                      height="150"
                      image={item.course_details.image}
                      style={{ borderRadius: 10 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {item.course_details.course_name}
                      </Typography>
                      Duration:
                      <Button><Typography fontSize={16} fontWeight={500} style={{ color: "blue" }}>{item.course_details.duration}</Typography></Button>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            );
          })}
        </Grid>
        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </Grid>

    );
  }
}
