import React, { Component } from 'react'
import "../styles/stuPlacement.scss"
import {
    Button, IconButton, InputBase, Paper, MenuItem, TableHead,
    TableCell,
    TableBody,
    TableRow, TableContainer,
    Table,
} from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import TextField from "@mui/material/TextField";

import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
export default class Placementstudent extends Component {
    constructor(props) {
        super(props);
        this.state = {

            selectedTab: 'Placement',
            page: 0,
            setPage: 0,
            rowsPerPage: 10,
            setRowsPerPage: "",
            job_Profile: "N",
            placestddata: [
                {
                    companyname: "ABCD Company",
                    companyemail: "abcd@abcd.com",
                    companywebsite: "ABCD.com",
                },
                {
                    companyname: "ABCD Company",
                    companyemail: "abcd@abcd.com",
                    companywebsite: "ABCD.com",
                },
                {
                    companyname: "ABCD Company",
                    companyemail: "abcd@abcd.com",
                    companywebsite: "ABCD.com",
                },
            ],
            appliedstddata: [
                {
                    companyname1: "ABCD Company",
                    profile1: "UI/UX Design Internship",
                    appliedon: "20 Feb",
                    applicationstatus: "Not Joined",
                },
                {
                    companyname1: "ABCD Company",
                    profile1: "UI/UX Design Internship",
                    appliedon1: "20 Feb",
                    applicationstatus1: "Not Selected",
                },
                {
                    companyname1: "ABCD Company",
                    profile1: "UI/UX Design Internship",
                    appliedon1: "20 Feb",
                    applicationstatus1: "Hired",
                },
                {
                    companyname1: "ABCD Company",
                    profile1: "UI/UX Design Internship",
                    appliedon1: "20 Feb",
                    applicationstatus1: "Applied",
                },
            ]

        }
    }
    handleTabClick = (tabName) => {
        this.setState({ selectedTab: tabName });
    };


    componentDidMount() {

        this.props.viewAllCompany(this.state.page, this.state.rowsPerPage);
        this.props.viewAppliedByStudent(this.props.login.user_id, this.state.page, this.state.rowsPerPage);

    }

    render() {
        const { snackbar, close_snack_bar } = this.props;


        const handleChangePlacementStudent = (event, newPage) => {
            this.props.viewAllCompany(newPage - 1, this.state.rowsPerPage)
            console.log(newPage)
            this.setState({
                page: newPage,
            });
        };

        const { selectedTab } = this.state;
        const { placement, login } = this.props;
        return (
            <div className="Placementstudent">
                <div className="tabs-container">
                    <div
                        className={`tab ${selectedTab === 'Placement' ? 'active' : ''}`}
                        onClick={() => this.handleTabClick('Placement')}
                    >
                        Placement
                    </div>
                    <div
                        className={`tab ${selectedTab === 'Applied' ? 'active' : ''}`}
                        onClick={() => this.handleTabClick('Applied')}
                    >
                        Applied
                    </div>



                </div>
                {/* Add content for the selected tab */}
                <div>
                    {selectedTab === 'Placement' && <div>
                        <div style={{ display: "flex" }}>

                            {/* <div className="selectBox">
                                <select name="" id="">
                                    <option value="Select Company">Select Company</option>
                                    <option value="Company1">
                                        <Link to="/Stuselectcompany">

                                            Company1
                                        </Link>
                                    </option>
                                    <option value="Company1">Company1</option>
                                </select>
                                <select name="" id="">
                                    <option value="Select Job Profile">Select Job Profile</option>
                                    <option value="Company1">Job profile1</option>
                                    <option value="Company1">Job profile 2</option>
                                </select>
                            </div> */}
                            <Paper
                                component="form"
                                style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "#F8F7F7", boxShadow: "none", marginTop: 12, marginBottom: "1%" }}
                            >
                                <IconButton

                                    type="button"
                                    style={{ p: "15px" }}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    style={{ ml: 5, flex: 1, fontSize: "15px" }}
                                    placeholder="Search "
                                    inputProps={{ "aria-label": "Search " }}
                                    onChange={(e) => this.props.searchCompany(placement.all_companys, e.target.value)}
                                />
                            </Paper>
                        </div>

                        <table className="studentviewTablecontent"  >
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Comapny Name</th>
                                    <th>Company Email</th>
                                    <th>Company Website</th>


                                </tr>
                            </thead>
                            {Array.isArray(placement.search_company) && placement.search_company.map((item, index) => {
                                return (
                                    <tbody>

                                        <Link to={"/companyselect"} style={{ textDecoration: "none", color: "#000" }} className="table-row"
                                            onClick={() => {

                                                this.props.setPlacementCompanyName(item.company_name);
                                                this.props.setPlacementCompanyEmail(item.company_email);
                                                this.props.setCompanyId(item._id);
                                                console.log("pritiii")
                                            }}
                                        >

                                            <td>{index + 1}</td>
                                            <td> {item.company_name}</td>
                                            <td>  {item.company_email}</td>
                                            <td>{item.company_website}</td>


                                        </Link>


                                    </tbody>
                                );
                            })}



                        </table>



                        <div className='page-button' style={{ display: "flex" }}>



                            <Pagination
                                count={parseInt(this.props.placement.company_length / 10) + 1}
                                size="small"
                                style={{ marginLeft: "85%", marginTop: "3%" }}
                                onChange={handleChangePlacementStudent}
                            />

                        </div>


                    </div>}
                    {selectedTab === 'Applied' && <div>

                        <div style={{ display: "flex" }}>

                            <div className="selectBox">
                                {/* <select name="" id="">
                                    <option value="Select Company">Select Company</option>
                                    <option value="Company1">
                                        <Link to="/Stuselectcompany">

                                            Company1
                                        </Link>
                                    </option>
                                    <option value="Company1">Company1</option>
                                </select> */}
                                <TextField
                                    style={{
                                        width: "155px",
                                        height: "36px",
                                        borderRadius: "5px",
                                        background: "#FFF",
                                        fontSize: "15px",
                                        marginLeft: "2%",
                                    }}
                                    id="outlined-select-currency-native"
                                    select
                                    value={this.state.job_Profile}
                                    onChange={(e) => {
                                        this.setState({ job_Profile: e.target.value });
                                        this.props.searchApplied(placement.applied_by_student, e.target.value, "course")
                                    }}
                                >
                                    <MenuItem value={"N"}>{"job Profile"}</MenuItem>
                                    {Array.isArray(this.props.placement.applied_by_student) && this.props.placement.applied_by_student.map((e) => (
                                        <MenuItem value={e._id}>{e.placement_details.job_profile}</MenuItem>
                                    ))}
                                </TextField>

                            </div>
                            <Paper
                                component="form"
                                style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "#F8F7F7", boxShadow: "none", marginTop: 12 }}
                            >
                                <IconButton

                                    type="button"
                                    style={{ p: "15px" }}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    style={{ ml: 5, flex: 1, fontSize: "15px" }}
                                    placeholder="Search "
                                    inputProps={{ "aria-label": "Search " }}
                                    // onChange={(e) => this.props.searchApplied(placement.applied_by_student, e.target.value)}
                                    onChange={(e) =>
                                        this.props.searchApplied(placement.applied_by_student, e.target.value, "search"
                                        )}
                                />
                            </Paper>
                        </div>
                        <table className="studentviewTablecontent" >
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Comapny Name</th>
                                    <th>Profile</th>
                                    <th>Applied On</th>
                                    <th>Application Status</th>
                                </tr>
                            </thead>

                            {Array.isArray(placement.search_applied) && placement.search_applied.map((item, index) => {
                                return (
                                    <tbody>
                                        <td>{index + 1}</td>
                                        <td>{item.company_details.company_name}</td>
                                        <td>{item.placement_details.job_profile}</td>
                                        <td>{item.placement_details.date}</td>
                                        <td>
                                            <Button style={{
                                                backgroundColor: item.status === 'Applied' ? 'blue' : item.status === 'Hired' ? '#62BC37' : item.status === ' Not Joined' ? '#788931' : 'red',
                                                border: item.status === 'Applied' ? 'blue' : item.status === 'Hired' ? '#62BC37' : item.status === ' Not Joined' ? '#788931' : 'red',
                                                color: "white",
                                                marginTop: "2%"
                                            }}
                                                variant="outlined" >

                                                {item.status}
                                            </Button>
                                        </td>






                                    </tbody>
                                );
                            })}


                            {/* {Array.isArray(placement.applied_by_student) && placement.applied_by_student.map((item,index)=>{
                            return(
                            <tbody>
                                 <tr>
                                    <td>{index+1}</td>
                                    <td>ABCD Company</td>
                                    <td>Front-end Internship</td>
                                    <td>20 Feb'23</td>
                                    <td>
                                        <Button variant="outlined" style={{ width: "49%", border: " 1px solid red", color: 'red' }}>
                                            Not Selected
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>ABCD Company</td>
                                    <td>Front-end Internship</td>
                                    <td>20 Feb'23</td>
                                    <td>
                                        <Button variant="outlined" style={{ width: "49%", border: " 1px solid red", color: 'red' }}>
                                            Not Selected
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>PQSR Company</td>
                                    <td>FullStack development</td>
                                    <td>5 jun'23</td>
                                    <td>
                                        <Button variant="outlined" style={{ width: "49%", border: " 1px solid #62BC37", color: '#62BC37' }}>
                                            Hired
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>PQSR Company</td>
                                    <td>FullStack development</td>
                                    <td>5 jun'23</td>
                                    <td>
                                        <Button variant="outlined" style={{ width: "49%", border: " 1px solid blue", color: 'blue' }}>
                                            Applied
                                        </Button>
                                    </td>
                                </tr>

                            </tbody>
                            );
                            })} */}

                        </table>
                        <div className='page-button' style={{ display: "flex" }}>


                            <Pagination
                                count={parseInt(this.props.placement.company_length / 10) + 1}
                                size="small"
                                style={{ marginLeft: "85%", marginTop: "3%" }}
                                onChange={handleChangePlacementStudent}
                            />

                        </div>






                    </div>}
                </div>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
            </div>
        )
    }
}
