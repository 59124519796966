import React, { Component } from "react";
import { connect } from "react-redux";
import Placement from "../Component/Placement";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    addPlacement,
    viewAllPlacement,
    editPlacement,
    deletePlacement,
    setApplyLink,
    setLastDate,
    setDescription,
    setPlacementId,
    setJobProfile
} from "../action"

// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <Placement {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        placement: store.placement,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {
        addPlacement: (admin_id, company_id, department, job_profile, last_date, description, apply_link, no_vacancy, page_number, rowper_page,org_id) => {
            dispatch(addPlacement(admin_id, company_id, department, job_profile, last_date, description, apply_link, no_vacancy, page_number, rowper_page,org_id));
        },
        viewAllPlacement: (company_id,page_number, rowper_page,org_id) => {
            dispatch(viewAllPlacement(company_id,page_number, rowper_page,org_id));
        },
        editPlacement: (placement_id, company_id, department, job_profile, last_date, description, apply_link, no_vacancy, page_number, rowper_page,org_id) => {
            dispatch(editPlacement(placement_id, company_id, department, job_profile, last_date, description, apply_link, no_vacancy, page_number, rowper_page,org_id));
        },
        deletePlacement: (admin_id, placement_id, page_number, rowper_page,company_id) => {
            dispatch(deletePlacement(admin_id, placement_id, page_number, rowper_page,company_id));
        },
        setApplyLink: (payload) => {
            dispatch(setApplyLink(payload))
        },
        setLastDate: (payload) => {
            dispatch(setLastDate(payload))
        },
        setDescription: (payload) => {
            dispatch(setDescription(payload))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },

        setPlacementId: (payload) => {
            dispatch(setPlacementId(payload));
        },

        setJobProfile: (payload) => {
            dispatch(setJobProfile(payload));
        },



    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);