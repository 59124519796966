import React, { Component } from 'react';
import "../styles/assignmentDetails.css";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Divider, Grid, Card, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Calender from "../../Images/Vector (1).png";
import CloseIcon from '@mui/icons-material/Close';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import moment from "moment";
import {
  cardContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Box,
  OutlinedInput
} from "@mui/material";



export default class assignmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatestatus: false,
      update_score: false,
      score: "",
      attachment:"",
      astatus:"Approved",
      status:"Reattempt",
    };
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"
    this.props.view_submit_by_assignment(this.props.courses.assignment_id)
    // console.log("ee", this.props.courses.submit_assign)
  }


  render() {
    const { tickets, snackbar, close_snack_bar } = this.props;
    const { courses, login, auth, assignment } = this.props
    return (

      <Grid>
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "80px" }}
        >
          <Link to="/course">
            <Button style={{ color: "#7A7E86" }}>Courses</Button>
          </Link>
          <ArrowForwardIosIcon
            style={{ marginTop: "0.8%", color: "#7A7E86", fontSize: "17px" }}
          />
          <Link to="/courseDetails">
            <Button style={{ marginLeft: "0.5%", color: "#7A7E86", marginTop: "0.5%" }}>
              Batches
            </Button>
          </Link>
          <ArrowForwardIosIcon
            style={{ marginTop: "0.8%", fontSize: "17px" }}
          />
          <Link to={this.props.login.type === "T" ? "/trainercourseclasses" : "/Batch"}>
            <Button style={{ marginLeft: "0.5%", color: "#7A7E86", marginTop: "0.5%" }}>
              Assignment
            </Button>
          </Link>
          <ArrowForwardIosIcon
            style={{ marginTop: "0.8%", fontSize: "17px" }}
          />
          <Typography style={{ marginLeft: "0.5%", marginTop: "0.5%" }}>
            View Assignment
          </Typography>
        </div>




        <Card style={{ margin: "1% 2.5% 2.5% 0%" }}>
          <div className="pgprogram-section-nn">
            <div style={{ display: "flex", justifyContent: "space-between" }} >
              <div className="pg-name-section-nn">
                <div className="pg-dot-nn"></div>
                <div className="pg-name-nn"> {this.props.courses.course_name}</div>
              </div>
              <div style={{ fontWeight: 500, fontSize: "18px" }}>
                {this.props.courses.batch_Name}
              </div>
            </div>

            <div className="pg-assignment-nn"><strong>Assignment : {this.props.courses.trainer_title_in_assignment}</strong>  </div>
            <div className="pg-assigned-img-nn">
              <span><strong>Assigned by : </strong></span>
              <img src={this.props.courses.trainer_profile_in_assignment}></img>
              <span><strong>{this.props.courses.trainer_name_in_assignment}</strong></span>
            </div>

            <div className="pg-cal-icon-section-nn">
              <div className='pg-cal-nn'>
                <div className='pgh-1'><CalendarMonthOutlinedIcon /></div>
                <div style={{ color: "#0000B3", fontSize: "0.9rem" }}>Due for {this.props.courses.trainer_duedate_in_assignment}</div>
              </div>
              <div className='pg-icon-nn '>
                <div className='pgh-2'><PictureAsPdfIcon /></div>
                <div><strong style={{ color: "#3679DC" }}><a href={this.props.courses.attachment}>Attchment</a></strong>
                </div>
              </div>
            </div>
          </div>
          <div className="pgprogram-table-setion-nn">
            <div>
              <Table>
                <TableHead style={{ backgroundColor: "#F8F7F7" }}>
                  <TableRow>
                    <TableCell >Student Name</TableCell>
                    <TableCell >Assignment</TableCell>
                    <TableCell >Due Date</TableCell>
                    <TableCell >Submitted</TableCell>
                    <TableCell >Status</TableCell>
                    <TableCell >Score</TableCell>
                  </TableRow>
                </TableHead>
                {Array.isArray(this.props.assignment.submit_assign) && this.props.assignment?.submit_assign?.map((item, index) => {
                  // console.log("Assigementsssss",item)
                  return (
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          <div className="table-cell-assignment">
                            <div>
                              <img src={item.student_details?.profile_pic}></img>
                            </div>
                            <div>
                              <div>
                                <strong>{item.student_details?.name}</strong>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <strong>{this.props.courses.trainer_title_in_assignment}</strong>
                        </TableCell>
                        <TableCell align="left">{this.props.courses.trainer_duedate_in_assignment}</TableCell>
                        <TableCell align="left">
                          <div className="table-cell-assignment">
                            <div id="table-cell-notes-icon">
                              <PictureAsPdfIcon />
                            </div>
                            <div id="table-cell-notes-text">
                              <a href={item.attachment}><strong>attachment</strong></a>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div id="status-show-nn" style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              this.setState({
                                submit_id: item._id,
                                student_id: item.student_details._id,
                                assignment_id: item.assignment_id,
                                score: item.score,
                                attachment:item.attachment

                                // tatus: item.status

                              })

                              this.setState({ update_score: true });
                            }}
                          > {item.status}</div>
                        </TableCell>
                        <TableCell >
                          {item.score}
                        </TableCell>
                      </TableRow>

                    </TableBody>
                  )
                })}
              </Table>
            </div>
          </div>
        </Card>
        <Dialog open={this.state.update_score}

          sx={{
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "10px",
              width: "550px",
              height: "500px",
              align: "center"

            },
          }}>
          <DialogContent>
            <Grid>
          
                   <CloseOutlinedIcon style={{marginLeft:"90%"}} onClick={() => {
                this.setState({update_score: false, })
              }}/>
              <Typography>
                <div style={{ display: "flex", justifyContent: "space-between",marginLeft:"4%" }} >
                  <div className="pg-name-section-nn">
                    <div className="pg-dot-nn"></div>
                    <div className="pg-name-nn"> {this.props.courses.course_name}</div>
                  </div>
                </div>
              </Typography>
              <Typography >
                <div style={{ display: "flex", justifyContent: "space-between" }} >
                  <div style={{ fontWeight: 500, fontSize: "18px",lineHeight:2}}>
                    {this.props.courses.trainer_title_in_assignment}
                  </div>
                </div>
              </Typography>
              <Typography>
                {/* {this.props.courses.trainer_duedate_in_assignment} */}
                <Typography style={{ marginLeft: "%" }} className="courseduration-student">
                  <img
                    src={Calender}
                    style={{ marginRight: "5px" }}
                  />
                  Due for {this.props.courses.trainer_duedate_in_assignment}
                </Typography>
              </Typography>
            </Grid>
            <Grid container>

              <Box>
                <Typography
                  style={{
                    position: "relative",
                    right: "3rem",
                    lineHeight: "1",
                  }}
                >
                  <div>
                    <h3 style={{ marginLeft: "9rem" }}>Project</h3>
                    <Typography>
                      {/* <div> */}
                      <div className='pg-icon-nn '>
                        <div className='pgh-2'><PictureAsPdfIcon /></div>
                        <div style={{ marginLeft: "20%" }}>
                          <a href={this.state.attachment}>
                            <strong style={{ color: "#3679DC" }}>Project.pdf</strong></a>
                        </div>
                      </div>
                      {/* <div style={{ marginLeft: "100%" }}>
                        <Link href={this.props.courses.trainer_duedate_in_assignment}>
                          <Button style={{ marginRight: "0.5%", color: "#7A7E86", marginTop: "0.5%" }}>
                            Download
                          </Button>
                        </Link>
                      </div> */}
                      {/* </div> */}
                    </Typography>
                  </div>
                  <div>
                    <h3 style={{ marginLeft: "9rem" }}>Score</h3>
                    <OutlinedInput
                      className="class-tab-233"
                      placeholder="Enter Score"
                      value={this.state.score}
                      onChange={(e) => { this.setState({ score: e.target.value }) }}
                    ></OutlinedInput>
                  </div>


                </Typography>

                <Typography></Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.props.update_score(this.state.submit_id, this.state.student_id, this.state.assignment_id, this.state.status, this.state.score)
                      this.setState({ update_score: false, status: "Reattempt", score: "" });
                      console.log("Chanfe", this.state.submit_id, this.state.student_id, this.state.assignment_id, this.state.status, this.state.score)
                    }}
                    style={{ width: "23%", color: "#0000B3" }}

                  >
                    Reattempt
                  </Button>
                  <Button
                    variant="contained"

                    onClick={() => {
                      this.props.update_score(this.state.submit_id, this.state.student_id, this.state.assignment_id, this.state.astatus, this.state.score)
                      this.setState({ update_score: false, status: "Approved", score: "" });
                      console.log("Approved", this.state.submit_id, this.state.student_id, this.state.assignment_id, this.state.astatus, this.state.score)
                    }}

                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                      color: "white",
                    }}
                  >
                    Approved
                  </Button>
                </Box>
              </Box>
            </Grid>
          </DialogContent>
        </Dialog>
        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </Grid>

    )
  }
}
