import React, { Component } from "react";
import { connect } from "react-redux";
import StudentAssignments from "../Components/StudentAssignments";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    viewAssignmentByBatch,
    viewAssignmentById,
    viewAssignmentByStudent,
    addAssignment
} from "../action"
// import { Controller } from "../../Assignment/container/assignmentConst";

export class Controller extends Component {
    render() {
        return (
            <StudentAssignments {...this.props} />
        );
    }

};

export const mapStateToProps = store => {
    return {

        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {
        viewAssignmentById: (assigement_id, page_number, rowper_page) => {
            dispatch(viewAssignmentById(assigement_id, page_number, rowper_page));
        },
        viewAssignmentByBatch: (batch_id, page_number, rowper_page) => {
            dispatch(viewAssignmentByBatch(batch_id, page_number, rowper_page));
        },
        viewAssignmentByStudent: (discussion_id) => {
            dispatch(viewAssignmentByStudent(discussion_id));
        },
        addAssignment: (trainer_id, course_id, batch_id, title, description, due_date, attachment, page_number, rowper_page, status) => {
            dispatch(addAssignment(trainer_id, course_id, batch_id, title, description, due_date, attachment, page_number, rowper_page, status));
        },

        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },




    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);