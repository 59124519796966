//Placement
export const PLACEMENTS="PLACEMENTS";
export const SET_APPLY_LINK="SET_APPLY_LINK";
export const SET_LAST_DATE="SET_LAST_DATE";
export const SET_DESCRIPTION="SET_DESCRIPTION";

//Compnay
export const COMPANYS="COMPANYS";
export const SET_COMPANY_ID="SET_COMPANY_ID";
export const SET_COMPANY_NAME="SET_COMPANY_NAME";
export const SET_COMPANY_EMAIL="SET_COMPANY_EMAIL";
export const SET_COMPANY_WEBSITE="SET_COMPANY_WEBSITE";
export const SEARCH_COMPANY="SEARCH_COMPANY";

export const VIEW_ALL_APPLIED="VIEW_ALL_APPLIED";
export const SET_PLACEMENT_ID="SET_PLACEMENT_ID";
export const SET_JOB_PROFILE="SET_JOB_PROFILE";
export const VIEW_PLACEMENT_BY_STUDENT="VIEW_PLACEMENT_BY_STUDENT";

export const SEARCH_PLACEMENT="SEARCH_PLACEMENT";
export const VIEW_APPLIED_BY_STUDENT="VIEW_APPLIED_BY_STUDENT";
export const SEARCH_APPLIED="SEARCH_APPLIED";
export const SET_PLACEMENT_COMPANY_NAME="SET_PLACEMENT_COMPANY_NAME";
export const SET_PLACEMENT_COMPANY_EMAIL="SET_PLACEMENT_COMPANY_EMAIL";

export const SET_PLACEMENT_APPLY_LINK="SET_PLACEMENT_APPLY_LINK";
export const SET_PLACEMENT_LAST_DATE="SET_PLACEMENT_LAST_DATE";

export const SET_PLACEMENT_JOB_PROFILE="SET_PLACEMENT_JOB_PROFILE";

export const SET_PLACEMENT_JOB_DESC="SET_PLACEMENT_JOB_DESC";

export const VIEW_APPLIED_STATUS_BY_STUDENT="VIEW_APPLIED_STATUS_BY_STUDENT";

export const SET_ALL_PLACEMENT_APPLY_LINK="SET_ALL_PLACEMENT_APPLY_LINK";

