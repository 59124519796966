import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../Components/Studashboard";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    // addNotice,
    // updateNotice,
    viewAllNotice,
    // deleteNotice,
    // viewNoticeById,
    viewRecordVideoByStudent,
    viewAssignmentStudentLength,
    viewAssignmentCompletedLength,
    viewOngoingCourseByStudent,
    viewAssignmentList,
    viewScheduleClassByStudent,
    searchNotice,



} from "../action"

import {view_course_by_student, subjectlength,add_attendance} from "../../Course/action"
// import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <Dashboard {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        dashboard: store.dashboard,
        courses: store.courses,
        assignment: store.assignment,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,


    };
};
export const mapDispatchToProps = dispatch => {
    return {
        // addNotice: (admin_id, course_id, batch_id, desc) => {
        //     dispatch(addNotice(admin_id, course_id, batch_id, desc));
        // },
        // updateNotice: (notice_id, course_id, batch_id, desc) => {
        //     dispatch(updateNotice(notice_id, course_id, batch_id, desc));
        // },
        viewAllNotice: () => {
            dispatch(viewAllNotice());
        },
        // deleteNotice: (notice_id) => {
        //     dispatch(deleteNotice(notice_id));
        // },
        subjectlength: () => {
            dispatch(subjectlength());
        },
        viewRecordVideoByStudent: (student_id) => {
            dispatch(viewRecordVideoByStudent(student_id));
        },
        viewAssignmentStudentLength: (student_id) => {
            dispatch(viewAssignmentStudentLength(student_id));
        },
        viewAssignmentCompletedLength: (student_id) => {
            dispatch(viewAssignmentCompletedLength(student_id));
        },
        viewOngoingCourseByStudent: (student_id) => {
            dispatch(viewOngoingCourseByStudent(student_id));
        },
        viewScheduleClassByStudent: () => {
            dispatch(viewScheduleClassByStudent());
        },
        viewAssignmentList: (student_id) => {
            dispatch(viewAssignmentList(student_id));
        },

        searchNotice: (notices, e) => {
            dispatch(searchNotice(notices, e));
        },
        viewScheduleClassByStudent: (student_id) => {
            dispatch(viewScheduleClassByStudent(student_id));
        },
        view_course_by_student: (student_id, page_number, rowper_page) => {
            dispatch(view_course_by_student(student_id, page_number, rowper_page))
        },
        add_attendance: (student_id, batch_id, subject_id) => {
            dispatch(add_attendance(student_id, batch_id, subject_id))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },



    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);