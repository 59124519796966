import {
    COURSES,
    COURSES_BY_ID,
    SET_COURSE_ID,
    SET_COURSE_NAME,
    SET_DURATION,
    SET_IMAGE,
    SET_TITLE,
    SET_DESC,
    SET_STATUS,
    VIEW_ALL_SUBJECT,
    SUBJECT_BY_ID,
    VIEW_ALL_BATCH,
    BATCH_BY_ID,
    SEARCH_COURSES,
    NOTES_BY_ID,
    NOTES_BY_BATCH_ID,
    SET_COURSE_CODE,
    VIEW_BATCH_BY_COURSES,
    FILTER_BATCH,
    FEEDBACK_BY_ID,
    VIEW_ALL_FEEDBACK,
    VIEW_ALL_LESSON,
    LESSON_BY_ID,
    VIEW_ALL_CLASSRECODER,
    VIEW_ALL_ASSIGNMENT,
    ASSIGNMENT_BY_ID, ASSIGNMENT_BY_STUDENT_ID, VIEW_ALL_SCORE,
    VIEW_BY_ASSIGNMENT,
    VIEW_SUBMIT_BY_STUDENT,
    VIEW_SUBMIT_BY_ASSIGNMENT,
    VIEW_ALL_SUBMIT,
    VIEW_ALL_SUBMIT_DETAILS,
    SEARCH_BATCH,
    VIEW_ALL_ASSIGN_TO_TRAINER,
    VIEW_ALL_ASSIGN_TO_TRAINER_LENGTH,
    STUDENT_COURSES,
    SET_BATCH_ID,
    VIEW_BATCH_RECORD,
    SET_ASSIGNMENT_DETAILS,
    VIEW_ALL_LESSION,
    SET_TRAINER_NAME,
    SET_TRAINER_PROFILE,
    BATCH_LENGTH,
    SUBJECT_LENGTH,
    SET_BATCH_NUMBER,
    SET_SUBJECT_NAME,
    VIEW_ASSIGNMENT_BY_BATCH,
    LESSON_BY_SUBJECT,
    SET_BATCH_NAME,
    SET_BATCH_ID2,
    SET_BATCH,
    LESSON_MEETING,
    SET_ATTACHMENT,
    SET_TRAINER_NAME_IN_ASSIGNMENT,
    SET_TRAINER_PROFILE_IN_ASSIGNMENT,
    SET_TRAINER_DUEDATE_IN_ASSIGNMENT,
    SET_TRAINER_TITLE_IN_ASSIGNMENT,
    SET_ASSIGNMENT_ID,
    SEARCH_ASSIGNMENT,
    VIEW_SCHEDULE_MEETING,
    SET_SUBJECT_ID,
    SET_LESSON_ID


} from "./constant";
import { viewAllScheduleClasses } from "../Dashboard/action"
import { view_submit_by_assignment } from "../Assignment/action"

import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import 'firebase/storage';
import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


{/*-------------------------------------------------------------COURSE  ------------------------------------------------------------*/ }
// SEARCH COURSES 
export function search_course(course, e, type) {
    return (dispatch) => {
        if (e == "" || e == "N") {
            dispatch({ type: SEARCH_COURSES, payload: course });
        }
        else if (type == "search") {
            const newArray = course.filter((el) => {
                return (el.course_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_COURSES, payload: newArray })
        }
        else if (type == "status") {
            const newArray = course.filter((el) => {
                console.log("a", el.status)
                return (el.status.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_COURSES, payload: newArray })
        }
    };
}

// FIREBASE ADD IMAGE 
export function uploadImageCourse(admin_id, image, course_name, duration, course_code, desc, page_number, rowper_page, org_id) {
    return dispatch => {
        // console.log("courseadd", admin_id, image, course_name, duration, course_code, desc)
        dispatch(setLoader(true));
        if (image !== "") {
            const storageRef = ref(getStorage(), "/courses/" + image + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, image);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (image) {
                        // console.log(image)
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(add_courses(admin_id, image, course_name, duration, course_code, desc, page_number, rowper_page, org_id));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(add_courses(admin_id, image, course_name, duration, course_code, desc, page_number, rowper_page, org_id));
        }
    }
}

// ADD 
export function add_courses(admin_id, image, course_name, duration, course_code, desc, page_number, rowper_page, org_id) {
    return (dispatch) => {
        console.log("add courseee", admin_id, image, course_name, duration, course_code, desc, page_number, rowper_page, org_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_course", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id
            },
            body: JSON.stringify({
                image: image,
                course_name: course_name,
                duration: duration,
                course_code: course_code,
                desc: desc,
                org_id: org_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_courses(page_number, rowper_page, org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// Viewall
export function view_all_courses(page_number, rowper_page, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_course", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                org_id: org_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: COURSES, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: COURSES, payload: { data: [], count: 0 } })
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// dashboard
export function view_all_course(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_courses", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                org_id: org_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("dddddd",responseJson.result)
                if (responseJson.status) {
                    dispatch({ type: COURSES, payload: { data: responseJson.result, count: responseJson.result.length } });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: COURSES, payload: { data: [], count: 0 } })
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// trainer 
export function view_trainer_course(trainer_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_trainer_course", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                trainer_id: trainer_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("courses")
                if (responseJson.status) {
                    dispatch({ type: COURSES, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: COURSES, payload: { data: [], count: 0 } })
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// student 
export function view_course_by_student(student_id, page_number, rowper_page) {
    return (dispatch) => {
        console.log("coursedetails", student_id, page_number, rowper_page)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_course_by_student", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                student_id: student_id,
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("courses", responseJson)
                if (responseJson.status) {
                    dispatch({ type: STUDENT_COURSES, payload: responseJson.result });
                    dispatch(view_subject_by_courses(student_id, responseJson.result[0].course_details._id,))
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: STUDENT_COURSES, payload: [] })
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// update status
export function update_course_status(status, course_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_course_status", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                status: status,
                course_id: course_id,
                org_id: org_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// FIREBASE EDIT IMAGE 
export function editUploadImageCourse(course_id, image, old_image, course_name, duration, course_code, desc, page_number, rowper_page,org_id) {
    return dispatch => {

        // console.log("courseedit", course_id, image, course_name, duration, course_code, desc, page_number, rowper_page);
        dispatch(setLoader(true));
        if (image !== "") {
            const storageRef = ref(getStorage(), "/Tags/" + course_id + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, image);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    // console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                    // dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (image) {
                        // console.log(gst)
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(updateCourse(course_id, image, course_name, duration, course_code, desc, page_number, rowper_page,org_id));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(updateCourse(course_id, old_image, course_name, duration, course_code, desc, page_number, rowper_page,org_id));
        }
    }
}

// UPDATE 
export function updateCourse(course_id, image, course_name, duration, course_code, desc, page_number, rowper_page, org_id) {
    return (dispatch) => {
        // console.log("updatecourse", course_id, image, course_name, duration, course_code, desc, page_number, rowper_page)

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_course", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                image: image,
                course_id: course_id,
                course_name: course_name,
                duration: duration,
                course_code: course_code,
                desc: desc,
                org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_courses(page_number, rowper_page, org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}

// VIEW COURSE BY ID 
export function view_course_by_id(course_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_course_by_id", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                course_id: course_id,
                org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: COURSES_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: COURSES_BY_ID, payload: [] })
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// DELETE COURSE 
export function delete_course(course_id, page_number, rowper_page, org_id) {
    return (dispatch) => {
        // console.log("deletecourese", course_id, page_number, rowper_page)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_course", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                course_id: course_id,
                org_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_courses(page_number, rowper_page, org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

{/*------------------------------------------------------------- SUBJECT  ------------------------------------------------------------*/ }
//add
export function add_subject(admin_id, course_id, subject_name, duration, org_id) {
    return (dispatch) => {
        // console.log("addsubject", admin_id, course_id, subject_name, duration)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_subject", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id
            },
            body: JSON.stringify({

                course_id: course_id,
                subject_name: subject_name,
                duration: duration,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_subject_by_courses(admin_id, course_id));
                    dispatch(set_snack_bar(true, responseJson.message));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(true, responseJson.message));

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

//viewall
export function view_all_subject() {
    // console.log("jhsgjdsg")
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_subject", {
            method: "GET",  // Corrected to GET
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_SUBJECT, payload: responseJson.result });
                    dispatch(set_snack_bar(true, responseJson.message));

                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_SUBJECT, payload: [] });
                    dispatch(set_snack_bar(true, responseJson.message));

                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

//update
export function edit_subject(admin_id, course_id, subject_id, subject_name, duration, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // console.log("editsub", admin_id, course_id, subject_id, subject_name, duration)
        return fetch(UNIVERSAL.BASEURL + "edit_subject", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                subject_id: subject_id,
                subject_name: subject_name,
                duration: duration,
                org_id: org_id

            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_subject_by_courses(admin_id, course_id));
                    dispatch(set_snack_bar(true, responseJson.message));

                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

//update status
export function update_subject_status(subject_id, status, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_subject_status", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                status: status,
                subject_id: subject_id,
                org_id: org_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // Dispatch your action for updating course status here
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// viewbycourse
export function view_subject_by_courses(admin_id, course_id, org_id) {
    // console.log(admin_id, course_id)
    return (dispatch) => {
        dispatch(setLoader());
        console.log(admin_id, course_id)
        return fetch(UNIVERSAL.BASEURL + "view_subject_by_courses", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                course_id: course_id,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: SUBJECT_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SUBJECT_BY_ID, payload: [] })
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_assign_subject_by_trainer_id(trainer_id, course_id, org_id) {
    // console.log(admin_id, course_id)
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_assign_subject_by_trainer_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                trainer_id: trainer_id,
                course_id: course_id,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("zjdhghjdgfg", responseJson)
                if (responseJson.status) {
                    dispatch({ type: SUBJECT_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SUBJECT_BY_ID, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// delete
export function delete_subject(subject_id, admin_id, course_id, org_id) {
    return (dispatch) => {
        // console.log("deletesub", subject_id, admin_id, course_id)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_subject", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                subject_id: subject_id,
                org_id: org_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_subject_by_courses(admin_id, course_id));
                    dispatch(set_snack_bar(true, responseJson.message));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(true, responseJson.message));

                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// viewbyid
export function view_subject_by_id(subject_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_course_by_id", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                subject_id: subject_id,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: SUBJECT_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(true, responseJson.message));

                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: SUBJECT_BY_ID, payload: [] })
                    dispatch(set_snack_bar(true, responseJson.message));


                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// length
export function subjectlength() {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_all_subject_length", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: SUBJECT_LENGTH, payload: { count: responseJson.length } });
                    dispatch(set_snack_bar(true, responseJson.message));

                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: SUBJECT_LENGTH, payload: { count: 0 } })
                    dispatch(set_snack_bar(true, responseJson.message));


                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


{/*-------------------------------------------------------------batch  ------------------------------------------------------------*/ }

// add
export function addBatch(admin_id, course_id, batch_number, start_date, batch_time, duration, page_number, rowper_page, org_id) {
    return (dispatch) => {
        console.log("batchadd", admin_id, course_id, batch_number, start_date, batch_time, duration, page_number, rowper_page, org_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_batch", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id
            },
            body: JSON.stringify({
                course_id: course_id,
                batch_number: batch_number,
                start_date: start_date,
                batch_time: batch_time,
                duration: duration,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewBatchByCourse(course_id, page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// view_subject_by_courses
export function viewBatchByCourse(course_id, page_number, rowper_page, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_batch_by_courses", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                page_number: page_number,
            },
            body: JSON.stringify({
                course_id: course_id,
                rowper_page: rowper_page,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_BATCH_BY_COURSES, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_BATCH_BY_COURSES, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_batch_by_courses_trainer(course_id, trainer_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_batch_by_courses_&_trainer", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                course_id: course_id,
                trainer_id: trainer_id,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_BATCH_BY_COURSES, payload: { data: responseJson.result } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_BATCH_BY_COURSES, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// viewall
export function view_all_batch(page_number, rowper_page) {
    return (dispatch) => {
        // console.log("ertyuilkjbv")
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_batch", {
            method: "POST",  // Corrected to GET
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                // org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("resbatch", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_BATCH, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_BATCH, payload: { data: [], count: 0 } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_all_batch_by_course(course_id) {
    return (dispatch) => {
        console.log("ertyuilkjbv",course_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_batch_course", {
            method: "GET",  // Corrected to GET
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                course_id: course_id
            },
            // body: JSON.stringify({
            //     // org_id: org_id

            // })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("resbatch", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_BATCH, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_BATCH, payload: { data: [], count: 0 } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// update
export function editBatch(batch_number, start_date, batch_time, duration, batch_id, page_number, rowper_page, course_id, org_id) {
    return (dispatch) => {
        // console.log("editbatch", batch_number, start_date, batch_time, duration, batch_id, page_number, rowper_page, course_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_batch", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                batch_number: batch_number,
                start_date: start_date,
                batch_time: batch_time,
                duration: duration,
                batch_id: batch_id,
                org_id: org_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewBatchByCourse(course_id, page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// filter
export function search_Batch(batch_by_course, e) {
    return (dispatch) => {
        // console.log(batch_by_course)
        if (e === "" || e === "N") {
            dispatch({ type: SEARCH_BATCH, payload: batch_by_course });
        }
        else {
            const newArray = batch_by_course.filter((el) => {
                console.log(el.batch_number)
                return (el.batch_number.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_BATCH, payload: newArray })

        }
    };
}

// view_by_id 
export function view_batch_by_id(batch_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_batch_by_id", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                batch_id: batch_id,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: BATCH_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: BATCH_BY_ID, payload: [] })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// delete
export function deleteBatch(batch_id, course_id, page_number, rowper_page, org_id) {
    return (dispatch) => {
        // console.log("deletebatch", batch_id, course_id, page_number, rowper_page)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_batch", {
            method: "delete",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                batch_id: batch_id,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewBatchByCourse(course_id, page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                console.error(error);
            });
    };
}

// batch_length
export function batchLength() {
    return (dispatch) => {
        // console.log("batchLength")
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_all_batch_length", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: BATCH_LENGTH, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: BATCH_LENGTH, payload: [] });
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_batch_by_courses(admin_id, course_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_batch_by_courses", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id
            },
            body: JSON.stringify({
                course_id: course_id,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_FEEDBACK, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_LESSON, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}





{/*-------------------------------------------------------------NOTES  ------------------------------------------------------------*/ }


export function add_notes(trainer_id, course_id, batch_id, subject_id, lesson_id, attachment, title, org_id) {
    return (dispatch) => {
        console.log("hii", title)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_notes", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                trainer_id: trainer_id
            },
            body: JSON.stringify({

                course_id: course_id,
                batch_id: batch_id,
                subject_id: subject_id,
                lession_id: lesson_id,
                attachment: attachment,
                title: title,
                // org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson.status) {
                    dispatch(view_notes_by_batch_id(batch_id, 0, 10,org_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function upload_notes(trainer_id, course_id, batch_id, subject_id, lesson_id, attachment, title,org_id) {
    return dispatch => {
        console.log("asdfghj")
        dispatch(setLoader());
        if (attachment !== "") {
            const storageRef = ref(getStorage(), "/Assignment/" + attachment + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachment);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachment) {
                        console.log(attachment)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            console.log("Adsdsad")
                            dispatch(add_notes(trainer_id, course_id, batch_id, subject_id, lesson_id, attachment, title,org_id));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(add_notes(trainer_id, course_id, batch_id, subject_id, lesson_id, attachment, title,org_id));
        }

    }
}

export function view_notes_by_id(notes_id, org_id) {
    return (dispatch) => {

        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_notes_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                notes_id: notes_id,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: NOTES_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: NOTES_BY_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function update_upload_notes(notes_id, course_id, batch_id, subject_id, lession_id, attachment, title,old_attachment,org_id ) {
    return dispatch => {
        console.log("asdfghj")
        dispatch(setLoader());
        if (attachment !== "") {
            const storageRef = ref(getStorage(), "/Assignment/" + attachment + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachment);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachment) {
                        console.log(attachment)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            console.log("Adsdsad")
                            dispatch(update_notes(notes_id, course_id, batch_id, subject_id, lession_id, attachment, title,org_id));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(update_notes(notes_id, course_id, batch_id, subject_id, lession_id,old_attachment,title,org_id));
        }

    }
}
export function update_notes(notes_id, course_id, batch_id, subject_id, lession_id, attachment, title,org_id) {
    return (dispatch) => {
        // console.log("hii",notes_id,course_id, batch_id, subject_id, lession_id, attachment, title)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_notes", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                notes_id: notes_id
            },
            body: JSON.stringify({
                course_id: course_id,
                batch_id: batch_id,
                subject_id: subject_id,
                lession_id: lession_id,
                attachment: attachment,
                title: title,
                // org_id:org_id,
               


            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_notes_by_batch_id(batch_id, 0, 10,org_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
// view_NOTES_by_courses 
export function view_notes_by_batch_id(batch_id, page_number, rowper_page, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // console.log(batch_id, page_number, rowper_page)
        return fetch(UNIVERSAL.BASEURL + "view_notes_by_batch_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                batch_id: batch_id
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                // org_id: org_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("res", responseJson)
                if (responseJson.status) {
                    dispatch({ type: NOTES_BY_BATCH_ID, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: NOTES_BY_BATCH_ID, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// DELETE NOTES 
export function delete_notes(notes_id, batch_id, org_id) {
    return (dispatch) => {
        // console.log("deletenote", notes_id)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_notes", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                notes_id: notes_id,
                org_id: org_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_notes_by_batch_id(batch_id, 0, 10,org_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



{/*-------------------------------------------------------------FEEDBACK  ------------------------------------------------------------*/ }


export function add_feedback(student_id, desc, page_number, rowper_page, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_feedback", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                student_id: student_id
            },
            body: JSON.stringify({

                student_id: student_id,
                desc: desc,
                org_id: org_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_feedback(page_number, rowper_page));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function add_student_feedback(student_id, subject_id, desc, lesson_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_feedback", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                student_id: student_id
            },
            body: JSON.stringify({
                lesson_id: lesson_id,
                subject_id: subject_id,
                desc: desc,
                org_id: org_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(view_all_feedback(page_number, rowper_page));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function view_all_feedback(page_number, rowper_page, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // console.log("view_all_feedback", page_number, rowper_page)
        return fetch(UNIVERSAL.BASEURL + "view_all_feedback", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("fed", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_FEEDBACK, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_FEEDBACK, payload: { data: [], count: 0 } })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function update_feedback(feedback_id, subject_id, student_id, desc, date, time, page_number, rowper_page, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_notes", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                feedback_id: feedback_id,
                student_id: student_id,
                subject_id: subject_id,
                desc: desc,
                date: date,
                time: time,
                org_id: org_id


            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_all_feedback(page_number, rowper_page));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function view_feedback_by_id(feedback_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_feedback_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                feedback_id: feedback_id,
                org_id: org_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: FEEDBACK_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: FEEDBACK_BY_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function delete_feedback(feedback_id, notes_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_feedback", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                feedback_id: feedback_id,
                org_id: org_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_notes_by_id(notes_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



{/*-------------------------------------------------------------LESSON  ------------------------------------------------------------*/ }
export function add_lesson(admin_id, course_id, batch_id, subject_id, subject_name, session_name, org_id) {
    return (dispatch) => {
        console.log("lession add", admin_id, course_id, batch_id, subject_id, subject_name, session_name)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_lession", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({

                admin_id: admin_id,
                course_id: course_id,
                batch_id: batch_id,
                subject_id: subject_id,
                subject_name: subject_name,
                session_name: session_name,
                org_id: org_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("resles", responseJson)
                if (responseJson.status) {
                    dispatch(view_lession_by_subject_id(subject_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllLession() {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_lession",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",

                },

            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_LESSION, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_LESSION, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function update_lesson(lession_id, subject_id, subject_name, session_name, org_id) {
    return (dispatch) => {
        console.log("updatelession", lession_id, subject_id, subject_name, session_name)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_lession", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                lession_id: lession_id,
                subject_name: subject_name,
                session_name: session_name,
                org_id: org_id


            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_lession_by_subject_id(subject_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function view_lesson_by_id(lession_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_lession_by_id", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                org_id: org_id

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: LESSON_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: LESSON_BY_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_lession_by_subject_id(subject_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // console.log(subject_id)
        return fetch(UNIVERSAL.BASEURL + "view_lession_by_subject_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                subject_id: subject_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("subject", responseJson)
                if (responseJson.status) {
                    dispatch({ type: LESSON_BY_SUBJECT, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: LESSON_BY_SUBJECT, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_shedule_by_lesson(subject_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // console.log(subject_id)
        return fetch(UNIVERSAL.BASEURL + "view_shedule_by_lesson", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                lession_id: subject_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("subject", responseJson)
                if (responseJson.status) {
                    dispatch({ type: LESSON_MEETING, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: LESSON_MEETING, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function delete_lesson(lession_id, subject_id) {
    return (dispatch) => {
        // console.log("lessiondelete", lession_id)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_lession", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                lession_id: lession_id,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_lession_by_subject_id(subject_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


{/*-------------------------------------------------------------ASSIGNMENT  ------------------------------------------------------------*/ }

export function search_Assignment(assignment_by_batch, e) {
    return (dispatch) => {
        // console.log(assignment_by_batch)

        if (e === "") {
            dispatch({ type: SEARCH_ASSIGNMENT, payload: assignment_by_batch });

        } else {
            const newArray = assignment_by_batch.filter((course) => {
                // console.log(course.title)
                return (course.title.toLowerCase().includes(e.toLowerCase()));
            })

            dispatch({ type: SEARCH_ASSIGNMENT, payload: newArray })

        }
    };
}

export function uploadAssignment(trainer_id, course_id, batch_id, title, description, due_date, attachment) {
    return dispatch => {
        console.log("asdfghj")
        dispatch(setLoader());
        if (attachment !== "") {
            const storageRef = ref(getStorage(), "/Assignment/" + attachment + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachment);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachment) {
                        console.log(attachment)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            console.log("Adsdsad")
                            dispatch(add_assignment(trainer_id, course_id, batch_id, title, description, due_date, attachment));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(add_assignment(trainer_id, course_id, batch_id, title, description, due_date, attachment));
        }

    }
}

export function editUploadAssignment(batch_id, assigement_id, title, description, due_date,old_attachment,attachment,) {
    return dispatch => {
        dispatch(setLoader(true));
        if (attachment !== "") {
            const storageRef = ref(getStorage(), "/Assignment/" + attachment + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, attachment);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    // console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                    // dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (attachment) {
                        // console.log(gst)
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(update_assignment(batch_id, assigement_id, title, description, due_date, attachment));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(update_assignment(batch_id, assigement_id, title, description, due_date, old_attachment));
        }
    }
}

export function add_assignment(trainer_id, course_id, batch_id, title, description, due_date, attachment) {
    return (dispatch) => {
        console.log("sads", trainer_id, course_id, batch_id, title, description, due_date, attachment)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_assigement", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                trainer_id: trainer_id,

            },
            body: JSON.stringify({
                course_id: course_id,
                batch_id: batch_id,
                description: description,
                title: title,
                due_date: due_date,
                attachment: attachment,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAssignmentByBatch(batch_id, 0, 6));
                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAssignmentByBatch(batch_id, page_number, rowper_page) {
    // console.log("assignment", batch_id, page_number, rowper_page)
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_assigement_by_batch", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                batch_id: batch_id
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("res", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ASSIGNMENT_BY_BATCH, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ASSIGNMENT_BY_BATCH, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_assignment_by_id(assigement_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_assigement_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                assigement_id: assigement_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: ASSIGNMENT_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: ASSIGNMENT_BY_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function delete_assignment(batch_id, assigement_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_assigement", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                assigement_id: assigement_id,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAssignmentByBatch(batch_id, 0, 7));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function update_assignment(batch_id, assigement_id, title, description, due_date, attachment) {
    return (dispatch) => {
        console.log("sa", assigement_id, title, description, due_date, attachment)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_assigement", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                assigement_id: assigement_id,
                title: title,
                description: description,
                due_date: due_date,
                attachment: attachment,
            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAssignmentByBatch(batch_id, 0, 7));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function update_assignment_status(assigement_id, status, date, time) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_assigement_status", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                assigement_id: assigement_id,
                date: date,
                time: time,
                status: status
            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_assignment_by_id(assigement_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function view_assignment_by_student(student_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_assignment_by_student", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                student_id: student_id,
                page_number: page_number,
                rowper_page: rowper_page
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: ASSIGNMENT_BY_STUDENT_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: ASSIGNMENT_BY_STUDENT_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

{/*-------------------------------------------------------------submit_assignment  ------------------------------------------------------------*/ }
export function add_score(batch_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_score", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                batch_id: batch_id,

            },
            body: JSON.stringify({


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_score());

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function view_all_score() {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_all_score", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_SCORE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_SCORE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function update_score(submit_id, student_id, assigement_id, status, score) {
    console.log("hiiiiiiiiiiiiiii", submit_id, student_id, assigement_id, status, score)
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_score", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                _id: submit_id,
                student_id: student_id,
                assigement_id: assigement_id,
                status: status,
                score: score

            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_submit_by_assignment(assigement_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function view_score_by_assignment(assignment_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_score_by_assignment", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify({
            //     assignment_id: assignment_id,

            // })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_BY_ASSIGNMENT, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_BY_ASSIGNMENT, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_submit_by_student(student_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_submit_by_student", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify({
            //     student_id: student_id,

            // })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_SUBMIT_BY_STUDENT, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_SUBMIT_BY_STUDENT, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
// export function view_submit_by_assignment(assignment_id) {
//     return (dispatch) => {
//         dispatch(setLoader());

//         return fetch(UNIVERSAL.BASEURL + "view_submit_by_assignment", {
//             method: "GET",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//             },
//             // body: JSON.stringify({
//             //     assignment_id: assignment_id,

//             // })
//         })
//             .then((response) => response.json())
//             .then((responseJson) => {
//                 // console.log("viewres", responseJson)
//                 if (responseJson.status) {
//                     dispatch({ type: VIEW_SUBMIT_BY_ASSIGNMENT, payload: responseJson.result });
//                     dispatch(set_snack_bar(responseJson.status, "Some message"));
//                     dispatch(unsetLoader());
//                 } else {

//                     dispatch({ type: VIEW_SUBMIT_BY_ASSIGNMENT, payload: [] })

//                 }
//                 dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }
export function delete_score(_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_score", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_score());

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function submit(student_id, assignment_id, attachment, type, date, time) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "submit", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                student_id: student_id
            },
            body: JSON.stringify({
                assignment_id: assignment_id,
                attachment: attachment,
                type: type,
                date: date,
                time: time
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_SUBMIT, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_SUBMIT, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_all_submit_details() {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_all_submit_details", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify({

            // })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_SUBMIT_DETAILS, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_SUBMIT_DETAILS, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function update_submit_details(_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_submit_details", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id

            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_all_submit_details());
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function delete_submit(_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_submit", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_submit_details());

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}




{/*-------------------------------------------------------------CLASSRECODER  ------------------------------------------------------------*/ }
export function uploadRecord(admin_id,
    record_image,
    course_id,
    batch_id,
    title,
    session,
    access_end_date, page_number, rowper_page) {
    return dispatch => {
        console.log("courseadd", admin_id, record_image, course_id, batch_id, title, session, access_end_date, page_number, rowper_page)
        dispatch(setLoader(true));
        if (record_image !== "") {
            const storageRef = ref(getStorage(), "/Record/" + record_image + Math.random() + ".png")
            const uploadrecord_image = uploadBytesResumable(storageRef, record_image);
            uploadrecord_image.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "record_image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadrecord_image.snapshot.ref).then(function (record_image) {
                        // console.log(record_image)
                        if (getDownloadURL(uploadrecord_image.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(add_record(admin_id, record_image, course_id, batch_id, title, session, access_end_date, page_number, rowper_page));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(add_record(admin_id, record_image, course_id, batch_id, title, session, access_end_date, page_number, rowper_page));
        }
    }
}

export function add_record(admin_id,
    record_image,
    course_id,
    batch_id,
    title,
    session,
    access_end_date, page_number, rowper_page
) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_record", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id,

            },
            body: JSON.stringify({

                course_id: course_id,
                batch_id: batch_id,
                record_image: record_image,
                title: title,
                session: session,
                access_end_date: access_end_date,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_record_by_batch(batch_id, 10, 0));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function view_record_by_course(course_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_record_by_course", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                course_id: course_id,
                page_number: page_number,
                rowper_page: rowper_page
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_CLASSRECODER, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_CLASSRECODER, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_all_records(page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_all_records", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_CLASSRECODER, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_CLASSRECODER, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_record_by_batch(batch_id, rowsPerPage, page_number) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_record_by_batch", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                batch_id: batch_id,
                page_number: page_number,
                rowper_page: rowsPerPage,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("Video", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_BATCH_RECORD, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_BATCH_RECORD, payload: { data: [], count: 0 } })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function editRecord(record_id, record_image, course_id, batch_id, title, session, access_end_date) {
    return (dispatch) => {
        console.log("edit", record_id, record_image, course_id, batch_id, title, session, access_end_date);
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "edit_record", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                record_id: record_id
            },
            body: JSON.stringify({
                record_image: record_image,
                course_id: course_id,
                batch_id: batch_id,
                title: title,
                session: session,
                access_end_date: access_end_date

            })

        })

            .then((response) => response.json())
            .then((responseJson) => {
                console.log("hehe", responseJson)
                if (responseJson.status) {
                    dispatch(view_record_by_batch(batch_id, 10, 0));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}


export function editUploadRecord(record_id, record_image, record_image2, course_id, batch_id, title, session, access_end_date) {
    return dispatch => {

        console.log("recordedit", record_id, record_image, record_image2, course_id, batch_id, title, session, access_end_date);
        dispatch(setLoader(true));
        if (record_image2 !== "") {
            console.log("check", record_image2)
            const storageRef = ref(getStorage(), "/Recorded Class/" + record_id + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, record_image2);
            console.log("Adsdsad", record_image2)
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                    // dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (record_image2) {
                        console.log('gst')
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            console.log("Adsdsad", record_image2)
                            dispatch(editRecord(record_id, record_image2, course_id, batch_id, title, session, access_end_date));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(editRecord(record_id, record_image, course_id, batch_id, title, session, access_end_date));
        }
    }
}

export function view_video_by_id(record_id,) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_video_by_id", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                record_id: record_id
            },
            body: JSON.stringify({
                record_id: record_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_CLASSRECODER, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_CLASSRECODER, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function delete_record(record_id, batch_id) {
    return (dispatch) => {
        // console.log("deleterecord",record_id,batch_id)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_record", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                record_id: record_id
            },
            body: JSON.stringify({

            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_record_by_batch(batch_id, 10, 0));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

{/*------------------------------------------------------------- ASSIGN ------------------------------------------------------------*/ }

export function assignTo(admin_id, trainer_id, batch_id, subject_id, page_number, rowper_page, course_id) {
    return (dispatch) => {
        // console.log("assignto", admin_id, trainer_id, batch_id, subject_id, page_number, rowper_page)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "assign_to", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id
            },
            body: JSON.stringify({
                trainer_id: trainer_id,
                batch_id: batch_id,
                subject_id: subject_id,
                course_id: course_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllAssignTo(course_id, page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllAssignTo(course_id, page_number, rowper_page) {
    return (dispatch) => {
        // console.log("viewassign", page_number, rowper_page)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_assign_trainer_course", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                course_id: course_id,
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("restrainer", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_ASSIGN_TO_TRAINER, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_ASSIGN_TO_TRAINER, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_all_assign_trainer_length(org_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_all_assign_trainer_length", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                org_id: org_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_ASSIGN_TO_TRAINER_LENGTH, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.satus, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_ASSIGN_TO_TRAINER_LENGTH, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_all_assign_trainer(page_number, rowper_page, org_id) {
    return (dispatch) => {
        console.log("viewtrainer")
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_all_assign_trainer", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("trainerres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_ASSIGN_TO_TRAINER, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_ASSIGN_TO_TRAINER, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function update_assign_to(assign_id, trainer_id, subject_id, batch_id, page_number, rowper_page, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_assign_to", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                assign_id: assign_id,
                trainer_id: trainer_id,
                subject_id: subject_id,
                batch_id: batch_id,
                org_id: org_id


            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_all_assign_trainer(page_number, rowper_page));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function delete_assign(course_id, trainer_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_assign", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                trainer_id: trainer_id,
                org_id: org_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllAssignTo(course_id, 0, 10));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

{/*------------------------------------------------------------- Schedule ------------------------------------------------------------*/ }
export function addSchedule(course_id, batch_id, subject_id, lession_id, duration, class_link, date, time) {
    return (dispatch) => {
        // console.log("schedule", course_id, batch_id, subject_id, lession_id, duration, class_link, date, time)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "shedule_class", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                course_id: course_id,
                batch_id: batch_id,
                subject_id: subject_id,
                lession_id: lession_id,
                duration: duration,
                class_link: class_link,
                date: date,
                time: time
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(view_lession_by_subject_id(subject_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function reschedule(class_id, date, time, lesson_id, duration) {
    return (dispatch) => {
        console.log("rescheduled", class_id, date, time, duration)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "reshedule_class", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",//

            },
            body: JSON.stringify({
                lession_id: lesson_id,
                class_id: class_id,
                date: date,
                time: time,
                duration: duration

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("editres", responseJson)
                if (responseJson.status) {
                    dispatch(view_shedule_by_lesson(lesson_id));
                    dispatch(viewAllScheduleClasses());
                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function view_all_shedule_subject_by_id(subject_id) {
    return (dispatch) => {
        console.log("viewtrainer")
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_all_shedule_subject_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                subject_id: subject_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("trainerres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_SCHEDULE_MEETING, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_SCHEDULE_MEETING, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

{/*------------------------------------------------------------- Attendence ------------------------------------------------------------*/ }
export function add_attendance(student_id, batch_id, subject_id) {
    return (dispatch) => {
        console.log("viewtrainer")
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "add_attendance", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                batch_id: batch_id,
                subject_id: subject_id,
                id: student_id,
                status: "present",
                attendance_type: "online"
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("trainerres", responseJson)
                if (responseJson.status) {
                    // dispatch({ type: VIEW_SCHEDULE_MEETING, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                    // dispatch({ type: VIEW_SCHEDULE_MEETING, payload: [] })
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

{/*------------------------------------------------------------- Setter ----------------------------------------------------------------*/ }
export function setSubjectName(payload) {
    console.log("subjectname", payload)
    return {
        type: SET_SUBJECT_NAME,
        payload: payload,
    };
}

export function setAttachment(payload) {

    return {
        type: SET_ATTACHMENT,
        payload: payload,
    };
}

export function setTrainerNameInAssignment(payload) {

    return {
        type: SET_TRAINER_NAME_IN_ASSIGNMENT,
        payload: payload,
    };
}

export function setTrainerProfileInAssignment(payload) {

    return {
        type: SET_TRAINER_PROFILE_IN_ASSIGNMENT,
        payload: payload,
    };
}

export function setTrainerTitleInAssignment(payload) {

    return {
        type: SET_TRAINER_TITLE_IN_ASSIGNMENT,
        payload: payload,
    };
}

export function setTrainerDuedateInAssignment(payload) {

    return {
        type: SET_TRAINER_DUEDATE_IN_ASSIGNMENT,
        payload: payload,
    };
}

export function setAssignmentId(payload) {

    return {
        type: SET_ASSIGNMENT_ID,
        payload: payload,
    };
}

export function setCourseId(payload) {
    console.log("course idd", payload)
    return {
        type: SET_COURSE_ID,
        payload: payload,
    };
}

export function setBatchId(payload) {
    // console.log("adjhvhgavdghadsvcghvadscvgh")
    return {
        type: SET_BATCH_ID,
        payload: payload,
    };
}

export function setBatch(payload) {
    console.log("adjhvhgavdghadsvcghvadscvgh")
    return {
        type: SET_BATCH,
        payload: payload,
    };
}

export function setBatchId2(payload) {
    // console.log("adjhvhgavdghadsvcghvadscvgh")
    return {
        type: SET_BATCH_ID2,
        payload: payload,
    };
}

export function setBatchName(payload) {
    return {
        type: SET_BATCH_NAME,
        payload: payload,
    };
}

export function setAssignMent(payload) {
    return {
        type: SET_ASSIGNMENT_DETAILS,
        payload: payload,
    };
}

export function setTrainerName(payload) {
    console.log("xyzzz", payload)
    return {

        type: SET_TRAINER_NAME,
        payload: payload,
    };
}

export function setTrainerProfilePic(payload) {
    return {
        type: SET_TRAINER_PROFILE,
        payload: payload,
    };
}

export function setBatchNumber(payload) {
    return {
        type: SET_BATCH_NUMBER,
        payload: payload,
    };
}

export function setCoursename(payload) {
    return {
        type: SET_COURSE_NAME,
        payload: payload,
    };
}

export function setDuration(payload) {
    return {
        type: SET_DURATION,
        payload: payload,
    };
}

export function setCourseCode(payload) {
    return {
        type: SET_COURSE_CODE,
        payload: payload,
    };
}

export function setImage(payload) {
    return {
        type: SET_IMAGE,
        payload: payload,
    };
}

export function setTitle(payload) {

    return {

        type: SET_TITLE,
        payload: payload,
    };
}

export function setDESC(payload) {

    return {

        type: SET_DESC,
        payload: payload,
    };
}

export function setStatus(payload) {

    return {

        type: SET_STATUS,
        payload: payload,
    };
}

export function setSubjectId(payload) {

    return {

        type: SET_SUBJECT_ID,
        payload: payload,
    };
}

export function setLessonId(payload) {

    return {

        type: SET_LESSON_ID,
        payload: payload,
    };
}

