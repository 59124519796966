import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Typography,
  Card,
} from "@mui/material";
import React, { Component } from "react";
import { AiOutlineSearch } from "react-icons/ai";
// import { AiOutlineCalendar } from 'react-icons/ai'
import "../styles/Recordedclass.scss";
import img1 from "../../Images/online-event.jpg";
import { BsThreeDots } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import SearchIcon from "@mui/icons-material/Search";
import "../../Trainer/styles/trainer.css";
import { Player } from "video-react";
import ReactPlayer from "react-player";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Dialog from "@mui/material/Dialog";
import { Grid } from "@mui/material/";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import Menu from "@mui/material/Menu";
import TextField from "@mui/material/TextField";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Calender from "../../Images/Vector (1).png";
import "video-react/dist/video-react.css";
import Pagination from '@mui/material/Pagination';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  // border: '2px solid #000',

  boxShadow: 24,
  p: 4,
  borderradius: "5.463px",
  background: "#FFF",
  padding: "20px",
  width: "24.725rem",
  // height: '24.5rem',
  display: "flex", // Add display flex
  flexDirection: "column",
  justifyContent: "space-between",
};

export default class Recordedclass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoadd: false,

      recordedclassdelete: false,

      menu: false,
      anchorEl: null,
      recordedclassedit: false,
      admin_course: "N",
      admin_batch: "N",
      admin_id: "",
      record_image: "",
      title: "",
      session: "",
      access_end_date: "",
      user_id: "",
      // filter_course: "N",
      // filter_batch: "N",
      type: "",
    };
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      menu: true,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      menu: false,
    });
  };


  render() {
    const { snackbar, close_snack_bar } = this.props;
    const { openVideoModal } = this.state;
    const { open, selectedIndex } = this.state;
    const { student, class_record } = this.props;
    return (
      <>
        <div
          className="recordedClass"
          style={{ marginTop: "8%", marginRight: "25px", marginLeft: "-15px" }}
        >
          <div className="header">
            <div>
              <h3>Recorded Class</h3>
            </div>
            <div>
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({
                    videoadd: true,
                  });
                }}
                style={{
                  width: "120px",
                  height: "5vh",
                  border: "1px solid #0000B3",
                  color: "#0000B3",
                }}
                className="AddVideo"
              >
                + Add video
              </Button>
            </div>
          </div>

          <div
            style={{
              marginLeft: "2%",
              display: "flex",
              flexdirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexdirection: "row" }}>
              <TextField
                style={{
                  fontSize: "15px",
                  height: "30px",
                  marginTop: "2%",
                  width: "160px",
                  display: "flex",
                  flexdirection: "row",
                  marginLeft: "5px",
                  fontWeight: 600,
                }}
                id="outlined-select-currency-native"
                select
                value={this.state.admin_course}
                onChange={(e) => {
                  this.props.search_RecordedClass(
                    class_record.view_all_record,
                    e.target.value,
                    "course"
                  );
                  this.setState({ admin_course: e.target.value });
                }}
              >
                <MenuItem value={"N"}>{"Select Course"}</MenuItem>
                {Array.isArray(this.props.courses.all_courses) &&
                  this.props.courses.all_courses.map((e) => (
                    <MenuItem value={e.course_name}>{e.course_name}</MenuItem>
                  ))}
              </TextField>

              <TextField
                style={{
                  fontSize: "15px",
                  height: "30px",
                  marginLeft: "2%",
                  marginTop: "2%",
                  width: "160px",
                  display: "flex",
                  flexdirection: "row",
                  fontWeight: 600,
                }}
                id="outlined-select-currency-native"
                select
                value={this.state.admin_batch}
                onChange={(e) => {
                  this.props.search_RecordedClass(
                    class_record.view_all_record,
                    e.target.value,
                    "batch"
                  );
                  this.setState({ admin_batch: e.target.value });
                }}
              >
                <MenuItem value={"N"}>{"Select Batch"}</MenuItem>
                {Array.isArray(this.props.courses.view_all_batch_details) &&
                  this.props.courses.view_all_batch_details.map((e) => (
                    <MenuItem value={e.batch_number}>{e.batch_number}</MenuItem>
                  ))}
              </TextField>
            </div>
            <div>
              {/* AiOutlineSearch */}
              <Paper
                component="form"
                style={{
                  display: "flex",
                  marginTop: "3%",
                  marginRight: "20px",
                  width: "27.25rem",
                  height: "2.25rem",
                  backgroundColor: "#E3E3E3",
                  boxShadow: "none",
                }}
              >
                <IconButton
                  type="button"
                  style={{ p: "15px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  style={{ ml: 5, flex: 1, fontSize: "15px" }}
                  placeholder="Search "
                  inputProps={{ "aria-label": "Search " }}
                  onChange={(e) =>
                    this.props.search_RecordedClass(
                      class_record.view_all_record,
                      e.target.value,
                      "search"
                    )
                  }
                />
              </Paper>
            </div>
          </div>
          <hr style={{ marginRight: "20px" }} />
          <div className="bottomOfRecordedClass" style={{}}>
            <h2></h2>

            {/* mapping */}

            <Grid
              container
              direction={"row"}
              spacing={2}
              className="course-container"
            >
              {Array.isArray(this.props.class_record.search_record) &&
                this.props.class_record.search_record.map((item, index) => {
                  return (
                    <Grid item xs={3}>
                      <div>
                        <Card style={{ margin: "10px", borderRadius: "20px" }}>
                          <div>
                            <div>
                              <MoreHorizOutlinedIcon
                                onClick={(e) => {
                                  this.handleClick(e);
                                }}
                                style={{
                                  color: "black",
                                  marginLeft: "90%",
                                }}
                              />
                              <Player
                                autoPlay
                                playsInline
                                poster="/assets/poster.png"
                                src={item.record_image}
                              />
                              {/* <ReactPlayer
                                                            url={item.record_image}
                                                            controls={true}
                                                            width="100%"
                                                            height="200px"
                                                            style={{ borderRadius: "20px", position: "relative"}}
                                                        /> */}
                              {/* <video style={{ height: "200px", width: "100%", borderRadius: "20px", position: "relative" }} controls>
                                                        <source src={item.record_image} type="video/mp4" />
                                                    </video> */}

                              {/* <Button
                                                            onClick={(e) => { this.handleClick(e) }}>
                                                            <BsThreeDots style={{ color: "white", fontSize: "16px" }} className='dotIcons' /></Button> */}

                              <Menu
                                anchorEl={this.state.anchorEl}
                                open={this.state.menu}
                                onClose={this.handleClose}
                              >
                                <MenuItem
                                  style={{ color: "#0000B3" }}
                                  onClick={() => {
                                    this.setState({
                                      recordedclassedit: true,
                                      record_image: item.record_image,
                                      admin_course: item.course_id,
                                      admin_batch: item.batch_id,
                                      title: item.title,
                                      session: item.session,
                                      access_end_date: item.access_end_date,
                                      record_id: item._id,
                                    });
                                  }}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  style={{ color: "#0000B3" }}
                                  onClick={() => {
                                    this.setState({
                                      recordedclassdelete: true,
                                      record_id: item._id,
                                    });
                                  }}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </div>
                            <div style={{ marginLeft: "20px" }}>
                              <Typography style={{ fontWeight: "bolder" }}>
                                {item.title}
                              </Typography>
                              <Typography>{item.session}</Typography>
                              <Typography>{item.course_name}</Typography>
                              <Typography>{item.batch_number}</Typography>
                              <Typography style={{ color: "blue" }}>
                                <img
                                  src={Calender}
                                  style={{ marginRight: "5px" }}
                                />
                                {item.access_end_date}
                              </Typography>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
            <div style={{ marginLeft: "70%", marginTop: "2%" }}>
              <Pagination count={10} variant="outlined" color="primary" />
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.videoadd}
          style={{
            height: "calc(100% - 5px)",
          }}
        >
          <Grid style={{ height: "630px", width: "470px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4 style={{ marginLeft: "7%", marginTop: "5%" }}>
                Upload Video
              </h4>

              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ videoadd: false });
                }}
                style={{
                  marginLeft: "300px",
                  marginTop: "2%",
                  cursor: "pointer",
                }}
              />
            </div>

            <center>
              <Box
                style={{
                  width: "243px",
                  height: "100px",
                  border: "1px solid grey",
                  backgroundColor: "#FCFCFC",
                }}
              >
                <label htmlFor="file">
                  <DriveFolderUploadIcon style={{ marginTop: "10%" }} />
                  <Typography style={{ fontSize: "18px", overflow: "hidden" }}>
                    upload video
                  </Typography>
                </label>

                <input
                  style={{ display: "none" }}
                  type="file"
                  id="file"
                  onChange={(e) => {
                    this.setState({ record_image: e.target.files[0] });
                  }}
                />
              </Box>
            </center>

            <InputLabel
              style={{
                marginLeft: "7%",
                marginTop: "2%",
                color: "black",
                fontWeight: 600,
              }}
            >
              Select Course
            </InputLabel>

            <TextField
              style={{
                width: "380px",
                height: "6vh",
                marginTop: 10,
                marginLeft: "7%",
              }}
              id="outlined-select-currency-native"
              select
              value={this.state.admin_course}
              onChange={(e) => {
                this.setState({ admin_course: e.target.value });
              }}
            >
              <MenuItem value={"N"}>{"Select Course"}</MenuItem>
              {Array.isArray(this.props.courses.all_courses) &&
                this.props.courses.all_courses.map((e) => (
                  <MenuItem value={e._id}>{e.course_name}</MenuItem>
                ))}
            </TextField>

            <InputLabel
              style={{
                marginLeft: "7%",
                marginTop: "2%",
                color: "black",
                fontWeight: 600,
              }}
            >
              Select Batch
            </InputLabel>
            <TextField
              style={{
                width: "380px",
                height: "6vh",
                marginTop: 10,
                marginLeft: "7%",
              }}
              id="outlined-select-currency-native"
              select
              value={this.state.admin_batch}
              onChange={(e) => {
                this.setState({ admin_batch: e.target.value });
                // console.log(all_category)
              }}
            >
              <MenuItem value={"N"}>{"Select Batch"}</MenuItem>
              {Array.isArray(this.props.courses.view_all_batch_details) &&
                this.props.courses.view_all_batch_details.map((e) => (
                  <MenuItem value={e._id}>{e.batch_number}</MenuItem>
                ))}
            </TextField>

            <FormControl
              sx={{
                "& > :not(style)": {
                  width: "380px",
                  height: "43px",
                  marginLeft: "7%",
                },
              }}
            >
              <Typography
                variant="h6"
                style={{
                  marginLeft: "8%",
                  marginTop: "2%",
                  color: "black",
                  fontWeight: 600,
                  display: "flex",
                  fontSize: "18px",
                  alignItems: "end",
                }}
              >
                Title
              </Typography>
              <OutlinedInput
                type="text"
                style={{
                  background: "#F5F5F5",
                  borderRadius: " 0.125rem",
                  height: "6vh",
                  marginBottom: "11px",
                  marginLeft: "8%",
                }}
                placeholder="Enter the Subject Title"
                onChange={(text) => this.setState({ title: text.target.value })}
              />
            </FormControl>

            <Box
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: 10,
                marginLeft: "7%",
              }}
            >
              {" "}
              <FormControl
                sx={{
                  "& > :not(style)": { width: "87%", height: "43px" },
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    color: "black",
                    display: "flex",
                    fontSize: "18px",
                    alignItems: "end",
                  }}
                >
                  Session
                </Typography>
                <OutlinedInput
                  type="text"
                  style={{
                    background: "#F5F5F5",
                    borderRadius: " 0.125rem",
                    height: "6vh",
                    marginBottom: "11px",
                  }}
                  placeholder="Enter session name"
                  onChange={(text) =>
                    this.setState({ session: text.target.value })
                  }
                />
              </FormControl>
              {/* <FormControl sx={{
                                '& > :not(style)': { width: '93%', height: '43px', },
                            }}>
                                <Typography variant="h6" style={{ color: "black", display: "flex", fontSize: "17px", alignItems: "end" }}>Access End Date </Typography>
                                <OutlinedInput
                                    type="date"
                                    style={{ background: "#F5F5F5", borderRadius: ' 0.125rem', height: '6vh', marginBottom: '11px' }}
                                    onChange={(text) => this.setState({ access_end_date: text.target.value })}


                                />


                            </FormControl> */}
            </Box>

            <center>
              <Button
                variant="outlined"
                style={{
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "#0000B3",
                  marginTop: "8%",
                }}
                onClick={() => {
                  this.setState({ videoadd: false });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "white",
                  marginTop: "8%",
                  marginLeft: "2%",
                }}
                onClick={() => {
                  this.setState({ videoadd: false });
                  this.props.add_upload_recordedclass(
                    this.props.login.user_id,
                    this.state.record_image,
                    this.state.admin_course,
                    this.state.admin_batch,
                    this.state.title,
                    this.state.session,
                    this.state.access_end_date
                  );
                }}
              >
                Save
              </Button>
            </center>
          </Grid>
        </Dialog>

        <Dialog open={this.state.recordedclassedit} style={{}}>
          <Grid style={{ height: "630px", width: "470px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4 style={{ marginLeft: "7%", marginTop: "5%" }}>Edit Video</h4>

              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ recordedclassedit: false });
                }}
                style={{
                  marginLeft: "300px",
                  marginTop: "2%",
                  cursor: "pointer",
                }}
              />
            </div>

            <center>
              <Box
                style={{
                  width: "242px",
                  height: "120px",
                  backgroundImage:
                    "url('https://images.unsplash.com/photo-1521133573892-e44906baee46?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHBpbmslMjBjb2xvcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60')",
                  border: "1px solid grey",
                  borderRadius: "5%",
                  backgroundColor: "#FCFCFC",
                }}
              >
                <label htmlFor="file">
                  <DriveFolderUploadIcon style={{ marginTop: "10%" }} />
                  <Typography style={{ fontSize: "18px", overflow: "hidden" }}>
                    upload video
                  </Typography>
                </label>

                <input style={{ display: "none" }} type="file" id="file" />
              </Box>
            </center>

            <InputLabel
              style={{
                marginLeft: "7%",
                marginTop: "2%",
                color: "black",
                fontWeight: 600,
              }}
            >
              Select Course
            </InputLabel>

            <TextField
              style={{
                width: "380px",
                height: "6vh",
                marginTop: 10,
                marginLeft: "7%",
              }}
              id="outlined-select-currency-native"
              select
              value={this.state.admin_course}
              onChange={(e) => {
                this.setState({ admin_course: e.target.value });
              }}
            >
              <MenuItem value={"N"}>{"Select Course"}</MenuItem>
              {Array.isArray(this.props.courses.all_courses) &&
                this.props.courses.all_courses.map((e) => (
                  <MenuItem value={e._id}>{e.course_name}</MenuItem>
                ))}
            </TextField>
            <InputLabel
              style={{
                marginLeft: "7%",
                marginTop: "2%",
                color: "black",
                fontWeight: 600,
              }}
            >
              Select Batch
            </InputLabel>
            <TextField
              style={{
                width: "380px",
                height: "6vh",
                marginTop: 10,
                marginLeft: "7%",
              }}
              id="outlined-select-currency-native"
              select
              value={this.state.admin_batch}
              onChange={(e) => {
                this.setState({ admin_batch: e.target.value });
                // console.log(all_category)
              }}
            >
              <MenuItem value={"N"}>{"Select Batch"}</MenuItem>
              {Array.isArray(this.props.courses.view_all_batch_details) &&
                this.props.courses.view_all_batch_details.map((e) => (
                  <MenuItem value={e._id}>{e.batch_number}</MenuItem>
                ))}
            </TextField>
            <FormControl
              sx={{
                "& > :not(style)": {
                  width: "380px",
                  height: "43px",
                  marginLeft: "5%",
                },
              }}
            >
              <Typography
                variant="h6"
                style={{
                  marginLeft: "7%",
                  marginTop: "2%",
                  color: "black",
                  fontWeight: 600,
                  display: "flex",
                  fontSize: "18px",
                  alignItems: "end",
                }}
              >
                Title
              </Typography>
              <OutlinedInput
                type="text"
                style={{
                  background: "#F5F5F5",
                  borderRadius: " 0.125rem",
                  height: "6vh",
                  marginBottom: "11px",
                  marginLeft: "7%",
                }}
                placeholder="Enter the Subject Title"
                value={this.state.title}
                onChange={(text) => this.setState({ title: text.target.value })}
              />
            </FormControl>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              {" "}
              <FormControl
                sx={{
                  "& > :not(style)": { width: "87%", height: "43px" },
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    color: "black",
                    display: "flex",
                    fontSize: "18px",
                    alignItems: "end",
                  }}
                >
                  Session
                </Typography>
                <OutlinedInput
                  type="text"
                  style={{
                    background: "#F5F5F5",
                    borderRadius: " 0.125rem",
                    height: "6vh",
                    marginBottom: "11px",
                  }}
                  placeholder="Enter session name"
                  value={this.state.session}
                  onChange={(text) =>
                    this.setState({ session: text.target.value })
                  }
                />
              </FormControl>
              <FormControl
                sx={{
                  "& > :not(style)": { width: "93%", height: "43px" },
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    color: "black",
                    display: "flex",
                    fontSize: "17px",
                    alignItems: "end",
                  }}
                >
                  Access End Date{" "}
                </Typography>
                <OutlinedInput
                  type="date"
                  style={{
                    background: "#F5F5F5",
                    borderRadius: " 0.125rem",
                    height: "6vh",
                    marginBottom: "11px",
                  }}
                  value={this.state.access_end_date}
                  onChange={(text) =>
                    this.setState({ access_end_date: text.target.value })
                  }
                />
              </FormControl>
            </Box>

            <center>
              <Button
                variant="outlined"
                style={{
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "#0000B3",
                  marginTop: "8%",
                }}
                onClick={() => {
                  this.setState({ recordedclassedit: false });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#0000B3",
                  fontSize: "15px",
                  height: "38px",
                  width: "120px",
                  color: "white",
                  marginTop: "8%",
                  marginLeft: "2%",
                }}
                // onClick={() => {
                //     this.setState({ recordedclassedit: false })
                // }}
                onClick={() => {
                  this.setState({ recordedclassedit: false });
                  this.props.updateRecordedClass(
                    this.props.login.user_id,
                    this.state.record_id,
                    this.state.record_image,
                    this.state.admin_course,
                    this.state.admin_batch,
                    this.state.title,
                    this.state.session,
                    this.state.access_end_date
                  );
                }}
              >
                Update
              </Button>
            </center>
          </Grid>
        </Dialog>

        <Dialog open={this.state.recordedclassdelete} style={{}}>
          <Grid style={{ height: "250px", width: "520px" }}>
            <CloseOutlinedIcon
              onClick={() => {
                this.setState({ recordedclassdelete: false });
              }}
              style={{
                marginLeft: "440px",
                marginTop: "20px",
                cursor: "pointer",
              }}
            />

            <Box>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 600,
                }}
              >
                Are you sure you want to delete ?
              </Typography>
              <Typography
                id="modal-modal-description"
                style={{
                  marginTop: "2%",
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  color: "black",
                }}
              >
                This process will lead to loss of entire data, proceed at yours
                own risk
              </Typography>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ recordedclassdelete: false });
                  }}
                  style={{ width: "23%", color: "#0000B3" }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.setState({ recordedclassdelete: false });
                    this.props.deleteRecord(
                      this.props.login.user_id,
                      this.state.record_id
                    );
                  }}
                  style={{
                    marginLeft: 10,
                    width: "23%",
                    backgroundColor: "#0000B3",
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Grid>
          <LoaderCon />
          {/* <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          /> */}
        </Dialog>
      </>
    );
  }
}
