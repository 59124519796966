// COURSE
export const COURSES="COURSES";
export const COURSES_BY_ID="COURSES_BY_ID";
export const SEARCH_COURSES="SEARCH_COURSES";

// SUBJECT
export const VIEW_ALL_SUBJECT="VIEW_ALL_SUBJECT";
export const SUBJECT_BY_ID="SUBJECT_BY_ID";
export const SUBJECT_LENGTH="SUBJECT_LENGTH";

//batch
export const VIEW_ALL_BATCH="VIEW_ALL_BATCH";
export const BATCH_LENGTH="BATCH_LENGTH";
export const BATCH_BY_ID="BATCH_BY_ID";
export const VIEW_BATCH_BY_COURSES="VIEW_BATCH_BY_COURSES";
export const SEARCH_BATCH="SEARCH_BATCH";


//notes
export const VIEW_ALL_NOTES="VIEW_ALL_NOTES";
export const NOTES_BY_ID="NOTES_BY_ID";
export const NOTES_BY_BATCH_ID="NOTES_BY_BATCH_ID";

//LESSON
export const VIEW_ALL_LESSON="VIEW_ALL_LESSON";
export const LESSON_BY_ID="LESSON_BY_ID";

//ASSIGNMENT
export const VIEW_ALL_ASSIGNMENT="VIEW_ALL_ASSIGNMENT";
export const ASSIGNMENT_BY_ID="ASSIGNMENT_BY_ID";
export const ASSIGNMENT_BY_STUDENT_ID="ASSIGNMENT_BY_STUDENT_ID";

//SUBMIT_ASSIGNMENT
export const VIEW_ALL_SCORE="VIEW_ALL_SCORE";
export const VIEW_SUBMIT_BY_STUDENT="VIEW_SUBMIT_BY_STUDENT";
export const VIEW_SUBMIT_BY_ASSIGNMENT="VIEW_SUBMIT_BY_ASSIGNMENT";
export const VIEW_ALL_SUBMIT="VIEW_ALL_SUBMIT";
export const VIEW_BY_ASSIGNMENT="VIEW_BY_ASSIGNMENT";
export const VIEW_ALL_SUBMIT_DETAILS="VIEW_ALL_SUBMIT_DETAILS";


//FEEDBACK
export const VIEW_ALL_FEEDBACK="VIEW_ALL_FEEDBACK";
export const FEEDBACK_BY_ID="FEEDBACK_BY_ID";

//CLASSRECODER
export const VIEW_ALL_CLASSRECODER="VIEW_ALL_CLASSRECODER";
export const CLASSRECODER_BY_ID="CLASSRECODER_BY_ID";


//ASSIGN
export const VIEW_ALL_ASSIGN_TO_TRAINER="VIEW_ALL_ASSIGN_TO_TRAINER";
export const VIEW_ALL_ASSIGN_TO_TRAINER_LENGTH="VIEW_ALL_ASSIGN_TO_TRAINER_LENGTH";

//SETTER
export const SET_COURSE_ID="SET_COURSE_ID";
export const SET_COURSE_NAME="SET_COURSE_NAME";
export const SET_DURATION="SET_DURATION";
export const SET_IMAGE="SET_IMAGE";
export const SET_TITLE="SET_COMPANY_TITLE";
export const SET_DESC="SET_DESC";
export const SET_STATUS="SET_STATUS";
export const SET_COURSE_CODE="SET_COURSE_CODE";
export const SET_BATCH_ID="SET_BATCH_ID";
export const SET_BATCH_ID2="SET_BATCH_ID2";

export const VIEW_ALL_LESSION="VIEW_ALL_LESSION";
export const SET_TRAINER_NAME="SET_TRAINER_NAME";
export const SET_TRAINER_PROFILE="SET_TRAINER_PROFILE";

export const SET_BATCH_NUMBER="SET_BATCH_NUMBER";

export const SET_SUBJECT_NAME="SET_SUBJECT_NAME";

export const VIEW_ASSIGNMENT_BY_BATCH="VIEW_ASSIGNMENT_BY_BATCH";

export const STUDENT_COURSES="STUDENT_COURSES";
export const VIEW_BATCH_RECORD="VIEW_BATCH_RECORD";
export const SET_ASSIGNMENT_DETAILS="SET_ASSIGNMENT_DETAILS";
export const LESSON_BY_SUBJECT="LESSON_BY_SUBJECT";
export const SET_BATCH_NAME="SET_BATCH_NAME";

export const SET_BATCH="SET_BATCH";
export const LESSON_MEETING="LESSON_MEETING";
export const SET_ATTACHMENT="SET_ATTACHMENT";

export const SET_TRAINER_NAME_IN_ASSIGNMENT="SET_TRAINER_NAME_IN_ASSIGNMENT";

export const SET_TRAINER_PROFILE_IN_ASSIGNMENT="SET_TRAINER_PROFILE_IN_ASSIGNMENT";
export const SET_TRAINER_TITLE_IN_ASSIGNMENT="SET_TRAINER_TITLE_IN_ASSIGNMENT";
export const SET_TRAINER_DUEDATE_IN_ASSIGNMENT="SET_TRAINER_DUEDATE_IN_ASSIGNMENT";

export const SET_ASSIGNMENT_ID="SET_ASSIGNMENT_ID";

export const SEARCH_ASSIGNMENT="SEARCH_ASSIGNMENT";
export const VIEW_SCHEDULE_MEETING="VIEW_SCHEDULE_MEETING";
export const SET_SUBJECT_ID="SET_SUBJECT_ID";
export const SET_LESSON_ID="SET_LESSON_ID";







