import "./styles/drawer.css";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

export const all_admin_option = [
    { id: 0, name: "Dashboard", icon: "grid_view", link: "/" },
    { id: 1, name: "Courses", icon: "laptopicon", link: "/course" },
    { id: 2, name: "Attendance", icon: "leaderboardOutlinedIcon", link: "/attendance" },
    { id: 3, name: "Placement", icon: "schoolOutlinedIcon", link: "/Company" },
    { id: 4, name: "Trainers", icon: "assignmentIndIcon", link: "/Trainer" },
    { id: 5, name: "Students", icon: "groupOutlinedIcon", link: "/Students" },
    { id: 6, name: "Help & Support", icon: "headsetMicOutlinedIcon", link: "/Help" },

    {
        // id: 7, name: "Setting", icon: <SettingsOutlinedIcon style={{}} />, link: "/Setting"
    },
    {
        // id: 8, name: "Wallet", icon: <AccountBalanceWalletIcon style={{}} />, link: "/Wallet"
    }




];

export const all_students_options = [
    { id: 0, name: "Dashboard", icon: "grid_view", link: "/" },
    { id: 1, name: "Courses", icon: "laptopicon", link: "/studentcourse" },
    { id: 2, name: " Discussion", icon: "dashboard_customize", link: "/discussion" },
    { id: 3, name: "Placement", icon: "schoolOutlinedIcon", link: "/Placementstudent" },
    { id: 4, name: " Help & Support", icon: "headsetMicOutlinedIcon", link: "/Studenthelp" },
];

export const all_trainer_options = [
    { id: 0, name: "Dashboard", icon: "grid_view", link: "/" },
    { id: 1, name: "Courses", icon: "laptopicon", link: "/course" },
    { id: 2, name: "Classes", icon: "classicon", link: "/trainerclass" },
    { id: 3, name: "Help & Support", icon: "headsetMicOutlinedIcon", link: "/Studenthelp" },
];


