export const NOTICE = "NOTICE";
export const NOTICE_DETAILS = "NOTICE_DETAILS";
export const RECORD_DETAILS = "RECORD_DETAILS";
export const ASSIGNMENT_LENGTH_DETAILS = "ASSIGNMENT_LENGTH_DETAILS";
export const COMPLETED_ASSIGNMENT_LENGTH = "COMPLETED_ASSIGNMENT_LENGTH";

export const SEARCH_NOTICE = "SEARCH_NOTICE";
export const COURSE_IN_PROGRESS = "COURSE_IN_PROGRESS";
export const ONGOING_COURSE_LENGTH = "ONGOING_COURSE_LENGTH";
export const ALL_SCHEDULE_CLASSES = "ALL_SCHEDULE_CLASSES";
export const BATCH_COURSE_LENGTH = "BATCH_COURSE_LENGTH";
export const COMPLETED_COURSE = "COMPLETED_COURSE";
export const VIEW_MONTHLY_FEEDBACK = "VIEW_MONTHLY_FEEDBACK";
export const STUDENT_ASSIGNMENT_LENGTH = "STUDENT_ASSIGNMENT_LENGTH";
export const SCHEDULE_CLASS_OF_STUDENT = "SCHEDULE_CLASS_OF_STUDENT";
export const SEARCH_SCHEDULE = "SEARCH_SCHEDULE";
export const INPROGRESS_BY_TRAINER = "INPROGRESS_BY_TRAINER";
export const SCHEDULE_BY_TRAINER = "SCHEDULE_BY_TRAINER";
export const SCHEDULE_CLASS_VIEW = "SCHEDULE_CLASS_VIEW";

