import React, { Component } from "react";
import "../styles/batch.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
// import { Tab, Tabs, TabList, Tabpanel} from "react-tabs";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import first from "../../Images/first.png";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputBase, IconButton,
  Paper, Select, TextareaAutosize
} from '@mui/material';


import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AddIcon from "@mui/icons-material/Add";
import Pagination from "@mui/material/Pagination";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Divider, OutlinedInput } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { Grid, cardContent, Dialog, Box } from "@mui/material";

import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";

export default class Batch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: " PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "HTML",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "HTML",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "CSS",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: " With PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full StackP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "volopement With PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack  With PHP",
          session: "10 Months",
          date: "24 JAN 2023"
        },

      ],
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
      type: "Classes",
      expanded: false,
      menubatch: false,
      deleteTopic: false,
      deleteRecordedTopic: false,
      editTopic: false,
      addAssignment: false,

      viewfeedback: false,

      addVideo: false,
      editVideo: false,
      deleteVideo: false,

      //notes

      add_notes: false,
      edit_notes: false,
      deleteNote: false,


      anchorEbatch: null,
      rescheduledSession: false,
      scheduledSession: false,
      editassignment: false,
      deleteassignment: false,
      deleteNote: false,

      createclass: false,
      notstartedcreateclass: false,
      notstartedscheduleadd: false,
      notstartedscheduleadit: false,
      notstartedscheduledelete: false,
      batch_id: "",
      admin_id: "",
      // course_id: "",
      subject_name: "",
      session_name: "",

      menu: false,
      anchorEl: null,

      menu1: false,
      anchorEl1: null,
      batch_id: '',

      //add
      page: 0,
      setPage: 0,
      rowsPerPage: 10,
      setRowsPerPage: "",
      feedback: "",
      feedbeck_img: "",
      feedback_id: "",
      feedback_subject_id: "",
      feedback_student_id: "",
      feedback_desc: "",
      feedback_date: "",
      feedback_time: "",
      setindex: "",
      clicked: "",
      click_count: 0,
      notes_id: "",
      meeting_id: "",
      video: "",
      video_title: "",
      session_name: "",
      record_image: "",
      record_image2: "",
      access_end_date: ""

    };
    this.anchorRef = React.createRef();
  }

  handleClick11 = (event) => {
    this.setState({
      anchorEclasses: event.currentTarget,
      menuEclasses: true,
    });
  };

  handleClose11 = () => {
    this.setState({
      anchorEclasses: null,
      menuEclasses: false,
    });
  };

  handleClick22 = (event) => {
    this.setState({
      anchorEclasses22: event.currentTarget,
      menuEclasses22: true,
    });
  };

  handleClose22 = () => {
    this.setState({
      anchorEclasses22: null,
      menuEclasses22: false,
    });
  };

  handleClick12 = (event) => {
    this.setState({
      anchorEassign: event.currentTarget,
      menuEassign: true,
    });
  };

  handleClose12 = () => {
    this.setState({
      anchorEassign: null,
      menuEassign: false,
    });
  };

  handleClick18 = (event) => {
    this.setState({
      anchorEschedule: event.currentTarget,
      menuEschedule: true,
    });
  };

  handleClose18 = () => {
    this.setState({
      anchorEschedule: null,
      menuEschedule: false,
    });
  };

  handleClick9 = (event) => {
    this.setState({
      anchorEnotstarted: event.currentTarget,
      menuEnotstarted: true,
    });
  };

  handleClose9 = () => {
    this.setState({
      anchorEnotstarted: null,
      menuEnotstarted: false,
    });
  };

  handleClick31 = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      menu: true,
    });
  };

  handleClose31 = () => {
    this.setState({
      anchorEl: null,
      menu: false,
    });
  };

  handleClick33 = (event) => {
    this.setState({
      anchorEl1: event.currentTarget,
      menu1: true,
    });
  };

  handleClose33 = () => {
    this.setState({
      anchorEl1: null,
      menu1: false,
    });
  };

  handleClick13 = (event) => {
    this.setState({
      anchorE26: event.currentTarget,
      menu1: true,
    });
  };

  handleClose13 = () => {
    this.setState({
      anchorE26: null,
      menu1: false,
    });
  };

  handleChange(panel, event, isExpanded) {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };
  viewSomething(bkb, index) {
    // console.log(bkb, index);
    if (bkb > 0 && this.state.setindex === index) {
      this.setState({ setindex: "" })
    }

  }

  calculateTimeDifference = (date, time) => {
    const formattedDate = moment(date.replace('/', '-') + " " + time);
    const now = moment();

    const duration = moment.duration(now.diff(formattedDate));

    const hours = duration.hours();
    const minutes = duration.minutes();

    return `${hours} hours and ${minutes} minutes ago`;
  }
  componentDidMount() {
    this.props.viewAllLession()
    this.props.view_notes_by_batch_id(this.props.courses.batch_details._id, this.state.page, this.state.rowsPerPage)
    this.props.viewAssignmentByBatch(this.props.courses.batch_details._id, this.state.page, this.state.rowsPerPage)
    console.log(this.props.courses.trainer_name)

  }

  render() {
    const { tickets, snackbar, close_snack_bar } = this.props;
    const handleChangeRecord = (event, newPage) => {
      // this.props.view_notes_by_batch_id(this.props.courses.batch_Id, newPage - 1, this.state.rowsPerPage)
      this.props.view_record_by_batch(this.props.courses.batch_details._id, this.state.rowsPerPage, newPage - 1)

      // this.props.viewAllTrainer(this.props.login.user_id, newPage - 1, this.state.rowsPerPage);
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };
    const handleChangeFeed = (event, newPage) => {
      this.props.view_notes_by_batch_id(this.props.courses.batch_Id, newPage - 1, this.state.rowsPerPage)
      this.props.view_record_by_batch(this.props.courses.batch_details._id, this.state.rowsPerPage, newPage - 1)
      this.props.view_all_feedback(newPage - 1, this.state.rowsPerPage)

      // this.props.viewAllTrainer(this.props.login.user_id, newPage - 1, this.state.rowsPerPage);
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };

    const handleChangeAssignment = (event, newPage) => {
      this.props.viewAssignmentByBatch(newPage - 1, this.state.rowsPerPage)
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };

    const handleChangeNotes = (event, newPage) => {
      this.props.view_notes_by_batch_id(newPage - 1, this.state.rowsPerPage)
      console.log(newPage)
      this.setState({
        page: newPage,
      });
    };

    const { courses, login, auth } = this.props
    return (
      <>
        <div className="batch-navigate-11t">
          <div>
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/course"
            >
              Courses
            </Link>
          </div>
          <div>
            <KeyboardArrowRightIcon
              style={{ color: "#7C7A7A", fontSize: "20px", marginTop: "2px" }}
            />
          </div>
          <div>
            {" "}
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/courseDetails"
            >
              Batches
            </Link>
          </div>
          <div>
            <KeyboardArrowRightIcon
              style={{ color: "#7C7A7A", marginTop: "2px" }}
            />
          </div>
          <div>Classes</div>
        </div>
        <div className="batch-main-tr">
          <div>
            <h2>  {this.props.courses.course_name} ({this.props.courses.batch_Name})</h2>
          </div>
          {/* <div id="batch-start-tr">
            <span>
              <DateRangeIcon id="batch-icon-tr" /> Started on 20 April 2023
            </span>
          </div> */}
          <div className="batch-accordion-tr">
            <Tabs value={this.state.type} style={{ width: "100%" }}>
              <TabList style={{ display: "flex", flexDirection: "row" }}>
                <Tab >
                  <Button className={this.state.type === "Classes" ? 'selected-tab' : ''}
                    style={this.state.type === "Classes" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "3px solid blue" } : { color: "#7A7E86", fontSize: "20px" }}

                    onClick={() => {
                      this.setState({ type: "Classes" });
                    }}
                    active
                  >
                    {" "}
                    Classes{" "}
                  </Button>
                </Tab>
                {(this.props.login.type === "A" || this.props.login.type === "Owner") &&
                  <Tab>
                    <Button className={this.state.type === "Notes" ? 'selected-tab' : ''}
                      style={this.state.type === "Notes" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "3px solid blue" } : { color: "#7A7E86", fontSize: "20px" }}

                      onClick={() => {
                        this.setState({ type: "Notes" });
                      }}
                    >
                      {" "}
                      Notes{" "}
                    </Button>
                  </Tab>
                }
                <Tab>
                  <Button className={this.state.type === "Assignments" ? 'selected-tab' : ''}
                    style={this.state.type === "Assignments" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "3px solid blue" } : { color: "#7A7E86", fontSize: "20px" }}

                    onClick={() => {
                      this.setState({ type: "Assignments" });
                    }}
                  >
                    {" "}
                    Assignments{" "}
                  </Button>
                </Tab>
                <Tab>
                  <Button className={this.state.type === "Recorded Class" ? 'selected-tab' : ''}
                    style={this.state.type === "Recorded Class" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "3px solid blue" } : { color: "#7A7E86", fontSize: "20px" }}

                    onClick={() => {
                      this.setState({ type: "Recorded Class" });
                      this.props.view_record_by_batch(this.props.courses.batch_details._id, this.state.rowsPerPage, this.state.page)
                    }}
                  >
                    {" "}
                    Recorded Class{" "}
                  </Button>
                </Tab>
                {(this.props.login.type === "A" || this.props.login.type === "Owner") &&
                  <Tab>
                    <Button className={this.state.type === "Feedback" ? 'selected-tab' : ''}
                      style={this.state.type === "Feedback" ? { color: "#7A7E86", fontSize: "20px", borderBottom: "3px solid blue" } : { color: "#7A7E86", fontSize: "20px" }}

                      onClick={() => {
                        this.setState({ type: "Feedback" });
                        this.props.view_all_feedback(this.state.page, this.state.rowsPerPage)
                      }}
                    >
                      {" "}
                      Feedback{" "}
                    </Button>
                  </Tab>}
                {/* <Button id="tablist-button" variant="outlined" startIcon={<AddCircleOutlinedIcon />}> Add </Button> */}
              </TabList>

              <TabPanel>
                {Array.isArray(courses.view_subject_by_id) && courses.view_subject_by_id.map((item, index) => {
                  return (
                    <div>
                      <Accordion

                        style={{ margin: "6px", }}
                        expanded={this.state.expanded === "panel1"}
                      // onChange={(e) => {
                      //   this.handleChange("panel1", e, !this.state.expanded,)
                      //   this.setState({ setindex: index, click_count: this.state.click_count + 1 })
                      //   this.viewSomething(this.state.click_count, index);
                      //   this.props.view_lession_by_subject_id(item._id)
                      // }
                      // }
                      >
                        {/* front mapping start */}
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <div className="accordion-sum-tr">
                            <div className="accordion-sum-text">
                              <div className="expandmore-icon">
                                <ExpandMoreIcon
                                  onClick={(e) => {
                                    console.log(e)
                                    this.handleChange("panel1", e, !this.state.expanded,)
                                    this.setState({ setindex: index, click_count: this.state.click_count + 1, subject_id: item._id })
                                    this.viewSomething(this.state.click_count, index);
                                    this.props.view_lession_by_subject_id(item._id)
                                  }
                                  }
                                />
                              </div>

                              <Typography
                                onClick={(e) => {
                                  this.handleChange("panel1", e, !this.state.expanded,)
                                  this.setState({ setindex: index, click_count: this.state.click_count + 1, subject_id: item._id })
                                  this.viewSomething(this.state.click_count, index);
                                  this.props.view_lession_by_subject_id(item._id)
                                }
                                }
                              >{index + 1}.{item.subject_name}</Typography>

                            </div>
                            <div className="accordion-sum-button">
                              <div
                                style={{ display: "flex", flexDirection: "row", }}
                              >
                                {/* <Button
                                  variant="outlined"
                                  style={{
                                    zIndex: "100",
                                    position: "relative",
                                    top: "5%",

                                    color: "green",
                                    border: "1px solid green",
                                  }}
                                  onClick={(e) => {
                                    this.handleClick(e);
                                  }}
                                >
                                  Completed
                                </Button> */}

                                <Button
                                  onClick={(e) => {
                                    this.handleClick11(e);
                                    this.setState({
                                      // subject_name:item.subject_name,
                                      subject_id: item._id,

                                    })
                                  }}
                                >
                                  <MoreVertIcon style={{ color: "black" }}

                                  />
                                </Button>

                                <Menu
                                  anchorEl={this.state.anchorEclasses}
                                  open={this.state.menuEclasses}
                                  onClose={this.handleClose11}
                                >
                                  <MenuItem
                                    style={{ color: "#0000B3" }}
                                    onClick={() => {
                                      this.handleClose11()
                                      this.setState({
                                        createclass: true,

                                      });
                                    }}
                                  >
                                    Create
                                  </MenuItem>
                                </Menu>
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        {/* front mapping start */}
                        {/* subparagraph start */}
                        {this.state.setindex === index ?

                          <AccordionDetails>
                            {Array.isArray(courses.view_all_lesson_subject) && courses.view_all_lesson_subject.map((item, index) => {
                              console.log("sssssssssssssssssssssssssss", item)
                              return (
                                <div className="accordion-det-tr">
                                  <div className="accordion-det-01">
                                    <div className="accordion-det-01-01">
                                      <div id="acc-icon">
                                        <DoneIcon className="acc-icon-tr" />
                                      </div>
                                      <div>
                                        <strong>{item.subject_name}</strong>
                                      </div>
                                      <div>({item.session_name})</div>
                                    </div>
                                    <div className="accordion-det-01-02">
                                      <img src={item.trainer_details !== undefined && item.trainer_details.profile_pic}></img>
                                      <div>
                                        <strong>{item.trainer_details !== undefined && item.trainer_details.name}</strong>
                                      </div>
                                    </div>
                                    <div className="accordion-det-01-03">
                                      <div style={{ display: "flex" }}>



                                        {item.shedule === false ? (
                                          <Button

                                            onClick={() => {
                                              this.setState({
                                                notstartedscheduleadd: true,
                                                subject_name: item.subject_name,
                                                session: item.session_name,
                                                subject_id: item.subject_id,
                                                lession_id: item._id
                                              });
                                            }}
                                            style={{
                                              backgroundColor: "green",
                                              color: "#FFFFFF",
                                              textTransform: "capitalize",
                                              width: "20%"
                                            }}
                                            size="small"
                                          >
                                            Scheduled
                                          </Button>
                                        )
                                          :

                                          <Button

                                            onClick={() => {
                                              this.props.view_shedule_by_lesson(item._id)
                                              this.setState({
                                                rescheduledSession: true,
                                                subject_name: item.subject_name,
                                                session: item.session_name,
                                                class_id: item._id,
                                                //date: item.date,
                                                //time: item.time
                                              })
                                            }}
                                            style={{
                                              backgroundColor: "#A8A8AA",
                                              color: "#FFFFFF",
                                              textTransform: "capitalize",

                                            }}
                                            size="small"
                                          >
                                            ReScheduled
                                          </Button>

                                        }

                                        <Button

                                          onClick={(e) => {
                                            this.handleClick18(e);
                                            this.setState({
                                              lession_id: item._id,
                                              subject_name: item.subject_name,
                                              session_name: item.session_name,

                                            })
                                          }
                                          }
                                        >
                                          <MoreVertIcon
                                            style={{ color: "black", fontSize: "20px" }}
                                          />
                                        </Button>

                                        <Menu
                                          anchorEl={this.state.anchorEschedule}
                                          open={this.state.menuEschedule}
                                          onClose={this.handleClose18}
                                        >
                                          <MenuItem
                                            style={{ color: "#0000B3" }}
                                            onClick={(e) => {
                                              this.setState({ editTopic: true, menuEschedule: false });
                                            }}
                                          >
                                            Edit
                                          </MenuItem>
                                          <MenuItem
                                            style={{ color: "#0000B3" }}
                                            onClick={(e) => {
                                              this.setState({ deleteTopic: true, menuEschedule: false });
                                            }}
                                          >
                                            Delete
                                          </MenuItem>
                                        </Menu>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-det-02">
                                    <div className="accordion-det-02-01">
                                      <div>
                                        <AccessTimeOutlinedIcon
                                          style={{ fontSize: "1rem" }}
                                        />
                                      </div>
                                      <div>{item.time}</div>
                                    </div>
                                    <div className="accordion-det-02-01">
                                      <div>
                                        <DateRangeIcon style={{ fontSize: "1rem" }} />
                                      </div>
                                      <div>{item.Date}</div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </AccordionDetails>

                          : this.state.clicked}



                      </Accordion>


                    </div>
                  );
                })}

              </TabPanel>

              <TabPanel>

                <div>
                  <Table>
                    <TableHead style={{ backgroundColor: "#F8F7F7" }}>
                      <TableRow>
                        <TableCell style={{ width: "20%" }} align="left">
                          Course Name
                        </TableCell>

                        <TableCell style={{ width: "10%" }} align="left">
                          Subject
                        </TableCell>
                        <TableCell style={{ width: "10%" }} align="left">
                          Session
                        </TableCell>
                        <TableCell style={{ width: "20%" }} align="center">
                          Notes
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(courses.view_notes_by_batch) && courses.view_notes_by_batch.map((item, index) => {
                        return (
                          <TableRow>
                            <TableCell align="left">
                              {item.course_details.course_name}
                            </TableCell>

                            <TableCell align="left">{item.lession_details.subject_name}</TableCell>
                            <TableCell align="left">{item.lession_details.session_name}</TableCell>
                            <TableCell align="right">
                              <div className="table-cell-notes">
                                <div id="table-cell-notes-icon">
                                  <PictureAsPdfIcon />
                                </div>
                                <div id="table-cell-notes-text">
                                  <a href={item.attachment}><strong>attachment</strong></a>
                                </div>
                                <div>
                                  <Button
                                    onClick={(e) => {
                                      this.handleClick33(e);
                                    }}
                                    variant=""
                                  >
                                    <MoreVertIcon id="table-cell-notes-icon2" />
                                  </Button>
                                  <Menu
                                    anchorEl={this.state.anchorEl1}
                                    open={this.state.menu1}
                                    onClose={this.handleClose33}
                                  >
                                    {/* <MenuItem>
                                  <Button
                                    onClick={(e) => {
                                      this.handleClick33(e);
                                    }}
                                    variant=""
                                  >
                                    <MoreVertIcon id="table-cell-notes-icon2" />
                                  </Button>
                                </MenuItem> */}
                                    <MenuItem>
                                      <Button
                                        onClick={() => {
                                          this.setState({
                                            deleteNote: true,
                                            notes_id: item._id
                                          });
                                        }}
                                        style={{ color: "#0000B3" }}
                                      >
                                        Delete
                                      </Button>
                                    </MenuItem>
                                  </Menu>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}

                    </TableBody>
                  </Table>

                  <div className="page-button" style={{ display: "flex" }}>
                    <Pagination
                      count={parseInt(this.props.courses.feed_length / 10) + 1}
                      size="small"
                      style={{ marginLeft: "85%", marginTop: "3%" }}
                      onChange={handleChangeNotes}
                    />
                  </div>
                </div>
              </TabPanel>

              <TabPanel>


                <div className="search-container-tg">
                  <div className="catagory-area-tg">

                  </div>
                  <div className="search-area-tg">

                    <Paper
                      component="form"
                      style={{ display: "flex", marginLeft: "auto", marginRight: "1%", width: "27.25rem", height: "2.25rem", backgroundColor: "#E3E3E3", boxShadow: "none" }}
                    >
                      <IconButton
                        type="button"
                        style={{ p: "15px" }}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                      <InputBase
                        style={{ ml: 5, flex: 1, fontSize: "15px" }}
                        placeholder="Search Assignments"
                        inputProps={{ "aria-label": "Search " }}
                        onChange={(e) => this.props.search_Assignment(courses.assignment_by_batch, e.target.value)}

                      />
                    </Paper>


                  </div>
                </div>

                <div>
                  <Table>
                    <TableHead style={{ backgroundColor: "#F8F7F7" }}>
                      <TableRow>
                        <TableCell style={{ width: "15%" }} align="left">
                          Title
                        </TableCell>
                        <TableCell style={{ width: "10%" }} align="center">
                          Assigned By
                        </TableCell>
                        <TableCell style={{ width: "10%" }} align="left">
                          Due Date
                        </TableCell>
                        <TableCell style={{ width: "10%" }} align="left">
                          Attachment
                        </TableCell>
                        <TableCell style={{ width: "10%" }} align="left">
                          Status
                        </TableCell>
                        {/* <TableCell style={{ width: "10%" }} align="left">
                          Assigned By
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    {Array.isArray(this.props.courses.search_assignment) && this.props.courses.search_assignment.map((item, index) => {
                      return (
                        <TableBody>
                          <Link style={{ textDecoration: "none", color: "black" }} to="/assignmentDetails" className="table-row1"
                            onClick={() => {
                              this.props.setAttachment(item.attachment);
                              this.props.setTrainerNameInAssignment(item.trainer_details.name);
                              this.props.setTrainerProfileInAssignment(item.trainer_details.profile_pic);
                              this.props.setTrainerDuedateInAssignment(item.due_date);
                              this.props.setTrainerTitleInAssignment(item.title);

                              this.props.setAssignmentId(item._id);
                            }}
                          >

                            <TableCell align="left">
                              <strong>{item.title}</strong>
                            </TableCell>
                            <TableCell align="left">
                              {/* <div className="table-cell-assignment">
                                <div>
                                  <img src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzR8fHdvbWVufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"></img>
                                </div>
                                <div>
                                  <strong>Ankit Das</strong>
                                </div>
                              </div> */}
                              <div className="table-cell-assignment">
                                <img src={item.trainer_details !== undefined && item.trainer_details.profile_pic}></img>
                                <div>
                                  <strong>{item.trainer_details !== undefined && item.trainer_details.name}</strong>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell align="left">{item.due_date
                            }</TableCell>
                            <TableCell align="right">
                              <div className="table-cell-assignment">
                                <div id="table-cell-notes-icon">
                                  <PictureAsPdfIcon />
                                </div>
                                <div id="table-cell-notes-text">
                                  <a href={item.attachment
                                  }><strong>Attachment</strong></a>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              <div id="status-show">{item.course_details.status
                              }</div>
                            </TableCell>
                            {/* <TableCell align="left">
                          <div className="table-cell-assignment">
                            <div>
                              <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bWVufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"></img>
                            </div>
                            <div>
                              <strong>Ankit Das</strong>
                            </div>
                            <div>
                              <Button
                                onClick={(e) => {
                                  this.handleClick13(e);
                                }}
                              >
                                <MoreVertIcon style={{ color: "black" }} />
                              </Button>

                              <Menu
                                anchorEl26={this.state.anchorEassign}
                                open={this.state.menuEassign}
                                onClose={this.handleClose13}
                              >
                                <MenuItem
                                  style={{ color: "#0000B3" }}
                                  onClick={() => {
                                    this.setState({
                                      editassignment: true,
                                    });
                                  }}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  style={{ color: "#0000B3" }}
                                  onClick={() => {
                                    this.setState({
                                      deleteassignment: true,
                                    });
                                  }}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </div>
                          </div>
                        </TableCell> */}

                          </Link>


                        </TableBody>

                      )
                    })}
                  </Table>
                </div>

                {/* <div className="page-button" style={{ display: "flex" }}>
                  <Pagination
                    count={3}
                    size="small"
                    style={{ marginLeft: "85%", marginTop: "3%" }}
                  />
                </div> */}
                <div className="page-button" style={{ display: "flex" }}>
                  <Pagination
                    count={parseInt(this.props.courses.feed_length / 10) + 1}
                    size="small"
                    style={{ marginLeft: "85%", marginTop: "3%" }}
                    onChange={handleChangeAssignment}
                  />
                </div>
              </TabPanel>

              <TabPanel>
                <Grid style={{ display: "flex", flexDirection: "column" }}>

                  <div>
                    <Button
                      onClick={() => {
                        this.setState({ addVideo: true });
                      }}
                      // id="batch-add-mk"
                      style={{
                        textTransform: "capitalize",
                        position: "relative",
                        left: "0.5rem",
                        top: "0.5rem",
                        color: "#0000B3",
                        border: "1px solid #0000B3",
                      }}
                      variant="outlined"
                      startIcon={<AddIcon />}
                    >
                      Add Video
                    </Button>
                  </div>
                  <Grid
                    container
                    display={"flex"}
                    direction="row"
                    xs={4}
                    sm={12}
                    lg={12}
                    md={12}
                    gap={4}
                    mt={2}
                  >
                    {this.props.courses.batch_record.map((item, index) => {
                      return (
                        <Card
                          sx={{ maxWidth: 220, height: 280, borderRadius: 4 }}
                        >
                          {/* <Link
                          style={{ textDecoration: "none", color: "black" }}
                          to="/courseDetails"
                        > */}
                          <CardMedia
                            CardMedia
                            component="video"
                            // autoPlay
                            controls
                            src={item.record_image}
                          />
                          {/* </Link> */}
                          <CardContent>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {" "}
                              <Typography
                                gutterBottom
                                style={{ fontSize: 15 }}
                                component="div"
                              >
                                {item.title}
                              </Typography>
                              <div>
                                <Button
                                  // onClick={(e) => {
                                  //   this.handleClick12(e);
                                  // }}

                                  onClick={(e) => {
                                    this.handleClick12(e);
                                    this.setState({
                                      record_id: item._id,
                                      record_image: item.record_image,
                                      title: item.title,
                                      session: item.session,
                                      access_end_date: item.date
                                    })
                                  }
                                  }
                                >
                                  <MoreVertIcon style={{ color: "black" }} />
                                </Button>

                                <Menu
                                  anchorEl={this.state.anchorEassign}
                                  open={this.state.menuEassign}
                                  onClose={this.handleClose12}
                                >
                                  <MenuItem
                                    style={{ color: "#0000B3" }}
                                    onClick={() => {
                                      this.setState({
                                        editVideo: true,

                                      });
                                    }}
                                  >
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    style={{ color: "#0000B3" }}
                                    onClick={() => {
                                      this.setState({
                                        deleteVideo: true,
                                      });
                                    }}
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </div>
                            </div>
                            <div>
                              {" "}
                              Session:{" "}
                              <Button size="small">{item.session}</Button>
                            </div>
                            <div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                <path d="M4.42943 5.70736H3.28498V6.97566H4.42943V5.70736ZM6.71832 5.70736H5.57387V6.97566H6.71832V5.70736ZM9.00721 5.70736H7.86276V6.97566H9.00721V5.70736ZM10.1516 1.2683H9.57943V0H8.43498V1.2683H3.8572V0H2.71276V1.2683H2.14054C1.50537 1.2683 1.00182 1.83904 1.00182 2.5366L0.996094 11.4147C0.996094 12.1123 1.50537 12.683 2.14054 12.683H10.1516C10.7811 12.683 11.2961 12.1123 11.2961 11.4147V2.5366C11.2961 1.83904 10.7811 1.2683 10.1516 1.2683ZM10.1516 11.4147H2.14054V4.43906H10.1516V11.4147Z" fill="#0000B3" />
                              </svg>
                              <Button size="small">{item.date}</Button>
                            </div>
                          </CardContent>
                        </Card>
                      );
                    })}
                  </Grid>

                  <div className="page-button" style={{ display: "flex" }}>
                    <Pagination
                      count={parseInt(this.props.courses.record_length / 10) + 1}
                      size="small"
                      style={{ marginLeft: "85%", marginTop: "3%" }}
                      onChange={handleChangeRecord}
                    />
                  </div>
                </Grid>
              </TabPanel>

              <TabPanel>
                {Array.isArray(courses.view_all_feedback) && courses.view_all_feedback.map((item, index) => {
                  // console.log("DDDDDDD", item)
                  return (
                    <div>
                      <div className="feedback-container-vv">
                        <div className="feedback-img-vv">
                          <img src={item.student_details?.profile_pic}></img>
                        </div>
                        <div className="feedback-content-vv">
                          <div>
                            <strong>{item.student_details?.name}</strong>
                          </div>
                          <div id="feedback-12">
                            {this.calculateTimeDifference(item.date, item.time)}
                          </div>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div id="feedback-13">
                              {item.desc}
                            </div>
                            {/* <div id="feedback-13">
                              <Button
                                onClick={() => {
                                  this.setState({
                                    viewfeedback: true,
                                    
                                  });
                                }}
                                variant="outlined"
                                style={{
                                  width: "80px",
                                  marginLeft: "8%",
                                  borderColor: "#0000B3",
                                  color: "#0000B3",
                                }}
                              >
                                View
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <Divider></Divider>
                    </div>
                  )
                })}

                <div className="page-button" style={{ display: "flex" }}>
                  <Pagination
                    count={parseInt(this.props.courses.feed_length / 10) + 1}
                    size="small"
                    style={{ marginLeft: "85%", marginTop: "3%" }}
                    onChange={handleChangeFeed}
                  />
                </div>
              </TabPanel>
            </Tabs>
          </div>

          {/* Dialog section */}
          <Dialog open={this.state.createclass} style={{}}>
            <Grid style={{ height: "500px", width: "600px" }}>
              <div id="trainer-tab-dialog-tt">
                <h2 style={{ position: "relative" }}>Create Session</h2>
                <Box
                  onClick={() => {
                    this.setState({ createclass: false });
                  }}
                >
                  <CloseOutlinedIcon style={{ cursor: "pointer" }} />
                </Box>
              </div>
              <center>
                <Typography id="acc-tab-2111">
                  {this.props.courses.batch_Name}
                </Typography>
              </center>
              <Typography id="trainer-tab-tt-78">
                {this.props.courses.course_name}
              </Typography>
              <Box>
                <Typography
                  style={{
                    position: "relative",
                    right: "3rem",
                    lineHeight: "1",
                  }}
                >
                  <div>
                    <h3 style={{ marginLeft: "9rem" }}>Subject Name</h3>
                    <OutlinedInput
                      className="class-tab-233"
                      placeholder="Enter Subject Name"
                      value={this.state.subject_name}
                      onChange={(text) =>
                        this.setState({ subject_name: text.target.value })
                      }
                    ></OutlinedInput>
                  </div>
                  <div>
                    <h3 style={{ marginLeft: "9rem" }}>Lession Name</h3>
                    <OutlinedInput
                      className="class-tab-233"
                      placeholder="Enter Session Name"
                      onChange={(text) =>
                        this.setState({ session_name: text.target.value })
                      }
                    ></OutlinedInput>
                  </div>
                </Typography>

                <Typography></Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ createclass: false });
                    }}
                    style={{ width: "23%", color: "#0000B3" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    // onClick={() => {
                    //   this.setState({ createclass: false });
                    // }}
                    onClick={() => {
                      this.setState({ createclass: false, subject_name: "", session_name: "" });

                      this.props.add_lesson(
                        this.props.login.user_id,
                        this.props.courses.Course_Id,
                        this.props.courses.batch_details._id,
                        this.state.subject_id,
                        this.state.subject_name,
                        this.state.session_name
                      );
                      console.log("session", this.props.login.user_id,
                        this.props.courses.Course_Id,
                        this.props.courses.batch_details._id,
                        this.state.subject_id,
                        this.state.subject_name,
                        this.state.session_name)
                    }}
                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                      color: "white",
                    }}
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Dialog>

          <Dialog style={{ width: "600" }} open={this.state.deleteTopic}>
            <Grid style={{ height: "250px", width: "520px" }}>
              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ deleteTopic: false });
                }}
                style={{
                  marginLeft: "440px",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              />
              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 600,
                  }}
                >
                  Are you sure you want to delete ?
                </Typography>
                <Typography
                  id="modal-modal-description"
                  style={{
                    marginTop: "2%",
                    fontSize: "1rem",
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    color: "black",
                  }}
                >
                  This process will lead to loss of entire data, proceed at
                  yours own risk
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ deleteTopic: false });
                    }}
                    style={{ width: "23%", color: "#0000B3" }}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"

                    onClick={() => {
                      this.props.delete_lesson(
                        this.state.lession_id, this.state.subject_id
                      );
                      this.setState({ deleteTopic: false });
                    }}
                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                      color: "white",
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Dialog>

          <Dialog open={this.state.editTopic} style={{}}>
            <Grid style={{ height: "500px", width: "600px" }}>
              <div id="trainer-tab-dialog-tt">
                <h2 style={{ position: "relative" }}>Edit Session</h2>
                <Box
                  onClick={() => {
                    this.setState({ editTopic: false });
                  }}
                >
                  <CloseOutlinedIcon style={{ cursor: "pointer" }} />
                </Box>
              </div>
              <center>
                <Typography id="acc-tab-211">{this.props.courses.course_name}</Typography>
              </center>
              <Typography id="trainer-tab-tt-78">
                {this.props.courses.batch_Name}
              </Typography>
              <Box>
                <Typography
                  style={{
                    position: "relative",
                    right: "3rem",
                    lineHeight: "1",
                  }}
                >
                  <div>
                    <h3 style={{ marginLeft: "9rem" }}>Subject Name</h3>
                    <OutlinedInput
                      className="class-tab-233"
                      placeholder="Enter Subject Name"
                      value={this.state.subject_name}
                      onChange={(e) => { this.setState({ subject_name: e.target.value }) }}
                    ></OutlinedInput>
                  </div>
                  <div>
                    <h3 style={{ marginLeft: "9rem" }}>Lession Name</h3>
                    <OutlinedInput
                      className="class-tab-233"
                      placeholder="Enter Session Name"
                      value={this.state.session_name}
                      onChange={(e) => { this.setState({ session_name: e.target.value }) }}
                    ></OutlinedInput>
                  </div>
                </Typography>

                <Typography></Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ editTopic: false });
                    }}
                    style={{ width: "23%", color: "#0000B3" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"

                    onClick={() => {
                      this.props.update_lesson(
                        this.state.lession_id,
                        this.state.subject_id,
                        this.state.subject_name,
                        this.state.session_name
                      );
                      this.setState({ editTopic: false });
                    }}
                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                      color: "white",
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Dialog>

          <Dialog open={this.state.deleteRecordedTopic} style={{}}>
            <Grid style={{ height: "250px", width: "520px" }}>
              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ deleteRecordedTopic: false });
                }}
                style={{
                  marginLeft: "440px",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              />
              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 600,
                  }}
                >
                  Are you sure you want to delete ?
                </Typography>
                <Typography
                  id="modal-modal-description"
                  style={{
                    marginTop: "2%",
                    fontSize: "1rem",
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    color: "black",
                  }}
                >
                  This process will lead to loss of entire data, proceed at
                  yours own risk
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ deleteRecordedTopic: false });
                    }}
                    style={{ width: "23%", color: "#0000B3" }}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({ deleteRecordedTopic: false });
                    }}
                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                      color: "white",
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Dialog>

          <Dialog open={this.state.deleteNote} style={{}}>
            <Grid style={{ height: "250px", width: "520px" }}>
              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ deleteNote: false });
                }}
                style={{
                  marginLeft: "440px",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              />
              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 600,
                  }}
                >
                  Are you sure you want to delete ?
                </Typography>
                <Typography
                  id="modal-modal-description"
                  style={{
                    marginTop: "2%",
                    fontSize: "1rem",
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    color: "black",
                  }}
                >
                  This process will lead to loss of entire data, proceed at
                  yours own risk
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ deleteNote: false });
                    }}
                    style={{ width: "23%", color: "#0000B3" }}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"

                    onClick={() => {
                      this.setState({ deleteNote: false });

                      this.props.delete_notes(
                        this.state.notes_id,
                        this.props.courses.batch_id
                      );
                    }}
                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                      color: "white",
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Dialog>

          {/* 
          //Recordedclass */}

          <Dialog open={this.state.addVideo}>
            <Grid style={{ height: "600 px", width: "600px" }}>
              <div
                style={{
                  display: "flex",
                  paddingLeft: "10px",
                  flexDirection: "row",
                }}
              >
                <h3 style={{ marginLeft: "3%" }}>Upload Video</h3>
              </div>




              <Typography
                style={{
                  fontSize: "20px",
                  marginLeft: "21%",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1%",
                  height: "8rem",
                }}
              >
                <Box
                  style={{
                    width: "303px",
                    height: "103px",
                    marginLeft: "7%",
                    border: "1px solid grey",
                    backgroundColor: "#FCFCFC",
                  }}
                >
                  <label
                    htmlFor="file"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "2rem",
                      position: "relative",
                      left: "4rem",
                    }}
                  >
                    {/* Upload Image  */}

                    <center>
                      <img style={{ height: '30px', width: "20px" }} src="https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png" />
                    </center>


                    <Typography style={{ fontSize: "18px", marginTop: "1%", cursor: "pointer" }}>

                      {this.state.video !== "" ? (this.state.video).name : "Upload Video "}
                    </Typography>
                  </label>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="file"
                    onChange={(e) => {
                      this.setState({ video: e.target.files[0] })
                    }}
                  />
                </Box>
              </Typography>




              <div style={{ marginLeft: "5rem" }}>
                <div style={{ position: "relative", bottom: "1.5rem" }}>
                  <h3>Title</h3>
                  <input
                    type="text"
                    placeholder="Enter Subject Title"
                    style={{
                      width: "25rem",
                      height: "3rem",
                      border: "none",
                      borderRadius: "8px",
                      backgroundColor: "#EFEFEF99",
                    }}
                    onChange={(e) => {
                      this.setState({ video_title: e.target.value })
                    }}
                  ></input>
                </div>
                <div style={{ position: "relative", bottom: "1.5rem" }}>
                  <h3>Session</h3>
                  <input
                    type="text"
                    placeholder="Enter Session Name"
                    style={{
                      width: "25rem",
                      height: "3rem",
                      border: "none",
                      borderRadius: "8px",
                      backgroundColor: "#EFEFEF99",
                    }}
                    onChange={(e) => {
                      this.setState({ session_name: e.target.value })
                    }}
                  ></input>
                </div>

              </div>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6%",
                  position: "relative",
                  bottom: "1rem",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ addVideo: false });
                  }}
                  style={{ width: "23%", color: "#0000B3" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.setState({ addVideo: false, video: "", video_title: "", session_name: "" });
                    this.props.uploadRecord(this.props.login.user_id,
                      this.state.video,
                      this.props.courses.Course_Id,
                      this.props.courses.batch_details._id,
                      this.state.video_title,
                      this.state.session_name,
                      new Date().toLocaleDateString("en-GB", { timeZone: 'Asia/Kolkata' }), 0, 10
                    )
                  }}
                  style={{
                    marginLeft: 10,
                    width: "23%",
                    backgroundColor: "#0000B3",
                    color: "white",
                  }}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Dialog>




          <Dialog open={this.state.editnote}>
            <Grid style={{ height: "600 px", width: "600px" }}>
              <div
                style={{
                  display: "flex",
                  paddingLeft: "10px",
                  flexDirection: "row",
                }}
              >
                <h3>Edit Video</h3>
              </div>

              <div className="rec-class-add">
                <input type="file" id="file-add-video"></input>
              </div>
              <div style={{ marginLeft: "5rem" }}>
                <div style={{ position: "relative", bottom: "1.5rem" }}>
                  <h3>Title</h3>
                  <input
                    type="text"
                    placeholder="Enter Subject Title"
                    style={{
                      width: "25rem",
                      height: "3rem",
                      border: "none",
                      borderRadius: "8px",
                      backgroundColor: "#EFEFEF99",
                    }}
                  ></input>
                </div>
                <div style={{ position: "relative", bottom: "1.5rem" }}>
                  <h3>Session</h3>
                  <input
                    type="text"
                    placeholder="Enter Session Name"
                    style={{
                      width: "25rem",
                      height: "3rem",
                      border: "none",
                      borderRadius: "8px",
                      backgroundColor: "#EFEFEF99",
                    }}
                  ></input>
                </div>

              </div>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6%",
                  position: "relative",
                  bottom: "1rem",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ editnote: false });
                  }}
                  style={{ width: "23%", color: "#0000B3" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.setState({ editnote: false });
                  }}
                  style={{
                    marginLeft: 10,
                    width: "23%",
                    backgroundColor: "#0000B3",
                    color: "white",
                  }}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Dialog>

          <Dialog open={this.state.editVideo}>
            <Grid style={{ height: "600 px", width: "600px" }}>
              <div
                style={{
                  display: "flex",
                  paddingLeft: "10px",
                  flexDirection: "row",
                }}
              >
                <h3>Edit Video</h3>
              </div>

              <div >

                {/* className="rec-class-add">
                <input type="file" id="file-add-video"
                  onChange={(e) => {
                    this.setState({ record_image2: e.target.files[0] })
                  }}></input> */}



                <Typography
                  style={{
                    fontSize: "20px",
                    marginLeft: "21%",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1%",
                    height: "8rem",
                  }}
                >
                  <Box
                    style={{
                      width: "303px",
                      height: "103px",
                      marginLeft: "7%",
                      border: "1px solid grey",
                      backgroundColor: "#FCFCFC",
                    }}
                  >
                    <label
                      htmlFor="file"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "2rem",
                        position: "relative",
                        left: "4rem",
                      }}
                    >
                      {/* Upload Image  */}

                      <center>
                        <img style={{ height: '30px', width: "20px" }} src="https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png" />
                      </center>


                      <Typography style={{ fontSize: "18px", marginTop: "1%", cursor: "pointer" }}>

                        {this.state.video !== "" ? (this.state.video).name : "Edit Video "}
                      </Typography>
                    </label>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id="file"
                      onChange={(e) => {
                        this.setState({ record_image2: e.target.files[0] })
                      }}
                    />
                  </Box>
                </Typography>


              </div>
              <div style={{ marginLeft: "5rem" }}>
                <div style={{ position: "relative", bottom: "1.5rem" }}>
                  <h3>Title</h3>
                  <input
                    type="text"
                    placeholder="Enter Subject Title"
                    style={{
                      width: "25rem",
                      height: "3rem",
                      border: "none",
                      borderRadius: "8px",
                      backgroundColor: "#EFEFEF99",
                    }}
                    value={this.state.title}
                    onChange={(e) => {
                      this.setState({ title: e.target.value })
                    }}
                  ></input>
                </div>
                <div style={{ position: "relative", bottom: "1.5rem" }}>
                  <h3>Session</h3>
                  <input
                    type="text"
                    placeholder="Enter Session Name"
                    style={{
                      width: "25rem",
                      height: "3rem",
                      border: "none",
                      borderRadius: "8px",
                      backgroundColor: "#EFEFEF99",
                    }}
                    value={this.state.session}
                    onChange={(e) => {
                      this.setState({ session: e.target.value })
                    }}
                  ></input>
                </div>

              </div>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6%",
                  position: "relative",
                  bottom: "1rem",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ editVideo: false });
                  }}
                  style={{ width: "23%", color: "#0000B3" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"

                  onClick={() => {
                    this.setState({ editVideo: false, record_image: "", record_image2: "", title: "", session: "" });
                    // record_id, record_image, record_image2, course_id, batch_id, title, session, access_end_date
                    this.props.editUploadRecord(this.state.record_id, this.state.record_image, this.state.record_image2, this.props.courses.Course_Id, this.props.courses.batch_details._id, this.state.title, this.state.session, this.state.access_end_date, 0, 10)
                    console.log("com", this.state.record_id,
                      this.state.record_image, this.state.record_image2,
                      this.props.courses.Course_Id,
                      this.props.courses.batch_details._id,
                      this.state.title,
                      this.state.session,
                      this.state.access_end_date, 0, 10)
                  }}
                  style={{
                    marginLeft: 10,
                    width: "23%",
                    backgroundColor: "#0000B3",
                    color: "white",
                  }}
                >
                  Update
                </Button>
              </Box>
            </Grid>
          </Dialog>

          <Dialog open={this.state.deleteVideo} style={{}}>
            <Grid style={{ height: "250px", width: "520px" }}>
              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 600,
                    marginTop: "14%",
                  }}
                >
                  Are you sure you want to delete ?
                </Typography>
                <Typography
                  id="modal-modal-description"
                  style={{
                    marginTop: "2%",
                    fontSize: "1rem",
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    color: "black",
                  }}
                >
                  This process will lead to loss of entire data, proceed at
                  yours own risk
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ deleteVideo: false });
                    }}
                    style={{ width: "23%", color: "#0000B3" }}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"


                    onClick={() => {
                      this.setState({ deleteVideo: false, video: "", video_title: "", session_name: "" });
                      this.props.delete_record(this.state.record_id, this.props.courses.batch_details._id)
                    }}
                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                      color: "white",
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Dialog>

          {/* //Reschedule & schedule */}

          <Dialog open={this.state.rescheduledSession} style={{}}>
            <Grid style={{ height: "400px", width: "520px" }}>
              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ rescheduledSession: false });
                }}
                style={{
                  marginLeft: "440px",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              />
              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    color: "#0000B3",
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 600,
                  }}
                >
                  {this.props.courses.course_name}
                </Typography>
                <Typography
                  id="modal-modal-description"
                  style={{
                    marginTop: "2%",
                    fontSize: "1rem",
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    color: "#787878",
                    textAlign: "center",
                  }}
                >
                  {this.props.courses.batch_Name}
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <h3>{this.state.subject_name}</h3>
                  <span>{this.state.session}</span>
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <center>
                    <div>
                      <span style={{ position: "relative", top: "0.4rem" }}>
                        <AccessTimeOutlinedIcon />
                      </span>
                      <span>{this.props.courses.lesson_meeting.duration} min.</span>
                    </div>
                  </center>
                  {/* <div>
                    <span style={{ position: "relative", top: "0.4rem" }}>
                      <GroupOutlinedIcon />
                    </span>
                    <span>130 Students</span>
                  </div> */}
                </Typography>
                <Typography
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    gap: "3rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label
                      for="date-uy"
                      style={{ color: "#0000B3", fontSize: "14px" }}
                    >
                      {this.props.courses.lesson_meeting.date}
                    </label>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label
                      for="time-uy"
                      style={{ color: "#0000B3", fontSize: "14px" }}
                    >
                      Time-{this.props.courses.lesson_meeting.time}-{this.props.courses.lesson_meeting.end_time}
                    </label>
                  </div>
                </Typography>

                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ scheduledSession: true, meeting_id: this.props.courses.lesson_meeting._id });
                    }}
                    style={{
                      width: "23%",
                      color: "#0000B3",
                      border: "1px solid #0000B3",
                    }}
                  >
                    Reschedule
                  </Button>
                  {/* <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({ scheduledSession: false });
                    }}
                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                      color: "white",
                    }}
                  >
                    Start Now
                  </Button> */}
                </Box>
              </Box>
            </Grid>
          </Dialog>

          <Dialog open={this.state.scheduledSession} style={{}}>
            <Grid style={{ height: "400px", width: "520px" }}>
              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ scheduledSession: false });
                }}
                style={{
                  marginLeft: "440px",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              />
              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    color: "#0000B3",
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 600,
                  }}
                >
                  {this.props.courses.course_name}
                </Typography>
                <Typography
                  id="modal-modal-description"
                  style={{
                    marginTop: "2%",
                    fontSize: "1rem",
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    color: "#787878",
                    textAlign: "center",
                  }}
                >
                  {this.props.courses.batch_Name}
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <h3>{this.state.subject_name}</h3>
                  <span>{this.state.session}</span>
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <center>
                    <div>
                      <span style={{ position: "relative", top: "0.4rem" }}>
                        <AccessTimeOutlinedIcon />
                      </span>
                      <span>{this.props.courses.lesson_meeting.duration}</span>
                    </div>
                  </center>
                  {/* <div>
                    <span style={{ position: "relative", top: "0.4rem" }}>
                      <GroupOutlinedIcon />
                    </span>
                    <span>130 Students</span>
                  </div> */}
                </Typography>
                <Typography
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    gap: "3rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label for="date-uy">Date</label>
                    <input
                      type="date"
                      id="date-uy"
                      style={{
                        color: "#787878",
                        borderRadius: "5px",
                        border: "none",
                        width: "7rem",
                        height: "2rem",
                        backgroundColor: "#E2E2E2",
                      }}
                      value={this.state.date}
                      onChange={(e) => { this.setState({ date: e.target.value }) }}
                    ></input>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label for="time-uy">Time</label>
                    <input
                      type="time"
                      id="time-uy"
                      style={{
                        color: "#787878",
                        borderRadius: "5px",
                        border: "none",
                        width: "7rem",
                        height: "2rem",
                        backgroundColor: "#E2E2E2",
                      }}
                      value={this.state.time}
                      onChange={(e) => { this.setState({ time: e.target.value }) }}
                    ></input>
                  </div>
                </Typography>

                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"

                    onClick={() => {
                      this.props.reschedule(this.state.meeting_id, this.state.date, this.state.time, this.state.class_id, this.props.courses.lesson_meeting.duration);
                      this.setState({
                        scheduledSession: false
                      });

                    }}
                    style={{
                      width: "23%",
                      color: "#0000B3",
                      border: "1px solid #0000B3",
                    }}
                  >
                    Submit
                  </Button>
                  {/* <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({ scheduledSession: false });
                    }}
                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                      color: "white",
                    }}
                  >
                    Save
                  </Button> */}
                </Box>
              </Box>
            </Grid>
          </Dialog>

          {/* //notstartedcreatemodal */}

          <Dialog open={this.state.notstartedcreateclass} style={{}}>
            <Grid style={{ height: "500px", width: "600px" }}>
              <div id="trainer-tab-dialog-tt">
                <h2 style={{ position: "relative" }}>Edit Session</h2>
                <Box
                  onClick={() => {
                    this.setState({ notstartedcreateclass: false });
                  }}
                >
                  <CloseOutlinedIcon style={{ cursor: "pointer" }} />
                </Box>
              </div>
              <center>
                <Typography id="acc-tab-2111">Batch-1</Typography>
              </center>
              <Typography id="trainer-tab-tt-78">
                Post Graduation Program On Full Stack<br></br> Development With
                PHP Or Python
              </Typography>
              <Box>
                <Typography
                  style={{
                    position: "relative",
                    right: "3rem",
                    lineHeight: "1",
                  }}
                >
                  <div>
                    <h3 style={{ marginLeft: "9rem" }}>Subject Name</h3>
                    <OutlinedInput
                      className="class-tab-233"
                      placeholder="Enter Subject Name"
                    ></OutlinedInput>
                  </div>
                  <div>
                    <h3 style={{ marginLeft: "9rem" }}>Session Name</h3>
                    <OutlinedInput
                      className="class-tab-233"
                      placeholder="Enter Session Name"
                    ></OutlinedInput>
                  </div>
                </Typography>

                <Typography></Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ notstartedcreateclass: false });
                    }}
                    style={{
                      width: "23%",
                      color: "#0000B3",
                      border: "1px solid #0000B3",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({ notstartedcreateclass: false });
                    }}
                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                      color: "white",
                    }}
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Dialog>

          {/* //notstartedschedulemodal */}

          <Dialog open={this.state.notstartedscheduleadd} style={{}}>
            <Grid style={{ height: "480px", width: "520px" }}>
              {/* <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ notstartedscheduleadd: false });
                }}
                style={{
                  marginLeft: "440px",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              /> */}

              <Typography style={{ fontSize: "20px", marginLeft: "4%" }}>
                <h3>Schedule Session</h3>
              </Typography>
              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    color: "#0000B3",
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 600,
                  }}
                >
                  {this.props.courses.batch_number}
                </Typography>
                <Typography
                  id="modal-modal-description"
                  style={{
                    marginTop: "2%",
                    fontSize: "1rem",
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    color: "#787878",
                    textAlign: "center",
                  }}
                >
                  {this.props.courses.course_name}
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <h3>{this.state.subject_name}</h3>
                  <span>({this.state.session})</span>
                </Typography>

                <Typography
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    gap: "3rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label for="date-uy">Date</label>
                    <input
                      type="date"
                      id="date-uy"
                      style={{
                        color: "#787878",
                        borderRadius: "5px",
                        border: "none",
                        width: "7rem",
                        height: "2rem",
                        backgroundColor: "#E2E2E2",
                      }}
                      onChange={(text) =>
                        this.setState({ date: text.target.value })
                      }
                    ></input>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label for="time-uy">Time</label>
                    <input
                      type="time"
                      id="time-uy"
                      style={{
                        color: "#787878",
                        borderRadius: "5px",
                        border: "none",
                        width: "7rem",
                        height: "2rem",
                        backgroundColor: "#E2E2E2",
                      }}
                      onChange={(text) =>
                        this.setState({ time: text.target.value })
                      }
                    ></input>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label for="time-uy">Duration</label>
                    <input
                      type="number"
                      placeholder="In min"
                      id="time-uy"
                      style={{
                        color: "#787878",
                        borderRadius: "5px",
                        border: "none",
                        width: "7rem",
                        height: "2rem",
                        backgroundColor: "#E2E2E2",
                      }}
                      onChange={(text) =>
                        this.setState({ duration: text.target.value })
                      }
                    ></input>
                  </div>
                </Typography>

                <Typography
                  style={{ marginTop: "1rem", marginLeft: "10%", gap: "3rem" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label for="date-uy">Class Link</label>
                    <input
                      type="text"
                      placeholder="Add Class Link"
                      id="date-uy"
                      style={{
                        color: "#787878",
                        borderRadius: "5px",
                        border: "none",
                        width: "420px",
                        height: "2rem",
                        backgroundColor: "#E2E2E2",
                      }}
                      onChange={(text) =>
                        this.setState({ class_link: text.target.value })
                      }
                    ></input>
                  </div>
                </Typography>

                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ notstartedscheduleadd: false });
                    }}
                    style={{
                      width: "23%",
                      color: "#0000B3",
                      border: "1px solid #0000B3",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"

                    onClick={() => {
                      this.setState({ notstartedscheduleadd: false });
                      this.props.addSchedule(
                        this.props.courses.Course_Id,
                        this.props.courses.batch_details._id,
                        this.state.subject_id,
                        this.state.lession_id,
                        this.state.duration,
                        this.state.class_link,
                        this.state.date,
                        this.state.time
                      );
                    }}
                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                      color: "white",
                    }}
                  >
                    Schedule
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Dialog>

          <Dialog open={this.state.notstartedscheduleadit} style={{}}>
            <Grid style={{ height: "500px", width: "600px" }}>
              <div id="trainer-tab-dialog-tt">
                <h2 style={{ position: "relative" }}>Edit Session</h2>
                <Box
                  onClick={() => {
                    this.setState({ notstartedscheduleadit: false });
                  }}
                >
                  <CloseOutlinedIcon style={{ cursor: "pointer" }} />
                </Box>
              </div>
              <center>
                <Typography id="acc-tab-211">Batch-1</Typography>
              </center>
              <Typography id="trainer-tab-tt-78">
                Post Graduation Program On Full Stack<br></br> Development With
                PHP Or Python
              </Typography>
              <Box>
                <Typography
                  style={{
                    position: "relative",
                    right: "3rem",
                    lineHeight: "1",
                  }}
                >
                  <div>
                    <h3 style={{ marginLeft: "9rem" }}>Subject Name</h3>
                    <OutlinedInput
                      className="class-tab-233"
                      placeholder="Enter Subject Name"
                    ></OutlinedInput>
                  </div>
                  <div>
                    <h3 style={{ marginLeft: "9rem" }}>Session Name</h3>
                    <OutlinedInput
                      className="class-tab-233"
                      placeholder="Enter Session Name"
                    ></OutlinedInput>
                  </div>
                </Typography>

                <Typography></Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ notstartedscheduleadit: false });
                    }}
                    style={{ width: "23%", color: "#0000B3" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({ notstartedscheduleadit: false });
                    }}
                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                      color: "white",
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Dialog>

          <Dialog open={this.state.notstartedscheduledelete} style={{}}>
            <Grid style={{ height: "250px", width: "520px" }}>
              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ notstartedscheduledelete: false });
                }}
                style={{
                  marginLeft: "440px",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              />

              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 600,
                  }}
                >
                  Are you sure you want to delete ?
                </Typography>
                <Typography
                  id="modal-modal-description"
                  style={{
                    marginTop: "2%",
                    fontSize: "1rem",
                    fontFamily: "Inter",
                    display: "flex",
                    justifyContent: "center",
                    color: "black",
                  }}
                >
                  This process will lead to loss of entire data, proceed at
                  yours own risk
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ notstartedscheduledelete: false });
                    }}
                    style={{
                      width: "23%",
                      color: "#0000B3",
                      border: "1px solid #0000B3",
                    }}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({ notstartedscheduledelete: false });
                    }}
                    style={{
                      marginLeft: 10,
                      width: "23%",
                      backgroundColor: "#0000B3",
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Dialog>

          {/* ///feedback */}

          <Dialog
            open={this.state.viewfeedback}
            maxWidth="xl"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{}}
          >
            <Grid style={{ minWidth: "600px", height: "400px" }}>
              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ viewfeedback: false });
                }}
                style={{
                  marginLeft: "85%",
                  cursor: "pointer",
                  marginTop: "3%",
                }}
              />

              <center>
                <h2 style={{}}>Feedback</h2>
              </center>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2%",
                  marginLeft: "10%",
                }}
              >
                <div style={{ marginTop: "2%" }}>
                  <img src={first} />
                </div>
                <div style={{ marginLeft: "3%" }}>
                  <Typography style={{ fontSize: "18px", fontWeight: 500 }}>
                    Subhasmita
                  </Typography>
                  <Typography
                    style={{ color: "gray", fontWeight: 400, fontSize: "15px" }}
                  >
                    06 May,2.20pm
                  </Typography>
                </div>
              </div>
              <div style={{ marginLeft: "13%", marginTop: "5%" }}>
                <Typography style={{ fontSize: "20px", fontWeight: 500 }}>
                  Message
                </Typography>
                <Typography
                  style={{ color: "gray", fontWeight: 400, fontSize: "15px" }}
                >
                  an AI-powered language model developed by OpenAI
                </Typography>
              </div>
            </Grid>
          </Dialog>
          <LoaderCon />
          {/* <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          /> */}
        </div>
      </>
    );
  }
}