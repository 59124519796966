import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "../styles/studentcourse.css";
import { Link } from "react-router-dom";
import { Grid, Paper, Typography, Dialog, Box, CardHeader, Menu, MenuItem, Select } from "@mui/material";
import { Flex } from "theme-ui";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Pagination from "@mui/material/Pagination";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Textarea from "@mui/joy/Textarea";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import { gridColumnGroupsLookupSelector } from "@mui/x-data-grid";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default class Studentcourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addcourse: false,
      editcourse: false,
      deleteCourse: false,
      anchorEl: null,
      admin_id: "",
      image: "",
      old_image: "",
      course_name: "",
      duration: "",
      course_code: "",
      desc: "",
      user_id: "",
      page: 0,
      setPage: 0,
      rowsPerPage: 10,
      setRowsPerPage: "",
      anchorEl: null,
      open: false,
      status: "N",
      data: [
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
        {
          img: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
          heading: "Full Stack Devolopement With PHP",
          duration: "10 Months",
        },
      ],

      addcourse: false,
    };
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = (event) => {
    this.setState({ anchorEl: null });
  };


  componentDidMount() {
    document.body.style.backgroundColor = "#F9FDFE"
    if (this.props.login.type !== "T") {
      this.props.view_all_courses(this.state.page, this.state.rowsPerPage, this.props.login.org_id);
    }
    else if (this.props.login.type === "T") {
      this.props.view_trainer_course(this.props.login.user_id)
    }
  }


  render() {
    const { courses, snackbar, close_snack_bar } = this.props
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const handleChangePage = (event, newPage) => {
      this.props.view_all_courses(newPage - 1, this.state.rowsPerPage);
      console.log("hfh", newPage)
      this.setState({
        page: newPage,
      });
    };
    return (
      <Grid style={{ marginTop: "8%" }}>
        <Typography style={{ color: "black", fontSize: "29px", margin: "1% 0% 0% 0%", fontWeight: 450 }}>
          Courses
        </Typography>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex" }}>
            <Typography marginTop={1} fontWeight={500}>Status: </Typography>
            <TextField
              style={{ width: '150px', height: "2.5rem" }}
              id="outlined-select-currency-native"
              select
              sx={{
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                borderRadius: "5px",
                backgroundColor: "white"
              }}
              value={this.state.status}
              onChange={(e) => {
                this.setState({ status: e.target.value });
                this.props.search_course(courses.course, e.target.value, "status")
                console.log("c", e.target.value)
              }}
            >
              <MenuItem value={"N"}>{"All Category"}</MenuItem>
              <MenuItem value={"Upcoming"}>{"Upcoming"}</MenuItem>
              <MenuItem value={"On-going"}>{"On-going"}</MenuItem>
              <MenuItem value={"Completed"}>{"Completed"}</MenuItem>

            </TextField>
          </div>
          <div style={{ marginTop: 10, display: "flex", gap: 10, marginLeft: "auto" }}>
            <div>
              <Paper
                component="form"
                style={{
                  width: "27.25rem",
                  height: '2.5rem',
                  borderRadius: "0.625rem",
                  background: "#FFF",
                  boxShadow: "0px 0px 10px 0px rgba(163, 163, 163, 0.25)",
                  marginRight: 28
                }}
              >
                <IconButton
                  type="button"
                  style={{ p: "150px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  style={{ ml: 5, flex: 1, fontSize: "15px" }}
                  placeholder="Search "
                  inputProps={{ "aria-label": "Search " }}
                  onChange={(e) => {
                    this.props.search_course(courses.course, e.target.value, "search")
                    console.log("df", this.props.courses.search_course.length)
                  }}
                />
              </Paper>
            </div>
            {(this.props.login.type === "A" ||this.props.login.type === "Owner") &&
              <div style={{ marginRight: 28, marginLeft: -28 }}>
                <Button variant="contained" style={{ backgroundColor: "#0000B3", borderRadius: "0.625rem", height: "2.5rem" }}
                  onClick={() => {
                    this.setState({ addcourse: true })
                  }}
                >
                  + Add Course
                </Button>
              </div>}
          </div>

        </div>

        {this.props.login.type !== "T" ?
          <Card style={{ margin: "1% 2.5% 2.5% 0%", paddingLeft: "5px" }} >
            {courses.search_course.length == "0" &&
              <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                <Typography>No Result Found</Typography>
              </Grid>
            }
            <Grid
              container
              xs={12}
              gap={4}
              mt={2}
              paddingBottom={0.5}
            >
              {Array.isArray(courses.search_course) && courses.search_course.map((item, index) => {
                return (
                  <Card sx={{ width: 220, height: 260, borderRadius: 4, }}>
                    <Link
                      style={{ textDecoration: "none", color: "black" }}
                      to="/courseDetails"
                      onClick={() => {
                        this.props.setImage(item.image);
                        this.props.setCoursename(item.course_name);
                        this.props.setDuration(item.duration);
                        this.props.setCourseCode(item.course_code);
                        this.props.setDESC(item.desc);
                        this.props.setCourseId(item._id);


                      }}
                    >
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        height={"140px"}
                        style={{ objectFit: "cover" }}
                        image={item.image}
                      />


                    </Link>
                    <CardContent>

                      <div style={{ display: "flex", justifyContent: "space-between" }}> <Typography gutterBottom style={{ fontSize: 15 }} component="div">
                        {item.course_name}
                      </Typography>
                        {(this.props.login.type === "A" ||this.props.login.type === "Owner") &&
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              this.handleClick(e);
                              this.setState({
                                old_image: item.image,
                                course_name: item.course_name,
                                duration: item.duration,
                                course_code: item.course_code,
                                desc: item.desc,
                                course_id: item._id,

                              })

                            }
                            }>
                            <MoreVertIcon />
                          </IconButton>}
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={this.handleClose}
                          PaperProps={{
                            style: {
                              width: '20ch',
                            },
                          }}
                        >
                          <MenuItem onClick={() => {
                            this.setState({
                              anchorEl: null,
                              editcourse: true
                            })
                          }} >
                            Edit
                          </MenuItem>
                          <MenuItem onClick={() => {
                            this.setState({
                              anchorEl: null,
                              deleteCourse: true
                            })
                          }}  >
                            Delete
                            {/* </span> */}
                          </MenuItem>
                        </Menu>
                      </div>
                      <div> Duration: <Button size="small">{item.duration}</Button></div>
                    </CardContent>
                  </Card>
                );
              })}
            </Grid>
            {this.props.login.type !== "T" &&
              <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
                <Pagination
                  count={parseInt(courses.course_length / 8) + 1}
                  siblingCount={0}
                  size="small"
                  onChange={handleChangePage}
                />
              </div>
            }
          </Card>
          :
          <Grid style={{ margin: "1% 2.5% 2.5% 0%", paddingLeft: "5px" }} >
            {courses.search_course.length == "0" &&
              <Grid style={{ textAlign: "center", marginTop: "20px" }}>
                <Typography>No Result Found</Typography>
              </Grid>
            }
            <Grid
              container
              display={"flex"}
              direction="row"
              gap={4}
              mt={2}
              paddingBottom={0.5}
            >
              {Array.isArray(courses.search_course) && courses.search_course.map((item, index) => {
                return (
                  <Card sx={{ width: 220, height: 260, borderRadius: 4 }}>
                    <Link
                      style={{ textDecoration: "none", color: "black" }}
                      to="/courseDetails"
                      onClick={() => {
                        this.props.setImage(item.image);
                        //this.props.setOldImage(item.old_image);
                        this.props.setCoursename(item.course_name);
                        this.props.setDuration(item.duration);
                        this.props.setCourseCode(item.course_code);
                        this.props.setDESC(item.desc);
                        this.props.setCourseId(item._id);


                      }}
                    >
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        height={"140px"}
                        style={{ objectFit: "cover" }}
                        image={item.image}
                      />


                    </Link>
                    <CardContent>

                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography gutterBottom style={{ fontSize: 15 }} component="div">
                          {item.course_name}
                        </Typography>
                      </div>
                      <div> Duration: <Button size="small">{item.duration}</Button></div>
                      <div style={{ color: "green", fontWeight: 500 }}> {item.status} </div>
                    </CardContent>
                  </Card>
                );
              })}
            </Grid>
          </Grid>
        }


        {/*----------------------------------------------------------------------------------------------------------*/}
        {/*--ADD--*/}
        <Dialog
          open={this.state.addcourse}
          maxWidth="xl"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{}}
        >
          <Grid style={{ minWidth: "300px", height: "600px" }}>
            <div id="form-dialog-tt">
              <h2 style={{ position: "relative" }}>Create Course</h2>
              <Box>
                <button
                  style={{
                    marginLeft: "540px",
                    marginTop: "20px",
                    marginRight: "20px",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  <CloseOutlinedIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ addcourse: false });
                    }}
                  />
                </button>
              </Box>
            </div>
            <Grid>
              <Typography
                style={{
                  fontSize: "20px",
                  marginLeft: "21%",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1%",
                  height: "8rem",
                }}
              >
                <Box
                  style={{
                    width: "343px",
                    height: "103px",
                    marginLeft: "7%",
                    border: "1px solid grey",
                    backgroundColor: "#FCFCFC",
                  }}
                >
                  <label
                    htmlFor="file"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "2rem",
                      position: "relative",
                      left: "4rem",
                    }}
                  >
                    {/* Upload Image  */}





                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" cursor="pointer" viewBox="0 0 27 27" fill="none">
                      <g clip-path="url(#clip0_271_8272)">
                        <path d="M22.5 6.75H13.5L11.25 4.5H4.5C3.2625 4.5 2.26125 5.5125 2.26125 6.75L2.25 20.25C2.25 21.4875 3.2625 22.5 4.5 22.5H22.5C23.7375 22.5 24.75 21.4875 24.75 20.25V9C24.75 7.7625 23.7375 6.75 22.5 6.75ZM22.5 20.25H4.5V9H22.5V20.25ZM9 14.6363L10.5863 16.2225L12.375 14.445V19.125H14.625V14.445L16.4137 16.2338L18 14.6363L13.5113 10.125L9 14.6363Z" fill="#8A8A8A" />
                      </g>
                      <defs>
                        <clipPath id="clip0_271_8272">
                          <rect width="27" height="27" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <Typography style={{ fontSize: "18px", marginTop: "1%", cursor: "pointer" }}>
                  
                      {this.state.image !== "" ? (this.state.image).name : "Uploaded Image "}
                    </Typography>
                  </label>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="file"
                    onChange={(e) => {
                      this.setState({ image: e.target.files[0] });
                    }}
                  />
                </Box>
              </Typography>

              <Typography
                style={{
                  fontSize: "20px",
                  marginLeft: "19%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="form-13-tt">
                  <div className="form-12-tt">
                    <div className="form-11-tt">
                      <Typography className="form-10-tt">Title:</Typography>
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "12rem",
                            height: "2.6rem",
                            backgroundColor: "#EFEFEF99",
                            marginLeft: "5%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Enter Class Name"
                          onChange={(text) =>
                            this.setState({ course_name: text.target.value })
                          }
                        />
                      </FormControl>
                    </div>
                    <div className="form-11-tt">
                      <Typography className="form-10-tt">Duration:</Typography>
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "12rem",
                            backgroundColor: "#EFEFEF99",
                            height: "2.6rem",
                            marginLeft: "5%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Enter Duration"
                          onChange={(text) =>
                            this.setState({ duration: text.target.value })
                          }
                        />
                      </FormControl>
                    </div>
                    {/* <div className="form-11-tt">
                      <Typography className="form-10-tt">
                        Course Code:
                      </Typography>
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "12rem",
                            backgroundColor: "#EFEFEF99",
                            height: "2.6rem",
                            marginLeft: "5%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Enter Courese Code"
                          onChange={(text) =>
                            this.setState({ course_code: text.target.value })
                          }
                        />
                      </FormControl>
                    </div> */}
                  </div>
                  <div className="form-12-tt">

                    <div className="form-11-tt">
                      <Typography className="form-10-tt">
                        Course Code:
                      </Typography>
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "12rem",
                            backgroundColor: "#EFEFEF99",
                            height: "2.6rem",
                            marginLeft: "5%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Enter Courese Code"
                          onChange={(text) =>
                            this.setState({ course_code: text.target.value })
                          }
                        />
                      </FormControl>
                    </div>

                    {/* <div className="form-11-tt">
                      <Typography className="form-10-tt">
                        Batch Size:
                      </Typography>
                      <FormControl
                        sx={{
                          "& > :not(style)": {
                            width: "12rem",
                            backgroundColor: "#EFEFEF99",
                            height: "2.6rem",
                            marginLeft: "5%",
                          },
                        }}
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Enter Batch Size"
                          onChange={(text) =>
                            this.setState({ batch_size: text.target.value })
                          }
                        />
                      </FormControl>
                    </div> */}

                  </div>
                </div>
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  marginTop: "1rem",
                  marginLeft: "19%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="form-desc-tt">
                  <div style={{ fontSize: "1rem" }}>Description</div>
                  <Textarea
                    style={{
                      width: "28.5rem",
                      height: "5rem",
                      border: "1px solid node",
                      backgroundColor: "#EFEFEF99",
                      color: "black",
                    }}
                    name="Solid"
                    placeholder="Type in here…"
                    variant="solid"
                    onChange={(text) =>
                      this.setState({ desc: text.target.value })
                    }
                  />
                </div>
              </Typography>

              <center style={{ marginBottom: 30 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ addcourse: false });
                  }}
                  style={{
                    border: "1px solid #0000B3",
                    fontSize: "13px",
                    width: "130px",
                    color: "#0000B3",
                    height: "35px",
                    borderRadius: "8px",
                  }}
                >
                  Cancel
                </Button>
                <button
                  // onClick={() => {
                  //   this.setState({ addcourse: false });
                  // }}
                  onClick={() => {
                    this.props.uploadImageCourse(this.props.login.user_id, this.state.image, this.state.course_name, this.state.duration, this.state.course_code, this.state.desc, this.state.page, this.state.rowsPerPage, this.props.login.org_id);
                    this.setState({ addcourse: false, image: "", course_name: "", duration: "", course_code: "" });
                  }}
                  style={{
                    backgroundColor: "#0000B3",
                    fontSize: "13px",
                    marginTop: "4%",
                    width: "130px",
                    marginLeft: "3%",
                    height: "35px",
                    color: "white",
                    borderRadius: "8px",
                  }}
                  variant="contained"
                >
                  Create Course
                </button>
              </center>
            </Grid>
          </Grid>
        </Dialog>

        {/*--EDIT--*/}
        <Dialog
          open={this.state.editcourse}
          maxWidth="xl"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <h2 style={{ position: "relative", marginLeft: "20px" }}>Edit Course</h2>
          <Grid container direction={"column"} spacing={2}>
            <Grid style={{ minWidth: "300px", height: "600px" }}>
              <Grid xs={1}></Grid>
              <Grid xs={10}>
                <div id="form-dialog-tt">
                  <Box>
                    <button
                      style={{
                        marginLeft: "540px",
                        marginTop: "20px",
                        marginRight: "20px",
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <CloseOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.setState({ editcourse: false });
                        }}
                      />
                    </button>
                  </Box>
                </div>
                <Grid>
                  <Typography
                    style={{
                      fontSize: "20px",
                      marginLeft: "21%",
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "1%",
                      height: "8rem",
                    }}
                  >
                    <Box
                      style={{
                        width: "343px",
                        height: "103px",
                        marginLeft: "7%",
                        border: "1px solid grey",
                        backgroundColor: "#FCFCFC",
                      }}
                    >
                      <label
                        htmlFor="file"

                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "2rem",
                          position: "relative",
                          left: "4rem",
                        }}
                      >
                        <AttachFileIcon
                          style={{ marginLeft: "3%", marginTop: "3%" }}
                        />
                        <Typography style={{ fontSize: "18px", marginTop: "3%" }}>
                          {this.state.image !== "" ? (this.state.image).name : "Change"}
                        </Typography>
                      </label>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="file"
                        onChange={(e) => { this.setState({ image: e.target.files[0] }) }}
                      />
                    </Box>
                  </Typography>

                  <Typography
                    style={{
                      fontSize: "20px",
                      marginLeft: "19%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div className="form-13-tt">
                      <div className="form-12-tt">
                        <div className="form-11-tt">
                          <Typography className="form-10-tt">Title:</Typography>
                          <FormControl
                            sx={{
                              "& > :not(style)": {
                                width: "12rem",
                                height: "2.6rem",
                                backgroundColor: "#EFEFEF99",
                                marginLeft: "5%",
                              },
                            }}
                          >
                            <OutlinedInput
                              type="text"
                              placeholder="Enter Class Name"

                              value={this.state.course_name}
                              onChange={(e) => { this.setState({ course_name: e.target.value }) }}
                            />
                          </FormControl>
                        </div>
                        <div className="form-11-tt">
                          <Typography className="form-10-tt">
                            Course Code:
                          </Typography>
                          <FormControl
                            sx={{
                              "& > :not(style)": {
                                width: "12rem",
                                backgroundColor: "#EFEFEF99",
                                height: "2.6rem",
                                marginLeft: "5%",
                              },
                            }}
                          >
                            <OutlinedInput
                              type="text"
                              placeholder="Enter Courese Code"
                              value={this.state.course_code}
                              onChange={(e) => { this.setState({ course_code: e.target.value }) }}
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="form-12-tt">

                        <div className="form-11-tt">
                          <Typography className="form-10-tt">Duration:</Typography>
                          <FormControl
                            sx={{
                              "& > :not(style)": {
                                width: "12rem",
                                backgroundColor: "#EFEFEF99",
                                height: "2.6rem",
                                marginLeft: "5%",
                              },
                            }}
                          >
                            <OutlinedInput
                              type="text"
                              placeholder="Enter Duration"
                              value={this.state.duration}
                              onChange={(e) => { this.setState({ duration: e.target.value }) }}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </Typography>

                  <Typography
                    style={{
                      fontSize: "20px",
                      marginTop: "1rem",
                      marginLeft: "19%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div className="form-desc-tt">
                      <div style={{ fontSize: "1rem" }}>Description</div>
                      <Textarea
                        style={{
                          width: "28.5rem",
                          height: "5rem",
                          border: "1px solid node",
                          backgroundColor: "#EFEFEF99",
                          color: "black",
                        }}
                        name="Solid"
                        placeholder="Type in here…"
                        variant="solid"
                        value={this.state.desc}
                        onChange={(e) => { this.setState({ desc: e.target.value }) }}
                      />
                    </div>
                  </Typography>

                  <center style={{ marginBottom: 30 }}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        this.setState({ editcourse: false });
                      }}
                      style={{
                        border: "1px solid #0000B3",
                        fontSize: "13px",
                        width: "130px",
                        color: "#0000B3",
                        height: "35px",
                        marginLeft: "8%",
                        borderRadius: "8px",
                      }}
                    >
                      Cancel
                    </Button>
                    <button

                      onClick={() => {
                        this.setState({ editcourse: false });


                        this.props.editUploadImageCourse(this.state.course_id, this.state.image, this.state.old_image, this.state.course_name, this.state.duration, this.state.course_code, this.state.desc, this.state.page, this.state.rowsPerPage, this.props.login.org_id)
                        console.log("courrrrrrseedit", this.state.course_id, this.state.image, this.state.old_image, this.state.course_name, this.state.duration, this.state.course_code, this.state.desc, this.state.page, this.state.rowsPerPage, this.props.login.org_id)
                      }}
                      style={{
                        backgroundColor: "#0000B3",
                        fontSize: "13px",
                        marginTop: "4%",
                        width: "130px",
                        marginLeft: "3%",
                        height: "35px",
                        color: "white",
                        borderRadius: "8px",
                      }}
                      variant="contained"
                    >
                      Update
                    </button>
                  </center>
                </Grid>
              </Grid>

              <Grid xs={1}></Grid>
            </Grid>
          </Grid>
        </Dialog>

        {/*--DELETE--*/}
        <Dialog
          open={this.state.deleteCourse} style={{}}>
          <Grid style={{ height: "250px", width: "520px" }}>
            <button
              style={{
                marginLeft: "440px",
                marginTop: "20px",
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <CloseOutlinedIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setState({ deleteCourse: false, cursor: "pointer" });
                }}
              />
            </button>
            <Box>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 600,
                }}
              >
                Are you sure you want to delete ?
              </Typography>
              <Typography
                id="modal-modal-description"
                style={{
                  marginTop: "2%",
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  color: "black",
                }}
              >
                This process will lead to loss of entire data, proceed at yours
                own risk
              </Typography>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState({ deleteCourse: false });
                  }}
                  style={{
                    height: "2rem",
                    color: "#0000B3",
                    borderRadius: "8px",
                    borderColor: "#0000B3",
                  }}
                >
                  No
                </Button>
                <Button
                  variant="contained"

                  onClick={() => {
                    this.setState({ deleteCourse: false });
                    this.props.delete_course(this.state.course_id, this.state.page, this.state.rowsPerPage, this.props.login.org_id)
                    console.log("deletecourse", this.state.course_id, this.state.page, this.state.rowsPerPage, this.props.login.org_id)
                  }}
                  style={{
                    marginLeft: 10,
                    height: "2rem",
                    backgroundColor: "#0000B3",
                    color: "white",
                    borderRadius: "8px",
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Grid>
        </Dialog>
        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </Grid>
    );
  }
}
