import React, { Component } from "react";
import add from "../../Images/add.png";
import "../styles/addplacement.css";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { AiOutlineSearch } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { FaGreaterThan } from "react-icons/fa";
import avatar from "../../Images/Avatar.png";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import {
    Card,
    FormControl,
    Grid,
    IconButton,
    InputBase,
    Table,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextareaAutosize,
    TableBody,
    TableContainer,
    DialogActions,
    DialogTitle,
    DialogContent,
    Dialog,
    TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../styles/Placement.scss";
import EditIcon from "@mui/icons-material/Edit";
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";

export default class addplacement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deletecompany: false,
            addplacementOpen: false,
            editplacementOpen: false,

            department: "",
            job_profile: "",
            last_date: "",
            description: "",
            apply_link: "",
            no_vacancy: "",

            page: 0,
            setPage: 0,
            rowsPerPage: 10,
            setRowsPerPage: "",
            anchorEl: null,
            open: false,

            addplacementdata: [
                {
                    number: "1",
                    vacancy: "10",
                    jobprofile: "Android Devolopement",
                    lastdate: "12-05-23",
                },
                {
                    number: "1",
                    vacancy: "10",
                    jobprofile: "Android Devolopement",
                    lastdate: "12-05-23",
                },
                {
                    number: "1",
                    vacancy: "10",
                    jobprofile: "Android Devolopement",
                    lastdate: "12-05-23",
                },
                {
                    number: "1",
                    vacancy: "10",
                    jobprofile: "Android Devolopement",
                    lastdate: "12-05-23",
                },
            ],
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F9FDFE";
        this.props.viewAllPlacement(
            this.props.placement.company_id,
            this.state.page,
            this.state.rowsPerPage,
            this.props.login.org_id
        );
        console.log("placeeeeeeeee", this.props.placement.all_placements)
    }

    render() {
        const { placement, snackbar, close_snack_bar,navigate } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const handleChangePage = (event, newPage) => {
            this.props.viewAllPlacement(
                this.props.placement.company_id,
                newPage - 1,
                this.state.rowsPerPage,
                this.props.login.org_id
            );

            this.setState({
                page: newPage,
            });
        };
        return (
            <Grid style={{ marginTop: "10%" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Link style={{ textDecoration: "none" }} to="/Company">
                        <Button style={{ color: "#7A7E86" }}>Placement Company</Button>
                    </Link>
                    <ArrowForwardIosIcon
                        style={{ fontSize: "20px", marginTop: "10px" }}
                    />
                    <Link style={{ textDecoration: "none" }} to="">
                        <Button style={{ color: "black" }}>Company Name</Button>
                    </Link>
                </div>
                <Card style={{ margin: "1% 2.5% 2.5% 0%" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "2%",
                        }}
                    >
                        <Typography variant="h4" margin={"1% 0% 0% 2%"}>
                            {this.props.placement.company_name}
                        </Typography>
                        <Button
                            onClick={() => {
                                this.setState({
                                    addplacementOpen: true,
                                });
                            }}
                            style={{
                                height: "2.25rem",
                                marginLeft: "auto",
                                marginTop: "1.5%",
                                marginRight: "2.5%",
                                color: "#0000B3",
                            }}
                            variant="text"
                        >
                            <img src={add} alt="" srcset="" />
                            Add Placement
                        </Button>
                    </div>
                    <div>
                        <TableContainer>
                            <Table aria-label="a dense table">
                                <TableHead>
                                    <TableRow style={{ backgroundColor: "#F8F7F7" }}>
                                        <TableCell
                                            style={{ textAlign: "center", color: "#766C6C" }}
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            style={{ textAlign: "center", color: "#766C6C" }}
                                        >
                                            Vacancy
                                        </TableCell>
                                        <TableCell
                                            style={{ textAlign: "center", color: "#766C6C" }}
                                        >
                                            Job Profile
                                        </TableCell>
                                        <TableCell
                                            style={{ textAlign: "center", color: "#766C6C" }}
                                        >
                                            Late Date
                                        </TableCell>
                                        <TableCell
                                            style={{ textAlign: "center", color: "#766C6C" }}
                                        >
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                             

                                {Array.isArray(this.props.placement.all_placements) &&
                                    placement.all_placements.map((item, index) => {
                                        return (
                                            <TableRow
                                                style={{
                                                    textDecoration: "none",
                                                    borderBottom: "1px solid #E3E3E3",
                                                }}
                                            >
                                                <TableCell
                                                    style={{
                                                        color: "#5F5A5A",
                                                        fontSize: "15px",
                                                        letterSpacing: 1,
                                                        textAlign: "center",
                                                    }}
                                                    // onClick={() => {
                                                    //     this.props.navigate("/addplacement");
                                                    // }}
                                                >
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        color: "#5F5A5A",
                                                        fontSize: "15px",
                                                        letterSpacing: 1,
                                                        textAlign: "center",
                                                    }}
                                                    // onClick={() => {
                                                    //     this.props.navigate("/addplacement");
                                                    // }}
                                                >
                                                    {item.no_vacancy}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        color: "#5F5A5A",
                                                        fontSize: "15px",
                                                        letterSpacing: 1,
                                                        textAlign: "center",
                                                    }}
                                                    // onClick={() => {
                                                    //     this.props.navigate("/addplacement");
                                                    // }}
                                                >
                                                    {item.job_profile}
                                                </TableCell>
                                                {/* <TableCell style={{ textAlign: "center", fontSize: "16px" }} onClick={() => { this.props.navigate('/addplacement') }}>{item.applylink}</TableCell> */}
                                                <TableCell
                                                    style={{
                                                        color: "#5F5A5A",
                                                        fontSize: "15px",
                                                        letterSpacing: 1,
                                                        textAlign: "center",
                                                    }}
                                                    onClick={() => {
                                                        this.props.navigate("/addplacement");
                                                    }}
                                                >
                                                    {item.date}
                                                </TableCell>
                                                <TableCell
                                                    style={{ textAlign: "center", fontSize: "16px" }}
                                                >
                                                    <span style={{ color: "#3B3BC4", marginRight: 10 }}>
                                                        <EditIcon
                                                            onClick={() => {
                                                                this.setState({
                                                                    editplacementOpen: true,
                                                                    department: item.department,
                                                                    job_profile: item.job_profile,
                                                                    last_date: item.last_date,
                                                                    description: item.description,
                                                                    apply_link: item.apply_link,
                                                                    no_vacancy: item.no_vacancy,
                                                                    placement_id: item._id,

                                                                });
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </span>
                                                    <Link to="/Jobprofile">
                                                        <span style={{ color: "#A76D42", marginRight: 10 }}>
                                                            <VisibilityOutlinedIcon
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    this.props.setPlacementId(item._id);
                                                                    this.props.setApplyLink(item.apply_link);
                                                                    this.props.setLastDate(item.last_date);
                                                                    this.props.setDescription(item.description);
                                                                    this.props.setDescription(item.description);
                                                                    this.props.setJobProfile(item.job_profile);
                                                                }}
                                                            />
                                                        </span>
                                                    </Link>
                                                    <span style={{ color: "#C43232" }}>
                                                        <DeleteOutlineOutlinedIcon
                                                            onClick={() => {
                                                                this.setState({
                                                                    deletecompany: true,
                                                                    placement_id: item._id,
                                                                });
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </Table>
                        </TableContainer>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "2% 2% 2% 0%",
                        }}
                    >
                        <Pagination
                            count={parseInt(placement.placement_length / 10) + 1}
                            siblingCount={0}
                            size="small"
                            onChange={handleChangePage}
                        />
                    </div>
                </Card>

                {/*------------------------------------------------ Placement Dialog ---------------------------------------------------------*/}

                {/*Add*/}
                <Dialog
                    open={this.state.addplacementOpen}
                    maxWidth="xl"
                    fullWidth
                    sx={{
                        // 👇 Another option to style Paper
                        "& .MuiDialog-paper": {
                            borderRadius: "10px",
                        },
                    }}
                >
                    <Grid>
                        <DialogTitle>
                            <Grid container>
                                <Grid item xs={11}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            fontWeight: "500",
                                            color: "#222222",
                                            letterSpacing: 1,
                                        }}
                                    >
                                        Placement
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} direction={"column"}>
                                    <Grid container>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Company Name
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Company Name"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    value={this.props.placement.company_name}
                                                    readOnly
                                                    disabled
                                                ></TextField>
                                            </div>
                                        </Grid>
                                        <Grid item xs={0.3} direction={"row"}></Grid>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Company Email
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Company Name"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    value={this.props.placement.company_email}
                                                    readOnly
                                                    disabled
                                                // onChange={(text) =>
                                                //     this.setState({ student_name: text.target.value })}
                                                ></TextField>
                                            </div>
                                        </Grid>
                                        <Grid item xs={0.3} direction={"row"}></Grid>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Department
                                                </Typography>

                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Department Name"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    onChange={(text) =>
                                                        this.setState({ department: text.target.value })
                                                    }
                                                ></TextField>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Job Profile
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Job Profile"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    onChange={(text) =>
                                                        this.setState({ job_profile: text.target.value })
                                                    }
                                                ></TextField>
                                            </div>
                                        </Grid>
                                        <Grid item xs={0.3} direction={"row"}></Grid>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Vacancy
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Vacancy"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    onChange={(text) =>
                                                        this.setState({ no_vacancy: text.target.value })
                                                    }
                                                ></TextField>
                                            </div>
                                        </Grid>
                                        <Grid item xs={0.3} direction={"row"}></Grid>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Apply Link
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Apply Link"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    onChange={(text) =>
                                                        this.setState({ apply_link: text.target.value })
                                                    }
                                                ></TextField>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Last Date
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    type="date"
                                                    placeholder="Enter Last Date"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    onChange={(text) =>
                                                        this.setState({ last_date: text.target.value })
                                                    }
                                                ></TextField>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        marginTop: "2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Job Description
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Description"
                                                    multiline
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    onChange={(text) =>
                                                        this.setState({ description: text.target.value })
                                                    }
                                                ></TextField>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: 6,
                                marginTop: 6,
                            }}
                        >
                            <Button
                                variant="outlined"
                                style={{
                                    fontSize: "15px",
                                    height: "38px",
                                    width: "120px",
                                    color: "#0000B3",
                                    border: "1px solid #0000B3",
                                }}
                                onClick={() => {
                                    this.setState({ addplacementOpen: false });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#0000B3",
                                    fontSize: "15px",
                                    height: "38px",
                                    width: "160px",
                                    color: "white",
                                    marginLeft: "2%",
                                }}
                                disabled={
                                    this.state.department == "" ||
                                    this.state.job_profile == "" ||
                                    this.state.last_date == "" ||
                                    this.state.apply_link == "" ||
                                    this.state.no_vacancy == ""
                                }
                                onClick={() => {
                                    this.setState({
                                        addplacementOpen: false,
                                        department: "",
                                        job_profile: "",
                                        last_date: "",
                                        apply_link: "",
                                        no_vacancy: "",
                                    });
                                    
                                    this.props.addPlacement(
                                        
                                        this.props.login.user_id,
                                        this.props.placement.company_id,
                                        this.state.department,
                                        this.state.job_profile,
                                        this.state.last_date,
                                        this.state.description,
                                        this.state.apply_link,
                                        this.state.no_vacancy,
                                        this.state.page,
                                        this.state.rowsPerPage,
                                        this.props.login.org_id
                                    );
                                    console.log("asdfghj", this.props.login.user_id,
                                    this.props.placement.company_id,
                                    this.state.department,
                                    this.state.job_profile,
                                    this.state.last_date,
                                    this.state.description,
                                    this.state.apply_link,
                                    this.state.no_vacancy,
                                    this.state.page,
                                    this.state.rowsPerPage,
                                    this.props.login.org_id)
                                }}
                            >
                                Add
                            </Button>
                        </DialogActions>
                    </Grid>
                </Dialog>

                {/*Edit*/}
                <Dialog
                    open={this.state.editplacementOpen}
                    maxWidth="xl"
                    fullWidth
                    sx={{
                        // 👇 Another option to style Paper
                        "& .MuiDialog-paper": {
                            borderRadius: "10px",
                        },
                    }}
                >
                    <Grid>
                        <DialogTitle>
                            <Grid container>
                                <Grid item xs={11}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            fontWeight: "500",
                                            color: "#222222",
                                            letterSpacing: 1,
                                        }}
                                    >
                                        Placement
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} direction={"column"}>
                                    <Grid container>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Company Name
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Company Name"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    value={this.props.placement.company_name}
                                                    readOnly
                                                    disabled
                                                ></TextField>
                                            </div>
                                        </Grid>
                                        <Grid item xs={0.3} direction={"row"}></Grid>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Company Email
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Company Name"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    value={this.props.placement.company_email}
                                                    readOnly
                                                    disabled
                                                ></TextField>
                                            </div>
                                        </Grid>
                                        <Grid item xs={0.3} direction={"row"}></Grid>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Department
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Department Name"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    value={this.state.department}
                                                    onChange={(e) => {
                                                        this.setState({ department: e.target.value });
                                                    }}
                                                ></TextField>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Job Profile
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Job Profile"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    value={this.state.job_profile}
                                                    onChange={(e) => {
                                                        this.setState({ job_profile: e.target.value });
                                                    }}
                                                ></TextField>
                                            </div>
                                        </Grid>
                                        <Grid item xs={0.3} direction={"row"}></Grid>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Vacancy
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Vacancy"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    value={this.state.no_vacancy}
                                                    onChange={(e) => {
                                                        this.setState({ no_vacancy: e.target.value });
                                                    }}
                                                ></TextField>
                                            </div>
                                        </Grid>
                                        <Grid item xs={0.3} direction={"row"}></Grid>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Apply Link
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Apply Link"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    value={this.state.apply_link}
                                                    onChange={(e) => {
                                                        this.setState({ apply_link: e.target.value });
                                                    }}
                                                ></TextField>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={3.8} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        margin: "5% 0% 0% 2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Last Date
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    type="date"
                                                    placeholder="Enter Company Name"
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    value={this.state.last_date}
                                                    onChange={(e) => {
                                                        this.setState({ last_date: e.target.value });
                                                    }}
                                                ></TextField>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} direction={"row"}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        marginTop: "2%",
                                                        fontWeight: 600,
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    Job Description
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Company Name"
                                                    multiline
                                                    sx={{
                                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                        borderRadius: "5px",
                                                        backgroundColor: "rgba(239, 239, 239, 0.60)",
                                                    }}
                                                    value={this.state.description}
                                                    onChange={(e) => {
                                                        this.setState({ description: e.target.value });
                                                    }}
                                                ></TextField>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: 6,
                                marginTop: 6,
                            }}
                        >
                            <Button
                                variant="outlined"
                                style={{
                                    fontSize: "15px",
                                    height: "38px",
                                    width: "120px",
                                    color: "#0000B3",
                                    border: "1px solid #0000B3",
                                }}
                                onClick={() => {
                                    this.setState({ editplacementOpen: false });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#0000B3",
                                    fontSize: "15px",
                                    height: "38px",
                                    width: "160px",
                                    color: "white",
                                    marginLeft: "2%",
                                }}
                                disabled={
                                    this.state.department == "" ||
                                    this.state.job_profile == "" ||
                                    this.state.last_date == "" ||
                                    this.state.apply_link == "" ||
                                    this.state.no_vacancy == ""
                                }
                                onClick={() => {
                                    this.setState({
                                        editplacementOpen: false,
                                        department: "",
                                        job_profile: "",
                                        last_date: "",
                                        apply_link: "",
                                        no_vacancy: "",
                                    });
                                    this.props.editPlacement(
                                        this.state.placement_id,
                                        this.props.placement.company_id,
                                        this.state.department,
                                        this.state.job_profile,
                                        this.state.last_date,
                                        this.state.description,
                                        this.state.apply_link,
                                        this.state.no_vacancy,
                                        this.state.page,
                                        this.state.rowsPerPage,
                                        this.props.login.org_id
                                    );
                                }}
                            >
                                Update
                            </Button>
                        </DialogActions>
                    </Grid>
                </Dialog>

                {/*Delete*/}
                <Dialog
                    open={this.state.deletecompany}
                    maxWidth="sm"
                    fullWidth
                    sx={{
                        // 👇 Another option to style Paper
                        "& .MuiDialog-paper": {
                            borderRadius: "10px",
                        },
                    }}
                >
                    <Grid>
                        <DialogTitle style={{ marginBottom: "1.5%" }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        style={{
                                            fontSize: "22px",
                                            fontWeight: "600",
                                            color: "#222222",
                                        }}
                                    >
                                        Delete
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent
                            style={{ flexDirection: "row", textAlign: "center" }}
                        >
                            <Grid container direction={"column"} spacing={1}>
                                <Grid item xs={12}>
                                    <Typography
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: 600,
                                            fontFamily: "",
                                        }}
                                    >
                                        Are you sure you want to delete ?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        This process will lead to loss of entire data, proceed at
                                        yours own risk
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "5% 0% 5% 0%",
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ deletecompany: false });
                                }}
                                style={{
                                    fontSize: "15px",
                                    height: "38px",
                                    width: "120px",
                                    color: "#0000B3",
                                    border: "1px solid #0000B3",
                                }}
                            >
                                No
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    this.setState({ deletecompany: false });
                                    this.props.deletePlacement(
                                        this.props.login.user_id,
                                        this.state.placement_id,
                                        this.state.page,
                                        this.state.rowsPerPage,
                                        this.props.placement.company_id,
                                        this.props.login.org_id
                                    );
                                }}
                                style={{
                                    backgroundColor: "#0000B3",
                                    fontSize: "15px",
                                    height: "38px",
                                    width: "120px",
                                    color: "white",
                                    marginLeft: "2%",
                                }}
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Grid>
                </Dialog>

                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
            </Grid>
        );
    }
}
