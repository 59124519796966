import {
    ATTENDANCE,
    SET_MONTH_DAYS,
} from "./constant";

const initial_state = {
    all_attendance: [],
    days:[],
    attendance_length: 0

};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case ATTENDANCE:
            console.log("attendence", action.payload)
            return {
                ...state,
                all_attendance: action.payload,
            };
        case SET_MONTH_DAYS:
            console.log("attendence", action.payload.data)
            return {
                ...state,
                days: action.payload
            };
        default:
            return state;
    }
}