
import React, { Component } from 'react';
// import Razorpay from 'razorpay'
import { Grid, Box, TextField, Button } from '@mui/material';
import { InputLabel, Typography } from '@material-ui/core';
import { DateField } from '@mui/x-date-pickers/DateField';
import Input from '@mui/material/Input';
// import TechLogo from "../image/technologo.png";
// import Pay from "../image/pay.jpg";
import tb2 from "../../Images/tb (2).jpeg"
import tb from "../../Images/tb (1).jpeg"
import "../../Payment/Components/pay.css"

export default class Pay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            contact: '',
            paymentAmount: 10000, // Replace with the actual payment amount
        };
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    render() {

        return (
            <div>
                <div>
                    <h2>Payment Details</h2>
                    <div>
                        <label>Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div>
                        <label>Contact:</label>
                        <input
                            type="text"
                            name="contact"
                            value={this.state.contact}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div>
                        <label>Payment Amount:</label>
                        <span>INR {this.state.paymentAmount / 100}</span>
                    </div>
                    <button onClick={this.handlePayment}>Pay with Razorpay</button>
                </div>
            </div>
        )
    }
}
