import { ATTENDANCE } from './constant'
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import { SET_MONTH_DAYS } from "./constant"
import 'firebase/storage';

import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

//--------------------------------add attendance -------------------------------------------//

export function viewAllAttendance(admin_id, attendance_type, page_number, rowper_page) {
    return (dispatch) => {
        console.log("ddd")
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_attendance", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                attendance_type: attendance_type
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: ATTENDANCE, payload: { date: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: ATTENDANCE, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });

    };

}

export function view_monthly_attendance_student(batch_id, date) {
    return (dispatch) => {
        console.log("ddd")
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_monthly_attendance_student", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                batch_id: batch_id,
                date: date,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    console.log(responseJson.result)
                    dispatch({ type: ATTENDANCE, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: ATTENDANCE, payload: [] })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });

    };

}

export function view_monthly_attendance_trainers(batch_id, date) {
    return (dispatch) => {
        console.log("ddd")
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_monthly_attendance_trainers", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                batch_id: batch_id,
                date: date,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    console.log(responseJson.result)
                    dispatch({ type: ATTENDANCE, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: ATTENDANCE, payload: [] })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };

}

export function setMonthDays(payload) {
    return {
        type: SET_MONTH_DAYS,
        payload: payload,
    };
}