import {
    SEARCH_CLASS, CLASSES
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';

import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { roRO } from "@mui/material/locale";


{/*------------------------------------------------------ Search  ------------------------------------------------------------*/ }
export function searchClass(classes, e, type) {
    return (dispatch) => {
        if (e == "" || e == "N") {
            dispatch({ type: SEARCH_CLASS, payload: classes });
        }
        else if (type == "search") {
            const newArray = classes.filter((el) => {
                return (el.course_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_CLASS, payload: newArray })
        }
        else if (type == "course") {
            const newArray = classes.filter((el) => {
                return (el.course_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_CLASS, payload: newArray })
        }
        else if (type == "batch") {
            const newArray = classes.filter((el) => {
                return (el.batch_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_CLASS, payload: newArray })
        }
    };
}

// filter schedule
export function filterClass(classes, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_CLASS, payload: classes });
        }
        else {
            const newArray = classes.filter((el) => {
                if (el.classes !== null) {
                    console.log("tic", el.date)
                    return ((el.date).toString().includes((e).toString()));
                }
            })

            dispatch({ type: SEARCH_CLASS, payload: newArray })

        }
    };
}

{/*------------------------------------------------------ Viewall  ------------------------------------------------------------*/ }
export function viewScheduleClassesByTrainer(trainer_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_trainer_class_schedule", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                trainer_id: trainer_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("restrainer", responseJson)
                if (responseJson.status) {
                    dispatch({ type: CLASSES, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: CLASSES, payload: [] })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}