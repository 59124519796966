import {
  VIEW_SUBMIT_ASSIGN, ASSIGNMENT_DETAIL, ASSIGNMENT_ID_DETAIL
} from "./constant";

const initial_state = {
  submit_assign: [],
  notice_details: [],

};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case VIEW_SUBMIT_ASSIGN:
      console.log("viewsubmitassignment",action.payload)
      return {
        ...state,
        submit_assign: action.payload
      };
    case ASSIGNMENT_DETAIL:
      return {
        ...state,
        assignment_details: action.payload
      };
    case ASSIGNMENT_ID_DETAIL:
      return {
        ...state,
        assignment_id_details: action.payload
      };
    default:
      return state;
  }
}
