import { TICKETS, SEARCH_TICKETS, TICKET_BY_ID } from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import 'firebase/storage';
import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { roRO } from "@mui/material/locale";

{/*------------------------------------------------------------- DATE FILTER -------------------------------- ------------------------*/ }
export function dateFilter(all_tickets, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_TICKETS, payload: all_tickets });

        }
        else {
            const newArray = all_tickets.filter((el) => {
                if (el.all_tickets !== null) {
                    return ((el.date).toString().includes((e).toString()));
                }
            })

            dispatch({ type: SEARCH_TICKETS, payload: newArray })

        }
    };
}

{/*------------------------------------------------------------ VIEWALL --------------------------------------------------------------*/ }
export function viewAllTicket(page_number, rowper_page, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_ticket", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                org_id:org_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("res", responseJson)
                if (responseJson.status) {
                    dispatch({ type: TICKETS, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: TICKETS, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

{/*--------------------------------------------------------- VIEW BY ID --------------------------------------------------------------*/ }
export function updateTicketStatusByTicketID(ticket_id, status, page_number, rowper_page,org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_ticket_status_by_ticketID", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ticket_id: ticket_id,
                status: status
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllTicket(page_number, rowper_page,org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

{/*-------------------------------------------------------- VIEWALL BY ID ------------------------------------------------------------*/ }
export function viewTicketByUserId(user_id, page_number, rowper_page,org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_ticket_by_user_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                user_id: user_id
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page,
                org_id:org_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: TICKET_BY_ID, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: TICKET_BY_ID, payload: { data: [], count: 0 } })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

{/*---------------------------------------------------------- FIREBASE ADD -----------------------------------------------------------*/}
export function uploadDocument(user_id, document, message, page_number, rowper_page,org_id) {
    return dispatch => {
        dispatch(setLoader(true));
        if (document !== "") {
            const storageRef = ref(getStorage(), "/Ticket/" + user_id + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, document);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    dispatch(setLoader(false));
                },
                
                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (document) {
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            dispatch(addTicket(user_id, document, message, page_number, rowper_page,org_id));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(addTicket(user_id, document, message, page_number, rowper_page,org_id));
        }
    }
}

{/*---------------------------------------------------------------- ADD --------------------------------------------------------------*/ }
export function addTicket(user_id, document, message, page_number, rowper_page ,org_id) {
    return (dispatch) => {
        console.log("Add", user_id, document, message, page_number, rowper_page,org_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_ticket", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                user_id: user_id
            },
            body: JSON.stringify({
                document: document,
                message: message,
                org_id:org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewTicketByUserId(user_id, page_number, rowper_page,org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

