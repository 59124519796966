import { Avatar, Card, Divider, Typography, Grid } from "@mui/material";
import React, { Component } from "react";
import { Button, Textarea } from "theme-ui";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import moment from "moment";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";

export default class discussion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            subject: [
                {
                    _id: "1724r3ytr83w7er934weyr843",
                    subject_name: "Html",
                },
                {
                    _id: "1724r3ytr83w7er934weyr843",
                    subject_name: "Html",
                },
                {
                    _id: "1724r3ytr83w7er934weyr843",
                    subject_name: "Html",
                },
                {
                    _id: "1724r3ytr83w7er934weyr843",
                    subject_name: "Html",
                },
                {
                    _id: "1724r3ytr83w7er934weyr843",
                    subject_name: "Html",
                },
                {
                    _id: "1724r3ytr83w7er934weyr843",
                    subject_name: "Html",
                },
                {
                    _id: "1724r3ytr83w7er934weyr843",
                    subject_name: "Html",
                },
                {
                    _id: "1724r3ytr83w7er934weyr843",
                    subject_name: "Html",
                },
                {
                    _id: "1724r3ytr83w7er934weyr843",
                    subject_name: "Html",
                },
                {
                    _id: "1724r3ytr83w7er934weyr843",
                    subject_name: "Html",
                },
                {
                    _id: "1724r3ytr83w7er934weyr843",
                    subject_name: "Html",
                },
            ],
            dics: [
                {
                    name: "Subhasmita Karna",
                    desc: "Lorem ipsum dolor sit amet. Sed neque velit eos fuga dolores estculpa alias aut quia cupiditate aut reiciendis voluptatum autfugit illum qui omnis eaque. Aut iure pariatur quo voluptatem corrupti ut totam voluptatum a voluptatum itaque ut atque dolores",
                    time: "6 hours ago",
                    reply: "Reply",
                },
                {
                    name: "Subhasmita Karna",
                    desc: "Lorem ipsum dolor sit amet. Sed neque velit eos fuga dolores estculpa alias aut quia cupiditate aut reiciendis voluptatum autfugit illum qui omnis eaque. Aut iure pariatur quo voluptatem corrupti ut totam voluptatum a voluptatum itaque ut atque dolores",
                    time: "6 hours ago",
                    reply: "Reply",
                },
                {
                    name: "Subhasmita Karna",
                    desc: "Lorem ipsum dolor sit amet. Sed neque velit eos fuga dolores estculpa alias aut quia cupiditate aut reiciendis voluptatum autfugit illum qui omnis eaque. Aut iure pariatur quo voluptatem corrupti ut totam voluptatum a voluptatum itaque ut atque dolores",
                    time: "6 hours ago",
                    reply: "Reply",
                },
            ],
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F9FDFE"
        this.props.viewAllDiscussion();
        console.log("l", this.props.discussion.discussion_length)
    }
    render() {
        const { snackbar, close_snack_bar } = this.props;
        return (
            <Grid style={{ marginTop: "10%" }}>
                <Card style={{ margin: "1% 2.5% 2.5% 0%" }}>
                    <Typography style={{ color: "black", fontSize: "29px", margin: "1% 0% 0% 2%", fontWeight: 450 }}>
                        Discussion
                    </Typography>
                    <div style={{ border: "1px solid #D6D6D6", margin: "20px" }}>
                        {Array.isArray(this.props.discussion.discussion_details) && this.props.discussion.discussion_details.map((item) => {
                            return (
                                <div >
                                    <div style={{ display: "flex", flexdirection: "row", padding: "15px" }}>
                                        <Avatar
                                            alt={item.user_details.name} src={item.user_details.profile_pic}
                                            style={{ width: 40, height: 40 }} >
                                        </Avatar>
                                        <Typography style={{ marginLeft: "2%" }}><span style={{ fontSize: "18px", fontWeight: 500 }}>{item.user_details.name}</span> <span style={{ color: "#0000B3" }}>( Student )</span></Typography>
                                    </div>
                                    <Typography
                                        style={{
                                            color: "#222",
                                            marginLeft: "25px"
                                        }}>
                                        {item.message}
                                    </Typography>
                                    <Typography
                                        style={{
                                            marginLeft: "25px",
                                            color: "gray",
                                            fontSize: "14px"
                                        }}
                                    >
                                        {moment().startOf(item.date).fromNow()}
                                    </Typography>
                                    <Divider sx={{ background: "#EEEEEE", padding: "0.2px", margin: "10px", borderBottom: "none" }} />
                                </div>
                            );
                        })}
                    </div>
                    <Divider style={{ width: "100%" }} />
                    <Grid container style={{ padding: "20px 0px 20px 0px" }} alignItems={"center"}>
                        <Grid item xs={9} justifyContent={"flex-start"}>
                            <TextField
                                multiline={4}
                                fullWidth
                                style={{ marginLeft: "25px" }}
                                placeholder="Type here…"
                                variant="outlined"
                                onChange={(text) =>
                                    this.setState({ message: text.target.value })}

                            />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} justifyContent={"flex-end"}>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#0000B3",
                                    width: "100px",
                                }}
                                onClick={() => {
                                    this.setState({ addDiscussion: false, message: "" });
                                    this.props.addDiscussion(this.props.login.user_id, this.state.message)
                                    console.log("dis", this.props.login.user_id, this.state.message)

                                }}
                            >
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
            </Grid>
        );
    }
}
