import {
    TRAINERS,
    SEARCH_TRAINER
} from "./constant";

const initial_state = {
    all_trainers: [],
    search_trainer: [],
    trainer_length: 0
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case TRAINERS:
            return {
                ...state,
                all_trainers: action.payload.data, search_trainer: action.payload.data, trainer_length: action.payload.count
            };
        case SEARCH_TRAINER:
            return {
                ...state,
                search_trainer: action.payload
            };
        default:
            return state;
    }
}
