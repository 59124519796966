import { DISCUSSION_DETAILS, DISCUSSION } from './constant'
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";


import 'firebase/storage';
import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

//----------------------------------add discussion-----------------------------------------//
export function addDiscussion(student_id, message) {
    return (dispatch) => {
        console.log("student", student_id, message)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_discussion", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                student_id: student_id,
                message: message
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllDiscussion());
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

//-------------------------view all discussion by batch id-------/

export function viewAllDiscussionById(discussion_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_discussion_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                discussion_id: discussion_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: DISCUSSION_DETAILS, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: DISCUSSION_DETAILS, payload: [] })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
//----------------------------------VIEW ALL DISCUSSION----------//
export function viewAllDiscussion() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_discussion", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson.status) {
                    dispatch({ type: DISCUSSION, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: DISCUSSION, payload: [] })
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
//-------------------------------------VIEW ALL DISCUSSION BY BATCH ID AND TYPE------------//
// export function viewAllDiscussionByBatchIdAndType(batch_id, type) {
//     return (dispatch) => {
//         dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "view_all_discussion_by_batch_id", {
//             method: "POST",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",

//             },
//             body: JSON.stringify({
//                 batch_id: batch_id,
//                 type: type
//             })
//         })
//             .then((response) => response.json())
//             .then((responseJson) => {
//                 if (responseJson.status) {
//                     dispatch({ type: DISCUSSION, payload: responseJson.result });
//                     dispatch(unsetLoader());
//                 } else {
//                     dispatch({ type: DISCUSSION, payload: [] })
//                     dispatch(unsetLoader());
//                 }
//                 dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }